import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCompanyQuery } from "../../app/companyApi";
import {
  close,
  openInfo,
  selectIsInfoOpen,
  selectIsOpen,
} from "../../app/myAiSlice";
import UserContext from "../../context/userContext";

import {
  CloseRounded as CloseRoundedIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useIntl } from "react-intl";
import { useLazyGetCompanyMediaBudgetQuery } from "../../app/companyMediaBudgetApi";
import { useLazyGetPromptActionQuery } from "../../app/myAiApi";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection";
import AppContext from "../../context/appContext";
import { getFormatters } from "../GridUtils";
import { useCreateScenarioMutation } from "../NewScenarioWizard/createScenarioApi";
import buildRequest from "./buildRequest";
import handleActions from "./handleActions";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoDialog from "./InfoDialog";
import { useGetUserQuery } from "../../app/userApi";

function MyAi() {
  const [prompt, setPrompt] = useState("");

  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);
  const [isRunningAction, setIsRunningAction] = useState(false);

  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);

  const {
    data: user,
    isLoading: userIsLoading,
    isFetching: userIsFetching,
    isSuccess: userIsSuccess,
  } = useGetUserQuery(
    {
      userId: userContext.userID,
    },
    {
      skip: userContext?.userID == null,
    }
  );

  const {
    data: company,
    isLoading: companyIsLoading,
    isFetching: companyIsFetching,
    isSuccess: companyIsSuccess,
  } = useGetCompanyQuery(
    {
      companyId: user?.companyId,
    },
    {
      skip: user == null || userIsLoading || !userIsSuccess,
    }
  );

  const isCompanyReady = useMemo(
    () => company != null && !companyIsLoading && !companyIsFetching,
    [company, companyIsLoading, companyIsFetching]
  );

  const [getPromptAction] = useLazyGetPromptActionQuery();

  const intl = useIntl();
  const formatters = getFormatters(intl);
  const [createScenario] = useCreateScenarioMutation();
  const [getCompanyMediaBudget] = useLazyGetCompanyMediaBudgetQuery();

  async function submit() {
    if (!isCompanyReady || !prompt) {
      console.error("Bad Submit", isCompanyReady, prompt);
      return;
    }
    // console.log("Submit", buildRequest({ message: prompt, company }));

    setIsRunningAction(true);

    const promptResponse = await getPromptAction(
      buildRequest({ message: prompt, company })
    );
    // console.log("promptResponse", promptResponse);
    // TODO handle errors
    const { data: action } = promptResponse;

    await handleActions(action, {
      companyId: user.companyId,
      userId: userContext.userID,
      formatters,
      appContext,
      dispatch,
      createScenario,
      getCompanyMediaBudget,
    });
  }

  function handleClose() {
    dispatch(close());
    setPrompt("");
  }

  return (
    <>
      {isOpen ? (
        <Box
          sx={{
            margin: 0,
            top: "auto",
            right: 20,
            bottom: 20,
            left: "auto",
            position: "fixed",
            minWidth: "300px",
          }}
        >
          <LoadingSection isLoading={!isCompanyReady} type={LoadingType.Cover}>
            <Paper style={{ borderRadius: 10 }}>
              <DialogTitle sx={{ p: 1.5, pb: 1 }}>
                My AI
                <IconButton
                  aria-label="info"
                  onClick={() => dispatch(openInfo())}
                  size="small"
                  sx={{
                    ml: "4px",
                    mb: "4px",
                  }}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 6,
                    top: 6,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseRoundedIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent sx={{ p: 1.5, py: 0 }}>
                {companyIsSuccess ? (
                  <TextField
                    value={prompt}
                    onChange={(event) => setPrompt(event.target.value)}
                    autoFocus
                    margin="dense"
                    label="Message"
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={5}
                    placeholder="Create a scenario to optimize my media budget"
                    InputLabelProps={{
                      shrink: true,
                      sx: !isCompanyReady ? { zIndex: 0 } : {}, // Fix issues with labels showing through loader
                    }}
                  />
                ) : (
                  <Typography variant={"body1"} sx={{maxWidth: 248}}>
                    Failed to load Model. Please make sure a model is selected
                    in the settings.
                  </Typography>
                )}
              </DialogContent>
              <DialogActions sx={{ p: 1.5, py: 1 }}>
                <LoadingButton
                  disabled={!prompt}
                  onClick={submit}
                  loading={isRunningAction}
                  loadingPosition="end"
                  endIcon={<SendIcon />}
                >
                  Send
                </LoadingButton>
              </DialogActions>
            </Paper>
          </LoadingSection>
        </Box>
      ) : (
        <></>
      )}
      <InfoDialog />
    </>
  );
}

export default MyAi;
