import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export const textColor = am4core.color("rgba(0, 0, 0, 0.87)");

export const createChartTitle = (
  chart,
  text,
  marginBottom = 10,
  marginTop = 20
) => {
  const title = chart.titles.create();
  return styleTitle(title, text, marginBottom, marginTop);
};

export function styleTitle(title, text, marginBottom = 10, marginTop = 20) {
  title.text = text;
  title.fontSize = 20;
  title.marginBottom = marginBottom;
  title.marginTop = marginTop;
  title.fill = am4core.color("rgba(0,0,0,0.87)");
  return title;
}

export function axisTooltips(axis) {
  axis.tooltip.background.fill = am4core.color("#636568");
  axis.tooltip.background.stroke = am4core.color("#636568");
}

// TODO change parameters to an object
export const createCurrencyAxis = (
  chart,
  xyAxis,
  text,
  fill = "rgba(0,0,0,0.87)",
  opposite = false,
  minimumFractionDigits = 0,
  maximumFractionDigits = 1,
  syncWithAxis = undefined
) => {
  let axis;
  if (xyAxis === "x") {
    axis = chart.xAxes.push(new am4charts.ValueAxis());
  } else if (xyAxis === "y") {
    axis = chart.yAxes.push(new am4charts.ValueAxis());
  }
  axis.title.text = text;
  axis.title.fontSize = 16;
  axis.title.fill = am4core.color(fill);
  axis.renderer.opposite = opposite;
  axis.renderer.labels.template.fill = am4core.color(fill);
  axis.extraTooltipPrecision = 10;
  axisTooltips(axis);
  axis.numberFormatter = new am4core.NumberFormatter();
  axis.numberFormatter.numberFormat = {
    style: "currency",
    currency: "USD",
    notation: "compact",
    compactDisplay: "short",
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  };
  axis.syncWithAxis = syncWithAxis;
  return axis;
};

export const defaultNumberFormat = "$#.0a";
export const getNumberFormatter = (numberFormat) => {
  const numberFormatter = new am4core.NumberFormatter();
  numberFormatter.numberFormat = numberFormat || defaultNumberFormat;
  numberFormatter.bigNumberPrefixes = [
    { number: 1e3, suffix: "K" },
    { number: 1e6, suffix: "M" },
    { number: 1e9, suffix: "B" },
  ];
  numberFormatter.smallNumberPrefixes = [];
  return numberFormatter;
};

export const bigMoneyFormatter = getNumberFormatter();
bigMoneyFormatter._format = bigMoneyFormatter.format;
bigMoneyFormatter.format = (value, format, precision) => {
  if (value < 1e6) {
    const roundedValue = Math.round(value / 1e3) * 1e3;
    if (roundedValue === 0) return "$0K";
    return bigMoneyFormatter._format(roundedValue, "$#a", precision);
  }
  return bigMoneyFormatter._format(value, "$#.0a", precision);
};

export const roiFormatter = new am4core.NumberFormatter();
roiFormatter.numberFormat = "$#.00|$#.00|$#.##";

// o can be either a series or an axis
export function zeroAnimations(o) {
  // series
  o.interpolationDuration = 0;
  o.defaultState.transitionDuration = 0;
  o.hiddenState.transitionDuration = 0;
  o.sequencedInterpolation = false;
  // axis
  o.rangeChangeDuration = 0;
}

/**
 * Determines the text of the label.
 *
 * @callback getTextValueCallback
 * @param {Object} dataItem The amcharts data item.
 */

/**
 * False when bar is a negative value (going down or left), else true.
 *
 * @callback isPositiveBarCallback
 * @param {Object} dataItem The amcharts data item.
 */

/**
 * False when bar is a negative value (going down or left), else true.
 *
 * @callback isPositiveBarCallback
 * @param {Object} dataItem The amcharts data item.
 */

// TODO clean up labelOtherSize addition
/**
 * Add labels to the bars of a bar chart.
 *
 * @param {Object} options Options for the label
 * @param {Object} options.series The series to add the labels too.
 * @param {am4core.NumberFormatter} options.numberFormatter The formatter to apply to label text.
 * @param {getTextValueCallback} options.getTextValue Called for each bullet.
 * @param {isPositiveBarCallback} options.isPositiveBar Called for each bullet.
 * @param {boolean} options.shouldHideWhenSmall Hide labels for small bars instead of showing a black label.
 * @param {boolean} options.isVertical Bars go vertical instead of horizontally.
 * @param {{mainSize: number, orthoSize: number}} options.labelSize Maximum predicted label size in pixels.
 * @param {number} options.verticalLabelSwapWidth Width cutoff to switch to vertical labels in pixels. Only applies to vertical bars
 * @param {float} options.defaultLocation Default location of the label.
 * @param {number} options.labelOtherSize Maximum predicted label size in pixels in the other direction. undefined if don't care about this direction
 * @param {Function} options.getOutsideBarColor The color the label text should be when not contained in the bar.
 * @param {string} options.tooltipNumberFormat The number format string for the bar's tooltip.
 * @param {string} options.tooltipNumberFormatter The number formatter for the bar's tooltip. Overrides tooltipNumberFormat.
 * @param {number} options.forceOutside Force the the label to be outside the bar.
 */
export function addBarLabels({
  series,
  numberFormatter,
  getTextValue,
  isPositiveBar,
  shouldHideWhenSmall: _shouldHideWhenSmall,
  isVertical: _isVertical,
  labelSize: { mainSize, orthoSize }, // TODO change to an object and make var names with dir and orth dir
  verticalLabelSwapWidth,
  defaultLocation,
  getOutsideBarColor: _getOutsideBarColor,
  tooltipNumberFormat: _tooltipNumberFormat,
  tooltipNumberFormatter: _tooltipNumberFormatter,
  forceOutside: _forceOutside,
}) {
  const defaultOnUndef = (v, d) => (v === undefined ? d : v);
  const isVertical = defaultOnUndef(_isVertical, true);
  const shouldHideWhenSmall = defaultOnUndef(_shouldHideWhenSmall, false);
  const getOutsideBarColor = defaultOnUndef(
    _getOutsideBarColor,
    () => textColor
  );
  const forceOutside = defaultOnUndef(_forceOutside, () => false);
  const tooltipNumberFormat = defaultOnUndef(_tooltipNumberFormat, "$#,###.");

  const toolTipNumberFormatter =
    _tooltipNumberFormatter || new am4core.NumberFormatter();
  if (
    _tooltipNumberFormatter === undefined &&
    tooltipNumberFormat !== undefined
  ) {
    toolTipNumberFormatter.numberFormat = tooltipNumberFormat;
  }

  const chartSizes = [
    (ev) => ev.target.pixelHeight,
    (ev) => ev.target.pixelWidth,
  ];
  if (!isVertical) chartSizes.reverse();
  const [getBarDirectionSize, getBarOrthogonalDirectionSize] = chartSizes;

  const labelBullet = series.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.fill = textColor;
  labelBullet.label.fontWeight = 700;
  labelBullet.label.truncate = false;

  const setLocation = (bullet, value, offset) => {
    if (isVertical) {
      bullet.locationY = value;
      bullet.dy = value === 1 || value === 0 ? offset : 0;
    } else {
      bullet.locationX = value;
      bullet.dx = value === 1 || value === 0 ? offset : 0;
    }
  };

  labelBullet.label.adapter.add("text", function (_, target) {
    return numberFormatter.format(getTextValue(target.dataItem));
  });

  series.columns.template.tooltipText = "{category}";
  series.columns.template.hoverOnFocus = true;

  series.columns.template.adapter.add("tooltipText", function (_, target) {
    const category = Object.values(target.dataItem.categories)?.[0] ?? "";
    return (
      category.trim() +
      ": [bold]" +
      toolTipNumberFormatter.format(getTextValue(target.dataItem)) +
      "[/]"
    );
  });

  const lightTextColor = am4core.color("#FFF");

  series.tooltip.autoTextColor = false;
  series.tooltip.label.fill = lightTextColor;

  series.columns.template.events.on("sizechanged", function (ev) {
    const dataItem = ev.target.dataItem;
    if (dataItem?.bullets) {
      dataItem.bullets.each(function (_, bullet) {
        bullet.appear();
        bullet.label.rotation = 0;
        bullet.label.horizontalCenter = "middle";
        // If label fits inside
        if (
          forceOutside(dataItem) === false &&
          getBarDirectionSize(ev) > mainSize &&
          (!orthoSize || getBarOrthogonalDirectionSize(ev) > orthoSize)
        ) {
          setLocation(bullet, defaultLocation, -mainSize / 2);
          bullet.label.fill = lightTextColor;
          return;
        }
        if (shouldHideWhenSmall) {
          bullet.hide();
          return;
        }
        // Switch label to be vertical if a vertical bar is too thin
        if (
          isVertical &&
          verticalLabelSwapWidth != null &&
          getBarOrthogonalDirectionSize(ev) < verticalLabelSwapWidth
        ) {
          bullet.label.rotation = -90;
          bullet.locationY = 0;
          bullet.label.horizontalCenter = isPositiveBar(dataItem)
            ? "left"
            : "right";
          bullet.dy = 5 * (isPositiveBar(dataItem) ? -1 : 1);
          bullet.label.fill = getOutsideBarColor(dataItem);
          return;
        }
        setLocation(
          bullet,
          0,
          isPositiveBar(dataItem) ? -mainSize / 2 : mainSize / 2
        );
        bullet.label.fill = getOutsideBarColor(dataItem);
      });
    }
  });

  zeroAnimations(series);
}

export function addLegend(chart) {
  chart.legend = new am4charts.Legend();
  chart.legend.position = "top";
  chart.legend.useDefaultMarker = true;
  const marker = chart.legend.markers.template.children.getIndex(0);
  const markerSize = 20;
  marker.strokeWidth = 2;
  marker.strokeOpacity = 0.5;
  marker.stroke = am4core.color("#ccc");
  marker.height = marker.width = markerSize;
}

export function disableLegendInteractivity(chart) {
  chart.legend.itemContainers.template.focusable = false;
  chart.legend.itemContainers.template.clickable = false;
  chart.legend.itemContainers.template.cursorOverStyle =
    am4core.MouseCursorStyle.default;
}

/**
 * Activate one series and deactivate all others.
 *
 * @param {String} newSeriesKey The key of the series to activate.
 * @param {*} chart The amcharts Chart
 * @param {Object} chartSeries Map of series keys to series info
 * @param {Function} nameF Optional function to adjust the name. Given the series name
 */
export function handleChangeSeries(newSeriesKey, chart, chartSeries, nameF) {
  const newSeries = chartSeries[newSeriesKey];
  Object.values(chartSeries).forEach((s) => s.visibility.hide());
  newSeries.visibility.show();
  chart.titles.getIndex(0).text =
    typeof nameF !== "function" ? newSeries.name : nameF(newSeries.name);
  chart.exporting.filePrefix = newSeries.exportPrefix;
}

// Fixes issue with axis labels having extra padding after hierarchy change
export function refreshAxis(axis) {
  axis.invalidateData();
}
