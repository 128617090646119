import React from "react";
import Paper from "@mui/material/Paper";

import { Box } from "@mui/material";
import Header from "../../components/Header/Header";
import ScenarioGrid from "./ScenarioGrid";
import CreateNewScenario from "./CreateNewScenario";

import { close as closeMyAi } from "../../app/myAiSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useVariableValue } from "@devcycle/react-client-sdk";

const MyScenarios = () => {
  const myAiValue = useVariableValue('my-ai', false)

  const dispatch = useDispatch();

  useEffect(() => () => dispatch(closeMyAi()), []);
  
  return (
    <Box
      sx={{
        flexGrow: 1,
        ...(myAiValue && { mb: 8 }),
      }}
    >
      <Header
        title="My Scenarios"
        subtitle="create and manage your scenarios"
      />
      <CreateNewScenario />

      <Box mt={3}>
        <Paper
          variant="outlined"
          sx={{ p: 3, borderRadius: "10px", pb: 0, overflow: "hidden" }}
        >
          <ScenarioGrid />
        </Paper>
      </Box>
    </Box>
  );
};

export default MyScenarios;
