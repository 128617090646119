import { Box, Tabs, Tab, Typography, Paper } from "@mui/material";
import React, { forwardRef } from "react";
import PropTypes from "prop-types";

function TabPanel({ children, id, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`${id}-tab-${index}`}
      style={{ flexGrow: 1 }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(id, index) {
  return {
    id: `${id}-tab-${index}`,
    "aria-controls": `${id}-tabpanel-${index}`,
  };
}

const TabbedContent = forwardRef(
  (
    {
      id,
      value,
      setValue,
      ariaLabel,
      disabled,
      tabsData,
      isInPresentationMode,
      children, // tab content footer
      ...other
    },
    ref
  ) => {
    const handleChange = (_, newValue) => {
      setValue(newValue);
    };

    return (
      <Paper
        sx={{
          flexGrow: 1,
          // backgroundColor: theme.palette.background.paper,
          ...(other.orientation === "vertical" && { display: "flex" }),
        }}
        style={{
          borderRadius: isInPresentationMode ? 0 : "10px",
          overflow: "hidden",
        }}
        variant="outlined"
        ref={ref}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label={ariaLabel}
          sx={{
            ...(other.orientation === "vertical" && {
              borderRight: `1px solid ${theme.palette.divider}`,
            }),
          }}
          {...other}
        >
          {tabsData.map((tab, index) => (
            <Tab
              key={tab.key}
              sx={{
                minWidth: "160px",
              }}
              label={tab.label}
              disabled={disabled}
              {...a11yProps(id, index)}
            />
          ))}
        </Tabs>
        {tabsData.map((tab, index) => (
          <TabPanel key={tab.key} id={id} value={value} index={index}>
            {tab.content}
            {children}
          </TabPanel>
        ))}
      </Paper>
    );
  }
);

TabbedContent.propTypes = {
  id: PropTypes.string.isRequired,
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.node,
    })
  ).isRequired,
  ariaLabel: PropTypes.string.isRequired,
};

export default TabbedContent;
