import { Box, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import FormattedNumberField from "../../components/FormattedNumberField/FormattedNumberField";
import {
  AspirationType,
  calculateNewSelection,
  parseDecimalInput,
} from "../../constants";
import { getFormatters } from "../GridUtils";
import {
  selectNewScenarioWizard,
  setChangeInSpend,
  setChangeInSpendNoUndo,
  setGrowthTarget,
  setGrowthTargetNoUndo,
  setMediaBudget,
  setMediaBudgetNoUndo,
} from "./newScenarioWizardSlice";

export default function NewScenarioQuestion({ aspiration }) {
  const {
    changeInSpend,
    mediaBudget,
    growthTarget,
    changeInSpendString,
    mediaBudgetString,
    growthTargetString,
  } = useSelector(selectNewScenarioWizard);
  const dispatch = useDispatch();

  const questionInfo = {
    [AspirationType.Simulate]: {
      getText: () => (
        <>
          What business results will my{" "}
          <strong>
            {formatters.percentOnePossible({ value: changeInSpend })}
          </strong>{" "}
          change in media spend deliver?
        </>
      ),
      getValue: () => changeInSpend,
      getFocused: () => changeInSpendString,
      getFocusedFormatted: (value) =>
        formatters.decimalOnePossible({
          value: value * 100,
        }),
      getBlurred: () => formatters.percentOnePossible({ value: changeInSpend }),
      parse: parsePercentInput,
      label: "Change in Spend",
      setValue: (payload) => {
        dispatch(setChangeInSpendNoUndo(payload));
      },
      setValueWithUndo: (payload) => {
        dispatch(setChangeInSpend(payload));
      },
    },
    [AspirationType.Optimize]: {
      getText: () => (
        <>
          How can I maximize my profit, given my{" "}
          <strong>
            {formatters.currencyWithoutCents({ value: mediaBudget })}
          </strong>{" "}
          media budget?
        </>
      ),
      getValue: () => mediaBudget,
      getFocused: () => mediaBudgetString,
      getFocusedFormatted: (value) =>
        formatters.decimalZero({
          value: value,
        }),
      getBlurred: () => formatters.currencyWithoutCents({ value: mediaBudget }),
      parse: parseDollarInput,
      label: "Media Budget",
      setValue: (payload) => {
        dispatch(setMediaBudgetNoUndo(payload));
      },
      setValueWithUndo: (payload) => {
        dispatch(setMediaBudget(payload));
      },
    },
    [AspirationType.Grow]: {
      getText: () => (
        <>
          How can I grow my sales by{" "}
          <strong>
            {formatters.percentOnePossible({ value: growthTarget })}
          </strong>{" "}
          most efficiently?
        </>
      ),
      getValue: () => growthTarget,
      getFocused: () => growthTargetString,
      getFocusedFormatted: (value) =>
        formatters.decimalOnePossible({
          value: value * 100,
        }),
      getBlurred: () => formatters.percentOnePossible({ value: growthTarget }),
      parse: parsePercentInput,
      label: "Growth Target",
      setValue: (payload) => {
        dispatch(setGrowthTargetNoUndo(payload));
      },
      setValueWithUndo: (payload) => {
        dispatch(setGrowthTarget(payload));
      },
    },
  };

  // TODO Update of media budget when sales channels or date changed
  // useEffect(() => {
  //   if (aspiration === AspirationType.Optimize) {
  //     // TODO
  //   }
  // }, [aspiration, mediaBudget]);

  const intl = useIntl();

  const formatters = getFormatters(intl);

  function parsePercentInput(v, event) {
    const numDecimals = 1;
    const beforeCursorString = parseDecimalInput(
      v.substring(0, event.target.selectionStart),
      numDecimals
    ).string;
    const parseValue = parseDecimalInput(v, numDecimals);

    const newCursorIndex = calculateNewSelection(
      beforeCursorString,
      parseValue.string
    );

    setTimeout(() => {
      event.target.selectionStart = newCursorIndex;
      event.target.selectionEnd = newCursorIndex;
    });

    return {
      ...parseValue,
      float: parseValue.float < -100 ? -1 : parseValue.float / 100,
    };
  }

  function parseDollarInput(v, event) {
    const formatString = (s) => s.replaceAll(/[^,\d]/g, "");

    const beforeCursorString = formatString(
      v.substring(0, event.target.selectionStart)
    );
    const filteredString = formatString(v);

    const newCursorIndex = calculateNewSelection(
      beforeCursorString,
      filteredString
    );
    setTimeout(() => {
      event.target.selectionStart = newCursorIndex;
      event.target.selectionEnd = newCursorIndex;
    });

    return {
      string: filteredString,
      float: Number(filteredString.replaceAll(/[^\d]/g, "")) || 0,
    };
  }

  const content = () => {
    const currentInfo = questionInfo[aspiration];
    return (
      <>
        <Typography variant={"body2"} mt={3}>
          {currentInfo.getText()}
        </Typography>
        <Stack direction="row" spacing={3} mt={3} mb={4} mr={3}>
          <FormattedNumberField
            label={currentInfo.label}
            focusedValue={currentInfo.getFocused()}
            blurredValue={currentInfo.getBlurred()}
            setValue={(v, event) => {
              const parsedValue = currentInfo.parse(v, event);
              currentInfo.setValue(parsedValue);
            }}
            onBlur={() => {
              currentInfo.setValueWithUndo(
                {
                  float: currentInfo.getValue(),
                  string: currentInfo.getFocusedFormatted(
                    currentInfo.getValue()
                  ),
                }
              );
            }}
          />
        </Stack>
      </>
    );
  };

  return (
    <>
      <Box mt={3} pl={3} pr={3}>
        <Typography variant={"body1"}>Define Business Question</Typography>
        {content()}
      </Box>
    </>
  );
}
