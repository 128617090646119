import { Box, ButtonBase, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateCard = ({ aspiration, setAspiration, handleOpen, icon, inset, contained, disabled }) => {

  const handleClick = () => {
    setAspiration(aspiration);
    handleOpen();
  };

  return (
    <ButtonBase sx={contained} focusRipple onClick={handleClick} disabled={disabled}>
      <Box component="span" sx={inset}>
        <Box>
          <FontAwesomeIcon icon={icon} size="5x" style={{ marginBottom: 10 }}/>
          <br />
          <Typography component="span" variant="h6" color="inherit">
            {aspiration.charAt(0).toUpperCase() + aspiration.slice(1)}
          </Typography>
        </Box>
      </Box>
    </ButtonBase>
  );
};

export default CreateCard;
