import React, { useLayoutEffect, useMemo, useRef, useState } from "react";

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {
  addBarLabels,
  addLegend,
  bigMoneyFormatter,
  createChartTitle,
  disableLegendInteractivity,
  handleChangeSeries,
  refreshAxis,
  roiFormatter,
  textColor,
  zeroAnimations,
} from "../ChartUtils";

import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetResultsGroupsQuery } from "../../app/scenarioApi";
import {
  selectExpandedAndSelectedShortMediaTargets,
  selectScenario,
} from "../../app/scenarioSlice";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection";
import {
  finalName,
  getColorBySeriesKey,
  oldDefaultExportFormats,
  resultName,
  startingName,
} from "../../constants";
import { getNameWithoutDuplicates } from "../../utils/targetUtils";
import ChartControls from "./ChartControls";

am4core.useTheme(am4themes_animated);

const CHART_ID = "results-detail-chart";

export const ChartSeriesEnum = {
  Spend: "spend",
  Contribution: "contribution",
  Roi: "roi",
};

function ResultsDetailChart({
  metadata,
  setMetadata,
  enterPresentationMode,
  exitPresentationMode,
  isInPresentationMode,
  presentationModePopOverContainer,
}) {
  const [chartSeries, setChartSeries] = useState(null);
  // maximum bound for the roi series needs to be set manually because amcharts does a bad job with it
  const [roiMax, setRoiMax] = useState(null);
  const chart = useRef(null);

  const {
    isScenarioReady,
    id: scenarioId,
    userId,
    resultsHierarchy,
    mediaHierarchy,
  } = useSelector(selectScenario);
  const targets = useSelector(selectExpandedAndSelectedShortMediaTargets);

  const {
    data: groups,
    isLoading: groupsIsLoading,
    isSuccess: groupsIsSuccess,
  } = useGetResultsGroupsQuery(
    {
      scenarioId,
      userId,
      targets,
      hierarchy: resultsHierarchy,
    },
    {
      skip: !isScenarioReady || targets === null,
    }
  );

  const isLoading = useMemo(
    () => groupsIsLoading || !groupsIsSuccess,
    [groupsIsLoading, groupsIsSuccess]
  );

  function setCurrentSeriesKey(currentSeriesKey) {
    setMetadata((prevState) => ({
      ...prevState,
      currentSeriesKey,
    }));
  }

  useLayoutEffect(() => {
    if (metadata?.currentSeriesKey) return;
    setCurrentSeriesKey(ChartSeriesEnum.Spend);
  }, [metadata]);

  /**
   * Instantiate the chart
   */
  useLayoutEffect(() => {
    // Create chart instance
    chart.current = am4core.create(CHART_ID, am4charts.XYChart);

    chart.current.paddingRight = 55;
    chart.current.maskBullets = false;

    chart.current.zoomOutButton.disabled = true;

    // Add chart title
    createChartTitle(chart.current, "", 15);

    // Create Axes

    // Create Value Axis
    const valueAxis = chart.current.xAxes.push(new am4charts.ValueAxis());
    valueAxis.adjustLabelPrecision = false;
    valueAxis.min = 0;
    valueAxis.numberFormatter = bigMoneyFormatter;

    zeroAnimations(valueAxis);

    // Category Axis
    const categoryAxis = chart.current.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.fill = textColor;
    categoryAxis.renderer.line.strokeOpacity = 1;
    categoryAxis.renderer.line.strokeWidth = 2;
    categoryAxis.renderer.line.stroke = am4core.color("#cdcdcd");

    categoryAxis.renderer.cellStartLocation = 0.05;
    categoryAxis.renderer.cellEndLocation = 0.95;

    function addSeries(seriesKey) {
      function _addSeries(name, fieldName) {
        const color = getColorBySeriesKey(fieldName);
        const series = chart.current.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = fieldName;
        series.dataFields.categoryY = "category";
        series.hidden = true;

        series.name = name;
        // series.legendSettings.labelText = `[${color}]{name}[/]`;
        // remove outline
        series.columns.template.strokeWidth = 0;
        series.columns.template.height = am4core.percent(100);

        series.fill = am4core.color(color);

        series.stroke = am4core.color("#fff");
        series.columns.template.width = am4core.percent(70);

        addBarLabels({
          series: series,
          numberFormatter:
            seriesKey === "roi" ? roiFormatter : bigMoneyFormatter,
          getTextValue: (dataItem) =>
            dataItem ? dataItem.dataContext[fieldName] : "",
          isPositiveBar: (_) => false,
          isVertical: false,
          labelSize: { mainSize: 70 },
          defaultLocation: 0,
          tooltipNumberFormat:
            seriesKey === "roi" ? roiFormatter.numberFormat : undefined,
          // force labels to always hide
          forceOutside: () => true,
          shouldHideWhenSmall: true,
        });

        return series;
      }

      const final = _addSeries(finalName, "final_" + seriesKey);
      const starting = _addSeries(startingName, "starting_" + seriesKey);

      const seriesList = [final, starting];

      return {
        visibility: {
          show: () =>
            seriesList.forEach((s) => {
              s.show();
              s.hiddenInLegend = false;
              s.clustered = true;
            }),
          hide: () =>
            seriesList.forEach((s) => {
              s.hide();
              s.hiddenInLegend = true;
              s.clustered = false;
            }),
        },
        final,
        starting,
      };
    }

    // Create Series
    setChartSeries({
      [ChartSeriesEnum.Spend]: {
        name: "Spend",
        exportPrefix: "ResultsSpendDetailChart",
        ...addSeries("spend"),
      },
      [ChartSeriesEnum.Contribution]: {
        name: resultName,
        exportPrefix: `Results${resultName}DetailChart`,
        ...addSeries("contribution"),
      },
      [ChartSeriesEnum.Roi]: {
        name: "ROI",
        exportPrefix: `ResultsRoiDetailChart`,
        ...addSeries("roi"),
      },
    });

    // Legend
    addLegend(chart.current);
    chart.current.legend.reverseOrder = true;
    disableLegendInteractivity(chart.current);

    // Exporting
    chart.current.exporting.filePrefix = "ResultsDetailChart";
    chart.current.exporting.dataFields = {
      category: "Category",
      starting_spend: `${startingName} Spend`,
      final_spend: `${finalName} Spend`,
      starting_contribution: `${startingName} ${resultName}`,
      final_contribution: `${finalName} ${resultName}`,
      starting_roi: `${startingName} ROI`,
      final_roi: `${finalName} ROI`,
    };
  }, []);

  useLayoutEffect(() => {
    function resize() {
      chart.current?.svgContainer.measure();
    }
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  useLayoutEffect(() => {
    if (chartSeries == null || metadata == null) return;
    handleChangeSeries(
      metadata.currentSeriesKey,
      chart.current,
      chartSeries,
      (n) => n + " Detail"
    );
  }, [chartSeries, metadata?.currentSeriesKey]);

  useLayoutEffect(() => {
    if (metadata == null) return;
    const axis = chart.current.xAxes.values[0];
    axis.hide();
    if (metadata.currentSeriesKey === "roi") {
      axis.max = roiMax;
      axis.numberFormatter = roiFormatter;
    } else {
      axis.max = undefined;
      axis.numberFormatter = bigMoneyFormatter;
    }
    axis.show();
  }, [roiMax, metadata?.currentSeriesKey]);

  const data = useMemo(() => {
    if (groups == null) return null;

    return groups.reduce(
      (acc, g) => ({
        ...acc,
        [getNameWithoutDuplicates(g, mediaHierarchy)]: g,
      }),
      {}
    );
  }, [groups]);

  useEffect(() => {
    // const sortedData = Object.entries(data || {}).sort(([aCat, a], [bCat, b]) => b.final_contribution - a.final_contribution);
    const sortedData = Object.entries(data || {});

    const chartDataList = [
      ...sortedData.map(([key, value]) => ({
        category: key,
        starting_spend: value?.starting_spend,
        final_spend: value?.final_spend,
        starting_contribution: value?.starting_contribution,
        final_contribution: value?.final_contribution,
        starting_roi: value?.starting_roi,
        final_roi: value?.final_roi,
      })),
    ];

    const rois = chartDataList.flatMap((d) => [d.starting_roi, d.final_roi]);
    if (rois.length) {
      setRoiMax(Math.max(...rois));
    }

    chart.current.data = chartDataList.slice().reverse();

    refreshAxis(chart.current.yAxes.values[0]);
  }, [data]);

  return (
    <LoadingSection isLoading={isLoading} type={LoadingType.Cover}>
      <ChartControls
        amChart={chart.current}
        // chartExportFormats={["svg", "png", "csv"]}
        chartExportFormats={oldDefaultExportFormats}
        presentationModePopOverContainer={presentationModePopOverContainer}
        isInPresentationMode={isInPresentationMode}
        enterPresentationMode={enterPresentationMode}
        exitPresentationMode={exitPresentationMode}
      >
        <ToggleButtonGroup
          color="primary"
          value={metadata?.currentSeriesKey}
          exclusive
          onChange={(_, v) => {
            if (v !== null) setCurrentSeriesKey(v);
          }}
        >
          <ToggleButton value={ChartSeriesEnum.Spend}>Spend</ToggleButton>
          <ToggleButton value={ChartSeriesEnum.Contribution}>
            {resultName}
          </ToggleButton>
          <ToggleButton value={ChartSeriesEnum.Roi}>ROI</ToggleButton>
        </ToggleButtonGroup>
      </ChartControls>
      <Box id={CHART_ID} style={{ width: "100%", height: "100%" }}></Box>
    </LoadingSection>
  );
}

export default ResultsDetailChart;
