import { Box } from "@mui/material";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import {
  useGetColStateQuery,
  useSetColStateMutation,
} from "../../app/insightsGridStateApi";
import {
  useUpdateConstraintGroupMutation,
  useUpdateConstraintItemMutation,
  useUpdateGroupMutation,
} from "../../app/scenarioApi";
import {
  useSetMediaHierarchyMutation,
  useSetSalesHierarchyMutation,
} from "../../app/scenarioMetadataApi";
import { selectScenario } from "../../app/scenarioSlice";
import { impressionsToSpend } from "../../scenarioDataUtils/media";
import {
  getShortTarget,
  getTargetFromNode,
  GridKeyEnum,
  optimizationInfinity,
  ScenarioDataKeys,
} from "../../scenarioDataUtils/scenarioDataUtils";
import {
  getSplitHierarchy,
  isHierarchyKey,
  isMediaKey,
  isSalesKey,
} from "../../utils/targetUtils";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "../grids.css";
import {
  channelAggFunc,
  combinedResultsAgg,
  getMinWidth,
  periodAggFunction,
} from "../GridUtils";
import ConstrainedNumberCellEditor from "./ConstrainedNumberCellEditor";
import { createServerSideDatasource } from "./createServerSideDatasource";

export const GridParsingEnum = {
  Number: "number",
  Boolean: "boolean",
};

/**
 * Compare to optimization infinity while accounting for +/- 2000 problem with grid paste
 * When optimization infinity is pasted into the grid by any method,
 * the result will be either optimization infinity, optimization infinity plus 2000, or optimization infinity minus 2000.
 * Comparisons should account for all three of these values.
 * Copies of a +/- 2000 value should be corrected to optimization infinity.
 */
export function isGridOptimizationInfinity(value) {
  return (
    value === optimizationInfinity ||
    value + 2000 === optimizationInfinity ||
    value - 2000 === optimizationInfinity
  );
}

function parseNumberInput(input, defaultValue) {
  if (defaultValue !== undefined && input === "") return defaultValue;
  if (input == null) return null;
  const filteredInput = input.toString().replace(/[^0-9.-]/g, "");
  return parseFloat(filteredInput);
}

function parseBooleanInput(input) {
  if (typeof input === "boolean") return input;
  if (typeof input === "string" && input.toLowerCase() === "false")
    return false;
  return Boolean(input);
}

// includes current level
export function getParentEffectedCVs(shortTarget, keys) {
  return Array(Object.keys(shortTarget).length + 1)
    .fill()
    .map((_, i) =>
      keys.slice(0, i).reduce((acc, k) => acc.concat(shortTarget[k]), [])
    );
}

export function makeFullBlankTarget(shortTarget, keys) {
  return keys.reduce(
    (acc, k, i) =>
      shortTarget[k] != null
        ? acc.concat(shortTarget[k])
        : acc.concat(acc[i - 1]),
    []
  );
}

export function getChildEffectedCVsWithBlanks(
  shortTarget,
  keys,
  getNestedChildrenTargets
) {
  const childCVs = [];
  const potentialBlanksEffectedCVs = [makeFullBlankTarget(shortTarget, keys)];

  // TODO handle blank rows better.
  // This just assumes that any non-full target it gets should also have a blank target
  // The blank cv this makes may nit exist but the grid does not seem to care. May slow down a bit

  if (shortTarget.period == null) {
    // calculate blanks for non-week edit
    const childrenMediaTargets = getNestedChildrenTargets(shortTarget);
    // console.log(
    //   "RESET MEDIA",
    //   shouldSetMediaGridOptions,
    //   effectedCVsWithoutBlanks,
    //   childrenMediaTargets
    // );

    // Add on all children that are expanded
    childrenMediaTargets.forEach((childTarget) => {
      const newCV = keys.reduce(
        (acc, k) => (childTarget[k] != null ? acc.concat(childTarget[k]) : acc),
        []
      );
      childCVs.push(newCV);

      // TODO handle blank rows better.
      // This just assumes that any non-full target it gets should also have a blank target
      // The blank cv this makes may nit exist but the grid does not seem to care. May slow down a bit
      const potentialNecessaryBlankCV = makeFullBlankTarget(childTarget, keys);
      // console.log(
      //   "BLANK",
      //   childTarget,
      //   potentialNecessaryBlankCV,
      //   newCV,
      //   potentialNecessaryBlankCV.every((v, i) => v === newCV[i])
      // );
      if (!potentialNecessaryBlankCV.every((v, i) => v === newCV[i])) {
        potentialBlanksEffectedCVs.push(potentialNecessaryBlankCV);
      }
    });
  }

  return [...childCVs, ...potentialBlanksEffectedCVs];
}

function DataGrid({
  // Defined for every grid
  id,
  gridKey,
  hierarchy,
  colStateKey,
  rowStateKey,
  agGridColumnItems,
  defaultColState,

  getIsNodeExpanded,
  getIsNodeSelected,
  updateIsNodeExpanded,
  updateIsNodeSelected,

  // Optional per grid
  cellEditorProps,
  gridOptions,
  getContextMenuItems,
  isInPresentationMode,

  getGroupChildren,
  getGroup,
  getItems,

  checkbox = false,

  gridApi,
  setGridApi,
}) {
  const {
    id: scenarioId,
    userId,
    isScenarioReady,
    mediaHierarchy,
  } = useSelector(selectScenario);

  // const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const intl = useIntl();

  const periodAggHandler = useCallback(
    (params) => periodAggFunction(intl, params),
    [intl]
  );

  const { data: loadedColState, isLoading: colStateIsLoading } =
    useGetColStateQuery(
      {
        scenarioId,
        userId,
        gridKey,
      },
      {
        skip: scenarioId == null || userId == null || scenarioId === 0,
      }
    );

  const [setColStateApiCall] = useSetColStateMutation();
  const [setMediaHierarchy] = useSetMediaHierarchyMutation();
  const [setSalesHierarchy] = useSetSalesHierarchyMutation();

  const setColStateCallback = useCallback(() => {
    if (
      !isScenarioReady ||
      gridColumnApi == null ||
      gridKey == null ||
      colStateIsLoading
    )
      return;
    const colState = gridColumnApi
      .getColumnState()
      .map(({ colId, hide, pinned }) => ({
        colId,
        hide,
        pinned: pinned !== null ? pinned : "",
      }));
    setColStateApiCall({ scenarioId, userId, gridKey, colState });
  }, [
    gridColumnApi,
    isScenarioReady,
    colStateIsLoading,
    setColStateApiCall,
    gridKey,
  ]);

  const updateHierarchy = useCallback(() => {
    if (!isScenarioReady || gridColumnApi == null) return;
    const newHierarchy = gridColumnApi
      .getColumnState()
      .reduce(
        (acc, { colId }) =>
          acc.concat(isMediaKey(colId) || isSalesKey(colId) ? colId : []),
        []
      );

    if (hierarchy.every((v, i) => v === newHierarchy[i])) return;

    const splitHierarchy = getSplitHierarchy(newHierarchy);

    if (splitHierarchy.media.length !== 0) {
      setMediaHierarchy({
        scenarioId,
        userId,
        mediaHierarchy: splitHierarchy.media,
      });
    }
    if (splitHierarchy.sales.length !== 0) {
      setSalesHierarchy({
        scenarioId,
        userId,
        salesHierarchy: splitHierarchy.sales,
      });
    }
  }, [hierarchy, gridColumnApi, isScenarioReady]);

  const saveColState = useDebouncedCallback(setColStateCallback, 250);

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
    },
    [setGridApi, setGridColumnApi]
  );

  const onDataRendered = useCallback((params) => {
    setSelectedSync(true);
    setExpandedSync(true);
  }, []);

  useEffect(() => {
    if (!isScenarioReady || gridApi == null) {
      return;
    }
    // console.log("Datasource Initialization", gridKey, scenarioId);
    const datasource = createServerSideDatasource(
      userId,
      scenarioId,
      hierarchy,
      getGroupChildren,
      getGroup,
      getItems,
      onDataRendered
    );
    gridApi.setServerSideDatasource(datasource);
    if (gridColumnApi == null || gridApi == null) return;
    gridColumnApi.autoSizeColumn("ag-Grid-AutoColumn");
    gridApi.sizeColumnsToFit();
  }, [
    isScenarioReady,
    scenarioId,
    userId,
    gridApi,
    getGroup,
    getGroupChildren,
    getItems,
    onDataRendered,
    gridKey,
    hierarchy,
  ]);

  const onGridSizeChanged = useCallback((params) => {
    params.columnApi.autoSizeColumn("ag-Grid-AutoColumn");
    params.api.sizeColumnsToFit();
  }, []);

  const colState = useMemo(
    () =>
      [...(loadedColState ?? defaultColState)].sort((a, b) => {
        if (hierarchy.includes(a.colId) && hierarchy.includes(b.colId))
          return hierarchy.indexOf(a.colId) - hierarchy.indexOf(b.colId);
        return 0;
      }),
    [loadedColState, defaultColState, hierarchy]
  );

  const onColumnMoved = useCallback(
    (params) => {
      if (params.source === "api") return;
      if (params.finished) {
        saveColState();
        if (params.columns.some(({ colId }) => isHierarchyKey(colId))) {
          updateHierarchy();
        }
      }
    },
    [saveColState, updateHierarchy]
  );

  const onColumnPinned = useCallback(
    (params) => {
      if (params.source === "api") return;
      saveColState();
    },
    [saveColState]
  );

  const onColumnVisible = useCallback(
    (params) => {
      if (params.source === "api") return;
      params.columnApi.autoSizeColumn("ag-Grid-AutoColumn");
      params.api.sizeColumnsToFit();
      saveColState();
    },
    [saveColState]
  );

  useEffect(() => {
    if (gridColumnApi == null || colState == null) return;
    // console.log("colState change applyColumnState", colState, gridKey);
    gridColumnApi.applyColumnState({
      state: colState,
      applyOrder: true,
      // defaultState: defaultColState,
    });
  }, [gridColumnApi, colState]);

  const onFirstDataRendered = useCallback((params) => {
    params.columnApi.autoSizeColumn("ag-Grid-AutoColumn");
    params.api.sizeColumnsToFit();
  }, []);

  const WrappedConstrainedNumberCellEditor = useMemo(() => {
    return forwardRef((props, ref) => {
      return (
        <ConstrainedNumberCellEditor
          {...props}
          {...cellEditorProps}
          gridKey={gridKey}
          ref={ref}
        />
      );
    });
  }, [gridKey]);

  const [updateGroup] = useUpdateGroupMutation();
  // TODO move into media and sales grid
  const [updateConstraintGroup] = useUpdateConstraintGroupMutation();
  const [updateConstraintItem] = useUpdateConstraintItemMutation();

  const columnDefs = useMemo(
    () =>
      agGridColumnItems.map((gridColumnItemPreFilter) => {
        const { defaultValue, gridParsingType, ...gridColumnItem } =
          gridColumnItemPreFilter;
        if (hierarchy.includes(gridColumnItem.field)) {
          return {
            minWidth: getMinWidth(gridColumnItem.headerName),
            ...gridColumnItem,
            rowGroupIndex: hierarchy.indexOf(gridColumnItem.field),
          };
        }
        return {
          minWidth: getMinWidth(gridColumnItem.headerName),
          ...gridColumnItem,
          valueSetter: (params) => {
            console.log(params);
            const parsedNewValue = (() => {
              switch (gridParsingType) {
                case GridParsingEnum.Number:
                  return parseNumberInput(params.newValue, defaultValue);
                case GridParsingEnum.Boolean:
                  return parseBooleanInput(params.newValue);
                default:
                  return params.newValue;
              }
            })();

            // console.log("Parsing grid input", params.newValue, parsedNewValue, gridParsingType, defaultValue)

            // don't allow deleting data unless it is one of the following fields.
            const clearableFields = [
              "lower_spend_pct",
              "upper_spend_pct",
              "lower_spend",
              "upper_spend",
              "lower_impressions",
              "upper_impressions",
            ];
            if (
              !clearableFields.includes(gridColumnItem.field) &&
              (isNaN(parsedNewValue) || parsedNewValue === null)
            ) {
              console.warn(
                "Aborted",
                gridColumnItem.field,
                "change because",
                parsedNewValue
              );
              return;
            }
            let changedField = params.colDef.field;
            let changedValue = parsedNewValue;

            // Deal with constraints
            if (gridKey === GridKeyEnum.Media) {
              ["lower", "upper"].forEach((prefix) => {
                if (changedField === prefix + "_spend_pct") {
                  changedField = prefix + "_spend";
                  if (!isNaN(changedValue) && changedValue != null) {
                    changedValue =
                      (params.node.data.starting_spend *
                        (100 + parsedNewValue)) /
                      100;
                    // All constraints are rounded to nearest cent
                    changedValue = Math.round(changedValue * 100) / 100;
                  } else {
                    // allow clearing
                    changedValue = changedField.startsWith("lower_")
                      ? 0
                      : optimizationInfinity;
                  }
                }
                if (changedField === prefix + "_impressions") {
                  changedField = prefix + "_spend";
                  if (
                    changedValue !== optimizationInfinity &&
                    changedValue !== 0
                  ) {
                    changedValue = impressionsToSpend(
                      parsedNewValue,
                      params.node.data.cpm
                    );
                  }
                }
              });
            }
            if (
              ["lower_spend", "upper_spend"].includes(changedField) &&
              0 < changedValue &&
              changedValue < 0.5
            ) {
              changedValue = 0;
            }

            // Deal with impressions
            if (gridKey === GridKeyEnum.Media) {
              ["starting", "final"].forEach((prefix) => {
                if (changedField === prefix + "_impressions") {
                  changedField = prefix + "_spend";
                  if (
                    changedValue !== optimizationInfinity &&
                    changedValue !== 0
                  ) {
                    changedValue = impressionsToSpend(
                      parsedNewValue,
                      params.node.data.cpm
                    );
                  }
                }
              });
            }

            if (gridKey === GridKeyEnum.Media && changedField === "cpm") {
              // handle copy of +/- 2000 optimization infinity
              // correct to optimization infinity
              if (isGridOptimizationInfinity(changedValue)) {
                changedValue = optimizationInfinity;
              }
            }
            // console.log(changedField, changedValue);

            // console.log(parsedNewValue, shortTarget);

            function getCollectionName() {
              if (gridKey === ScenarioDataKeys.Sales) return "sales";
              if (gridKey === ScenarioDataKeys.Media) {
                if (["lower_spend", "upper_spend"].includes(changedField))
                  return "constraints";
                return "media";
              }
            }

            const collectionName = getCollectionName();

            if (collectionName == null) {
              console.error(
                "Could not get collection name",
                gridKey,
                changedField
              );
              return false;
            }

            if (params.node.group) {
              // TODO investigate why blank row shortTargets have nulls at the bottom
              const target = getShortTarget(
                params.node.data.shortTarget,
                hierarchy
              );
              if (collectionName === "constraints") {
                updateConstraintGroup({
                  updateMap: {
                    [changedField]: changedValue,
                  },
                  userId,
                  scenarioId,
                  target,
                  mediaHierarchy,
                });
              } else {
                updateGroup({
                  collectionName,
                  updateMap: {
                    [changedField]: changedValue,
                  },
                  userId,
                  scenarioId,
                  target,
                });
              }
            } else {
              const target = getTargetFromNode(params.node, hierarchy);
              if (collectionName === "constraints") {
                updateConstraintItem({
                  updateMap: {
                    [changedField]: changedValue,
                  },
                  userId,
                  scenarioId,
                  target,
                });
              } else {
                updateGroup({
                  collectionName,
                  updateMap: {
                    [changedField]: changedValue,
                  },
                  userId,
                  scenarioId,
                  target,
                });
              }
            }

            return false; // do not automatically refresh cell
          },
        };
      }),
    [agGridColumnItems, userId, scenarioId, updateGroup, gridKey, hierarchy]
  );

  // EXPANSION AND SELECTION
  const [expandedSync, setExpandedSync] = useState(false);
  const [selectedSync, setSelectedSync] = useState(false);

  // Sync expanded on expandedSync
  useEffect(() => {
    if (
      gridApi == null ||
      gridColumnApi == null ||
      getIsNodeExpanded == null ||
      !isScenarioReady ||
      !expandedSync
    )
      return;
    setExpandedSync(false);
    gridApi.forEachNode((node) => {
      // Skip non-group and premature nodes.
      if (!node.group || node.key == null || node.data.isTotalRow) return;
      // If blank row set child to parent.
      if (node.data.isBlankRow) {
        node.setExpanded(node.parent.expanded);
        return;
      }
      node.setExpanded(getIsNodeExpanded(node.data.shortTarget));
    });
    setTimeout(() => {
      gridColumnApi.autoSizeColumn("ag-Grid-AutoColumn");
      gridApi.sizeColumnsToFit();
    }, 0);
  }, [
    gridApi,
    gridColumnApi,
    getIsNodeExpanded,
    isScenarioReady,
    expandedSync,
  ]);

  // Sync selected on selectedSync
  useEffect(() => {
    if (
      gridApi == null ||
      getIsNodeSelected == null ||
      !isScenarioReady ||
      !selectedSync
    )
      return;
    setSelectedSync(false);

    gridApi.forEachNode((node) => {
      // Skip non-group and premature nodes.
      if (!node.group || node.key == null || node.data.isTotalRow) return;
      // If blank row set child to parent.
      if (node.data.isBlankRow) {
        node.setSelected(node.parent.isSelected());
        return;
      }
      node.setSelected(getIsNodeSelected(node.data.shortTarget, node.field));
    });
  }, [gridApi, getIsNodeSelected, isScenarioReady, selectedSync]);

  const onRowGroupOpened = useCallback(
    ({ node }) => {
      if (updateIsNodeExpanded == null) return;
      // Skip non-group and premature nodes.
      if (!node.group || node.key == null || node.data.isTotalRow) return;
      // If blank row set parent to child.
      if (node.data.isBlankRow) {
        node.parent.setExpanded(node.expanded);
        return;
      }
      updateIsNodeExpanded(node.data.shortTarget, node.expanded);
      setExpandedSync(true);
    },
    [updateIsNodeExpanded, gridColumnApi]
  );

  const onSelectionChanged = useCallback(
    (params) => {
      if (updateIsNodeSelected == null) return;
      let needsToSync = false;
      params.api.forEachNode((node) => {
        // Skip non-group and premature nodes.
        if (!node.group || node.key == null || node.data.isTotalRow) return;
        // If blank row set parent to child.
        if (node.data.isBlankRow) {
          node.parent.setSelected(node.isSelected());
          return;
        }
        const treeNodeSelected = getIsNodeSelected(
          node.data.shortTarget,
          node.field
        );
        if (node.selected === treeNodeSelected) return;
        needsToSync = true;

        updateIsNodeSelected(node.data.shortTarget, node.selected, node.field);
      });
      if (needsToSync) {
        setSelectedSync(true);
      }
    },
    [getIsNodeSelected, updateIsNodeSelected]
  );

  const cellRendererSelector = useCallback((params) => {
    if (params.data.isTotalRow) {
      return null;
    }
    return {
      component: "agGroupCellRenderer",
    };
  }, []);

  return (
    <Box
      id={id}
      className="ag-theme-material"
      style={{ width: "100%", height: "100%" }}
    >
      <AgGridReact
        rowModelType={"serverSide"}
        maxConcurrentDatasourceRequests={1}
        purgeClosedRowNodes={false}
        cacheBlockSize={20}
        animateRows={true}
        autoGroupColumnDef={{
          cellRendererParams: {
            checkbox,
            // suppressCount: true,
          },
          cellRendererSelector,
          headerName: "Level",
          suppressSizeToFit: true,
        }}
        groupAllowUnbalanced={true}
        defaultColDef={{
          resizable: true,
        }}
        maintainColumnOrder={true}
        columnDefs={columnDefs}
        aggFuncs={{
          periodAgg: periodAggHandler,
          combinedResultsAgg,
          channelAgg: channelAggFunc,
        }}
        // groupRowsSticky={true}
        enableFillHandle={true}
        enableGroupEdit={true}
        enableRangeSelection={true}
        fillHandleDirection={"y"}
        // groupDefaultExpanded={0}
        groupMultiAutoColumn={false}
        // groupSelectsChildren={true}
        // onColumnResized={onColumnResized}
        onColumnMoved={onColumnMoved}
        // onDragStarted={onDragStarted}
        // onDragStopped={onDragStopped}
        onColumnPinned={onColumnPinned}
        onColumnVisible={onColumnVisible}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        onGridSizeChanged={onGridSizeChanged}
        // onRowDataUpdated={onRowDataUpdated}
        onRowGroupOpened={onRowGroupOpened}
        onExpandOrCollapseAll={onRowGroupOpened}
        onSelectionChanged={onSelectionChanged}
        getRowId={function (params) {
          const id = hierarchy
            .concat("period")
            .slice(0, params.level + 1)
            .map((k) => params.data[k])
            .join();
          // console.log(params, id);
          return id;
        }}
        rowSelection={"multiple"}
        suppressAggFuncInHeader={true}
        suppressCopyRowsToClipboard={true}
        suppressLastEmptyLineOnPaste={true}
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
        // undoRedoCellEditing={true}
        // undoRedoCellEditingLimit={20}
        getContextMenuItems={(params) => {
          return getContextMenuItems?.(params) || [];
        }}
        {...gridOptions}
        components={{
          constrainedNumberEditor: WrappedConstrainedNumberCellEditor,
          ...gridOptions.components,
        }}
      />
    </Box>
  );
}

export default DataGrid;
