import { KeyboardArrowLeft } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useMemo, useState } from "react";

function ChartControls({
  children,
  amChart,
  am5Exporting,
  chartExportFormats = [],
  isInPresentationMode,
  enterPresentationMode,
  exitPresentationMode,
  presentationModePopOverContainer,
}) {
  const [exportMenuAnchor, setExportMenuAnchor] = useState(null);
  const isExportMenuOpen = useMemo(
    () => exportMenuAnchor != null,
    [exportMenuAnchor]
  );

  function exportHandler(exportFormat) {
    if (am5Exporting == null) {
      return amChart.exporting.export(exportFormat);
    }
    return am5Exporting.download(exportFormat);
  };

  const exportMenuOptions = useMemo(() => {
    return [
      ...(!isInPresentationMode
        ? [{ label: "Full screen", handler: enterPresentationMode }]
        : [{ label: "Exit full screen", handler: exitPresentationMode }]),
      ...chartExportFormats.map(({ format, label }) => ({
        label,
        handler: () => exportHandler(format.toLowerCase()),
      })),
    ];
  }, [
    chartExportFormats,
    isInPresentationMode,
    enterPresentationMode,
    exitPresentationMode,
    amChart,
    am5Exporting,
  ]);

  const controlsTopSpacing = 2.5;

  return (
    <Box sx={{ position: "relative", mx: 2.5, zIndex: 1 }}>
      <Box
        sx={{
          position: "absolute",
          mt: controlsTopSpacing,
        }}
      >
        {children}
      </Box>
      <IconButton
        aria-controls={isExportMenuOpen ? "exportMenu" : undefined}
        aria-haspopup="true"
        aria-expanded={isExportMenuOpen ? "true" : undefined}
        aria-label="Export Menu"
        onClick={(e) => setExportMenuAnchor(e.currentTarget)}
        sx={{ right: 0, position: "absolute", mt: controlsTopSpacing }}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <Menu
        id="exportMenu"
        anchorEl={exportMenuAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isExportMenuOpen}
        onClose={() => setExportMenuAnchor(null)}
        container={presentationModePopOverContainer}
        disableScrollLock={true}
      >
        {exportMenuOptions.map(({ label, handler }) => (
          <MenuItem
            key={label}
            onClick={() => {
              setExportMenuAnchor(null);
              handler();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default ChartControls;
