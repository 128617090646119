import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpointsUrl } from "./endpoints";

export const companyMediaBudgetApi = createApi({
  reducerPath: "companyMediaBudgetApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  endpoints: (builder) => ({
    getCompanyMediaBudget: builder.query({
      query: ({ companyId, mediaChannels, startDate, endDate }) => ({
        url: "company/getMediaBudget",
        method: "POST",
        body: {
          company_id: companyId,
          media_channels: mediaChannels,
          start_date: startDate,
          end_date: endDate,
        },
      }),
      transformResponse: (responseData) => responseData.data,
    }),
  }),
});

export default companyMediaBudgetApi;
export const { useLazyGetCompanyMediaBudgetQuery } = companyMediaBudgetApi;
