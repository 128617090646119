import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpointsUrl } from "./endpoints";

// Define a service using a base URL and expected endpoints
const insightsGridStateApi = createApi({
  reducerPath: "insightsGridStateApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  tagTypes: ["ColState"],
  endpoints: (builder) => ({
    getColState: builder.query({
      query: ({ userId, scenarioId, gridKey }) => ({
        url: "scenarios/getInsightsColState",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          gridKey,
        },
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (_result, _error, { gridKey }) => {
        if (gridKey) return [{ type: "ColState", id: gridKey }, "ColState"];
        return ["ColState"];
      },
    }),
    // TODO handle 304
    setColState: builder.mutation({
      query: ({ userId, scenarioId, gridKey, colState }) => ({
        url: "scenarios/setInsightsColState",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          gridKey,
          colState,
        },
      }),

      async onQueryStarted(
        { userId, scenarioId, gridKey, colState },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          insightsGridStateApi.util.updateQueryData(
            "getColState",
            { userId, scenarioId, gridKey },
            (_draft) => colState
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export default insightsGridStateApi;

export const { useGetColStateQuery, useSetColStateMutation } =
  insightsGridStateApi;
