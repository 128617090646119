import { enqueueSnackbar } from "notistack";
import { AspirationType } from "../../constants";
import optimizationLevelSliderApi from "../OptimizationLevel/optimizationLevelApi";
import curveCountApi from "./curveCountApi";
import scenarioMetadataApi from "../../app/scenarioMetadataApi";

async function getRunStatusSnackParams({
  scenarioId,
  userId,
  dispatch,
  aspiration,
  mediaHierarchy,
  resultsHierarchy,
}) {
  let optimizationLevel;
  let curvesCount;
  let curvesTotal;

  if (
    aspiration == null ||
    mediaHierarchy == null ||
    resultsHierarchy == null
  ) {
    const result = await dispatch(
      scenarioMetadataApi.endpoints.getScenario.initiate({
        userId,
        scenarioId,
      })
    );
    const { data, isSuccess } = result;
    if (!isSuccess) {
      console.error("getScenario", result);
      return;
    }
    aspiration = data.aspiration;
    mediaHierarchy = data.mediaHierarchy;
    resultsHierarchy = data.resultsHierarchy;
  }

  if (
    aspiration === AspirationType.Optimize ||
    aspiration === AspirationType.Grow
  ) {
    const result = await dispatch(
      optimizationLevelSliderApi.endpoints.getOptimizationLevel.initiate({
        userId,
        scenarioId,
      })
    );
    const { data, isSuccess } = result;
    if (!isSuccess || typeof data !== "number") {
      console.error("getOptimizationLevel", result);
      return;
    }
    optimizationLevel = data;
  }

  const result = await dispatch(
    curveCountApi.endpoints.getCurveCount.initiate({
      userId,
      scenarioId,
      aspiration,
      hierarchy: resultsHierarchy,
      optimizationLevel,
    })
  );
  const { data, isSuccess } = result;
  if (!isSuccess) {
    console.error("getCurveCount", result);
    return;
  }
  curvesCount = data.curvesCount;
  curvesTotal = data.curvesTotal;

  return {
    scenarioId,
    userId,
    dispatch,
    aspiration,
    mediaHierarchy,
    resultsHierarchy,
    optimizationLevel,
    curvesCount,
    curvesTotal,
  };
}

export async function addRunStatusSnack({
  scenarioId,
  userId,
  dispatch,
  aspiration: _aspiration,
  mediaHierarchy: _mediaHierarchy,
  resultsHierarchy,
}) {
  const res = await getRunStatusSnackParams({
    scenarioId,
    userId,
    dispatch,
    // optional. will be gathered from mongo / rtk stash
    aspiration: _aspiration,
    mediaHierarchy: _mediaHierarchy,
    resultsHierarchy,
  });
  const {
    aspiration,
    mediaHierarchy,
    optimizationLevel,
    curvesCount,
    curvesTotal,
  } = res;

  if (!curvesCount || !curvesTotal) {
    console.error("no curve info");
    return;
  }

  let message;

  if (aspiration === AspirationType.Simulate) {
    message = `Simulating ${curvesTotal.toLocaleString()} curves.`;
  } else {
    if (optimizationLevel > mediaHierarchy.length) {
      message = `Optimizing ${curvesCount.toLocaleString()} curves.`;
    } else {
      message = `Optimizing ${curvesCount.toLocaleString()} channels. Calculating ${curvesTotal.toLocaleString()} curves.`;
    }
  }

  if (!message) {
    console.error("no message");
    return;
  }
  return enqueueSnackbar(message, {
    variant: "info",
    persist: "true",
  });
}
