import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpointsUrl } from "./endpoints";
import {
  selectMediaHierarchy,
  selectSalesHierarchy,
  selectScenario,
  selectSelectedChart,
  setGrowthTarget,
  setMediaBudget,
  setMediaHierarchy,
  setSalesHierarchy,
  setSelectedChart,
} from "./scenarioSlice";
import { enqueueSnackbar } from "notistack";
import LogRocket from "logrocket";

export const scenarioMetadataApi = createApi({
  reducerPath: "scenarioMetadataApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  tagTypes: ["ScenarioMetadata"],
  endpoints: (builder) => ({
    getScenario: builder.query({
      query: ({ userId, scenarioId }) => ({
        url: "scenarios/get",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
        },
      }),
      transformResponse: (responseData) => {
        const {
          scenario_id,
          scenario_name,
          results_selected_chart,
          growth_target,
          media_budget,
          mediaHierarchy,
          salesHierarchy,
          ...other
        } = responseData.data;
        return {
          ...other,
          scenarioId: scenario_id,
          name: scenario_name,
          selectedChart: results_selected_chart,
          growthTarget: growth_target,
          mediaBudget: media_budget,
          resultsHierarchy: [...mediaHierarchy, ...salesHierarchy],
          mediaHierarchy,
          salesHierarchy,
        };
      },
      providesTags: (_result, _error, { scenarioId }) => {
        if (scenarioId)
          return [
            { type: "ScenarioMetadata", id: scenarioId },
            "ScenarioMetadata",
          ];
        return ["ScenarioMetadata"];
      },
    }),

    setMediaHierarchy: builder.mutation({
      query: ({ userId, scenarioId, mediaHierarchy }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(mediaHierarchy && { mediaHierarchy }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, mediaHierarchy },
        { dispatch, getState, queryFulfilled }
      ) {
        const state = getState();
        const currentMediaHierarchy = selectMediaHierarchy(state);
        if (state.scenario.id === scenarioId) {
          // assumes hierarchy length does not change
          if (!currentMediaHierarchy.every((v, i) => v === mediaHierarchy[i])) {
            dispatch(setMediaHierarchy(mediaHierarchy));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set media hierarchy failed";
          console.error(errorMessage, e);
          dispatch(setMediaHierarchy(currentMediaHierarchy));
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              mediaHierarchy,
              currentMediaHierarchy,
            },
          });
        }
      },
    }),

    setSalesHierarchy: builder.mutation({
      query: ({ userId, scenarioId, salesHierarchy }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(salesHierarchy && { salesHierarchy }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, salesHierarchy },
        { dispatch, getState, queryFulfilled }
      ) {
        const state = getState();
        const currentSalesHierarchy = selectSalesHierarchy(state);
        if (state.scenario.id === scenarioId) {
          // assumes hierarchy length does not change
          if (!currentSalesHierarchy.every((v, i) => v === salesHierarchy[i])) {
            dispatch(setSalesHierarchy(salesHierarchy));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set sales hierarchy failed";
          console.error(errorMessage, e);
          dispatch(setSalesHierarchy(currentSalesHierarchy));
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              salesHierarchy,
              currentSalesHierarchy,
            },
          });
        }
      },
    }),

    setSelectedChart: builder.mutation({
      query: ({ userId, scenarioId, selectedChart }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(selectedChart && { results_selected_chart: selectedChart }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, selectedChart },
        { dispatch, getState, queryFulfilled }
      ) {
        if (scenarioId == null) {
          console.error("Cannot call setSelectedChart without a scenario id");
        }
        const state = getState();
        const isScenarioReady = state.scenario.isScenarioReady;
        if (!isScenarioReady) {
          console.error(
            "Cannot call setSelectedChart while scenario slice is not ready"
          );
        }
        const currentSelectedChart = selectSelectedChart(state);
        if (isScenarioReady && scenarioId === state.scenario.id) {
          if (currentSelectedChart !== selectedChart) {
            dispatch(setSelectedChart(selectedChart));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Persist selected chart failed";
          console.error(errorMessage, e);
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              selectedChart,
              currentSelectedChart,
            },
          });
        }
      },
    }),

    setMediaBudget: builder.mutation({
      query: ({ userId, scenarioId, mediaBudget }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(mediaBudget && { media_budget: mediaBudget }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, mediaBudget },
        { dispatch, getState, queryFulfilled }
      ) {
        const state = getState();
        const { mediaBudget: currentMediaBudget } = selectScenario(state);
        if (scenarioId === state.scenario.id) {
          if (currentMediaBudget !== mediaBudget) {
            dispatch(setMediaBudget(mediaBudget));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set media budget failed";
          console.error(errorMessage, e);
          dispatch(setMediaBudget(currentMediaBudget));
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              mediaBudget,
              currentMediaBudget,
            },
          });
        }
      },
    }),

    setGrowthTarget: builder.mutation({
      query: ({ userId, scenarioId, growthTarget }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            ...(growthTarget && { growth_target: growthTarget }),
          },
        },
      }),
      invalidatesTags: (_result, error, { scenarioId }) => {
        if (error?.status === 304) return;
        return [{ type: "ScenarioMetadata", id: scenarioId }];
      },
      async onQueryStarted(
        { userId, scenarioId, growthTarget },
        { dispatch, getState, queryFulfilled }
      ) {
        const state = getState();
        const { growthTarget: currentGrowthTarget } = selectScenario(state);
        if (scenarioId === state.scenario.id) {
          if (currentGrowthTarget !== growthTarget) {
            dispatch(setGrowthTarget(growthTarget));
          }
        }
        try {
          await queryFulfilled;
        } catch (e) {
          const errorMessage = "Set growth target failed";
          console.error(errorMessage, e);
          dispatch(setGrowthTarget(currentGrowthTarget));
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              scenarioId,
              scenarioSliceId: state.scenario.id,
              growthTarget,
              currentGrowthTarget,
            },
          });
        }
      },
    }),
  }),
});

export default scenarioMetadataApi;
export const {
  useGetScenarioQuery,
  useSetMediaHierarchyMutation,
  useSetSalesHierarchyMutation,
  useSetSelectedChartMutation,
  useSetMediaBudgetMutation,
  useSetGrowthTargetMutation,
} = scenarioMetadataApi;
