import { useVariableValue } from "@devcycle/react-client-sdk";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseRounded as CloseRoundedIcon } from "@mui/icons-material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useGetCompanyNamesQuery } from "../../app/companyApi";
import {
  useDeleteLoadMutation,
  useGetUserQuery,
  useImportLoadMutation,
  useSetCurrentLoadMutation,
} from "../../app/userApi";
import ActionModal from "../../components/ActionModal/ActionModal";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection";
import UserContext from "../../context/userContext";

function SettingsModal({ isOpen, close }) {
  const importModelFlag = useVariableValue("import-model", false);
  const deleteModelFlag = useVariableValue("delete-model", false);

  const userContext = useContext(UserContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    data: user,
    isLoading: userIsLoading,
    isFetching: userIsFetching,
    isSuccess: userIsSuccess,
  } = useGetUserQuery(
    {
      userId: userContext.userID,
    },
    {
      skip: userContext?.userID == null,
    }
  );

  const {
    data: companiesWithNameNames,
    isLoading: companiesWithNameNamesIsLoading,
    isFetching: companiesWithNameNamesIsFetching,
    isSuccess: companiesWithNameNamesIsSuccess,
  } = useGetCompanyNamesQuery(
    {
      userId: userContext.userID,
      companyIds: (() => {
        if (user == null) {
          return;
        }
        if (user?.loads == null) {
          return [user.companyId];
        }
        if (!user.loads.includes(user.companyId) && user.companyId != null) {
          return user.loads.concat(user.companyId);
        }
        return user.loads;
      })(),
    },
    {
      skip:
        userContext?.userID == null ||
        user == null ||
        userIsLoading ||
        !userIsSuccess,
    }
  );

  const isLoadingCompaniesWithNameNames = useMemo(
    () =>
      companiesWithNameNamesIsLoading ||
      !companiesWithNameNamesIsSuccess ||
      companiesWithNameNamesIsFetching,
    [
      companiesWithNameNamesIsLoading,
      companiesWithNameNamesIsSuccess,
      companiesWithNameNamesIsFetching,
    ]
  );

  const sortedCompanies = useMemo(() => {
    if (companiesWithNameNames == null) return [];
    return companiesWithNameNames
      .slice()
      .sort((a, b) => b.name.localeCompare(a.name));
  }, [isLoadingCompaniesWithNameNames, companiesWithNameNames]);

  const isLoading = useMemo(
    () => isLoadingCompaniesWithNameNames,
    [isLoadingCompaniesWithNameNames]
  );

  const [load, setLoad] = useState(null);
  const [initialLoad, setInitialLoad] = useState(null);

  const loadName = useMemo(() => {
    if (isLoading) return null;
    return (
      companiesWithNameNames.find((c) => c.companyId === load)?.name ?? null
    );
  }, [load, isLoading, companiesWithNameNames]);

  const [deleteLoadName, setDeleteLoadName] = useState("");

  useEffect(() => {
    setDeleteLoadName("");
  }, [load]);

  const deleteLoadNameMatchesCurrent = useMemo(() => {
    if (loadName == null) return false;
    return deleteLoadName.trim() === loadName;
  });

  const deleteAllowed = useMemo(() => {
    if (isLoading || userContext.userID == null) {
      return false;
    }
    const c = companiesWithNameNames.find((c) => c.companyId === load);

    return c?.isDeletable ?? false;
  }, [load, isLoading, userContext.userID]);

  // initialize load
  useEffect(() => {
    if (user == null) return;
    // set on open or close
    setLoad(user.companyId);
    setInitialLoad(user.companyId);
  }, [isOpen, user]);

  const [setCurrentLoad] = useSetCurrentLoadMutation();

  const handleSubmit = useCallback(async () => {
    if (userContext.userID == null) return;
    if (initialLoad === load) {
      close();
      return;
    }
    const {error} = await setCurrentLoad({
      userId: userContext.userID,
      companyId: load,
      companyName: companiesWithNameNames.find((c) => c.companyId === load)
        ?.name,
    });
    if (error == null) {
      close();
      setLoad(initialLoad);
    }
  }, [initialLoad, load, userContext.userID]);

  const [importLoad, { isLoading: isImportingLoad }] = useImportLoadMutation();

  const importLoadHandler = useCallback(
    async (file) => {
      const response = await importLoad({
        userId: userContext.userID,
        file,
      });

      let { message, status: variant } =
        (response.error ?? response).data ?? {};

      if (message == null && response.error) {
        message = "Import failed: unexpected error";
        variant = "error";
        console.error(response);
      }

      enqueueSnackbar(message, {
        variant,
        autoHideDuration: variant === "success" ? 3000 : null,
        noCloseButton: variant === "success",
      });
    },
    [userContext.userID, enqueueSnackbar, closeSnackbar]
  );

  const [deleteLoad, { isLoading: isDeletingLoad }] = useDeleteLoadMutation();

  const handleDeleteLoad = useCallback(
    async (companyId) => {
      const response = await deleteLoad({
        userId: userContext.userID,
        companyId,
      });

      let message;
      let variant;

      if (response.error == null) {
        message = "Model deleted";
        variant = "success";
      } else {
        message = "Model delete failed";
        variant = "error";
      }

      if (message == null && response.error) {
        message = "Import failed: unexpected error";
        variant = "error";
        console.error(response);
      }

      enqueueSnackbar(message, {
        variant,
        autoHideDuration: variant === "success" ? 3000 : null,
        noCloseButton: variant === "success",
      });
    },
    [userContext.userID, enqueueSnackbar, closeSnackbar]
  );

  return (
    <ActionModal
      name="settings"
      open={isOpen}
      handleClose={close}
      title="Settings"
      primaryButton={{
        text: "Save",
        onClick: handleSubmit,
      }}
      secondaryButton={{ text: "Cancel", onClick: close }}
    >
      <LoadingSection isLoading={isLoading} type={LoadingType.Cover}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            py: 2,
          }}
        >
          <FormControl fullWidth>
            <InputLabel
              id="load-select-label"
              sx={isLoading ? { zIndex: 0 } : {}} // Fix issues with labels showing through loader
            >
              Model
            </InputLabel>
            <Select
              labelId="load-select-label"
              id="load-select"
              value={
                load != null &&
                (user.loads?.includes?.(load) || user.companyId === load)
                  ? load
                  : ""
              }
              label="Model"
              onChange={(event) => setLoad(event.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 36 * 6.5 + 8,
                  },
                },
              }}
            >
              {sortedCompanies.map(({ companyId, name }) => (
                <MenuItem key={companyId} value={companyId}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {importModelFlag ? (
            <FormControl fullWidth>
              <LoadingButton
                loading={isImportingLoad}
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<FileUploadIcon />}
                sx={{
                  lineHeight: "2.5em",
                  backgroundColor: "transparent!important", // remove hover effect
                  borderWidth: "2px!important",
                  borderStyle: "dashed!important",
                  borderColor: "rgba(41, 171, 226, 0.5)!important", // remove hover effect
                }}
                onClick={() => {
                  const input = document.createElement("input");
                  input.accept =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                  input.type = "file";
                  input.onchange = () => importLoadHandler(input.files[0]);
                  input.click();
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  importLoadHandler(e.dataTransfer.files[0]);
                }}
                onDragOver={(e) => {
                  // prevent download of file and fix drop not firing
                  e.preventDefault();
                }}
              >
                Upload Model
              </LoadingButton>
            </FormControl>
          ) : (
            <></>
          )}
          {deleteModelFlag ? (
            <FormControl fullWidth>
              <Grid container>
                <Grid item xs={10}>
                  <TextField
                    sx={{ width: "100%", paddingRight: "1em" }}
                    error
                    label="Delete Model"
                    helperText={
                      deleteAllowed
                        ? `Enter "${loadName}" to delete this model`
                        : `You cannot delete this model`
                    }
                    value={deleteLoadName}
                    onChange={(event) => {
                      setDeleteLoadName(event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        ...(!deleteAllowed && {
                          color: "rgba(0, 0, 0, 0.6)!important",
                        }),
                        ...(isLoading ? { zIndex: 0 } : {}), // Fix issues with labels showing through loader
                      },
                    }}
                    FormHelperTextProps={{
                      sx: { color: "rgba(0, 0, 0, 0.6)!important" },
                    }}
                    placeholder={loadName}
                    disabled={!deleteAllowed}
                  />
                </Grid>
                <Grid item xs={2}>
                  <LoadingButton
                    loading={isDeletingLoad}
                    variant="contained"
                    color="error"
                    sx={{ height: "56px" }}
                    disabled={!deleteAllowed || !deleteLoadNameMatchesCurrent}
                    onClick={() => {
                      setDeleteLoadName("");
                      handleDeleteLoad(load);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                  </LoadingButton>{" "}
                </Grid>
              </Grid>
            </FormControl>
          ) : (
            <></>
          )}
        </Box>
      </LoadingSection>
    </ActionModal>
  );
}

export default SettingsModal;
