export const applyColState = (columnApi, colState) => {
  // Apply column state
  columnApi.applyColumnState({
    state: colState,
    applyOrder: true,
  });
};

export const setColState = (columnApi, scenarioContext, colStateKey) => {
  // Get col state
  let colStateValue = columnApi.getColumnState().map((item) => ({
    colId: item.colId,
    hide: item.hide,
    pinned: item.pinned !== null ? item.pinned : "",
  }));

  // Set col state
  scenarioContext.setScenario((prevScenario) => {
    let newScenario = { ...prevScenario };
    newScenario[colStateKey] = colStateValue;
    return newScenario;
  });
};

export const applyRowState = (api, rowState) => {
    // Apply row state
    let i = 0;
    api.forEachNode((node) => {
      node.setExpanded(
        rowState?.[i]?.expanded !== undefined ? rowState?.[i]?.expanded : false
      );
      node.setSelected(
        rowState?.[i]?.selected !== undefined ? rowState?.[i]?.selected : true
      );
      i++;
    });
  };

// export const setRowState = (api, scenarioContext, rowStateKey) => {
//   // Get row state
//   let rowStateValue = [];
//   api.forEachNode((node) => {
//     rowStateValue.push({
//       expanded: node.expanded,
//       selected: node.selected,
//     });
//   });

//   // Set row state
//   scenarioContext.setScenario((prevScenario) => {
//     let newScenario = { ...prevScenario };
//     newScenario[rowStateKey] = rowStateValue;
//     return newScenario;
//   });
// };
