import { enqueueSnackbar } from "notistack";

export function addRunMessage({ status, overrideVariant, overrideMessage }) {
  const message = overrideMessage ?? getMessageFromStatus(status);
  const variant = overrideVariant ?? getVariantFromStatus(status);
  return enqueueSnackbar(message, {
    variant,
    autoHideDuration: getOpenTimeFromVariant(variant),
  });
}

function getMessageFromStatus(status) {
  switch (status) {
    case 0:
      return "Scenario succeeded!";
    case 1:
      return "Scenario solved to acceptable level.";
    case 2:
      return "Scenario infeasible.";
    case 3:
      return "Scenario failed. Search direction too small.";
    case 4:
      return "Scenario failed. Diverging iterates.";
    case 5:
      return "Scenario failed. User requested stop.";
    case -1:
      return "Scenario failed. Maximum iterations exceeded.";
    case -2:
      return "Scenario failed. Restoration failed.";
    case -3:
      return "Scenario failed. Error in step computation.";
    case -4:
      return "Scenario failed. CPU time exceeded.";
    case -5:
      return "Scenario failed. Wall time exceeded.";
    case -10:
      return "Scenario failed. Not enough degrees of freedom.";
    case -11:
      return "Scenario failed. Invalid problem definition.";
    case -12:
      return "Scenario failed. Invalid option.";
    case -13:
      return "Scenario failed. Invalid number detected.";
    case -100:
      return "Scenario failed. Unrecoverable exception.";
    case -101:
      return "Scenario failed. Non IPOPT exception.";
    case -102:
      return "Scenario failed. Insufficient memory.";
    case -199:
      return "Scenario failed. Internal Error.";
    default:
      return "Scenario failed.";
  }
}

function getVariantFromStatus(status) {
  switch (status) {
    case 0:
    case 1:
      return "success";
    case 2:
      return "warning";
    default:
      return "error";
  }
}

function getOpenTimeFromVariant(variant) {
  switch (variant) {
    case "warning":
    case "error":
      return 5000;
    case "success":
    default:
      return 2000;
  }
}
