import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import ResultsGrid from "./ResultsGrid";
import PlanChart from "./PlanChart/PlanChart";
import ResultsSummaryChart from "./ResultsSummaryChart";
import ResultsWaterfallChart from "./ResultsWaterfallChart";
import Header from "../../components/Header/Header";
import TabbedContent from "../../components/TabbedContent/TabbedContent";
import ResultsDetailChart from "./ResultsDetailChart";
import Sliders from "./Sliders/Sliders";
import SalesGrid from "./SalesGrid";
import MediaGrid from "./MediaGrid";
import { GridKeyEnum } from "../../scenarioDataUtils/scenarioDataUtils";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ChartControls from "./ChartControls";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection";
import CurvesChart from "./CurvesChart/CurvesChart";

import { addLicense as am4AddLicense } from "@amcharts/amcharts4/core";
import { addLicense as am5AddLicense } from "@amcharts/amcharts5";
import { useDispatch, useSelector } from "react-redux";
import {
  addToSwitchingState,
  selectScenario,
  setSelectedChart,
} from "../../app/scenarioSlice";
// import { selectExpansionData } from "../../app/scenarioSlice";
am4AddLicense("CH368908636");
am5AddLicense("AM5C368908636");

import * as am4core from "@amcharts/amcharts4/core";
import scenarioMetadataApi, {
  useSetSelectedChartMutation,
} from "../../app/scenarioMetadataApi";
am4core.options.autoDispose = true;

const headerHeight = 64;
const tabsHeight = 50; // TODO fix. it's actually 49 and the other px is on the bottom
const presentationLogoHeight = 64;
const bottomPadding = 24;
const chartContainerHeight = {
  min: 300 + tabsHeight,
  max: 520 + tabsHeight,
  spacing: headerHeight,
};
const gridHeight = {
  min: 319,
  max: "100%",
  spacing: headerHeight + bottomPadding,
};

const ChartKeyEnum = {
  Summary: "summary",
  Detail: "detail",
  Story: "waterfall",
  Plan: "plan",
  Tactic: "tactic",
  PresentationModeResultsGrid: "presentationModeResultsGrid",
};

const MyResults = () => {
  const dispatch = useDispatch();
  const {
    id: scenarioId,
    userId,
    isScenarioReady,
    switchingToId,
    name: scenarioName,
    selectedChart,
  } = useSelector(selectScenario);

  const [chartTab, setChartTab] = useState(0);
  const [setSelectedChart] = useSetSelectedChartMutation();
  // chartTab and presentationModeChartTab are synced unless presentationModeChartTab is a presentation mode only tab
  const [presentationModeChartTab, setPresentationModeChartTab] = useState(0);
  const [gridTab, setGridTab] = useState(0);

  const [chartsData, setChartsData] = useState(
    Object.values(ChartKeyEnum).reduce(
      (acc, k) => ({
        ...acc,
        [k]: {
          seriesData: null, // the data used to construct the series of the chart.
          metadata: null, // the data about the chart (current shown series, ect.). Includes any data that needs to be persisted on tab change
        },
      }),
      {}
    )
  );

  const presentationModeFullscreenHandler = useFullScreenHandle();
  const presentationModePopOverContainerRef = useRef();

  const isInPresentationMode = useMemo(() => {
    return presentationModeFullscreenHandler.active;
  }, [presentationModeFullscreenHandler.active]);

  function setChartSeriesData(chartKey, seriesData) {
    setChartsData((prevState) => ({
      ...prevState,
      [chartKey]: {
        ...prevState[chartKey],
        seriesData:
          typeof seriesData === "function"
            ? seriesData(prevState[chartKey].seriesData)
            : seriesData,
      },
    }));
  }

  function setChartMetadata(chartKey, metadata) {
    setChartsData((prevState) => ({
      ...prevState,
      [chartKey]: {
        ...prevState[chartKey],
        metadata:
          typeof metadata === "function"
            ? metadata(prevState[chartKey].metadata)
            : metadata,
      },
    }));
  }

  const presentationModeOnlyChartTabs = [
    ChartKeyEnum.PresentationModeResultsGrid,
  ];

  // Decides ordering of the tabs.
  // Maps tab indexes stored in `chartTab` to the tab key used in the db.
  const selectedChartKeys = [
    ChartKeyEnum.Summary,
    ChartKeyEnum.Detail,
    ChartKeyEnum.Story,
    ChartKeyEnum.Plan,
    ChartKeyEnum.Tactic,
    ...(isInPresentationMode ? presentationModeOnlyChartTabs : []),
  ];

  function getChartTabIndex(key) {
    for (let index = 0; index < selectedChartKeys.length; index++) {
      if (selectedChartKeys[index] === key) return index;
    }
    return 0;
  }

  function getChartTabKey(index) {
    return selectedChartKeys[index] ? selectedChartKeys[index] : null;
  }

  function wrapTabContent(getContent, sizingOptions) {
    return <Box sx={sizingOptions}>{getContent()}</Box>;
  }

  function wrapChart(getContent) {
    return wrapTabContent(getContent, {
      margin: "0 1em",
      paddingBottom: "1em",
      height: `calc(100vh - ${presentationLogoHeight}px - ${tabsHeight}px)`,
      ...(!isInPresentationMode && {
        height: `calc(100vh - ${chartContainerHeight.spacing}px - ${tabsHeight}px - 1em)`,
        minHeight: chartContainerHeight.min - tabsHeight,
        maxHeight: chartContainerHeight.max - tabsHeight,
      }),
    });
  }

  function wrapGrid(getContent) {
    return wrapTabContent(getContent, {
      minHeight: gridHeight.min,
      maxHeight: gridHeight.max,
      height: `calc(100vh - ${gridHeight.spacing}px)`,
    });
  }

  const tabsData = [];

  function addChartToTabsData(ChartTag, label, key, other) {
    tabsData.push({
      label,
      key,
      content: (() => {
        let content = (
          <ChartTag
            data={chartsData[key].seriesData}
            setData={(data) => setChartSeriesData(key, data)}
            metadata={chartsData[key].metadata}
            setMetadata={(metadata) => setChartMetadata(key, metadata)}
            isInPresentationMode={isInPresentationMode}
            enterPresentationMode={presentationModeFullscreenHandler.enter}
            exitPresentationMode={presentationModeFullscreenHandler.exit}
            presentationModePopOverContainer={
              presentationModePopOverContainerRef.current
            }
            {...other}
            {...(key === ChartKeyEnum.PresentationModeResultsGrid && {
              sx: {
                height: "100%",
                width: "100%",
              },
            })}
          />
        );
        if (key === ChartKeyEnum.PresentationModeResultsGrid) {
          // add title to results grid
          // content.sx = {height: "100%", width: "100%"};
          const chartOffset = 80; // px
          content = (
            <Box sx={{ height: "100%", width: "100%" }}>
              <Box
                sx={{
                  height: "100%",
                  width: "calc(100% - 2em)",
                  margin: "0 1em",
                }}
              >
                <ChartControls
                  presentationModePopOverContainer={
                    presentationModePopOverContainerRef.current
                  }
                  isInPresentationMode={isInPresentationMode}
                  enterPresentationMode={
                    presentationModeFullscreenHandler.enter
                  }
                  exitPresentationMode={presentationModeFullscreenHandler.exit}
                ></ChartControls>
                <Box
                  sx={{
                    height: `${chartOffset}px`,
                    width: "100px",
                    margin: "0 auto",
                    pt: "30px",
                  }}
                >
                  <Typography
                    component={"div"}
                    sx={{ fontSize: 20, whiteSpace: "nowrap" }}
                  >
                    Results Table
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: `calc(100% - ${chartOffset}px)`,
                    width: "100%",
                  }}
                >
                  {content}
                </Box>
              </Box>
            </Box>
          );
        }

        return wrapChart(() => content);
      })(),
    });
  }

  addChartToTabsData(ResultsSummaryChart, "Summary", ChartKeyEnum.Summary);
  addChartToTabsData(ResultsDetailChart, "Detail", ChartKeyEnum.Detail);
  addChartToTabsData(ResultsWaterfallChart, "Story", ChartKeyEnum.Story);
  addChartToTabsData(PlanChart, "Series", ChartKeyEnum.Plan);
  addChartToTabsData(CurvesChart, "Curves", ChartKeyEnum.Tactic);
  if (isInPresentationMode) {
    addChartToTabsData(
      ResultsGrid,
      "Results",
      ChartKeyEnum.PresentationModeResultsGrid,
      {
        gridKey: GridKeyEnum.Results,
        isInPresentationMode,
        isPresenter: true,
      }
    );
  }

  tabsData.sort((item1, item2) => {
    return getChartTabIndex(item1.key) - getChartTabIndex(item2.key);
  });

  const gridTabsData = [];
  const gridTabToKeyMap = {};

  function addGridToTabsData(GridTag, label, key, gridOptions) {
    gridTabsData.push({
      label,
      key,
      content: (
        <>
          {wrapGrid(() => (
            <LoadingSection
              isLoading={!isScenarioReady}
              type={LoadingType.Cover}
            >
              <GridTag {...gridOptions} gridKey={key} />
            </LoadingSection>
          ))}
        </>
      ),
    });
    gridTabToKeyMap[gridTabsData.length - 1] = key;
  }

  addGridToTabsData(ResultsGrid, "Results", GridKeyEnum.Results, {
    isInPresentationMode,
    isPresenter: false,
  });

  addGridToTabsData(MediaGrid, "Media", GridKeyEnum.Media);

  addGridToTabsData(SalesGrid, "Sales", GridKeyEnum.Sales);

  function setChartTabHandler(newIndex) {
    const selectedChart = getChartTabKey(newIndex);
    if (scenarioId == null) {
      console.warn("scenario is unavailable");
      return;
    } else if (isScenarioReady) {
      setSelectedChart({ userId, scenarioId, selectedChart });
    } else {
      dispatch(addToSwitchingState(["selectedChart", selectedChart]));
    }
  }

  function setGridTabHandler(newIndex) {
    setGridTab(newIndex);
  }

  useEffect(() => {
    if (!isInPresentationMode) {
      setPresentationModeChartTab(chartTab);
    }
  }, [isInPresentationMode, chartTab]);

  useEffect(() => {
    if (isScenarioReady && selectedChart != null) {
      const chartTabIndex = getChartTabIndex(selectedChart);
      setPresentationModeChartTab(chartTabIndex);
      if (presentationModeOnlyChartTabs.includes(selectedChart)) {
        return;
      }
      setChartTab(chartTabIndex);
    }
  }, [isScenarioReady, selectedChart]);

  return (
    <Box>
      <Header title="My Insights" subtitle="drive your business results">
        {isScenarioReady ? scenarioName || "" : ""}
      </Header>

      <Box mt={2.5}>
        <Grid container spacing={3}>
          <Grid container item spacing={3}>
            <Grid item xs={3}>
              <Box
                sx={{
                  minHeight: chartContainerHeight.min,
                  maxHeight: chartContainerHeight.max,
                  height: `calc(100vh - ${chartContainerHeight.spacing}px)`,
                }}
              >
                <Sliders />
              </Box>
            </Grid>
            <Grid item xs={9}>
              <FullScreen handle={presentationModeFullscreenHandler}>
                <TabbedContent
                  id={"results-charts"}
                  value={
                    isInPresentationMode ? presentationModeChartTab : chartTab
                  }
                  setValue={setChartTabHandler}
                  ariaLabel={"Chart Selection"}
                  variant="scrollable"
                  tabsData={tabsData}
                  isInPresentationMode={isInPresentationMode}
                  ref={presentationModePopOverContainerRef}
                >
                  {isInPresentationMode ? (
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Box
                        item
                        component="img"
                        src="Logo_White_Horizontal.svg"
                        alt="Optimetry"
                        pb={1.5}
                        sx={{
                          height: `${presentationLogoHeight}px`,
                          maxWidth: "90vw",
                          filter: "brightness(90%)", // use https://codepen.io/sosuke/pen/Pjoqqp for more complicated colors
                        }}
                      ></Box>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </TabbedContent>
              </FullScreen>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TabbedContent
              id={"results-grids"}
              value={gridTab}
              setValue={setGridTabHandler}
              ariaLabel={"Grid Selection"}
              variant="scrollable"
              tabsData={gridTabsData}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MyResults;
