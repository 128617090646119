import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpointsUrl } from "../../app/endpoints";

// Define a service using a base URL and expected endpoints
const createScenarioApi = createApi({
  reducerPath: "createScenarioApi",
  baseQuery: fetchBaseQuery({
    baseUrl: endpointsUrl,
  }),
  endpoints: (builder) => ({
    createScenario: builder.mutation({
      query: ({
        company_id,
        user_id,
        scenario_id,
        scenario_name,
        start_date,
        end_date,
        media_channels,
        aspiration,
        change_in_spend,
        media_budget,
        growth_target,
      }) => ({
        url: "scenario/createScenario",
        method: "POST",
        body: {
          company_id,
          user_id,
          scenario_id,
          scenario_name,
          start_date,
          end_date,
          media_channels,
          aspiration,
          change_in_spend,
          media_budget,
          growth_target,
        },
      }),
    }),
  }),
});

export default createScenarioApi;

export const { useCreateScenarioMutation } = createScenarioApi;
