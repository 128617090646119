export function initializeChannelTree(keys) {
  return keys.reduce((acc, k) => ({ ...acc, [k]: {} }), {});
}

export function getNewNode(treeKeys, shortTarget, initialData = {}) {
  const node = {
    target: shortTarget,
    children: {},
    data: { ...initialData },
  };
  node.id = getShortName(treeKeys, shortTarget);
  const nodeKeys = treeKeys.filter((k) => shortTarget[k] !== undefined);
  node.levelId = shortTarget[nodeKeys.at(-1)];
  return node;
}

// TODO this can have collisions.
// The hierarchy would need to be purposely made to cause an issue though
export function getShortName(treeKeys, target) {
  return treeKeys
    .reduce(
      (acc, k) => (target[k] === undefined ? acc : acc.concat(target[k])),
      []
    )
    .join(" ");
}

export function getFullId(treeKeys, target) {
  return treeKeys.map((k) => target[k] ?? "").join(" ");
}

export function getParentsFullId(nodeKeys, node) {
  return nodeKeys
    .slice(0, -1)
    .reduce((acc, k) => ({ ...acc, [k]: node.target[k] }), {});
}

export function getNewShortTarget(shortKeys, target) {
  // not sure about what to do with undefined values here
  return shortKeys.reduce((acc, k) => ({ ...acc, [k]: target[k] ?? null }), {});
}

export function getNodeKeys(treeKeys, shortTarget) {
  return treeKeys.filter((k) => shortTarget[k] !== undefined);
}

export function getChildTarget(treeKeys, shortTarget, childLevelId) {
  const shortKeysLength = getNodeKeys(treeKeys, shortTarget).length;
  return treeKeys.slice(0, shortKeysLength + 1).reduce(
    (acc, k, i) => ({
      ...acc,
      [k]: i < shortKeysLength ? shortTarget[k] : childLevelId,
    }),
    {}
  );
}

export function nodeHasChildren(node) {
  return Object.keys(node.children).length !== 0;
}

export function getShortKeys(target, keys) {
  return keys.slice(
    0,
    keys.reduce((acc, k, i) => {
      if (target[k] != null) {
        return i + 1;
      }
      return acc;
    }, 0)
  );
}

export function fullTargetToShortTarget(target, keys) {
  const shortKeys = getShortKeys(target, keys);
  return getNewShortTarget(shortKeys, target);
}

export class ChannelTreeError extends Error {
  constructor(message, cause) {
    super(message, cause);
    this.name = "ChannelTreeError";
  }
}
