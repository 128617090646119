import { createSlice } from "@reduxjs/toolkit";

function changeChangeInSpend(changeInSpend) {
  return {
    changeInSpend: changeInSpend.float,
    changeInSpendString: changeInSpend.string,
  };
}

function changeMediaBudget(mediaBudget) {
  return {
    mediaBudget: mediaBudget.float,
    mediaBudgetString: mediaBudget.string,
  };
}

function changeGrowthTarget(growthTarget) {
  return {
    growthTarget: growthTarget.float,
    growthTargetString: growthTarget.string,
  };
}

function _setChangeInSpend(state, action) {
  return {
    ...state,
    ...changeChangeInSpend(action.payload),
  };
}

function _setMediaBudget(state, action) {
  return {
    ...state,
    ...changeMediaBudget(action.payload),
  };
}

function _setGrowthTarget(state, action) {
  return {
    ...state,
    ...changeGrowthTarget(action.payload),
  };
}

export const newScenarioWizardSlice = createSlice({
  name: "newScenarioWizard",
  initialState: {
    activeStep: 0,
    loading: true,
  },
  reducers: {
    incrementActiveStep: (state) => {
      state.activeStep += 1;
    },
    decrementActiveStep: (state) => {
      state.activeStep -= 1;
    },
    setInitialState: (_, action) => {
      const {
        payload: {
          startDate,
          endDate,
          salesChannels,
          mediaChannels,
          changeInSpend,
          mediaBudget,
          growthTarget,
        },
      } = action;
      return {
        activeStep: 0,
        startDate: startDate,
        endDate: endDate,
        salesChannels: salesChannels,
        mediaChannels: mediaChannels,
        ...(changeInSpend && changeChangeInSpend(changeInSpend)),
        ...(mediaBudget && changeMediaBudget(mediaBudget)),
        ...(growthTarget && changeGrowthTarget(growthTarget)),
        loading: false,
      };
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setMediaChannels: (state, action) => {
      state.mediaChannels = action.payload;
    },
    setChangeInSpend: (state, action) => {
      return _setChangeInSpend(state, action);
    },
    setMediaBudget: (state, action) => {
      return _setMediaBudget(state, action);
    },
    setGrowthTarget: (state, action) => {
      return _setGrowthTarget(state, action);
    },
    setChangeInSpendNoUndo: (state, action) => {
      return _setChangeInSpend(state, action);
    },
    setMediaBudgetNoUndo: (state, action) => {
      return _setMediaBudget(state, action);
    },
    setGrowthTargetNoUndo: (state, action) => {
      return _setGrowthTarget(state, action);
    },
  },
});

export const {
  incrementActiveStep,
  decrementActiveStep,
  setInitialState,
  setMediaChannels,
  setChangeInSpend,
  setMediaBudget,
  setGrowthTarget,
  setChangeInSpendNoUndo,
  setMediaBudgetNoUndo,
  setGrowthTargetNoUndo,
  setStartDate,
  setEndDate,
} = newScenarioWizardSlice.actions;

export const selectNewScenarioWizard = (state) => ({
  ...state.newScenarioWizard.present,
  canUndo: state.newScenarioWizard.past.length > 0,
  canRedo: state.newScenarioWizard.future.length > 0,
});

export default newScenarioWizardSlice.reducer;
