import { useContext, useEffect } from "react";
import WizardDialog from "../../components/Wizard/WizardDialog";
import WizardDialogTitle from "../../components/Wizard/WizardDialogTitle";
import WizardStepper from "../../components/Wizard/WizardStepper";
import NewScenarioPeriod from "./NewScenarioPeriod";
import NewScenarioChannels from "./NewScenarioChannels";
import NewScenarioQuestion from "./NewScenarioQuestion";
import UserContext from "../../context/userContext";
import AppContext from "../../context/appContext";
import { useIntl } from "react-intl";
import { AspirationType } from "../../constants";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNewScenarioWizard,
  setInitialState,
  setMediaBudgetNoUndo,
} from "../../containers/NewScenarioWizard/newScenarioWizardSlice";
import { ActionCreators } from "redux-undo";
import { getFormatters } from "../GridUtils";
import { optimizationInfinity } from "../../scenarioDataUtils/scenarioDataUtils";
import {
  addIsSwitchingScenario,
  selectScenario,
} from "../../app/scenarioSlice";
import scenariosApi from "../../app/scenariosApi";
import { useGetCompanyQuery } from "../../app/companyApi";
import { useLazyGetCompanyMediaBudgetQuery } from "../../app/companyMediaBudgetApi";
import { useCreateScenarioMutation } from "./createScenarioApi";
import { useGetUserQuery } from "../../app/userApi";

export const defaultChangeInSpend = 0.1;
export const defaultGrowthTarget = 0.05;

export function createScenarioName({
  aspiration,
  changeInSpend,
  mediaBudget,
  growthTarget,
  formatters,
}) {
  switch (aspiration) {
    case AspirationType.Simulate:
      return `Simulate My ${formatters.percentOnePossible({
        value: changeInSpend,
      })} Change in Spend`;
    case AspirationType.Optimize:
      return `Optimize My ${formatters.currencyWithoutCents({
        value: mediaBudget,
      })} Budget`;
    case AspirationType.Grow:
      return `Grow My Sales by ${formatters.percentOnePossible({
        value: growthTarget,
      })}`;
  }
}

export default function NewScenarioWizard({ aspiration, open, setOpen }) {
  const userContext = useContext(UserContext);

  const {
    data: user,
    isLoading: userIsLoading,
    isFetching: userIsFetching,
    isSuccess: userIsSuccess,
  } = useGetUserQuery(
    {
      userId: userContext.userID,
    },
    {
      skip: userContext?.userID == null,
    }
  );

  const {
    data: company,
    isLoading: companyIsLoading,
    isFetching: companyIsFetching,
    isSuccess: companyIsSuccess,
  } = useGetCompanyQuery(
    {
      companyId: user?.companyId,
    },
    {
      skip: user == null || userIsLoading || !userIsSuccess,
    }
  );

  const [getCompanyMediaBudget] = useLazyGetCompanyMediaBudgetQuery();

  const appContext = useContext(AppContext);

  const [errors, setErrors] = useState({});
  const [scenarioId, setScenarioId] = useState(null);

  const {
    startDate,
    endDate,
    mediaChannels,
    changeInSpend,
    mediaBudget,
    growthTarget,
    loading: wizardDataIsLoading,
    canUndo,
    canRedo,
  } = useSelector(selectNewScenarioWizard);

  const { id: selectedScenarioId, switchingToId } = useSelector(selectScenario);

  const dispatch = useDispatch();

  const intl = useIntl();

  const formatters = getFormatters(intl);

  function getPercentQuestionPayload(v) {
    return {
      float: v,
      string: formatters.decimalOnePossible({
        value: v * 100,
      }),
    };
  }

  function getDollarQuestionPayload(v) {
    return {
      float: v,
      string: formatters.decimalZero({
        value: v,
      }),
    };
  }

  function setCompanyDefaults() {
    if (!open || !companyIsSuccess || companyIsFetching || companyIsLoading) {
      return;
    }
    // Set initial state
    dispatch(
      setInitialState({
        startDate: company.defaultStartDate,
        endDate: company.defaultEndDate,
        mediaChannels: company.mediaChannels,
        ...(() => {
          switch (aspiration) {
            case AspirationType.Simulate:
              return {
                changeInSpend: getPercentQuestionPayload(defaultChangeInSpend),
              };
            case AspirationType.Optimize:
              // mediaBudget handled by useEffect
              return;
            case AspirationType.Grow:
              return {
                mediaBudget: getDollarQuestionPayload(optimizationInfinity),
                growthTarget: getPercentQuestionPayload(defaultGrowthTarget),
              };
          }
        })(),
      })
    );
    // Clear undo / redo history
    dispatch(ActionCreators.clearHistory());
  }

  // Reset state on open
  useEffect(() => {
    (async () => {
      if (open) {
        setCompanyDefaults();
      } else {
        appContext.setShouldOpenNewScenario(false);
        setScenarioId(null);
      }
    })();
  }, [open]);

  useEffect(() => {
    if (!wizardDataIsLoading) {
      return;
    }
    setCompanyDefaults();
  }, [company, companyIsLoading, companyIsFetching, companyIsSuccess]);

  // Update media budget anytime anything changes.
  useEffect(() => {
    if (
      aspiration !== AspirationType.Optimize ||
      !open ||
      startDate == null ||
      endDate == null ||
      mediaChannels == null ||
      user == null
    )
      return;
    (async () => {
      {
        const {
          isSuccess,
          data: mediaBudget,
          error,
        } = await getCompanyMediaBudget({
          companyId: user.companyId,
          mediaChannels,
          startDate: epochToIsoDate(startDate),
          endDate: epochToIsoDate(endDate),
        });
        if (isSuccess) {
          dispatch(setMediaBudgetNoUndo(getDollarQuestionPayload(mediaBudget)));
        } else {
          console.error("Error getting media budget: " + error?.data?.message);
        }
      }
    })();
  }, [aspiration, open, startDate, endDate, mediaChannels, user]);

  function epochToIsoDate(epochDate) {
    return new Date(epochDate).toISOString().split("T")[0];
  }

  const [createScenario] = useCreateScenarioMutation();

  async function handleCreate() {
    const newScenarioID = new Date().getTime();
    setScenarioId(newScenarioID);
    dispatch(
      addIsSwitchingScenario({ id: newScenarioID, isCreatingScenario: true })
    );
    appContext.setShouldOpenNewScenario(true);

    const scenarioName = createScenarioName({
      aspiration,
      changeInSpend,
      mediaBudget,
      growthTarget,
      formatters,
    });

    await createScenario({
      company_id: user.companyId,
      user_id: userContext.userID,
      scenario_id: newScenarioID,
      scenario_name: scenarioName,
      start_date: epochToIsoDate(startDate),
      end_date: epochToIsoDate(endDate),
      media_channels: mediaChannels,
      aspiration: aspiration,
      change_in_spend: changeInSpend,
      media_budget: mediaBudget,
      growth_target: growthTarget,
    });
    dispatch(scenariosApi.util.invalidateTags(["Scenarios"]));
    // console.log("response:", result);

    appContext.setScenariosToRun((prevState) =>
      prevState.concat(newScenarioID)
    );
  }

  useEffect(() => {
    if (
      scenarioId != null &&
      switchingToId == null &&
      scenarioId === selectedScenarioId
    ) {
      setOpen(false);
    }
  }, [switchingToId, scenarioId]);

  return (
    <WizardDialog open={open} setOpen={setOpen}>
      <WizardDialogTitle
        id="wizard-dialog-title"
        setOpen={setOpen}
        undoState={() => dispatch(ActionCreators.undo())}
        redoState={() => dispatch(ActionCreators.redo())}
        canUndoState={canUndo}
        canRedoState={canRedo}
      >
        Create New Scenario
      </WizardDialogTitle>
      <WizardStepper
        handleCreate={handleCreate}
        scenarioId={scenarioId}
        errors={errors}
        companyIsLoaded={!companyIsLoading && companyIsSuccess}
        companyIsSuccess={companyIsSuccess}
      >
        <NewScenarioPeriod
          company={company}
          aspiration={aspiration}
          errors={errors}
          setErrors={setErrors}
        />
        <NewScenarioChannels
          company={company}
          aspiration={aspiration}
          errors={errors}
          setErrors={setErrors}
        />
        <NewScenarioQuestion aspiration={aspiration} />
      </WizardStepper>
    </WizardDialog>
  );
}
