import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ErrorBoundary } from "react-error-boundary";
import TopLevelError from "./containers/TopLevelError/TopLevelError";

if (process.env.NODE_ENV === "production") {
  console.productionLog = console.log;
  console.productionDir = console.dir;
  console.productionTable = console.table;
  console.productionDebug = console.debug;
  console.log = () => {};
  console.dir = () => {};
  console.table = () => {};
  console.debug = () => {};
} else {
  function nonProductionWrapper(f) {
    return (...args) => {
      console.log("%cProduction Console", "color:red;font-weight:bold");
      f(...args);
    };
  }
  console.productionLog = nonProductionWrapper(console.log);
  console.productionDir = nonProductionWrapper(console.dir);
  console.productionTable = nonProductionWrapper(console.table);
  console.productionDebug = nonProductionWrapper(console.debug);
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-1wsdxf02.auth0.com"
      clientId="icFQHwfiFZFN8CcjOLY9JaqfytpTobXu"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <BrowserRouter>
        <ErrorBoundary
          FallbackComponent={TopLevelError}
          onError={(error) => {
            console.error("ErrorBoundary", error);
          }}
        >
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
