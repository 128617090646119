import { Box, Button, Typography, Modal } from "@mui/material";

import classes from "./ActionModal.module.css";

function ActionModal({
  name,
  open,
  handleClose,
  title,
  description,
  primaryButton,
  secondaryButton,
  children,
}) {
  const { text: primaryButtonText, ...primaryButtonProps } = primaryButton;
  const { text: secondaryButtonText, ...secondaryButtonProps } =
    secondaryButton;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby={`${name}-modal-title`}
      aria-describedby={`${name}-modal-description`}
    >
      <Box className={classes.actionModal} sx={{ width: 450, p: 4 }}>
        <Typography id={`${name}-modal-title`} variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id={`${name}-modal-description`} sx={{ mt: 1 }}>
          {description}
        </Typography>
        {children}
        <Box className={classes.actionModalButtons} sx={{ pt: 1.5 }}>
          <Button variant="outlined" size="large" {...secondaryButtonProps}>
            {secondaryButtonText}
          </Button>
          <Button
            variant="contained"
            size="large"
            disableElevation
            {...primaryButtonProps}
          >
            {primaryButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ActionModal;
