import { useState, useEffect } from "react";
import ActionModal from "../../components/ActionModal/ActionModal";
import { Box, TextField } from "@mui/material";
import { useRenameScenarioMutation } from "../../app/scenariosApi";
import { useRef } from "react";

function RenameModal({ isOpen, actionProps, close, handle }) {
  const [name, setName] = useState("");

  useEffect(() => {
    if (actionProps?.node.data.name == null) return;
    setName(actionProps?.node.data.name);
  }, [actionProps]);

  const inputRef = useRef();

  // Manual auto focus on open.
  // The autofocus prop doesn't seem to work for modals
  useEffect(() => {
    if (!isOpen) return;
    setTimeout(() => {
      if (inputRef.current == null) return;
      inputRef.current.focus();
    });
  }, [isOpen]);

  const [renameScenario] = useRenameScenarioMutation();

  function submit() {
    handle(
      actionProps,
      async () =>
        await renameScenario({
          userId: actionProps.node.data.userId,
          scenarioId: actionProps.node.data.scenarioId,
          name,
        })
    );
    close();
  }

  return (
    <ActionModal
      name="rename"
      open={isOpen}
      handleClose={close}
      title="Edit Scenario"
      primaryButton={{
        text: "Save",
        onClick: submit,
      }}
      secondaryButton={{ text: "Cancel", onClick: close }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <TextField
          size="small"
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          sx={{ mt: 2, mb: 1, width: "100%" }}
          onFocus={(event) => {
            event.target.select();
          }}
          inputRef={inputRef}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              submit();
              e.preventDefault();
            }
          }}
        />
      </Box>
    </ActionModal>
  );
}

export default RenameModal;
