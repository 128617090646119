import { dateToISODateString } from "../../constants";

export default function ({ message, company }) {
  const minStartDate = new Date(company.defaultStartDate);
  const maxStartDate = (() => {
    const result = new Date(company.defaultEndDate);
    result.setDate(result.getDate() - 6);
    return result;
  })();
  const minEndDate = (() => {
    const result = new Date(company.defaultStartDate);
    result.setDate(result.getDate() + 6);
    return result;
  })();
  const maxEndDate = new Date(company.defaultEndDate);

  return {
    message,
    minStartDate: dateToISODateString(minStartDate),
    maxStartDate: dateToISODateString(maxStartDate),
    minEndDate: dateToISODateString(minEndDate),
    maxEndDate: dateToISODateString(maxEndDate),
    companyMediaChannels: company.mediaChannels,
    companyMediaHierarchy: company.mediaHierarchy,
  };
}
