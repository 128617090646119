import * as Realm from "realm-web";

import { APP_ID } from "./endpoints";

export const app = new Realm.App(APP_ID);

export async function getValidAccessToken() {
  if (!app.currentUser) {
    // If no current user, log in anonymous user with valid access token.
    await app.logIn(Realm.Credentials.anonymous());
  } else {
    // Else refresh current user's custom data, to refresh their access token, in case it has become stale.
    await app.currentUser.refreshCustomData();
  }
  // Return current user's access token.
  return app.currentUser.accessToken;
}
