import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Checkbox, Chip, createFilterOptions, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { objectFilter } from "../../utils/objectUtil";
import {
  areTargetsEqual,
  getNameWithoutDuplicates,
  getTargetFromObject,
} from "../../utils/targetUtils";
import {
  StyledPopper,
  VirtualizedListboxComponent,
} from "../VirtualizedAutocompleteComponents/VirtualizedAutocompleteComponents";

export const errorDelay = 750;

function ScenarioChannelsInput({
  channelsKey,
  label,
  initialValue,
  channels: _channels,
  setChannels,
  allChannels: _allChannels,
  hierarchy: _hierarchy,
  errors,
  setErrors,
  isLoading,
}) {
  const filter = createFilterOptions();
  const [inputValue, setInputValue] = useState("");
  const channels = useMemo(
    () => (!isLoading ? _channels : []) ?? [],
    [_channels, isLoading]
  );
  const allChannels = useMemo(
    () => (!isLoading ? _allChannels : []) ?? [],
    [_allChannels, isLoading]
  );
  const hierarchy = useMemo(() => _hierarchy ?? [], [_hierarchy]);

  useEffect(() => {
    // null -> empty; undefined -> initial value not used
    if (initialValue === undefined) return;
    setChannels(initialValue);
  }, [initialValue]);

  const filteredOptions = useMemo(
    () =>
      filter(allChannels, {
        inputValue: inputValue,
        getOptionLabel: (option) => getNameWithoutDuplicates(option, hierarchy),
      }),
    [filter, allChannels, hierarchy, inputValue]
  );

  const areFilteredChannelsSelected = useMemo(
    () =>
      filteredOptions.every((option) => {
        for (const t of channels) {
          if (areTargetsEqual(t, option)) {
            return true;
          }
        }
        return false;
      }),
    [channels, filteredOptions]
  );

  const [recentlyEditedChannels, setRecentlyEditedChannels] = useState(false);
  const debouncedClearRecentlyEditedChannels = useDebouncedCallback(
    () => setRecentlyEditedChannels(false),
    errorDelay
  );

  function handleChangeChannels(value) {
    setRecentlyEditedChannels(true);
    debouncedClearRecentlyEditedChannels();
    setChannels(value.map((o) => getTargetFromObject(o)));
    setErrors((prevState) => ({
      ...prevState,
      [channelsKey]: value.length === 0 ? "Select at least one" : undefined,
    }));
  }

  return (
    <Autocomplete
      multiple
      id={`${channelsKey}-channels-autocomplete`}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={VirtualizedListboxComponent}
      options={allChannels.map((option) => ({
        ...option,
        label: getNameWithoutDuplicates(option, hierarchy),
      }))}
      isOptionEqualToValue={areTargetsEqual}
      clearOnEscape
      disableCloseOnSelect
      clearIcon={null}
      renderOption={(props, option, { selected }) => {
        if (option.isNoResults) {
          console.log(props);
          return (
            <li
              style={{ fontSize: "small", cursor: "auto" }}
              className={props.className}
              id={props.id}
              key={props.key}
              role={props.role}
              tabIndex={props.tabIndex}
            >
              {option.label}
            </li>
          );
        }
        if (option.isSelectAll) {
          return (
            <li
              {...props}
              style={{ fontSize: "small" }}
              onClick={() => {
                handleChangeChannels(
                  areFilteredChannelsSelected
                    ? // filter out if is one of the filtered
                      channels.filter((option) => {
                        for (const t of filteredOptions) {
                          if (areTargetsEqual(t, option)) {
                            return false;
                          }
                        }
                        return true;
                      })
                    : // filter filtered for not selected and then concat
                      channels.concat(
                        filteredOptions.filter((option) => {
                          for (const t of channels) {
                            if (areTargetsEqual(t, option)) {
                              return false;
                            }
                          }
                          return true;
                        })
                      )
                );
              }}
              aria-selected={areFilteredChannelsSelected}
            >
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={areFilteredChannelsSelected}
              />
              {option.label}
            </li>
          );
        }

        return (
          <li {...props} style={{ fontSize: "small" }}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        );
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, {
          ...params,
          inputValue: inputValue,
        });
        if (filtered.length === 0) {
          return [{ label: `(No Results)`, isNoResults: true }];
        }
        const selectWord = areFilteredChannelsSelected ? "Deselect" : "Select";
        const suffix =
          "All" +
          (filtered.length !== allChannels.length ? " " + filtered.length : "");
        return [
          { label: `(${selectWord} ${suffix})`, isSelectAll: true },
          ...filtered,
        ];
      }}
      value={channels.map((option) => ({
        ...option,
        label: getNameWithoutDuplicates(option, hierarchy),
      }))}
      // filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={
            label + ` (${channels.length} of ${allChannels.length} selected)`
          }
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(!recentlyEditedChannels && errors[channelsKey])}
          helperText={
            (!recentlyEditedChannels ? errors[channelsKey] : undefined) || " "
          }
          placeholder="Search"
          value={inputValue}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              // stop deletion of tags
              event.stopPropagation();
            }
          }}
        />
      )}
      renderTags={() => null}
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          // ignore other reasons that clear the input
          setInputValue(value);
        }
      }}
      onChange={(_, value) => handleChangeChannels(value)}
      inputValue={inputValue}
      // disablePortal
      // open={true}
    />
  );
}

export default ScenarioChannelsInput;
