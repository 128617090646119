import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL, endpointsUrl } from "./endpoints";
import scenarioApi from "./scenarioApi";
import scenarioChannelsApi from "./scenarioChannelsApi";
import scenarioPeriodApi from "./scenarioPeriodApi";
import {
  addIsSwitchingScenario,
  selectScenario,
  setScenarioName,
} from "./scenarioSlice";
import scenarioMetadataApi from "./scenarioMetadataApi";

function getStringTimestamp(milliseconds) {
  const date = new Date(milliseconds);
  return isNaN(date) ? null : date.toISOString();
}

export const scenariosApi = createApi({
  reducerPath: "scenariosApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  tagTypes: ["Scenarios"],
  endpoints: (builder) => ({
    getScenarios: builder.query({
      query: ({ userId }) => ({
        url: "/scenarios/getMany",
        method: "POST",
        body: {
          user_id: userId,
        },
      }),
      transformResponse: (responseData) =>
        responseData.data?.map(
          ({
            _id,
            aspiration,
            last_run,
            user_id,
            scenario_id,
            scenario_name,
            company_id,
          }) => ({
            _id,
            userId: user_id,
            scenarioId: scenario_id,
            companyId: company_id,
            aspiration,
            name: scenario_name,
            createdDate: getStringTimestamp(scenario_id),
            lastRunDate: getStringTimestamp(last_run),
          })
        ),
      providesTags: ["Scenarios"],
      // update scenario name in scenario slice after it is changed
      async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
        try {
          const { data: scenarios } = await queryFulfilled;
          const state = getState();
          const {
            id: currentScenarioId,
            switchingToId,
            name,
          } = selectScenario(state);
          if (switchingToId != null) return;
          const newScenarioMatch = scenarios.find(
            ({ scenarioId }) => scenarioId === currentScenarioId
          );
          if (newScenarioMatch == null || newScenarioMatch.name === name)
            return;
          dispatch(setScenarioName(newScenarioMatch.name));
        } catch {}
      },
    }),
    renameScenario: builder.mutation({
      query: ({ userId, scenarioId, name }) => ({
        url: "scenarios/update",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          updateMap: {
            scenario_name: name,
          },
        },
      }),
      invalidatesTags: ["Scenarios"],
      async onQueryStarted({ scenarioId }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          scenarioMetadataApi.util.invalidateTags([
            { type: "ScenarioMetadata", id: scenarioId },
          ])
        );
      },
    }),
    editScenario: builder.mutation({
      query: ({
        userId,
        scenarioId,
        aspiration,
        companyId,
        companyMediaHierarchy,
        companySalesHierarchy,
        startPeriod,
        endPeriod,
        mediaChannels,
        periodChanged,
        channelsChanged,
      }) => ({
        url: "scenarios/edit",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          aspiration,
          company_id: companyId,
          companyMediaHierarchy,
          companySalesHierarchy,
          startPeriod,
          endPeriod,
          mediaChannels,
          periodChanged,
          channelsChanged,
        },
      }),
      invalidatesTags: ["Scenarios"],
      async onQueryStarted(
        { userId, scenarioId, periodChanged, channelsChanged },
        { dispatch, queryFulfilled, getState }
      ) {
        const id = JSON.stringify({
          userId,
          scenarioId,
        });
        await queryFulfilled;
        if (periodChanged) {
          dispatch(
            scenarioPeriodApi.util.invalidateTags([
              {
                type: "ScenarioPeriod",
                id,
              },
            ])
          );
        }
        if (channelsChanged) {
          dispatch(
            scenarioChannelsApi.util.invalidateTags([
              {
                type: "ScenarioChannel",
                id,
              },
            ])
          );
        }
        dispatch(
          scenarioApi.util.invalidateTags(
            [
              "ResultsGroup",
              "ResultsItem",
              "MediaGroup",
              "MediaItem",
              "SalesGroup",
              "SalesItem",
            ].map((type) => ({
              type,
              id,
            }))
          )
        );

        const state = getState();
        if (state?.scenario?.id != null && state?.scenario?.id === scenarioId) {
          dispatch(addIsSwitchingScenario({ id: scenarioId }));
        }
      },
    }),
    copyScenario: builder.mutation({
      query: ({ userId, scenarioId, newScenarioId, newName }) => ({
        url: "scenarios/copy",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          new_scenario_id: newScenarioId,
          new_scenario_name: newName,
        },
      }),
      invalidatesTags: ["Scenarios"],
    }),
    importScenario: builder.mutation({
      query: ({ userId, file }) => {
        const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("file", file);
        return {
          url: BASE_API_URL.concat("import-scenario/"),
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Scenarios"],
    }),
    deleteScenario: builder.mutation({
      query: ({ userId, scenarioId }) => ({
        url: "scenarios/delete",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
        },
      }),
      invalidatesTags: ["Scenarios"],
      // update user's selected scenario if the selected one if deleted
      async onQueryStarted(
        { scenarioId },
        { dispatch, getState, queryFulfilled }
      ) {
        await queryFulfilled;

        const state = getState();
        const needToReset =
          state.scenario.id === scenarioId ||
          state.scenario.switchingToId === scenarioId;
        if (needToReset) {
          dispatch(addIsSwitchingScenario({ id: 0 }));
        }
      },
    }),
  }),
});

export default scenariosApi;

export const {
  useGetScenariosQuery,
  useRenameScenarioMutation,
  useEditScenarioMutation,
  useCopyScenarioMutation,
  useImportScenarioMutation,
  useDeleteScenarioMutation,
} = scenariosApi;
