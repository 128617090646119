import parse from "autosuggest-highlight/parse";

export function getOptionId(channel, hierarchy) {
  return hierarchy
    .map((k) => channel[k])
    .slice(0, Object.keys(channel).length)
    .join();
}

export function makeChannelWithoutBlanks(channelWithBlanks, hierarchy) {
  const result = { ...channelWithBlanks };
  for (let i = 0; i < hierarchy.length; i++) {
    const k = hierarchy[i];
    if (i !== 0 && result[k] == null) {
      result[k] = result[hierarchy[i - 1]];
    }
  }
  return result;
}

export function getOptionIdWithoutBlanks(channel, depth, hierarchy) {
  let result = "";
  let matchDiff = 0;
  for (let i = 0; i < depth + 1; i++) {
    const k = hierarchy[i];
    if (result) {
      result += ",";
    }
    if (i !== 0 && channel[k] == null) {
      result += channel[hierarchy[i - 1 - matchDiff++]];
    } else {
      matchDiff = 0;
      result += channel[k];
    }
  }
  return result;
}

export function getOptionParentId(channel, hierarchy) {
  return hierarchy
    .slice(0, Object.keys(channel).length - 1)
    .map((k) => channel[k])
    .join();
}

// function getOptionParentName(channel, hierarchy) {
//   return hierarchy
//     .map((k) => channel[k])
//     .slice(0, Object.keys(channel).length - 1)
//     .join();
// }

// TODO simplify
export function toOptions(channels, hierarchy) {
  const existingIds = new Set();

  const options = channels
    .flatMap((channel) => {
      const options = [];
      const option = {};
      for (const k of hierarchy) {
        option[k] = channel[k];
        const id = getOptionId(option, hierarchy);
        if (existingIds.has(id)) {
          continue;
        }
        existingIds.add(id);
        options.push({ ...option });
      }
      return options;
    })
    .map((channel) => {
      const depth = Object.keys(channel).length - 1;
      const channelWithoutBlanks = makeChannelWithoutBlanks(
        channel,
        hierarchy.slice(0, depth + 1)
      );
      // const channelWithoutBlanks = channel
      const matchTerms = hierarchy.map((k) => channelWithoutBlanks[k]);
      const matchName = hierarchy
        .map((k) => channelWithoutBlanks[k])
        .join(" ")
        .trim();
      const name = matchTerms[depth];
      const id = getOptionId(channelWithoutBlanks, hierarchy);
      return {
        id,
        name,
        channel,
        depth,
        matchTerms,
        matchName,
        ancestorsIds: [],
        descendantsIds: [],
        isGroup: depth !== hierarchy.length - 1,
      };
    })
    .reduceRight((acc, o) => {
      const descendantsIds = [];
      for (const child of acc) {
        if (
          child.depth === o.depth + 1 &&
          o.id === getOptionParentId(child.channel, hierarchy)
        ) {
          descendantsIds.push(child.id, ...child.descendantsIds);
        }
      }
      return [
        {
          ...o,
          descendantsIds,
        },
        ...acc,
      ];
    }, [])
    .reduce((acc, o) => {
      const ancestorsIds = [];
      for (const parent of acc) {
        if (
          parent.depth === o.depth - 1 &&
          getOptionParentId(o.channel, hierarchy) === parent.id
        ) {
          ancestorsIds.push(parent.id, ...parent.ancestorsIds);
        }
      }
      return [
        ...acc,
        {
          ...o,
          ancestorsIds,
        },
      ];
    }, []);

  // console.log(options);

  return options;
}

/**
 *
 * @param option Option selected
 * @param selectedIds Non-group options
 * @param filteredIds Non-group options
 * @param allOptions Non-group options
 * @returns
 */
export function getOptionsWithSelect(
  option,
  selectedIds,
  filteredIds,
  allOptions
) {
  // console.log("getOptionsWithSelect", option.id);
  const initialSet = new Set(selectedIds);
  const filteredSet = new Set(filteredIds);
  if (initialSet.has(option.id)) return selectedIds;
  const idsToAdd = [];
  for (const o of allOptions) {
    if (initialSet.has(o.id) || !filteredSet.has(o.id)) {
      continue;
    }
    if (o.id === option.id || option.descendantsIds.includes(o.id)) {
      idsToAdd.push(o.id);
    }
  }
  // console.log("getOptionsWithSelect add", idsToAdd);
  return [...selectedIds, ...idsToAdd];
}

/**
 *
 * @param option Option deselected
 * @param selectedIds Non-group options
 * @param filteredIds Non-group options
 * @param allOptions Non-group options
 * @returns
 */
export function getOptionsWithDeselect(
  option,
  selectedIds,
  filteredIds,
  allOptions
) {
  const initialSet = new Set(selectedIds);
  const filteredSet = new Set(filteredIds);
  const idsToRemove = [];
  for (const id of selectedIds) {
    if (!initialSet.has(id) || !filteredSet.has(id)) {
      continue;
    }
    if (id === option.id || option.descendantsIds.includes(id)) {
      idsToRemove.push(id);
    }
  }
  return selectedIds.filter((id) => !idsToRemove.includes(id));
}

export function isGroupOptionSelected(option, selectedIds, filteredIds) {
  for (const descendantId of option.descendantsIds) {
    if (
      filteredIds.includes(descendantId) &&
      selectedIds.includes(descendantId)
    ) {
      return true;
    }
  }
  return false;
}

// export function isSelectAllSelected(
//   selectedIds,
//   filteredIds
// ) {
//   return areIdsContained(selectedIds, filteredIds);
// }

// export function areIdsContained(sup, sub) {
//   if (sup.length < sub.length) {
//     return false;
//   }
//   for (const id of sub) {
//     if (sup.includes(id)) {
//       return true;
//     }
//   }
//   return false;
// }


function getCommaCount(string) {
  let commaCount = 0;
  for (let i = 0; i < string.length; commaCount += +("," === string[i++]));
  return commaCount;
}

export function isGroupOptionSelectedWithIndeterminate(
  option,
  selectedIds,
  filteredIds,
  allIdsCount,
  bottomDepth
) {
  if (selectedIds.length === 0) {
    return "unchecked";
  }
  // filter list as [] is meaningless. Exit early with unchecked
  if (filteredIds.length === 0) {
    return "unchecked";
  }
  if (selectedIds.length === allIdsCount) {
    return "checked";
  }
  let hasOneMiss = false;
  let hasOneHit = false;
  for (const descendantId of option.descendantsIds) {
    if (
      getCommaCount(descendantId) !== bottomDepth ||
      (filteredIds.length !== allIdsCount &&
        !filteredIds.includes(descendantId))
    ) {
      continue;
    }
    if (selectedIds.includes(descendantId)) {
      hasOneHit = true;
    } else {
      hasOneMiss = true;
    }
    if (hasOneMiss && hasOneHit) {
      return "indeterminate";
    }
  }
  return hasOneHit ? "checked" : "unchecked";
}

export function isSelectAllSelectedWithIndeterminate(selectedIds, filteredIds) {
  let allSelected = true;
  // if not all selected is indeterminate when true
  let meetsIndeterminate = false;
  for (const id of filteredIds) {
    if (selectedIds.includes(id)) {
      meetsIndeterminate = true;
    } else {
      allSelected = false;
      if (meetsIndeterminate) {
        break;
      }
    }
  }
  return allSelected
    ? "checked"
    : meetsIndeterminate
    ? "indeterminate"
    : "unchecked";
}

export function areIdListsEqual(a, b) {
  if (a.length !== b.length) {
    return false;
  }

  const sortedA = a.slice().sort();
  const sortedB = b.slice().sort();

  for (let i = 0; i < sortedA.length; i++) {
    if (sortedA[i] !== sortedB[i]) {
      return false;
    }
  }
  return true;
}

export function getOptionParts(option, query) {
  const matches = [];

  // handle split matches on spaces so spaces aren't bold
  function addMatchesWithSafeSpaces(match) {
    const matchString = option.name.slice(...match);
    const matchStringSpaceIndexes = [];
    for (let i = 0; i < matchString.length; i++) {
      if (matchString[i] === " ") {
        matchStringSpaceIndexes.push(i);
      }
    }
    const spaceSafeMatchesFlat = [
      match[0],
      ...matchStringSpaceIndexes.flatMap((v) => [
        match[0] + v,
        match[0] + v + 1,
      ]),
      match[1],
    ];
    const spaceSafeMatches = [];
    for (let i = 0; i < spaceSafeMatchesFlat.length; i += 2) {
      spaceSafeMatches.push([
        spaceSafeMatchesFlat[i],
        spaceSafeMatchesFlat[i + 1],
      ]);
    }
    matches.push(...spaceSafeMatches);
  }

  const nameStart = option.matchName.lastIndexOf(option.name);

  if (query.length !== 0) {
    let matchIndex = -1;
    while (
      (matchIndex = option.matchName
        .toLowerCase()
        .indexOf(query.toLowerCase(), matchIndex + 1)) !== -1
    ) {
      const end = matchIndex + query.length - nameStart;
      if (end < 1) {
        continue;
      }
      const start = Math.max(matchIndex - nameStart, 0);
      const match = [start, end];
      addMatchesWithSafeSpaces(match);
    }

    // handle match of with descendants
    if (matches.length === 0) {
      // note: this is bad code because this is a low priority feature
      // and doing it better will have a potential performance hit
      const descendantMatchNames = option.descendantsIds
        // .filter((d) => (d.match(/,/g) || []).length === option.depth + 1)
        .map((d) => d.replaceAll(",", " ").trim());

      for (const descendantMatchName of descendantMatchNames) {
        if (matches.length !== 0) {
          break;
        }
        let matchIndex = -1;
        while (
          (matchIndex = descendantMatchName
            .toLowerCase()
            .indexOf(query.toLowerCase(), matchIndex + 1)) !== -1
        ) {
          const end = matchIndex + query.length - nameStart;
          const start = Math.max(matchIndex - nameStart, 0);
          if (end < 1 || start > option.name.length - 1) {
            continue;
          }
          const match = [start, end];
          addMatchesWithSafeSpaces(match);
        }
      }
    }
  }

  const parts = parse(option.name, matches);

  const checkName = parts.map(({ text }) => text).join("");
  if (checkName !== option.name) {
    console.error(checkName, "Does not match", option.name);
    return parse(option.name, []);
  }

  return parts;
}

export function getHeaderOptionParts(option, query) {
  const setTerms = option.matchTerms.filter((term) => term != null);
  const termMatches = setTerms.map(() => []);
  // non inclusive end index
  const termEnds = setTerms.reduce(
    (acc, v) => [...acc, (acc.at(-1) ?? -1) + v.length + 1],
    []
  );
  // inclusive start index
  const termStarts = [0, ...termEnds.slice(0, -1).map((e) => e + 1)];

  // handle split matches on spaces so spaces aren't bold
  function addMatchesWithSafeSpaces(termIndex, match) {
    const matchString = option.name.slice(...match);
    const matchStringSpaceIndexes = [];
    for (let i = 0; i < matchString.length; i++) {
      if (matchString[i] === " ") {
        matchStringSpaceIndexes.push(i);
      }
    }
    const spaceSafeMatchesFlat = [
      match[0],
      ...matchStringSpaceIndexes.flatMap((v) => [
        match[0] + v,
        match[0] + v + 1,
      ]),
      match[1],
    ];
    const spaceSafeMatches = [];
    for (let i = 0; i < spaceSafeMatchesFlat.length; i += 2) {
      spaceSafeMatches.push([
        spaceSafeMatchesFlat[i],
        spaceSafeMatchesFlat[i + 1],
      ]);
    }
    termMatches[termIndex].push(...spaceSafeMatches);
  }

  function addMatch(match) {
    for (let termIndex = 0; termIndex < termEnds.length; termIndex++) {
      const termStart = termStarts[termIndex];
      const termEnd = termEnds[termIndex];
      const noOverlap = termEnd <= match[0] || termStart >= match[1];
      const endInRange = match[1] <= termEnd;
      const startInRange = termStart <= match[0];
      if (noOverlap) {
        continue;
      } else {
        addMatchesWithSafeSpaces(termIndex, [
          startInRange ? match[0] - termStart : 0,
          endInRange ? match[1] - termStart : termEnd - termStart,
        ]);
      }
    }
  }

  if (query.length !== 0) {
    let matchIndex = -1;
    while (
      (matchIndex = option.matchName
        .toLowerCase()
        .indexOf(query.toLowerCase(), matchIndex + 1)) !== -1
    ) {
      const end = matchIndex + query.length;
      if (end < 1) {
        continue;
      }
      const start = Math.max(matchIndex, 0);
      const match = [start, end];
      addMatch(match);
    }

    // handle match of with descendants
    if (termMatches.every((m) => m.length === 0)) {
      // note: this is bad code because this is a low priority feature
      // and doing it better will have a potential performance hit
      const descendantMatchNames = option.descendantsIds
        // .filter((d) => (d.match(/,/g) || []).length === option.depth + 1)
        .map((d) => d.replaceAll(",", " ").trim());

      for (const descendantMatchName of descendantMatchNames) {
        if (termMatches.some((m) => m.length !== 0)) {
          break;
        }
        let matchIndex = -1;
        while (
          (matchIndex = descendantMatchName.indexOf(query, matchIndex + 1)) !==
          -1
        ) {
          const end = matchIndex + query.length;
          const start = Math.max(matchIndex, 0);
          // if (end < 1 || start > option.name.length - 1) {
          //   continue;
          // }
          const match = [start, end];
          addMatch(match);
        }
      }
    }
  }

  const termParts = termMatches.map((m, i) => parse(option.matchTerms[i], m));

  const checkName = termParts
    .map((parts) => parts.map(({ text }) => text).join(""))
    .join(" ");
  if (checkName !== option.matchName) {
    console.error(checkName, "Does not match", option.name);
    return setTerms.map((term) => parse(term, []));
  }

  return termParts;
}
