import { PlayArrowRounded, PlayCircleFilledRounded } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, ListItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { getAspirationDisplayName, isValidAspiration } from "../../constants";
import AppContext from "../../context/appContext";
import {
  getEmptyGroupTarget,
  scenarioDataHelpers,
  ScenarioDataKeys,
} from "../../scenarioDataUtils/scenarioDataUtils";
import { addPersistanceEvent } from "../MyResults/scenarioDataSlice";

import { useDispatch, useSelector } from "react-redux";
import { useRunScenarioMutation } from "../../app/scenarioApi";
import { selectScenario } from "../../app/scenarioSlice";
import scenariosApi from "../../app/scenariosApi";
import { addRunMessage } from "../../features/RunMessage/runMessageSnack";

const RunButton = ({ open, aspiration }) => {
  const dispatch = useDispatch();

  const appContext = useContext(AppContext);

  const theme = useTheme();

  const {
    id: scenarioId,
    userId,
    isSwitchingScenario,
  } = useSelector(selectScenario);
  const [runScenario] = useRunScenarioMutation();

  async function handleRunScenario() {
    appContext.setIsRunningCurrentScenario(true);
    try {
      await runScenario({
        userId,
        scenarioId,
      });

      dispatch(scenariosApi.util.invalidateTags(["Scenarios"])); // refetch to get updated last run date

      dispatch(
        addPersistanceEvent({
          key: ScenarioDataKeys.Results,
          target: getEmptyGroupTarget(
            scenarioDataHelpers[ScenarioDataKeys.Results].channelKeys
          ),
        })
      );
    } catch (error) {
      addRunMessage({
        overrideMessage: "Failed to run or update scenario",
        overrideVariant: "error",
      });
      console.error("Scenario Run Failed", error);
    } finally {
      appContext.setIsRunningCurrentScenario(false);
    }
  }

  if (!isValidAspiration(aspiration) || isSwitchingScenario) return <></>;

  if (appContext.isRunningCurrentScenario) {
    return (
      <ListItem sx={{ display: "flex", justifyContent: "center", mt: 1.5 }}>
        <CircularProgress color={aspiration} size={35} />
      </ListItem>
    );
  }

  if (open) {
    return (
      <Button
        variant="contained"
        disableElevation
        startIcon={<PlayArrowRounded />}
        size="large"
        color={aspiration}
        sx={{
          margin: 2,
          "&:hover": {
            backgroundColor: theme.palette[aspiration].hover,
          },
        }}
        aria-label="run scenario"
        onClick={handleRunScenario}
      >
        {getAspirationDisplayName(aspiration)}
      </Button>
    );
  }

  return (
    <IconButton
      aria-label="run scenario"
      onClick={handleRunScenario}
      size="large"
      color={aspiration}
      sx={{ aspectRatio: "1" }}
    >
      <PlayCircleFilledRounded fontSize="large" />
    </IconButton>
  );
};

export default RunButton;
