import { getAggNodeCategoryVector } from "../containers/CategoryDataUtils";
import salesGridHelper from "./sales";
import mediaGridHelper from "./media";
import coefficientsGridHelper from "./coefficients";
import resultsGridHelper from "./results";
import constraintsGridHelper from "./constraints";
import { copySpendChange } from "../utils/resultsUtils";
import { objectFilter, objectMap } from "../utils/objectUtil";

export const optimizationInfinity = 1e19;

export const ScenarioDataKeys = {
  Sales: "sales",
  Media: "media",
  Coefficients: "coefficients",
  Results: "results",
  Constraints: "constraints",
};

export const ValidationErrorKeys = {
  EditGroup: "editGroup",
  EditItem: "editItem",
};

export const GridKeyEnum = (() => {
  const { Constraints, ...GridKeys } = ScenarioDataKeys;
  return GridKeys;
})();

const gridTransactionsKeys = Object.values(GridKeyEnum);
const itemDbUpdatesKeys = Object.values(GridKeyEnum);
const groupDbUpdatesKeys = Object.values(ScenarioDataKeys);

export const scenarioDataHelpers = {
  [ScenarioDataKeys.Sales]: salesGridHelper,
  [ScenarioDataKeys.Media]: mediaGridHelper,
  [ScenarioDataKeys.Coefficients]: coefficientsGridHelper,
  [ScenarioDataKeys.Results]: resultsGridHelper,
  [ScenarioDataKeys.Constraints]: constraintsGridHelper,
};

function addGridTransactions(sdKey, gridTransactions, newItems) {
  if (!gridTransactionsKeys.includes(sdKey)) return gridTransactions;
  return {
    ...gridTransactions,
    ...(Array.isArray(gridTransactions[sdKey])
      ? {
          [sdKey]: gridTransactions[sdKey].concat(...newItems),
        }
      : {}),
  };
}

function addValidationErrors(veKey, { validationErrors }, newSdKey, newErrors) {
  if (!Object.values(ValidationErrorKeys).includes(veKey))
    return validationErrors;
  return {
    ...validationErrors,
    ...(Array.isArray(newErrors) && newErrors.length !== 0
      ? {
          [veKey]:
            veKey === ValidationErrorKeys.EditItem ||
            veKey === ValidationErrorKeys.EditGroup
              ? {
                  sdKey: validationErrors[veKey]?.sdKey || newSdKey,
                  errors: (validationErrors[veKey]?.errors || []).concat(
                    ...newErrors
                  ),
                }
              : {
                  sdKey: newSdKey,
                  errors: newErrors,
                },
        }
      : {}),
  };
}

export function mergeItems(sdKey, oldItems, newItems) {
  const itemsToMerge = [...newItems];
  return oldItems.map((oldItem) => {
    const oldItemMatcher = getItemMatcher(oldItem, sdKey);
    for (let index = 0; index < itemsToMerge.length; index++) {
      const newItem = itemsToMerge[index];
      if (oldItemMatcher(newItem)) {
        itemsToMerge.splice(index, 1);
        if (newItem._id === undefined && oldItem._id != null) {
          newItem._id = oldItem._id;
        }
        return newItem;
      }
    }
    return oldItem;
  });
}

function filterObject(o, keys, defaultValue) {
  return keys.reduce(
    (acc, k) => ({
      ...acc,
      [k]: o[k] || defaultValue,
    }),
    {}
  );
}

export function getTargetFromItem(item, channelKeys) {
  return filterObject(item, ["period", ...channelKeys], null);
}

export function getGroupTargetFromItem(item, channelKeys) {
  return filterObject(item, channelKeys, null);
}

export function getTargetFromNode(node, channelKeys) {
  if (node.group) {
    return getTargetFromCV(
      getAggNodeCategoryVector(node, channelKeys),
      channelKeys
    );
  }
  return getTargetFromItem(node.data, channelKeys);
}

export function getTargetFromCV(cv, channelKeys) {
  return channelKeys.reduce(
    (acc, k, i) => ({
      ...acc,
      [k]: cv[i] || null,
    }),
    {}
  );
}

export function getEmptyGroupTarget(channelKeys) {
  return getTargetFromCV([], channelKeys);
}

export function getShortCVFromShortTarget(shortTarget, channelKeys) {
  return channelKeys.map((k) => shortTarget[k]).filter((v) => v !== undefined);
}

export function getCVFromTarget(target, channelKeys) {
  return channelKeys.map((k) => target[k]);
}

// A target with keys outside the range of populated channelKeys fields being undefined.
export function getShortTarget(d, channelKeys) {
  const { shortTarget } = channelKeys.reduce((acc, k) => {
    let { shortTarget = {}, addOnValue = {} } = acc;
    if (d[k] != null) {
      shortTarget = {
        ...shortTarget,
        ...addOnValue,
        [k]: d[k],
      };
      addOnValue = {};
    } else if (Object.keys(shortTarget).length !== 0) {
      addOnValue = {
        ...addOnValue,
        [k]: d[k],
      };
    }
    // console.log("getShortTarget", d, channelKeys, addOnValue, shortTarget);
    return {
      shortTarget,
      addOnValue,
    };
  }, {});
  return shortTarget;
}

/**
 * Get a target with only the keys used in the category vector.
 *
 * @param {*} cv The category vector to convert.
 * @param {*} channelKeys The channel keys for the data. Length can be greater than the cv but not shorter.
 * @returns The new target object.
 */
export function getExpansionTargetFromExpansionCV(cv, channelKeys) {
  return Object.fromEntries(cv.map((v, i) => [channelKeys[i], v]));
}

// null only matches to null. So an all nulls target would only match all nulls items.
export function getItemMatcher(t, sdKey) {
  const keys = scenarioDataHelpers[sdKey].channelKeys.concat(
    Object.values(GridKeyEnum).includes(sdKey) ? ["period"] : []
  );
  return (i) => keys.every((k) => (i[k] || null) === t[k]);
}

// null matches to anything. So an all nulls target would match every item.
export function getItemGroupMatcher(t, sdKey) {
  const keys = scenarioDataHelpers[sdKey].channelKeys;
  return (i) => keys.every((k) => t[k] == null || (i[k] || null) === t[k]);
}

export function mergeTwoTargets(targetOne, targetTwo, sdKey) {
  const keys = scenarioDataHelpers[sdKey].channelKeys;
  const matcherOne = getItemGroupMatcher(targetOne, sdKey);
  const matcherTwo = getItemGroupMatcher(targetTwo, sdKey);

  if (matcherOne(targetTwo)) {
    return getGroupTargetFromItem(targetOne, keys);
  }

  if (matcherTwo(targetOne)) {
    return getGroupTargetFromItem(targetTwo, keys);
  }

  const shortCvOne = getShortCVFromShortTarget(
    getShortTarget(targetOne, keys),
    keys
  );
  const shortCvTwo = getShortCVFromShortTarget(
    getShortTarget(targetTwo, keys),
    keys
  );

  const [longerCv, shorterTarget] =
    shortCvOne.length >= shortCvTwo.length
      ? [shortCvOne, targetTwo]
      : [shortCvTwo, targetOne];

  const longerParentTarget = getTargetFromCV(longerCv.slice(0, -1), keys);

  return mergeTwoTargets(longerParentTarget, shorterTarget, sdKey);
}

export function mergeTargets(sdKey, ...targets) {
  if (targets.length === 1) {
    return getGroupTargetFromItem(
      targets[0],
      scenarioDataHelpers[sdKey].channelKeys
    );
  }
  return targets.reduce((acc, v) => mergeTwoTargets(acc, v, sdKey));
}

export function executeUpdateOnItem({
  sdKey,
  item,
  updateMap,
  scenarioAspiration,
  resultsCpm, // TODO fix this so this isn't so gross. (results needs media cpm for side effects)
  skipValidation = false,
}) {
  const changedFields = [];
  let validationErrors = [];
  let newUpdateMap = updateMap;

  if (!skipValidation) {
    const { validatedUpdateMap, validationErrors: errors } =
      scenarioDataHelpers[sdKey]?.validateUpdateMap?.(
        updateMap,
        getTargetFromItem(item, scenarioDataHelpers[sdKey].channelKeys),
        item
      ) || { validatedUpdateMap: {}, validationErrors: [] };
    // console.log("Validation", validatedUpdateMap, errors);
    validationErrors = errors;
    newUpdateMap = validatedUpdateMap;
  }

  Object.entries(newUpdateMap).forEach(([field, value]) => {
    if (item[field] !== value) {
      item[field] = value;
      changedFields.push(field);
    }
  });
  return {
    changedFields: scenarioDataHelpers[sdKey].runSideEffectsOnItem(
      item,
      changedFields,
      scenarioAspiration,
      sdKey === ScenarioDataKeys.Results ? resultsCpm : undefined
    ),
    validationErrors,
  };
}

function editItem(state, { sdKey, target, updateMap, scenarioAspiration }) {
  const targetItemCopy = {
    ...state[sdKey].find(getItemMatcher(target, sdKey)),
  };
  const resultsCpm =
    sdKey === ScenarioDataKeys.Results
      ? state[ScenarioDataKeys.Media].find(
          getItemMatcher(
            getTargetFromItem(
              targetItemCopy,
              scenarioDataHelpers[ScenarioDataKeys.Results].channelKeys
            ),
            ScenarioDataKeys.Media
          )
        )?.cpm
      : undefined;
  const { changedFields, validationErrors } = executeUpdateOnItem({
    sdKey,
    item: targetItemCopy,
    updateMap,
    scenarioAspiration,
    resultsCpm,
  });

  if (changedFields.length === 0) {
    const temp = {
      ...state,
      validationErrors: addValidationErrors(
        ValidationErrorKeys.EditItem,
        state,
        sdKey,
        validationErrors
      ),
    };
    return temp;
  }

  console.log("editItem", sdKey, targetItemCopy, updateMap, changedFields);

  const targetMatcher = getItemMatcher(target, sdKey);
  let newState = {
    ...state,
    [sdKey]: state[sdKey].map((item) =>
      targetMatcher(item) ? targetItemCopy : item
    ),
    gridTransactions: addGridTransactions(sdKey, state.gridTransactions, [
      targetItemCopy,
    ]),
    itemDbUpdates:
      sdKey !== ScenarioDataKeys.Results
        ? {
            ...state.itemDbUpdates,
            [sdKey]: {
              item: targetItemCopy,
              changedFields: [...changedFields],
            },
          }
        : state.itemDbUpdates,
    validationErrors: addValidationErrors(
      ValidationErrorKeys.EditItem,
      state,
      sdKey,
      validationErrors
    ),
  };

  newState =
    scenarioDataHelpers[sdKey].totalUpdateSideEffects?.(newState, state, {
      target: objectFilter(target, ([k]) => k !== "period"),
      modifiedFields: changedFields,
      scenarioAspiration,
    }) || newState;

  if (
    ["starting_spend", "final_spend"].some((k) => changedFields.includes(k))
  ) {
    // changed fields shouldn't need to be updated
    // because this only changes fields which have already been changed
    const newResults = copySpendChange({
      target,
      media: newState[ScenarioDataKeys.Media],
      results: newState[ScenarioDataKeys.Results],
      scenarioAspiration,
      changedFields,
    });

    return {
      ...newState,
      [ScenarioDataKeys.Results]: mergeItems(
        ScenarioDataKeys.Results,
        newState[ScenarioDataKeys.Results],
        newResults
      ),
      gridTransactions: addGridTransactions(
        ScenarioDataKeys.Results,
        newState.gridTransactions,
        newResults
      ),
      groupDbUpdates: {
        ...newState.groupDbUpdates,
        [ScenarioDataKeys.Results]: {
          group: newState.groupDbUpdates[ScenarioDataKeys.Results].concat(
            ...newResults
          ),
          changedFields: [
            ...newState.groupDbUpdates[ScenarioDataKeys.Results].changedFields,
            ...["starting_spend", "final_spend"].filter((k) =>
              changedFields.includes(k)
            ),
          ],
        },
      },
    };
  }

  return newState;
}

export const GroupAggregationType = {
  Sum: "sum",
  Average: "avg",
  Copy: "copy",
  Nothing: "nothing",
  CpmAverage: "cpmAverage",
};

function editGroupWithUpdateMaps(
  state,
  {
    sdKey,
    target,
    itemsWithUpdates,
    scenarioAspiration,
    changedFields: preChangedFields = [],
  }
) {
  // console.log(
  //   "editGroupWithUpdateMaps",
  //   sdKey,
  //   target,
  //   itemsWithUpdates,
  //   scenarioAspiration,
  //   preChangedFields
  // );
  const changedItems = [];
  const changedFieldsSet = new Set();
  preChangedFields.forEach((f) => changedFieldsSet.add(f));
  const groupValidationErrors = [];
  itemsWithUpdates.forEach(([item, updateMap]) => {
    const itemCopy = { ...item };
    // console.log("Group Item Update Map", itemCopy, updateMap)
    const resultsCpm =
      sdKey === ScenarioDataKeys.Results
        ? state[ScenarioDataKeys.Media].find(
            getItemMatcher(
              getTargetFromItem(
                itemCopy,
                scenarioDataHelpers[ScenarioDataKeys.Results].channelKeys
              ),
              ScenarioDataKeys.Media
            )
          )?.cpm
        : undefined;
    const { changedFields: itemChangedFields, validationErrors } =
      executeUpdateOnItem({
        sdKey,
        item: itemCopy,
        updateMap,
        scenarioAspiration,
        resultsCpm,
      });
    if (itemChangedFields.length !== 0) {
      changedItems.push(itemCopy);
      itemChangedFields.forEach((f) => changedFieldsSet.add(f));
    }
    groupValidationErrors.push(...validationErrors);
  });

  console.log("groupValidationErrors", groupValidationErrors);

  const mergedItems = mergeItems(sdKey, state[sdKey], changedItems);

  const newState = {
    ...state,
    [sdKey]: mergedItems,
    gridTransactions: addGridTransactions(
      sdKey,
      state.gridTransactions,
      changedItems
    ),
    groupDbUpdates: {
      ...state.groupDbUpdates,
      [sdKey]: {
        group: state.groupDbUpdates[sdKey].concat(changedItems),
        changedFields: [...changedFieldsSet],
      },
    },
    validationErrors: addValidationErrors(
      ValidationErrorKeys.EditGroup,
      state,
      sdKey,
      groupValidationErrors
    ),
  };

  if (["starting_spend", "final_spend"].some((k) => changedFieldsSet.has(k))) {
    // changed fields shouldn't need to be updated
    // because this only changes fields which have already been changed
    const newResults = copySpendChange({
      target,
      media: newState[ScenarioDataKeys.Media],
      results: newState[ScenarioDataKeys.Results],
      scenarioAspiration,
      changedFields: [...changedFieldsSet],
    });

    return {
      state: {
        ...newState,
        [ScenarioDataKeys.Results]: mergeItems(
          ScenarioDataKeys.Results,
          newState[ScenarioDataKeys.Results],
          newResults
        ),
        gridTransactions: addGridTransactions(
          ScenarioDataKeys.Results,
          newState.gridTransactions,
          newResults
        ),
        groupDbUpdates: {
          ...newState.groupDbUpdates,
          [ScenarioDataKeys.Results]: {
            group: newState.groupDbUpdates[ScenarioDataKeys.Results].concat(
              ...newResults
            ),
            changedFields: [...changedFieldsSet],
          },
        },
      },
      changedFields: [...changedFieldsSet],
    };
  }

  return { state: newState, changedFields: [...changedFieldsSet] };
}

export function getDisaggregatedUpdateMap({ sdKey, items, updateMap }) {
  const disaggregatedUpdateMap = {};

  Object.entries(updateMap).forEach(([field, value]) => {
    const aggType = scenarioDataHelpers[sdKey].getFieldAggregationType(field);
    if (aggType === GroupAggregationType.Copy) {
      disaggregatedUpdateMap[field] = () => value;
      return;
    }
    if (aggType === GroupAggregationType.Sum) {
      const oldValue = items.reduce((acc, item) => acc + item[field], 0);
      if (oldValue === 0) {
        // not supposed to happen but bugs happen and this lets the user recover
        disaggregatedUpdateMap[field] = () => value / items.length; // disaggregate evenly
        return;
      }
      disaggregatedUpdateMap[field] = (v) => (v * value) / oldValue;
      return;
    }
    if (aggType === GroupAggregationType.CpmAverage) {
      if (field !== "cpm") throw new Error("Must be cpm");
      // TODO use groupItem for totals.
      const total = items.reduce(
        (acc, item) => ({
          spend: acc.spend + item.starting_spend,
          impressions: acc.impressions + item.starting_impressions,
        }),
        { spend: 0, impressions: 0 }
      );
      const oldCpm = (total.spend / total.impressions) * 1000;
      // console.table({
      //   value,
      //   oldCpm,
      //   "total Impressions": total.impressions,
      // });
      // console.log("ITEMS", items);
      disaggregatedUpdateMap[field] = (v) => {
        // CPM * (newCPM / oldCPM)
        // spend / impressions * 1000 =
        return (v * value) / oldCpm;
      };
      return;
    }
  });

  return disaggregatedUpdateMap;
}

export function getItemUpdateMapFromDisaggregated({
  item,
  disaggregatedUpdateMap,
}) {
  return Object.fromEntries(
    Object.entries(disaggregatedUpdateMap).map(([field, getNewValue]) => [
      field,
      getNewValue(item[field]),
    ])
  );
}

function editGroup(state, { sdKey, target, updateMap, scenarioAspiration }) {
  console.log("editGroup", sdKey, target, updateMap, scenarioAspiration);
  const groupTargetMatcher = getItemGroupMatcher(target, sdKey);
  const groupItems = state[sdKey].filter(groupTargetMatcher);

  // console.log(
  //   target,
  //   scenarioDataHelpers[sdKey]?.getGroupItem?.(groupItems, state, target)
  // );

  const { validatedUpdateMap, validationErrors } = scenarioDataHelpers[
    sdKey
  ].validateUpdateMap(
    updateMap,
    target,
    scenarioDataHelpers[sdKey]?.getGroupItem?.(groupItems, state, target)
  );
  // console.log("Validation", validatedUpdateMap, validationErrors);

  const stateWithValidationErrors = {
    ...state,
    validationErrors: addValidationErrors(
      ValidationErrorKeys.EditGroup,
      state,
      sdKey,
      validationErrors
    ),
  };

  const {
    state: newState,
    updateMap: newUpdateMap,
    changedFields,
  } = scenarioDataHelpers[sdKey].runSpecialGroupUpdates?.(
    stateWithValidationErrors,
    {
      target,
      updateMap: validatedUpdateMap,
      scenarioAspiration,
    }
  ) || { state: stateWithValidationErrors, updateMap: validatedUpdateMap };

  const disaggregatedUpdateMap = getDisaggregatedUpdateMap({
    sdKey,
    items: groupItems,
    updateMap: newUpdateMap,
  });

  const itemsWithUpdates =
    Object.keys(disaggregatedUpdateMap).length !== 0
      ? groupItems.map((item) => [
          item,
          getItemUpdateMapFromDisaggregated({ item, disaggregatedUpdateMap }),
        ])
      : [];

  const { state: updatedState, changedFields: updatedChangedFields } =
    editGroupWithUpdateMaps(newState, {
      sdKey,
      target,
      itemsWithUpdates,
      scenarioAspiration,
      changedFields,
    });

  return (
    scenarioDataHelpers[sdKey].totalUpdateSideEffects?.(updatedState, state, {
      target,
      modifiedFields: updatedChangedFields,
      scenarioAspiration,
    }) || updatedState
  );
}

function generateGridKeyState(keys, getValue) {
  return keys.reduce(
    (acc, k) => ({
      ...acc,
      [k]: getValue(k),
    }),
    {}
  );
}

const getGridTransactionsStoppedValue = () => null;
const getGridTransactionsStartedValue = () => [];
const getItemDbUpdatesDefaultValue = () => undefined;
const getGroupDbUpdatesDefaultValue = () => [];

function init(
  _,
  {
    id = 0,
    sales = [],
    media = [],
    coefficients = [],
    results = [],
    constraints = [],
  }
) {
  return {
    id,
    sales,
    media,
    coefficients,
    results,
    constraints,
    gridTransactions: generateGridKeyState(
      gridTransactionsKeys,
      getGridTransactionsStoppedValue
    ),
    itemDbUpdates: generateGridKeyState(
      itemDbUpdatesKeys,
      getItemDbUpdatesDefaultValue
    ),
    groupDbUpdates: generateGridKeyState(
      groupDbUpdatesKeys,
      getGroupDbUpdatesDefaultValue
    ),
    validationErrors: objectMap(ValidationErrorKeys, ([_, v]) => [v, null]),
  };
}

function setKey(state, { sdKey, value }) {
  // Add transactions but not persist.
  return {
    ...state,
    [sdKey]: value,
    gridTransactions: addGridTransactions(
      ScenarioDataKeys.Results,
      state.gridTransactions,
      value
    ),
  };
}

function startReceivingGridTransactions(state, { sdKey }) {
  return {
    ...state,
    gridTransactions: {
      ...state.gridTransactions,
      [sdKey]: getGridTransactionsStartedValue(),
    },
  };
}

function stopReceivingGridTransactions(state, { sdKey }) {
  return {
    ...state,
    gridTransactions: {
      ...state.gridTransactions,
      [sdKey]: getGridTransactionsStoppedValue(),
    },
  };
}

function clearItemDbUpdate(state, { sdKey }) {
  return {
    ...state,
    itemDbUpdates: {
      ...state.itemDbUpdates,
      [sdKey]: getItemDbUpdatesDefaultValue(),
    },
  };
}

function clearGroupDbUpdate(state, { sdKey }) {
  return {
    ...state,
    groupDbUpdates: {
      ...state.groupDbUpdates,
      [sdKey]: getGroupDbUpdatesDefaultValue(),
    },
  };
}

function clearValidationError(state, { veKey }) {
  return {
    ...state,
    validationErrors: {
      ...state.validationErrors,
      [veKey]: null,
    },
  };
}

export const ScenarioDataDispatchType = {
  Init: "init",
  SetKey: "setKey",
  StartReceivingGridTransactions: "startReceivingGridTransactions",
  ClearGridTransactions: "clearGridTransactions",
  StopReceivingGridTransactions: "stopReceivingGridTransactions",
  ClearItemDbUpdate: "clearItemDbUpdate",
  ClearGroupDbUpdate: "clearGroupDbUpdate",
  EditItem: "editItem",
  EditGroup: "editGroup",
  ClearValidationError: "clearValidationError",
};

const typeToHandlerMap = {
  [ScenarioDataDispatchType.Init]: init,
  [ScenarioDataDispatchType.SetKey]: setKey,
  [ScenarioDataDispatchType.StartReceivingGridTransactions]:
    startReceivingGridTransactions,
  // clear needs to set it to an empty array. Same as start receiving
  [ScenarioDataDispatchType.ClearGridTransactions]:
    startReceivingGridTransactions,
  [ScenarioDataDispatchType.StopReceivingGridTransactions]:
    stopReceivingGridTransactions,
  [ScenarioDataDispatchType.ClearItemDbUpdate]: clearItemDbUpdate,
  [ScenarioDataDispatchType.ClearGroupDbUpdate]: clearGroupDbUpdate,
  [ScenarioDataDispatchType.EditItem]: editItem,
  [ScenarioDataDispatchType.EditGroup]: editGroup,
  [ScenarioDataDispatchType.ClearValidationError]: clearValidationError,
};

function preprocessDispatchPayload(type, payload) {
  let newPayload = { ...payload };
  if (payload.gridKey) {
    newPayload.sdKey = payload.gridKey;
    delete newPayload.gridKey;
  }
  if (
    [
      ScenarioDataDispatchType.EditItem,
      ScenarioDataDispatchType.EditGroup,
    ].includes(type)
  ) {
    if (newPayload.sdKey === ScenarioDataKeys.Results) {
      // convert results edits to media edits
      newPayload.sdKey = ScenarioDataKeys.Media;
    }

    // convert $0 spend to $0.01
    [
      "starting_spend",
      "final_spend",
      "starting_impressions",
      "final_impressions",
    ].forEach((f) => {
      if (newPayload.updateMap[f] === 0) {
        newPayload.updateMap[f] = 0.01;
      }
    });
  }
  console.log("processed payload", newPayload);
  return newPayload;
}

export function scenarioDataReducer(state, action) {
  if (action.type === undefined) {
    throw new Error("scenarioDataReducer: invalid action", action);
  }
  const { type, ...payload } = action;
  // console.log("scenarioDataReducer", type, payload, state);
  console.log("scenarioDataReducer", type, payload);
  if (state == null && type !== ScenarioDataDispatchType.Init) {
    // only init is allowed until state is initialized
    console.warn("Ignored", type, "because no init");
    return state;
  }
  if (typeToHandlerMap.hasOwnProperty(type)) {
    return typeToHandlerMap[type](
      state,
      preprocessDispatchPayload(type, payload)
    );
  }
  console.error("Unhandled case", type, payload, state);
  return state;
}
