import { Box, CircularProgress } from "@mui/material";
import { forwardRef } from "react";
import { useMemo } from "react";

export const LoadingType = {
  Hide: "hide",
  Cover: "cover",
  Show: "show",
};

const LoadingSection = forwardRef(function LoadingSection(
  { isLoading, type, coverColor, children, progressSx, ...props },
  ref
) {
  const loadingContent = useMemo(() => {
    return (
      <Box
        sx={{
          ...(type !== LoadingType.Hide && {
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            background:
              coverColor ||
              (type === LoadingType.Cover ? "white" : "transparent"),
            ...(type === LoadingType.Show && { pointerEvents: "none" }),
          }),
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={progressSx || {}}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }, [type, coverColor]);

  return (
    <Box
      {...props}
      sx={{ width: "100%", height: "100%", position: "relative", ...props.sx }}
      ref={ref}
    >
      {(() => {
        switch (type) {
          case LoadingType.Hide:
            return isLoading ? loadingContent : children;
          case LoadingType.Cover:
            return (
              <>
                {children}
                {isLoading ? loadingContent : <></>}
              </>
            );
          case LoadingType.Show:
            return (
              <>
                {children}
                {isLoading ? loadingContent : <></>}
              </>
            );
          default:
            break;
        }
      })()}
    </Box>
  );
});

export default LoadingSection;
