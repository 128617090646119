import React, {
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

import {
  defaultExportFormats,
  finalName,
  getColorBySeriesKey,
  resultName,
  startingName,
  zeroTimeString,
} from "../../../constants";
import {
  Box,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ChartControls from "../ChartControls";
import LoadingSection, {
  LoadingType,
} from "../../../components/LoadingSection/LoadingSection";
import {
  batchSetSeriesProperty,
  ChartSeriesEnum,
  ChartToggleSeriesEnum,
  selectIsAllMediaFiltered,
  selectPlanChartData,
  selectPlanChartSeriesProperties,
  selectToggleSeries,
  setSeriesData,
  setSeriesProperty,
  setToggleSeries,
} from "./planChartSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdaptiveYAxes,
  addBigNumberPrefixes,
  addCategoryAxis,
  addColumnSeries,
  addLegend,
  addLineSeries,
  addTitle,
  addValueAxis,
  addZeroBigNumberPrefix,
  dollarsFormatString,
  getDefaultTheme,
  setupZoomOutButton,
} from "../../../utils/am5ChartUtils";
import { getFormatters } from "../../GridUtils";
import { useIntl } from "react-intl";
import { ScenarioDataKeys } from "../../../scenarioDataUtils/scenarioDataUtils";
import { useOnScenarioDataPersist } from "../../../hooks/onScenarioDataPersist";
import {
  selectAllSelectedMediaTargets,
  selectAllSelectedSalesTargets,
  selectScenario,
} from "../../../app/scenarioSlice";
import { useLazyGetPlanChartDataQuery } from "../../../app/planChartApi";

const CHART_ID = "plan-chart";

const toggleSeriesKeys = {
  [ChartToggleSeriesEnum.Contribution]: [
    ChartSeriesEnum.StartingContribution,
    ChartSeriesEnum.FinalContribution,
  ],
  [ChartToggleSeriesEnum.Sales]: [
    ChartSeriesEnum.StartingSales,
    ChartSeriesEnum.FinalSales,
  ],
};

const hideOnAllMediaFiltered = [
  ChartSeriesEnum.StartingSpend,
  ChartSeriesEnum.FinalSpend,
  ChartSeriesEnum.StartingContribution,
  ChartSeriesEnum.FinalContribution,
];

const chartTitleSuffix = "Series";
const getChartTitleText = (toggleSeries) =>
  toggleSeries === ChartToggleSeriesEnum.Contribution
    ? `${resultName} ${chartTitleSuffix}`
    : toggleSeries === ChartToggleSeriesEnum.Sales
    ? `Sales ${chartTitleSuffix}`
    : chartTitleSuffix;

const barFullWidth = am5.percent(80);
const barHalfWidth = am5.percent(42);

function PlanChart({
  isInPresentationMode,
  enterPresentationMode,
  exitPresentationMode,
  presentationModePopOverContainer,
}) {
  const { id: scenarioId, userId } = useSelector(selectScenario);

  const intl = useIntl();
  const formatters = useMemo(
    () => getFormatters(intl, { hideOnValue: true }),
    [intl]
  );

  const [getPlanChartData] = useLazyGetPlanChartDataQuery();

  const data = useSelector(selectPlanChartData);
  const seriesProperties = useSelector(selectPlanChartSeriesProperties);
  const currentToggleSeries = useSelector(selectToggleSeries);
  const isAllMediaFiltered = useSelector(selectIsAllMediaFiltered);
  const dispatch = useDispatch();

  const [exporting, setExporting] = useState(null);

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const manageUpdateLoading = useMemo(() => {
    let outstandingShows = 0;
    return {
      show: () => {
        outstandingShows += 1;
        setIsUpdateLoading(true);
      },
      hide: () => {
        outstandingShows -= 1;
        if (outstandingShows === 0) {
          setIsUpdateLoading(false);
        }
      },
    };
  }, [setIsUpdateLoading]);

  // TODO stop using this selector function
  const mediaTargets = useSelector(selectAllSelectedMediaTargets);
  const salesTargets = useSelector(selectAllSelectedSalesTargets);

  const isLoading = useMemo(
    () => !data || !mediaTargets || !salesTargets,
    [data, mediaTargets, salesTargets]
  );

  const series = useRef({});

  const root = useRef(null);
  const chart = useRef(null);
  const chartTitleLabel = useRef(null);
  const legend = useRef(null);
  const periodAxis = useRef(null);
  const spendAxis = useRef(null);
  const contributionAxis = useRef(null);
  const salesAxis = useRef(null);

  useLayoutEffect(() => {
    dispatch(
      batchSetSeriesProperty([
        {
          seriesKey: ChartSeriesEnum.StartingSpend,
          key: "isVisible",
          value: true,
        },
        {
          seriesKey: ChartSeriesEnum.FinalSpend,
          key: "isVisible",
          value: true,
        },
      ])
    );
  }, []);

  useLayoutEffect(() => {
    dispatch(
      batchSetSeriesProperty(
        Object.entries(toggleSeriesKeys).flatMap(([toggleKey, seriesKeys]) =>
          seriesKeys.map((key) => ({
            seriesKey: key,
            key: "isVisible",
            value: toggleKey === currentToggleSeries,
          }))
        )
      )
    );
  }, [currentToggleSeries]);

  useLayoutEffect(() => {
    root.current = am5.Root.new(CHART_ID);

    root.current.setThemes([getDefaultTheme(root)]);

    addBigNumberPrefixes(root.current);

    chart.current = root.current.container.children.push(
      am5xy.XYChart.new(root.current, {
        panY: false,
        layout: root.current.verticalLayout,
        cursor: am5xy.XYCursor.new(root.current, { behavior: "zoomX" }),
      })
    );

    chart.current.plotContainer.setAll({ cursorOverStyle: "crosshair" });

    setupZoomOutButton(root.current, chart.current);

    const { axis: xAxisBottom } = addCategoryAxis({
      root: root.current,
      axes: chart.current.xAxes,
      direction: "x",
      axisOptions: {
        categoryField: "period",
      },
      labelOptions: {
        text: "Period",
      },
      labelIndex: -1,
    });
    const xRenderer = xAxisBottom.get("renderer");
    xRenderer.setAll({
      minGridDistance: 40,
    });
    xRenderer.labels.template.setAll({
      rotation: -90,
      location: 0.5,
      multiLocation: 0.5,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 5,
      paddingBottom: 10,
    });
    xRenderer.ticks.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
      visible: false,
    });
    periodAxis.current = xAxisBottom;

    const { axis: yAxisLeft } = addValueAxis({
      root: root.current,
      axes: chart.current.yAxes,
      direction: "y",
      labelOptions: {
        text: "Spend",
      },
    });
    addZeroBigNumberPrefix(yAxisLeft);
    spendAxis.current = yAxisLeft;

    function addZeroMinValue(axis) {
      axis.events.on("boundschanged", ({ target }) => {
        if (target.getPrivate("min") < 0) {
          target.set("min", 0);
        }
      });
    }

    const { axis: yAxisRightOne } = addValueAxis({
      root: root.current,
      axes: chart.current.yAxes,
      direction: "y",
      opposite: true,
      labelOptions: {
        text: resultName,
      },
      labelIndex: -1,
    });
    addZeroBigNumberPrefix(yAxisRightOne);
    addZeroMinValue(yAxisRightOne);
    contributionAxis.current = yAxisRightOne;

    const { axis: yAxisRightTwo } = addValueAxis({
      root: root.current,
      axes: chart.current.yAxes,
      direction: "y",
      opposite: true,
      labelOptions: {
        text: "Sales",
      },
      labelIndex: -1,
    });
    addZeroBigNumberPrefix(yAxisRightTwo);
    addZeroMinValue(yAxisRightTwo);
    salesAxis.current = yAxisRightTwo;

    // Visuals only axis
    chart.current.xAxes.push(
      am5xy.ValueAxis.new(root.current, {
        renderer: am5xy.AxisRendererX.new(root.current, {
          opposite: true,
          stroke: am5.color("#d5d5d5"),
          strokeOpacity: 1,
          strokeWidth: 2,
        }),
      })
    );

    series.current[ChartSeriesEnum.StartingSpend] = addColumnSeries({
      root: root.current,
      chart: chart.current,
      seriesOptions: {
        name: `${startingName} Spend`,
        xAxis: periodAxis.current,
        yAxis: spendAxis.current,
        categoryXField: "period",
        valueYField: ChartSeriesEnum.StartingSpend,
        clustered: false,
      },
      numberFormat: dollarsFormatString,
      color: getColorBySeriesKey(ChartSeriesEnum.StartingSpend),
    });

    series.current[ChartSeriesEnum.StartingSpend].columns.template.setAll({
      width: barFullWidth,
    });

    series.current[ChartSeriesEnum.FinalSpend] = addColumnSeries({
      root: root.current,
      chart: chart.current,
      seriesOptions: {
        name: `${finalName} Spend`,
        xAxis: periodAxis.current,
        yAxis: spendAxis.current,
        categoryXField: "period",
        valueYField: ChartSeriesEnum.FinalSpend,
        clustered: false,
      },
      numberFormat: dollarsFormatString,
      color: getColorBySeriesKey(ChartSeriesEnum.FinalSpend),
    });

    series.current[ChartSeriesEnum.StartingContribution] = addLineSeries({
      root: root.current,
      chart: chart.current,
      seriesOptions: {
        name: `${startingName} ${resultName}`,
        xAxis: periodAxis.current,
        yAxis: contributionAxis.current,
        categoryXField: "period",
        valueYField: ChartSeriesEnum.StartingContribution,
      },
      numberFormat: dollarsFormatString,
      color: getColorBySeriesKey(ChartSeriesEnum.StartingContribution),
      backgroundSeriesOptions: {},
    });

    series.current[ChartSeriesEnum.FinalContribution] = addLineSeries({
      root: root.current,
      chart: chart.current,
      seriesOptions: {
        name: `${finalName} ${resultName}`,
        xAxis: periodAxis.current,
        yAxis: contributionAxis.current,
        categoryXField: "period",
        valueYField: ChartSeriesEnum.FinalContribution,
      },
      numberFormat: dollarsFormatString,
      color: getColorBySeriesKey(ChartSeriesEnum.FinalContribution),
      backgroundSeriesOptions: {},
    });

    series.current[ChartSeriesEnum.StartingSales] = addLineSeries({
      root: root.current,
      chart: chart.current,
      seriesOptions: {
        name: `${startingName} Sales`,
        xAxis: periodAxis.current,
        yAxis: salesAxis.current,
        categoryXField: "period",
        valueYField: ChartSeriesEnum.StartingSales,
      },
      numberFormat: dollarsFormatString,
      color: getColorBySeriesKey(ChartSeriesEnum.StartingSales),
      backgroundSeriesOptions: {},
    });

    series.current[ChartSeriesEnum.FinalSales] = addLineSeries({
      root: root.current,
      chart: chart.current,
      seriesOptions: {
        name: `${finalName} Sales`,
        xAxis: periodAxis.current,
        yAxis: salesAxis.current,
        categoryXField: "period",
        valueYField: ChartSeriesEnum.FinalSales,
      },
      numberFormat: dollarsFormatString,
      color: getColorBySeriesKey(ChartSeriesEnum.FinalSales),
      backgroundSeriesOptions: {},
    });

    addAdaptiveYAxes({
      axesInfo: [
        {
          axis: spendAxis.current,
          series: [
            ChartSeriesEnum.StartingSpend,
            ChartSeriesEnum.FinalSpend,
          ].map((k) => series.current[k]),
          side: "left",
          desiredSide: "left",
        },
        {
          axis: contributionAxis.current,
          series: toggleSeriesKeys[ChartToggleSeriesEnum.Contribution].map(
            (k) => series.current[k]
          ),
          side: "right",
          desiredSide: "left",
        },
        {
          axis: salesAxis.current,
          series: toggleSeriesKeys[ChartToggleSeriesEnum.Sales].map(
            (k) => series.current[k]
          ),
          side: "right",
          desiredSide: "left",
        },
      ],
    });

    legend.current = addLegend({
      root: root.current,
      chart: chart.current,
      options: {
        clickTarget: "none",
      },
      onClick: (e) => {
        const seriesKey = e.target._dataItem.dataContext._settings.valueYField;
        dispatch(setSeriesProperty({ seriesKey, key: "isVisible" }));
      },
    });

    legend.current.data.setAll(Object.values(series.current));

    chartTitleLabel.current = addTitle({
      root: root.current,
      chart: chart.current,
      options: {
        text: "Series",
      },
    });

    return () => {
      root.current.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    chartTitleLabel.current.set("text", getChartTitleText(currentToggleSeries));
    legend.current.itemContainers.values.forEach((itemContainer) => {
      const seriesKey =
        itemContainer.dataItem.dataContext._settings.valueYField;
      if (!Object.values(toggleSeriesKeys).flat().includes(seriesKey)) return;
      itemContainer.set(
        "visible",
        toggleSeriesKeys[currentToggleSeries].includes(seriesKey)
      );
    });
  }, [currentToggleSeries]);

  // set series properties on change
  useLayoutEffect(() => {
    if (
      Object.values(ChartSeriesEnum).some(
        (seriesKey) => seriesProperties[seriesKey] == null
      )
    )
      return;

    function setVisibility(o, condition) {
      if (condition) {
        o.show();
        return;
      }
      o.hide();
    }

    function shouldShowSeries(key) {
      return (
        seriesProperties[key]?.isVisible &&
        !(isAllMediaFiltered && hideOnAllMediaFiltered.includes(key))
      );
    }

    Object.values(ChartSeriesEnum).forEach((key) => {
      setVisibility(series.current[key], shouldShowSeries(key));
    });

    if (shouldShowSeries(ChartSeriesEnum.FinalSpend)) {
      series.current[ChartSeriesEnum.FinalSpend].columns.template.setAll({
        width: seriesProperties[ChartSeriesEnum.StartingSpend].isVisible
          ? barHalfWidth
          : barFullWidth,
      });
      series.current[ChartSeriesEnum.FinalSpend].hide();
      series.current[ChartSeriesEnum.FinalSpend].show();
    }
  }, [seriesProperties, isAllMediaFiltered]);

  useEffect(() => {
    if (data == null) return;
    const formattedData = data.map((d) => ({
      ...d,
      period: formatters.date({ value: d.period + zeroTimeString }),
    }));

    setExporting(
      am5plugins_exporting.Exporting.new(root.current, {
        filePrefix: "Series",
        dataSource: formattedData,
      })
    );

    Object.values(ChartSeriesEnum).forEach((key) => {
      series.current[key].data.setAll(formattedData);
    });

    periodAxis.current.data.setAll(formattedData);
  }, [data]);

  useLayoutEffect(() => {
    if (mediaTargets == null || salesTargets == null) return;
    let abort = false;
    (async () => {
      manageUpdateLoading.show();
      const { data: newData, isSuccess } = await getPlanChartData({
        userId,
        scenarioId,
        mediaTargets,
        salesTargets,
      });
      if (!abort && isSuccess) dispatch(setSeriesData(newData));
      manageUpdateLoading.hide();
    })();
    return () => {
      abort = true;
    };
  }, [mediaTargets, salesTargets]);

  const updateOnPersist = useCallback(
    async (shouldAbort, changes) => {
      if (mediaTargets == null || salesTargets == null) return;
      manageUpdateLoading.show();
      const { data: newData, isSuccess } = await getPlanChartData({
        userId,
        scenarioId,
        mediaTargets,
        salesTargets,
      });
      if (!shouldAbort() && isSuccess) dispatch(setSeriesData(newData));
      manageUpdateLoading.hide();
    },
    [userId, scenarioId, mediaTargets, salesTargets]
  );

  const onPersistKeys = useMemo(
    () => [
      ScenarioDataKeys.Results,
      ScenarioDataKeys.Media,
      ScenarioDataKeys.Sales,
    ],
    []
  );

  useOnScenarioDataPersist(onPersistKeys, updateOnPersist);

  return (
    <LoadingSection
      isLoading={isLoading || isUpdateLoading}
      type={
        isUpdateLoading && !isLoading ? LoadingType.Show : LoadingType.Cover
      }
      coverColor={
        isUpdateLoading && !isLoading ? "hsl(0 0% 100% / 70%)" : undefined
      }
    >
      <ChartControls
        am5Exporting={exporting}
        chartExportFormats={defaultExportFormats}
        presentationModePopOverContainer={presentationModePopOverContainer}
        isInPresentationMode={isInPresentationMode}
        enterPresentationMode={enterPresentationMode}
        exitPresentationMode={exitPresentationMode}
      >
        <FormControl>
          <ToggleButtonGroup
            color="primary"
            value={currentToggleSeries}
            exclusive
            onChange={(_, v) => dispatch(setToggleSeries(v))}
          >
            <ToggleButton
              value={ChartToggleSeriesEnum.Contribution}
              disabled={
                ChartToggleSeriesEnum.Contribution === currentToggleSeries
              }
            >
              {resultName}
            </ToggleButton>
            <ToggleButton
              value={ChartToggleSeriesEnum.Sales}
              disabled={ChartToggleSeriesEnum.Sales === currentToggleSeries}
            >
              Sales
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </ChartControls>
      <Box id={CHART_ID} style={{ width: "100%", height: "100%" }}></Box>
    </LoadingSection>
  );
}

export default PlanChart;
