import * as React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";

export default function WizardDialog({ open, setOpen, children }) {

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: "10px" },
      }}
      fullWidth
      maxWidth={"xs"}
    >
      {children}
    </Dialog>
  );
}

WizardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node,
};