import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { colors } from "../constants";

export const bigDollarsFormatString = "$#.0a|-$#.0as";
export const dollarsFormatString = "$#,###.|-$#,###.s";
export const roiDollarsFormatString = "$#.00|-$#.00s";
export const bigNumberFormatString = "#.0a";

export const axisLabelSeriesSeparator = " | ";

export function getDefaultTheme(root) {
  const am5Theme = am5.Theme.new(root);

  am5Theme.rule("Grid", ["base"]).setAll({
    strokeOpacity: 0,
  });

  am5Theme.rule("Grid").setAll({
    strokeOpacity: 0,
  });

  am5Theme.rule("AxisTick").setAll({
    stroke: am5.color("#d5d5d5"),
    strokeOpacity: 1,
    strokeWidth: 2,
    visible: true,
  });

  am5Theme.rule("AxisLabel").setAll({
    fill: am5.color("#323232"),
    fontSize: "1em",
    // paddingTop: 20,
  });

  am5Theme.rule("Label").setAll({
    fill: am5.color("#323232"),
    fontSize: "1em",
    // paddingTop: 20,
  });
  return am5Theme;
}

export function addBigNumberPrefixes(root) {
  root.numberFormatter.setAll({
    bigNumberPrefixes: [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "M" },
      { number: 1e9, suffix: "B" },
    ],
  });
}

export function addZeroBigNumberPrefix(axis) {
  let previousLabelPrefix = "";
  axis
    .get("renderer")
    .labels.template.adapters.add("text", function (text, target) {
      if (text == null) return text;
      if (text.replace("$", "") === "0.0") {
        return text + previousLabelPrefix;
      } else {
        const prefix = text.charAt(text.length - 1);
        previousLabelPrefix = ["K", "M", "B"].includes(prefix) ? prefix : "";
      }
      return text;
    });
}

export function setupZoomOutButton(root, chart) {
  chart.zoomOutButton.set(
    "background",
    am5.RoundedRectangle.new(root, {
      cursorOverStyle: "pointer",
      cornerRadiusBL: 20,
      cornerRadiusBR: 20,
      cornerRadiusTL: 20,
      cornerRadiusTR: 20,
      fill: am5.color("#29abe2"),
      height: 30,
      width: 30,
      // fillOpacity: 0.2,
    })
  );
  chart.zoomOutButton.set("exportable", false);
}

const labelPadding = 10;
function getDefaultLabelsOptions(direction, opposite) {
  let position = opposite ? "Left" : "Right";
  if (direction === "x") {
    position = opposite ? "Bottom" : "Top";
  }
  return { ["padding" + position]: labelPadding };
}

function getDefaultLabelOptions(direction, opposite) {
  return {
    ...(direction === "x" && {
      centerY: am5.p50,
      x: am5.p50,
      centerX: am5.p50,
    }),
    ...(direction === "y" && {
      rotation: opposite ? 90 : -90,
      y: am5.p50,
      centerX: am5.p50,
    }),
  };
}

function setupAxis({
  root,
  axis,
  direction,
  opposite,
  labelsOptions,
  labelOptions,
  labelIndex,
}) {
  const defaultLabelsOptions = getDefaultLabelsOptions(direction, opposite);
  const defaultLabelOptions = getDefaultLabelOptions(direction, opposite);

  const axisRenderer = axis.get("renderer");
  axisRenderer.labels.template.setAll({
    ...defaultLabelsOptions,
    ...labelsOptions,
  });

  const axisLabel = axis.children.insertIndex(
    // Ternary to support negative indexing.
    labelIndex >= 0 ? labelIndex : axis.children.length + labelIndex,
    am5.Label.new(root, {
      ...defaultLabelOptions,
      ...labelOptions,
    })
  );

  const tooltip = axis.get("tooltip");

  tooltip.get("background").setAll({
    fill: am5.color(colors.darkGrey),
    fillOpacity: 1,
  });

  axis.on("visible", function (visible) {
    tooltip.set("forceHidden", !visible);
  });
  return axisLabel;
}

export function addValueAxis({
  root,
  axes,
  direction = "x",
  opposite = false,
  axisOptions,
  labelsOptions,
  labelOptions,
  labelIndex = 0,
}) {
  const AxisRenderer =
    direction === "y" ? am5xy.AxisRendererY : am5xy.AxisRendererX;

  const axis = axes.push(
    am5xy.ValueAxis.new(root, {
      renderer: AxisRenderer.new(root, {
        opposite,
        stroke: am5.color("#d5d5d5"),
        strokeOpacity: 1,
        strokeWidth: 2,
      }),
      tooltip: am5.Tooltip.new(root, {}),
      numberFormat: bigDollarsFormatString,
      ...axisOptions,
    })
  );

  const axisLabel = setupAxis({
    root,
    axis,
    direction,
    opposite,
    labelsOptions,
    labelOptions,
    labelIndex,
  });

  return { axis, axisLabel };
}

export function addCategoryAxis({
  root,
  axes,
  direction = "x",
  opposite = false,
  axisOptions,
  labelsOptions,
  labelOptions,
  labelIndex = 0,
}) {
  const AxisRenderer =
    direction === "y" ? am5xy.AxisRendererY : am5xy.AxisRendererX;

  const axis = axes.push(
    am5xy.CategoryAxis.new(root, {
      renderer: AxisRenderer.new(root, {
        opposite,
        stroke: am5.color("#d5d5d5"),
        strokeOpacity: 1,
        strokeWidth: 2,
      }),
      tooltip: am5.Tooltip.new(root, {}),
      numberFormat: bigDollarsFormatString,
      ...axisOptions,
    })
  );

  const axisLabel = setupAxis({
    root,
    axis,
    direction,
    opposite,
    labelsOptions,
    labelOptions,
    labelIndex,
  });

  return { axis, axisLabel };
}

export function addAxisRange({
  axis,
  data,
  color,
  gridOptions = {},
  axisFillOptions = {},
}) {
  const rangeDataItem = axis.makeDataItem(data);
  const axisRange = axis.createAxisRange(rangeDataItem);
  rangeDataItem.get("grid").setAll({
    ...{ stroke: color, strokeWidth: 2, strokeOpacity: 1, visible: true },
    ...gridOptions,
  });
  rangeDataItem
    .get("axisFill")
    .setAll({ ...{ fill: color, visible: true }, ...axisFillOptions });
  rangeDataItem.get("tick").set("visible", false);
  return axisRange;
}

export function addLineSeries({
  root,
  chart,
  seriesOptions,
  numberFormat,
  color,
  backgroundSeriesOptions,
}) {
  const series = chart.series.push(
    am5xy.LineSeries.new(root, {
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: `{name}: [bold]{valueY.formatNumber('${numberFormat}')}[/]`,
      }),
      fill: am5.color(color),
      stroke: am5.color(color),
      ...seriesOptions,
    })
  );

  series.strokes.template.setAll({
    strokeWidth: 2,
  });

  if (backgroundSeriesOptions != null) {
    const backgroundSeries = chart.series.insertIndex(
      chart.series.length - 1,
      am5xy.LineSeries.new(root, {
        ...seriesOptions,
        name: `${seriesOptions.name} Background`,
        fill: am5.color(backgroundSeriesOptions.color || "#fff"),
        stroke: am5.color(backgroundSeriesOptions.color || "#fff"),
        opacity: backgroundSeriesOptions.opacity || 0.75,
        ...backgroundSeriesOptions.seriesOptions,
      })
    );

    backgroundSeries.strokes.template.setAll({
      strokeWidth: 4,
    });

    series.events.on("datavalidated", function ({ target }) {
      backgroundSeries.data.setAll(target.data.values);
    });

    series.on("visible", function (visible) {
      if (visible) backgroundSeries.show();
      else backgroundSeries.hide();
    });
  }

  return series;
}

export function addColumnSeries({
  root,
  chart,
  seriesOptions,
  numberFormat,
  color,
}) {
  // Create profit series
  const series = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: `{name}: [bold]{valueY.formatNumber('${numberFormat}')}[/]`,
      }),
      fill: am5.color(color),
      stroke: am5.color(color),
      ...seriesOptions,
    })
  );
  return series;
}

export function addLegend({ root, chart, options, onClick }) {
  const legend = chart.children.unshift(
    am5.Legend.new(root, {
      x: am5.percent(50),
      centerX: am5.percent(50),
      layout: am5.GridLayout.new(root, {
        maxColumns: 4,
      }),
      nameField: "name",
      fillField: "color",
      strokeField: "color",
      // cursorOverStyle: "type"
      marginBottom: 25,
      ...options,
    })
  );

  legend.itemContainers.template.setAll({
    cursorOverStyle: "pointer",
  });

  if (typeof onClick === "function") {
    legend.itemContainers.template.events.on("click", onClick);
  }

  return legend;
}

export function addTitle({ root, chart, options }) {
  return chart.children.unshift(
    am5.Label.new(root, {
      fontSize: 20,
      textAlign: "center",
      x: am5.percent(50),
      centerX: am5.percent(50),
      marginBottom: 10,
      marginTop: 20,
      ...options,
    })
  );
}

export function setSeriesProperties(state, { seriesKey, key, value }) {
  state.seriesProperties = state.seriesProperties || {};
  state.seriesProperties[seriesKey] = state.seriesProperties[seriesKey] || {};
  if (key === "isVisible" && value === undefined) {
    // toggle isVisible with undefined
    state.seriesProperties[seriesKey][key] =
      !state.seriesProperties[seriesKey][key];
    return;
  }
  state.seriesProperties[seriesKey][key] = value;
}

function am5ChildrenReverse(children) {
  for (let index = 0; index < children.values.length; index++) {
    children.insertIndex(0, children.removeIndex(index));
  }
}

function changeAxisSide(axisInfo, newSide) {
  const { axis, currentSide, labelsOptions = {}, labelOptions = {} } = axisInfo;
  const opposite = newSide !== "left";
  const oppositeCurrent = currentSide === "left" ? opposite : !opposite;
  const defaultLabelsOptions = getDefaultLabelsOptions("y", opposite);
  const oppositeDefaultLabelsOptions = Object.fromEntries(
    Object.keys(getDefaultLabelsOptions("y", !opposite)).map((k) => [
      k,
      undefined,
    ])
  );
  const defaultLabelOptions = getDefaultLabelOptions("y", opposite);

  const axisRenderer = axis.get("renderer");
  axisRenderer.set("opposite", opposite);
  axisRenderer.labels.template.setAll({
    ...oppositeDefaultLabelsOptions,
    ...defaultLabelsOptions,
    ...labelsOptions,
    centerX: opposite ? am5.p0 : am5.p100,
  });

  const title = axis.children.values.find((c) => c.className === "Label");
  title.setAll({
    ...defaultLabelOptions,
    ...labelOptions,
  });
  if (oppositeCurrent) {
    am5ChildrenReverse(axis.children);
  }
  axisInfo.currentSide = newSide;
}

export function addAdaptiveYAxes({ axesInfo }) {
  axesInfo.forEach((axisInfo) => {
    axisInfo.currentSide = axisInfo.side;
  });
  const wantInvertedSide = axesInfo.filter(
    ({ side, desiredSide }) => side !== desiredSide
  );

  function adjust() {
    axesInfo.forEach(({ axis, series }) => {
      if (series.some((s) => s.get("visible"))) axis.show();
      else axis.hide();
    });

    wantInvertedSide.forEach((axisInfo) => {
      const { side, desiredSide, currentSide } = axisInfo;
      const canUseDesired = !axesInfo.some(
        ({ axis, side }) => side === desiredSide && axis.get("visible")
      );
      if (canUseDesired && desiredSide !== currentSide) {
        changeAxisSide(axisInfo, desiredSide);
      }
      if (!canUseDesired && desiredSide === currentSide) {
        changeAxisSide(axisInfo, side);
      }
    });
  }

  const allSeries = axesInfo.flatMap((o) => o.series);
  allSeries.forEach((series) => {
    series.on("visible", adjust);
  });
}
