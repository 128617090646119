import React, { useEffect, useState } from "react";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoadingSection, { LoadingType } from "../LoadingSection/LoadingSection";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementActiveStep,
  incrementActiveStep,
  selectNewScenarioWizard,
} from "../../containers/NewScenarioWizard/newScenarioWizardSlice";
import { selectScenario } from "../../app/scenarioSlice";

export default function WizardStepper({
  handleCreate,
  scenarioId,
  children,
  errors,
  companyIsLoaded,
  companyIsSuccess,
}) {
  const theme = useTheme();

  const maxSteps = Array.isArray(children) ? children.length : 1;

  const { activeStep, loading: wizardDataIsLoading } = useSelector(
    selectNewScenarioWizard
  );

  const { switchingToId } = useSelector(selectScenario);

  const dispatch = useDispatch();

  const [isLoadingCreation, setIsLoadingCreation] = useState(false);

  useEffect(() => {
    // Do not unset, the modal closes when the creation is done.
    // Don't create an opportunity for a second scenario to be created
    if (isLoadingCreation) return;
    setIsLoadingCreation(switchingToId != null && switchingToId === scenarioId);
  }, [switchingToId, scenarioId]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {companyIsSuccess || !companyIsLoaded ? (
        <LoadingSection
          isLoading={wizardDataIsLoading}
          type={LoadingType.Cover}
        >
          {Array.isArray(children) ? children[activeStep] : children}
        </LoadingSection>
      ) : (
        <Box mt={3} mb={2} ml={3}>
          <Typography variant={"body1"}>
            Failed to load Model. Please make sure a model is selected in the
            settings.
          </Typography>
        </Box>
      )}
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          activeStep < maxSteps - 1 ? (
            <Button
              size="small"
              onClick={() => dispatch(incrementActiveStep())}
              disabled={Boolean(
                (activeStep === 0 &&
                  (errors.startDate || errors.endDate || !companyIsLoaded)) ||
                  (activeStep === 1 &&
                    (errors.salesChannels || errors.mediaChannels))
              )}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          ) : (
            <LoadingButton
              size="small"
              loading={isLoadingCreation}
              onClick={handleCreate}
            >
              Create
            </LoadingButton>
          )
        }
        backButton={
          <Button
            size="small"
            onClick={() => dispatch(decrementActiveStep())}
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
        sx={{ padding: 2 }}
      />
    </Box>
  );
}
