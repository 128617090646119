import { useVariableValue } from "@devcycle/react-client-sdk";
import { Box, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ChannelSelect from "../../components/ChannelSelect/ChannelSelect";
import ScenarioChannelsInput from "../../components/ScenarioChannelsInput/ScenarioChannelsInput";
import {
  selectNewScenarioWizard,
  setMediaChannels,
} from "./newScenarioWizardSlice";

export default function NewScenarioChannels({
  company,
  aspiration,
  errors,
  setErrors,
}) {
  const hierarchicalChannelSelectFlag = useVariableValue(
    "hierarchical-channel-select",
    false
  );

  const dispatch = useDispatch();
  const { mediaChannels } = useSelector(selectNewScenarioWizard);

  return (
    <>
      <Box mt={3} ml={3}>
        <Typography variant={"body1"}>
          {`Select Media Channels to ${
            aspiration.charAt(0).toUpperCase() + aspiration.slice(1)
          }`}
        </Typography>
        <Stack direction="column" spacing={3} mt={3} mb={4} mr={3}>
          {hierarchicalChannelSelectFlag ? (
            <ChannelSelect
              channelsKey="mediaChannels"
              label="Media Channels"
              channels={mediaChannels}
              setChannels={(channels) => dispatch(setMediaChannels(channels))}
              allChannels={company?.mediaChannels}
              hierarchy={company?.mediaHierarchy}
              errors={errors}
              setErrors={setErrors}
              isLoading={company == null}
            />
          ) : (
            <ScenarioChannelsInput
              channelsKey="mediaChannels"
              label="Media Channels"
              channels={mediaChannels}
              setChannels={(channels) => dispatch(setMediaChannels(channels))}
              allChannels={company?.mediaChannels}
              hierarchy={company?.mediaHierarchy}
              errors={errors}
              setErrors={setErrors}
              isLoading={company == null}
            />
          )}
        </Stack>
      </Box>
    </>
  );
}
