import { Box, Button, CircularProgress, Typography } from "@mui/material";

const centerScreenStyle = {
  margin: "auto",
  textAlign: "center",
};

function Login({ error, loginWithRedirect }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        height: "100vh",
      }}
    >
      {error ? (
        <Box sx={centerScreenStyle}>
          <Typography variant="h6" gutterBottom>
            {error.message}
          </Typography>
          <Button onClick={loginWithRedirect} variant="outlined">
            Retry
          </Button>
        </Box>
      ) : (
        <CircularProgress color="primary" sx={centerScreenStyle} />
      )}
    </Box>
  );
}

export default Login;
