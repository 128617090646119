import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  FolderOpenRounded as FolderOpenRoundedIcon,
  Menu as MenuIcon,
  TrendingUpRounded as TrendingUpRoundedIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer as MuiDrawer,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Toolbar,
  useTheme,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import MyResults from "../MyResults/MyResults";
import MyScenarios from "../MyScenarios/MyScenarios";

import { useAuth0 } from "@auth0/auth0-react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Router,
} from "react-router-dom";

import AppContext from "../../context/appContext";
import ScenarioContext from "../../context/scenarioContext";
import UserContext from "../../context/userContext";
import RunButton from "./RunButton";

import { useState } from "react";
import { addKonamiEvent } from "../../konami";

import {
  ScenarioDataDispatchType,
  ScenarioDataKeys,
  ValidationErrorKeys,
} from "../../scenarioDataUtils/scenarioDataUtils";

import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { useRunScenarioMutation } from "../../app/scenarioApi";
import { useGetScenarioChannelsQuery } from "../../app/scenarioChannelsApi";
import { useGetScenarioQuery } from "../../app/scenarioMetadataApi";
import scenariosApi from "../../app/scenariosApi";
import {
  addIsDoneCreatingScenario,
  addIsSwitchingScenario,
  changeScenario,
  changeToEmptyScenario,
  selectScenario,
} from "../../app/scenarioSlice";
import { useOnScenarioDataPersist } from "../../hooks/onScenarioDataPersist";
import { updateSeriesDataValidation } from "../MyResults/CurvesChart/curvesChartSlice";
import {
  open as openMyAi,
  selectIsOpen as selectIsMyAiOpen,
} from "../../app/myAiSlice";
import MyAi from "../MyAi/MyAi";

import { useVariableValue } from "@devcycle/react-client-sdk";
import SettingsModal from "./SettingsModal";
import {
  useGetUserQuery,
  useSetCurrentScenarioMutation,
  useSetDrawerOpenMutation,
} from "../../app/userApi";

// https://mui.com/material-ui/react-drawer/#mini-variant-drawer
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function DrawerLink({ open, label, Icon, linkProps }) {
  return (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2,
        }}
        component={Link}
        {...linkProps}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            justifyContent: "center",
            ...(open && { mr: 4, ml: "7px" }),
          }}
        >
          <Icon />
        </ListItemIcon>
        <ListItemText
          primary={label}
          sx={{ ...(!open && { display: "none" }) }}
        ></ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

const toolbarStyles = (theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
});

const Home = () => {
  const myAiValue = useVariableValue("my-ai", false);
  const settingsFlag = useVariableValue("settings", false);
  const dispatch = useDispatch();

  const { switchingToId } = useSelector(selectScenario);

  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const scenarioContext = useContext(ScenarioContext);
  const location = useLocation();
  const theme = useTheme();
  const { logout } = useAuth0();

  const { isScenarioReady, isEmptyScenario, isCreatingScenario, aspiration } =
    useSelector(selectScenario);

  const isMyAiOpen = useSelector(selectIsMyAiOpen);

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const {
    data: user,
    isLoading: userIsLoading,
    isSuccess: userIsSuccess,
  } = useGetUserQuery(
    {
      userId: userContext.userID,
    },
    {
      skip: userContext?.userID == null,
    }
  );

  // Initialize User
  const initUserOnceRef = useRef(false);
  useEffect(() => {
    if (!userIsSuccess || userIsLoading) return;

    if (initUserOnceRef.current) return;
    initUserOnceRef.current = true;

    dispatch(addIsSwitchingScenario({ id: user.scenarioId }));
  }, [user, userIsSuccess, userIsLoading]);

  const [debouncedEditItemValidationError] = useDebounce(
    scenarioContext.scenarioData?.validationErrors?.[
      ValidationErrorKeys.EditItem
    ],
    0
  );

  const [setDrawerOpen] = useSetDrawerOpenMutation();

  // const appContext = useContext(AppContext);

  // const [openSayMe, setOpenSaysMe] = React.useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    appContext.setSnackbarOpen(false);
  };

  const [shouldThrow, setShouldThrow] = useState(false);
  addKonamiEvent(() => {
    // TODO move to home and set state to cause error
    setShouldThrow(true);
  });

  // CURVES CHART - INVALIDATE CURVES

  const updateDataOnPersist = useCallback(
    async (shouldAbort = () => false, changes = []) => {
      changes.forEach((change) => {
        dispatch(updateSeriesDataValidation(change));
      });
    },
    []
  );

  const [onPersistKeys] = useState([
    ScenarioDataKeys.Results,
    ScenarioDataKeys.Media,
    ScenarioDataKeys.Sales,
  ]);

  useOnScenarioDataPersist(onPersistKeys, updateDataOnPersist);

  // scenario and scenarioChannels only have values when the scenario is changed
  const {
    data: scenario,
    isLoading: scenarioIsLoading,
    isFetching: scenarioIsFetching,
    isSuccess: scenarioIsSuccess,
  } = useGetScenarioQuery(
    {
      scenarioId: switchingToId,
      userId: userContext.userID,
    },
    {
      skip:
        isCreatingScenario ||
        switchingToId == null ||
        userContext.userID == null,
    }
  );

  const scenarioIsReady = useMemo(
    () =>
      !scenarioIsLoading &&
      !scenarioIsFetching &&
      scenario != null &&
      scenarioIsSuccess,
    [scenarioIsLoading, scenarioIsFetching, scenario, scenarioIsSuccess]
  );

  const {
    data: scenarioChannels,
    isLoading: scenarioChannelsAreLoading,
    isFetching: scenarioChannelsAreFetching,
    isSuccess: scenarioChannelsAreSuccess,
  } = useGetScenarioChannelsQuery(
    {
      scenarioId: switchingToId,
      userId: userContext.userID,
    },
    {
      skip:
        isCreatingScenario ||
        switchingToId == null ||
        userContext.userID == null,
    }
  );

  const scenarioChannelsAreReady = useMemo(
    () =>
      !scenarioChannelsAreLoading &&
      !scenarioChannelsAreFetching &&
      scenarioChannels != null &&
      scenarioChannelsAreSuccess,
    [
      scenarioChannelsAreLoading,
      scenarioChannelsAreFetching,
      scenarioChannels,
      scenarioChannelsAreSuccess,
    ]
  );

  const [setCurrentScenario] = useSetCurrentScenarioMutation();

  // CHANGE SCENARIO
  useEffect(() => {
    if (isCreatingScenario || !scenarioIsReady || !scenarioChannelsAreReady)
      return;

    if (user.scenarioId !== switchingToId) {
      setCurrentScenario({
        userId: userContext.userID,
        scenarioId: switchingToId,
      });
    }

    if (switchingToId === 0) {
      dispatch(changeToEmptyScenario(switchingToId));
      return;
    }

    const {
      results: resultsChannels,
      media: mediaChannels,
      sales: salesChannels,
    } = scenarioChannels;

    const {
      name,
      aspiration,
      selectedChart,
      flighting,
      growthTarget,
      mediaBudget,
      mediaHierarchy,
      salesHierarchy,
    } = scenario;

    dispatch(
      changeScenario({
        id: switchingToId,
        userId: userContext.userID,
        name: name,
        aspiration: aspiration,
        selectedChart: selectedChart,
        flighting: flighting,
        growthTarget,
        mediaBudget,
        resultsChannels,
        mediaChannels,
        salesChannels,
        mediaHierarchy,
        salesHierarchy,
      })
    );
  }, [
    isCreatingScenario,
    scenario,
    scenarioChannels,
    scenarioIsReady,
    scenarioChannelsAreReady,
  ]);

  const [runScenario] = useRunScenarioMutation();

  useEffect(() => {
    if (appContext.scenariosToRun.length === 0) return;
    appContext.setScenariosToRun((prevState) =>
      prevState.filter((id) => !appContext.scenariosToRun.includes(id))
    );

    appContext.scenariosToRun.forEach(async (scenarioIdToRun) => {
      try {
        const result = await runScenario({
          userId: userContext.userID,
          scenarioId: scenarioIdToRun,
        });
        dispatch(scenariosApi.util.invalidateTags(["Scenarios"]));
        // if (result.data.status < 0) {
        //   setShouldOpenNewScenario(false);
        // }
        appContext.setScenariosRan((prevState) =>
          prevState.concat(scenarioIdToRun)
        );
      } catch (error) {
        console.error(error);
        return;
      }
    });
  }, [appContext.scenariosToRun, appContext.shouldOpenNewScenario]);

  useEffect(() => {
    if (appContext.scenariosRan.length === 0) return;
    appContext.setScenariosRan((prevState) =>
      prevState.filter((id) => !appContext.scenariosRan.includes(id))
    );
    appContext.scenariosRan.forEach((scenarioRan) => {
      if (scenarioRan === switchingToId) {
        dispatch(addIsDoneCreatingScenario());
        if (appContext.shouldOpenNewScenario) {
          navigate("/my-insights");
        }
      }
    });
  }, [
    appContext.scenariosRan,
    switchingToId,
    appContext.shouldOpenNewScenario,
  ]);

  useEffect(() => {
    if (!shouldThrow) return;
    setShouldThrow(false);
    throw new Error("Test Error");
  }, [shouldThrow]);

  function addValidationEditErrorSnack(sdKey, errors, severity) {
    const prefix = (() => {
      if (sdKey === ScenarioDataKeys.Media) return "media ";
      if (sdKey === ScenarioDataKeys.Sales) return "sales ";
      return "";
    })();
    // enqueueSnackbar(`There was an issue with the ${prefix}edit`, {
    //   autoHideDuration: 5000,
    //   content: (key, message) => (
    //     <ValidationErrorSnack
    //       id={key}
    //       message={message}
    //       severity={severity}
    //       modalProps={{ sdKey, items: errors }}
    //     />
    //   ),
    // });
  }

  function addValidationImportSuccessSnack(sdKey) {
    // const prefix = (() => {
    //   if (sdKey === ScenarioDataKeys.Media) return "Media ";
    //   if (sdKey === ScenarioDataKeys.Sales) return "Sales ";
    //   return "";
    // })();
    // enqueueSnackbar(`${prefix}Import Successful`, {
    //   autoHideDuration: 2000,
    //   content: (key, message) => (
    //     <ValidationErrorSnack id={key} message={message} severity={"success"} />
    //   ),
    // });
  }

  useEffect(() => {
    if (!debouncedEditItemValidationError) return;
    // console.log("editItem ValidationErrors", debouncedEditItemValidationErrors);
    const { sdKey, errors } = debouncedEditItemValidationError;
    addValidationEditErrorSnack(sdKey, errors, "error");
    scenarioContext.dispatchScenarioData({
      type: ScenarioDataDispatchType.ClearValidationError,
      veKey: ValidationErrorKeys.EditItem,
    });
  }, [scenarioContext.dispatchScenarioData, debouncedEditItemValidationError]);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <AppBar position="fixed" open={user?.drawerOpen ?? false}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setDrawerOpen({
                userId: userContext.userID,
                drawerOpen: true,
              });
            }}
            edge="start"
            sx={{
              marginRight: 4.5,
              ...((user?.drawerOpen ?? false) && {
                display: "none",
              }),
            }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            src="Logo_White_Horizontal.svg"
            alt="Optimetry"
            sx={{
              height: 32,
            }}
          />
          <Box style={{ marginLeft: "auto" }}>
            <Button
              color="inherit"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              size="large"
            >
              Logout
            </Button>
            {settingsFlag ? (
              <>
                <IconButton
                  color="inherit"
                  onClick={() => setIsSettingsOpen(true)}
                  size="large"
                  edge="end"
                  aria-label="Open Settings"
                >
                  <SettingsIcon />
                </IconButton>
                <SettingsModal
                  isOpen={isSettingsOpen}
                  close={() => setIsSettingsOpen(false)}
                />
              </>
            ) : (
              <></>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={user?.drawerOpen ?? false}>
        <DrawerHeader>
          <IconButton
            onClick={() => {
              setDrawerOpen({
                userId: userContext.userID,
                drawerOpen: false,
              });
            }}
            size="large"
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <DrawerLink
            open={user?.drawerOpen ?? false}
            label={"My Scenarios"}
            Icon={FolderOpenRoundedIcon}
            linkProps={{
              to: "/my-scenarios",
              selected:
                location.pathname.startsWith("/my-scenarios") ||
                location.pathname.endsWith("/"),
            }}
          />
        </List>
        <Divider />
        <List>
          {[
            {
              label: "My Insights",
              Icon: TrendingUpRoundedIcon,
              route: "/my-insights",
            },
          ].map(({ label, Icon, route }, index) => (
            <DrawerLink
              key={route}
              open={user?.drawerOpen ?? false}
              label={label}
              Icon={Icon}
              linkProps={{
                to: route,
                selected: location.pathname.startsWith(route),
                disabled: isEmptyScenario,
              }}
            />
          ))}
        </List>
        <Divider />
        {location.pathname.startsWith("/my-insights") && isScenarioReady ? (
          <RunButton
            open={user?.drawerOpen ?? false}
            aspiration={aspiration}
          ></RunButton>
        ) : (
          <></>
        )}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <DrawerHeader />
        <Routes>
          <Route
            exact
            path="/"
            element={<Navigate to="/my-scenarios" replace />}
          />
          <Route path="/my-scenarios" element={<MyScenarios />} />
          <Route path="/my-insights" element={<MyResults />} />
        </Routes>
        {myAiValue && location.pathname.startsWith("/my-scenarios") ? (
          <>
            {!isMyAiOpen ? (
              <Fab
                color="primary"
                aria-label="Open AI Assistant"
                sx={{
                  margin: 0,
                  top: "auto",
                  right: 20,
                  bottom: 20,
                  left: "auto",
                  position: "fixed",
                  "&:hover": {
                    backgroundColor: "#39b0e3",
                  },
                }}
                onClick={() => dispatch(openMyAi())}
              >
                <ChatBubbleOutlineIcon />
              </Fab>
            ) : (
              <></>
            )}
            <MyAi />
          </>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default Home;
