import React, { useRef, useLayoutEffect, useMemo, useState } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {
  createChartTitle,
  addBarLabels,
  getNumberFormatter,
  textColor,
  zeroAnimations,
  bigMoneyFormatter,
  styleTitle,
} from "../ChartUtils";

import {
  finalName,
  getColorBySeriesKey,
  oldDefaultExportFormats,
  resultName,
  startingName,
} from "../../constants";
import ChartControls from "./ChartControls";
import { Box } from "@mui/material";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection";
import { useGetResultsGroupsQuery } from "../../app/scenarioApi";
import { useSelector } from "react-redux";
import {
  selectExpandedAndSelectedShortMediaTargets,
  selectScenario,
} from "../../app/scenarioSlice";

am4core.useTheme(am4themes_animated);

const CHART_ID = "results-summary-chart";

const titleYOffset = 35;
const chartYOffset = 57;

function ResultsSummaryChart({
  kpi,
  isInPresentationMode,
  enterPresentationMode,
  exitPresentationMode,
  presentationModePopOverContainer,
}) {
  const charts = useRef([]);
  const [exportingChart, setExportingChart] = useState(null);
  const shouldRoiUseExtraPrecision = useRef(false);
  const roiNumberFormats = {
    short: "$0.00",
    long: "$0.000",
  };

  const {
    isScenarioReady,
    id: scenarioId,
    userId,
    resultsHierarchy,
  } = useSelector(selectScenario);

  const targets = useSelector(selectExpandedAndSelectedShortMediaTargets);

  const {
    data: groups,
    isLoading: groupsIsLoading,
    isSuccess: groupsIsSuccess,
  } = useGetResultsGroupsQuery(
    {
      scenarioId,
      userId,
      targets,
      // Does not care about sorting
    },
    {
      skip: !isScenarioReady || targets === null,
    }
  );

  const isLoading = useMemo(
    () => groupsIsLoading || !groupsIsSuccess,
    [groupsIsLoading, groupsIsSuccess]
  );

  /**
   * Instantiate the chart
   */
  useLayoutEffect(() => {
    charts.current = [];

    // Create a container
    const container = am4core.create(CHART_ID, am4core.Container);
    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.layout = "horizontal";

    // createChartTitle(container, "Summary", 15);
    const title = container.createChild(am4core.Label);
    // title.text = "Summary";
    // title.fontSize = 25;
    // title.marginBottom = 30;

    styleTitle(title, "Summary", 15);
    title.isMeasured = false;
    title.align = "center";
    title.x = am4core.percent(50);
    title.y = titleYOffset;
    title.horizontalCenter = "middle";

    function getVariance(dataItem) {
      if (dataItem) {
        const value = dataItem.valueY;
        const openValue = dataItem.openValueY;
        const change = value - openValue;
        return change;
      }
      return 0;
    }

    const roiFormatter = getNumberFormatter(
      shouldRoiUseExtraPrecision.current
        ? roiNumberFormats.long
        : roiNumberFormats.short
    );

    // Creates and adds a chart to the chart container
    function addChart(oPayload) {
      // Create chart instance
      const chart = container.createChild(am4charts.XYChart);
      charts.current.push(chart);
      chart.marginLeft = oPayload.marginLeft || 0;
      chart.marginRight = oPayload.marginRight || 0;

      // try not to cut-off the variance bullet labels
      chart.maskBullets = false;

      // Add chart title
      createChartTitle(chart, oPayload.title, 35, titleYOffset + 35);

      // Create axes
      //

      // Category Axis
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.startLocation = 0;
      categoryAxis.endLocation = 0.5;
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.location = 0.25;
      categoryAxis.renderer.labels.template.fill = textColor;
      categoryAxis.renderer.line.strokeOpacity = 1;
      categoryAxis.renderer.line.strokeWidth = 2;
      categoryAxis.renderer.line.stroke = am4core.color("#cdcdcd");

      // Value Axis
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.adjustLabelPrecision = false;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.renderer.grid.template.disabled = true;
      // value format
      valueAxis.numberFormatter = bigMoneyFormatter;

      zeroAnimations(valueAxis);

      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "value";
      series.dataFields.categoryX = "category";
      series.stroke = am4core.color("#fff");
      series.columns.template.width = am4core.percent(70);

      zeroAnimations(series);

      function getColor(dataItem) {
        return getColorBySeriesKey(dataItem.dataContext?.seriesKey);
      }

      series.columns.template.adapter.add("fill", function (fill, target) {
        if (!target.dataItem) return fill;
        return getColor(target.dataItem);
      });

      addBarLabels({
        series: series,
        numberFormatter:
          oPayload.title === "ROI" ? roiFormatter : bigMoneyFormatter,
        getTextValue: (dataItem) =>
          dataItem ? dataItem.dataContext.value : "",
        isPositiveBar: (_) => true,
        labelSize: {
          mainSize: oPayload.title === "ROI" ? 38 : 30,
          orthoSize: 65,
        }, // TODO fix main size for extra zero
        defaultLocation: 0.5,
        tooltipNumberFormat:
          oPayload.title !== "ROI" ? oPayload.tooltipNumberFormat : undefined,
        tooltipNumberFormatter:
          oPayload.title === "ROI" ? roiFormatter : undefined,
      });

      // Add series for showing variance arrows
      const series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.dataFields.valueY = "valueNext";
      series2.dataFields.openValueY = "value";
      series2.dataFields.categoryX = "category";
      series2.columns.template.width = 1;
      series2.fill = "#404040";
      series2.stroke = "#404040";

      zeroAnimations(series2);

      // Add a triangle for arrow tip
      const arrow = series2.bullets.push(new am4core.Triangle());
      arrow.width = 10;
      arrow.height = 10;
      arrow.horizontalCenter = "middle";
      arrow.verticalCenter = "top";
      arrow.dy = -1;
      arrow.fill = "#404040";

      // Returns the numeric part of the variance displayed after formatting and rounding.
      // $4.5M -> 4.5
      // $0.0 -> 0.0
      function getVarianceFormattedPrefix(dataItem) {
        function _getValueAfterFormat(value, forcedFormatter) {
          function _format(value) {
            return (
              forcedFormatter ||
              (oPayload.title === "ROI" ? roiFormatter : bigMoneyFormatter)
            ).format(value);
          }
          function _parseFormat(value) {
            return parseFloat(value.replaceAll(/[^-.\d]/g, ""));
          }
          return _parseFormat(_format(value));
        }
        function _getVarianceFormattedPrefix() {
          return _getValueAfterFormat(getVariance(dataItem));
        }
        const result = _getVarianceFormattedPrefix();
        if (oPayload.title !== "ROI" || dataItem?.dataContext == null)
          return result;

        // For the ROI chart, switch to the long format if it shows a difference not present in the short format.
        const { value, valueNext } = dataItem.dataContext;
        const shortFormatter = getNumberFormatter(roiNumberFormats.short);
        const longFormatter = getNumberFormatter(roiNumberFormats.long);
        const shouldChange =
          shouldRoiUseExtraPrecision.current !==
          (_getValueAfterFormat(value, shortFormatter) ===
            _getValueAfterFormat(valueNext, shortFormatter) &&
            _getValueAfterFormat(value, longFormatter) !==
              _getValueAfterFormat(valueNext, longFormatter));

        if (!shouldChange) return result;
        shouldRoiUseExtraPrecision.current =
          !shouldRoiUseExtraPrecision.current;
        roiFormatter.numberFormat = shouldRoiUseExtraPrecision.current
          ? roiNumberFormats.long
          : roiNumberFormats.short;
        return _getVarianceFormattedPrefix();
      }

      // Set up a rotation adapter which would rotate the triangle if its a negative change
      arrow.adapter.add("rotation", function (rotation, target) {
        return getVarianceFormattedPrefix(target.dataItem) < 0 ? 180 : rotation;
      });

      // Set up a rotation adapter which adjusts Y position
      arrow.adapter.add("dy", function (dy, target) {
        return getVarianceFormattedPrefix(target.dataItem) < 0 ? 1 : dy;
      });

      // Only enable when the variance is not 0
      arrow.adapter.add("disabled", function (disabled, target) {
        return getVarianceFormattedPrefix(target.dataItem) === 0;
      });

      // Add a label
      const label = series2.bullets.push(new am4core.Label());
      label.padding(10, 10, 10, 10);
      label.text = "";
      label.fill = "#404040";
      label.strokeWidth = 0;
      label.horizontalCenter = "middle";
      label.verticalCenter = "bottom";
      label.fontWeight = "bold";

      // Adapter for label text which calculates change in percent
      label.adapter.add("textOutput", function (text, target) {
        const change = Math.abs(getVariance(target.dataItem));
        return (
          oPayload.title === "ROI" ? roiFormatter : bigMoneyFormatter
        ).format(change);
      });

      // Adapter which shifts the label if it's below the variance column
      label.adapter.add("verticalCenter", function (center, target) {
        return getVarianceFormattedPrefix(target.dataItem) < 0 ? "top" : center;
      });

      //   // Adapter which changes color of label to red
      //   label.adapter.add("fill", function (fill, target) {
      //     return getVariance(target.dataItem) < 0 ? am4core.color("#C51F46") : fill;
      //   });

      // Add a Dot for cases when there is no variance
      const dot = series2.bullets.push(new am4core.Circle());
      dot.radius = 4;
      dot.horizontalCenter = "middle";
      dot.verticalCenter = "top";
      dot.fill = "#404040";

      // Only enable when the variance is 0
      dot.adapter.add("disabled", function (disabled, target) {
        return getVarianceFormattedPrefix(target.dataItem) !== 0;
      });

      chart.exporting.filePrefix = "ResultsSummary";
    }

    // Add charts
    addChart({
      marginLeft: 10,
      marginRight: 10,
      title: "Spend",
    });

    addChart({
      marginLeft: 10,
      marginRight: 10,
      title:
        kpi === "romi" ? `Profit = ${resultName} x Margin - Spend` : resultName,
    });

    addChart({
      marginLeft: 10,
      marginRight: 10,
      title: "ROI",
      numberFormat: "$#.00",
      tooltipNumberFormat: "$#.00",
    });

    setExportingChart(charts.current.at(-1));
  }, [kpi]);

  useLayoutEffect(() => {
    function resize() {
      charts.current?.forEach((chart) => chart.svgContainer.measure());
    }
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  useLayoutEffect(() => {
    if (groups == null) return;

    const totalGroup = {
      starting_spend: groups.reduce((acc, g) => acc + g["starting_spend"], 0),
      starting_contribution: groups.reduce(
        (acc, g) => acc + g["starting_contribution"],
        0
      ),
      final_spend: groups.reduce((acc, g) => acc + g["final_spend"], 0),
      final_contribution: groups.reduce(
        (acc, g) => acc + g["final_contribution"],
        0
      ),
    };
    totalGroup["starting_roi"] =
      totalGroup["starting_contribution"] / totalGroup["starting_spend"];
    totalGroup["final_roi"] =
      totalGroup["final_contribution"] / totalGroup["final_spend"];

    function getChartDataItem(prefix, suffix, nextPrefix) {
      const field = [prefix, suffix].join("_");
      return {
        category: prefix === "starting" ? startingName : finalName,
        seriesKey: field,
        value: totalGroup[field],
        ...(nextPrefix != null && {
          valueNext: totalGroup[[nextPrefix, suffix].join("_")],
        }),
      };
    }

    const seriesKeySuffixes = ["spend", "contribution", "roi"];
    seriesKeySuffixes.forEach((suffix, i) => {
      charts.current[i].data = ["starting", "final"].map((prefix, i, arr) =>
        getChartDataItem(prefix, suffix, arr[i + 1])
      );
    });
  }, [groups]);

  return (
    <LoadingSection isLoading={isLoading} type={LoadingType.Cover}>
      <ChartControls
        amChart={exportingChart}
        chartExportFormats={oldDefaultExportFormats.filter(
          ({ format }) => format !== "csv"
        )}
        presentationModePopOverContainer={presentationModePopOverContainer}
        isInPresentationMode={isInPresentationMode}
        enterPresentationMode={enterPresentationMode}
        exitPresentationMode={exitPresentationMode}
      />
      <Box id={CHART_ID} style={{ width: "100%", height: "100%" }}></Box>
    </LoadingSection>
  );
}

// ResultsSummaryChart.propTypes = {
//   kpi: PropTypes.string,
// };

export default ResultsSummaryChart;
