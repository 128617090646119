import { TextField } from "@mui/material";
import React, { useState } from "react";

function FormattedNumberField({
  label,
  focusedValue,
  blurredValue,
  setValue,
  onFocus,
  onBlur,
}) {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
    onFocus?.();
  };

  const handleChange = (event) => {
    setValue(event.target.value, event);
  };

  const handleBlur = () => {
    setFocused(false);
    onBlur?.();
  };

  return (
    <TextField
      id={label.toLowerCase().replace(/ /g, "-") + "-input"}
      label={label}
      variant="standard"
      InputLabelProps={{
        shrink: true,
      }}
      value={focused ? focusedValue : blurredValue}
      onFocus={handleFocus}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

FormattedNumberField = React.memo(FormattedNumberField);

export default FormattedNumberField;
