import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpointsUrl } from "./endpoints";

const scenarioPeriodApi = createApi({
  reducerPath: "getScenarioPeriodApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  tagTypes: ["ScenarioPeriod"],
  endpoints: (builder) => ({
    getScenarioPeriod: builder.query({
      query: ({ userId, scenarioId }) => ({
        url: "/scenarios/getPeriod",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
        },
      }),
      transformResponse: ({ data }) => ({
        start: data.startPeriod,
        end: data.endPeriod,
      }),
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "ScenarioPeriod",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
  }),
});

export default scenarioPeriodApi;

export const { useGetScenarioPeriodQuery, useInvalidateGetPeriodMutation } =
  scenarioPeriodApi;
