import {
  ALL_CHANNEL_KEYS,
  ALL_MEDIA_CHANNEL_KEYS,
  ALL_SALES_CHANNEL_KEYS,
} from "../containers/CategoryDataUtils";
import { getCVFromTarget } from "../scenarioDataUtils/scenarioDataUtils";
import { objectFilter } from "./objectUtil";

// takes in two category vectors and returns true they are equal
export function categoryVectorsAreEqual(keyA, keyB) {
  if (keyA == null || keyB == null) return false;
  if (keyA.length !== keyB.length) return false;
  return categoryVectorContains(keyA, keyB);
}

// TODO this function doesn't seem to work how I expect. expected more like categoryVectorContains2
// takes in two category vector and returns true if the second is contained or equal to the first
export function categoryVectorContains(keyA, keyB) {
  if (keyA === keyB) return true;
  if (keyA == null || keyB == null) return false;

  for (let i = 0; i < keyA.length; ++i) {
    if (keyA[i] !== keyB[i]) return false;
  }
  return true;
}

// takes in two category vector and returns true if the second is contained or equal to the first
export function categoryVectorContains2(keyA, keyB) {
  if (keyA === keyB) return true;
  if (keyA == null || keyB == null) return false;

  for (let i = 0; i < keyA.length; ++i) {
    if (keyA[i] !== keyB[i] && keyA[i] != null) return false;
  }
  return true;
}

export function targetContains(a, b) {
  return categoryVectorContains2(
    getCVFromTarget(a, ALL_CHANNEL_KEYS),
    getCVFromTarget(b, ALL_CHANNEL_KEYS)
  );
}

function handelPeriod(baseKeys, target, forceNoPeriod) {
  return [
    ...baseKeys,
    ...(forceNoPeriod || target.period === undefined ? [] : ["period"]),
  ];
}

export function targetToMediaTarget(target, forceNoPeriod = false) {
  return objectFilter(target, ([k]) =>
    handelPeriod(ALL_MEDIA_CHANNEL_KEYS, target, forceNoPeriod).includes(k)
  );
}

// export function targetToSalesTarget(target, forceNoPeriod = false) {
//   return objectFilter(target, ([k]) =>
//     handelPeriod(ALL_SALES_CHANNEL_KEYS, target, forceNoPeriod).includes(k)
//   );
// }
