import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpointsUrl } from "./endpoints";

export const planChartApi = createApi({
  reducerPath: "planChartApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  endpoints: (builder) => ({
    getPlanChartData: builder.query({
      query: ({ userId, scenarioId, mediaTargets, salesTargets }) => ({
        url: "charts/plan",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          mediaTargets,
          salesTargets,
        },
      }),
      transformResponse: (responseData) => responseData.data,
    }),
  }),
});

export default planChartApi;
export const { useLazyGetPlanChartDataQuery } = planChartApi;
