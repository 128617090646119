import { createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false, isInfoOpen: false };

const slice = createSlice({
  name: "myAi",
  initialState,
  reducers: {
    open() {
      return { ...initialState, isOpen: true, isInfoOpen: false };
    },
    close(state) {
      state.isOpen = false;
    },
    openInfo(state) {
      state.isInfoOpen = true;
    },
    closeInfo(state) {
      state.isInfoOpen = false;
    },
  },
});

export const { open, close, openInfo, closeInfo } = slice.actions;
export default slice;

export const selectIsOpen = (state) => state[slice.name].isOpen;
export const selectIsInfoOpen = (state) => state[slice.name].isInfoOpen;
