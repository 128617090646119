import { autocompleteClasses } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from "react";
import { VariableSizeList } from "react-window";

const LISTBOX_PADDING = 8;

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

export const VirtualizedListboxComponent = forwardRef(
  function VirtualizedListboxComponent(props, ref) {
    const { children, ...other } = props;

    const itemCount = children.length;
    const itemSize = 50;

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemCount * itemSize;
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={children}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={() => itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {({ data, index, style }) => {
              const item = data[index];
              return (
                <li
                  key={item.key}
                  {...item.props}
                  style={{ ...style, ...item.props.style }}
                />
              );
            }}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

VirtualizedListboxComponent.propTypes = {
  children: PropTypes.node,
};

export const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});
