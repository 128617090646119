import { BASE_API_URL } from "../app/endpoints";

export default async function exportScenario({ userId, scenarioId }) {
  return await fetch(BASE_API_URL.concat("export-scenario/"), {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id: userId, scenario_id: scenarioId }),
  })
    .then((response) => {
      if (!response.ok) {
        console.log(response);
        throw new Error(
          `Request Failed with ${response.status} ${response.statusText}`
        );
      }
      const filename = response.headers.get("File-Name") || "export.xlsx";
      return Promise.all([filename, response.blob()]);
    })
    .then(([filename, blob]) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((error) => console.error("Export Failed:", error));
}
