import * as React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { CloseRounded, RedoRounded, UndoRounded } from "@mui/icons-material";

export default function WizardDialogTitle({ children, setOpen, undoState, redoState, canUndoState, canRedoState }) {
  return (
    <DialogTitle sx={{ m: 0, pl: 3, bgcolor: "background.default" }}>
      {children}
      <IconButton
        aria-label="undo"
        onClick={undoState}
        disabled={!canUndoState}
        sx={{
          position: "absolute",
          right: 93,
          top: 13,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <UndoRounded />
      </IconButton>
      <IconButton
        aria-label="redo"
        onClick={redoState}
        disabled={!canRedoState}
        sx={{
          position: "absolute",
          right: 53,
          top: 13,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <RedoRounded />
      </IconButton>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 13,
          top: 13,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseRounded />
      </IconButton>
    </DialogTitle>
  );
}

WizardDialogTitle.propTypes = {
  children: PropTypes.node,
  setOpen: PropTypes.func.isRequired,
};