import { updateGridRows } from "../Api";
import {
  AspirationType,
  isOptimizationAspiration,
  startingName,
} from "../constants";
import { ALL_SALES_CHANNEL_KEYS } from "../containers/CategoryDataUtils";
import {
  getTargetFromItem,
  GroupAggregationType,
  scenarioDataHelpers,
  ScenarioDataKeys,
} from "./scenarioDataUtils";

const contributionRecalculationKeys = [];

const fieldsOrder = ["starting_sales", "maximize"];

const channelKeys = ALL_SALES_CHANNEL_KEYS;

function getFieldAggregationType(field) {
  switch (field) {
    case "starting_sales":
      return GroupAggregationType.Sum;
    case "maximize":
      return GroupAggregationType.Copy;
    default:
      return GroupAggregationType.Copy;
  }
}

function getFieldDisplayName(field, scenarioAspiration) {
  switch (field) {
    case "starting_sales":
      return startingName + " Sales";
    case "maximize":
      if (scenarioAspiration === AspirationType.Optimize) return "Maximize";
      if (scenarioAspiration === AspirationType.Grow) return "Seek Growth";
      return null;
    default:
      return null;
  }
}

function getFieldConstraints(field) {
  switch (field) {
    case "starting_sales":
      return {
        isValueValid: (v) => v >= 0,
        valueConstraintsText: "Must be positive",
      };
    case "maximize":
      return {
        isValueValid: (v) => typeof v === "boolean",
        valueConstraintsText: "Must be true or false",
      };
    default:
      return {
        isValueValid: (v) => true,
        valueConstraintsText: "",
      };
  }
}

/**
 * Prepare the item to be persisted.
 *
 * @param {Object} item The item to prepare.
 * @param {Object} user_id The current user ID.
 * @param {Object} scenario_id The current scenario ID.
 * @returns a new object which is safe for persisting in the backend.
 */
function prepareItemForBackend(item, user_id, scenario_id) {
  return {
    ...item,
    user_id,
    scenario_id,
  };
}

/**
 * Save the items in the database.
 *
 * @param {Object} item List of items which were prepared by `prepareItemForBackend`.
 * @see {@link prepareItemForBackend}
 */
async function persistManyHandler(items) {
  const response = await updateGridRows("sales", items);
  // console.log("persistManyHandler SALES", items, response);
  // TODO handel errors
}

/**
 * Modify the given item with side effects of the edit.
 *
 * @param {Object} item The item to modify
 * @param {Object[]} modifiedFields The fields that were modified
 * @returns the field that were modified (including those in the input)
 */
function runSideEffectsOnItem(item, modifiedFields, scenarioAspiration) {
  return [...modifiedFields];
}

function processImportItem(item, oldMatch, scenarioAspiration) {
  const getValueWithChange = (field) => item[field] || oldMatch[field];
  const processedItem = { ...item };

  if (
    isOptimizationAspiration(scenarioAspiration) &&
    typeof processedItem.maximize !== "boolean"
  ) {
    const inputString = processedItem.maximize.toString().toLowerCase();
    if (["0", "false"].includes(inputString)) {
      processedItem.maximize = false;
    }
    if (["1", "true"].includes(inputString)) {
      processedItem.maximize = true;
    }
  }

  const validationErrors = [];
  Object.entries(processedItem).forEach(([k, v]) => {
    const constraint = getFieldConstraints(k);
    if (processedItem[k] != null && !constraint.isValueValid(v)) {
      const error = {
        target: getTargetFromItem(
          processedItem,
          scenarioDataHelpers[ScenarioDataKeys.Media].channelKeys
        ),
        field: k,
        value: v,
      };
      validationErrors.push(error);
      processedItem[k] = undefined;
    }
  });

  return { result: processedItem, validationErrors };
}

/**
 * Removes invalid values
 *
 */
function validateUpdateMap(updateMap, target) {
  const validatedUpdateMap = { ...updateMap };
  const validationErrors = [];
  Object.entries(updateMap).forEach(([k, v]) => {
    const validationConstraint = getFieldConstraints(k);
    if (updateMap[k] != null && !validationConstraint?.isValueValid?.(v)) {
      const error = {
        target,
        field: k,
        value: v,
      };
      validationErrors.push(error);
      delete validatedUpdateMap[k];
    }
  });
  return { validatedUpdateMap, validationErrors };
}

export default {
  contributionRecalculationKeys,
  channelKeys,
  getFieldAggregationType,
  prepareItemForBackend,
  // persistItemHandler,
  persistManyHandler,
  runSideEffectsOnItem,

  processImportItem,

  getFieldConstraints,
  getFieldDisplayName,
  fieldsOrder,

  validateUpdateMap,
};
