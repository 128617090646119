import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "../../../app/endpoints";
import { getNameFromTarget } from "../../CategoryDataUtils";

// Define a service using a base URL and expected endpoints
const curvesChartApi = createApi({
  reducerPath: "curvesChartApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
    // baseUrl: "http://localhost:5000/",
  }),
  endpoints: (builder) => ({
    getCurvesChart: builder.query({
      query: ({ userId, scenarioId, target, mediaHierarchy, salesHierarchy }) => ({
        url: "response-curve/",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          target,
          mediaHierarchy,
          salesHierarchy,
        },
      }),
      transformResponse: (responseData, _meta, { target, mediaHierarchy }) => {
        return {
          ...responseData,
          name: getNameFromTarget(
            target,
            mediaHierarchy,
          ).trim(),
          target,
        };
      },
    }),
  }),
});

export default curvesChartApi;

export const { useLazyGetCurvesChartQuery } = curvesChartApi;
