import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {
  addBarLabels,
  bigMoneyFormatter,
  createChartTitle,
  getNumberFormatter,
  handleChangeSeries,
  refreshAxis,
  textColor,
  zeroAnimations,
} from "../ChartUtils";

import {
  finalName,
  getColorBySeriesKey,
  oldDefaultExportFormats,
  resultName,
  startingName,
} from "../../constants";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ChartControls from "./ChartControls";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection";
import { useSelector } from "react-redux";
import {
  selectExpandedAndSelectedShortMediaTargets,
  selectScenario,
} from "../../app/scenarioSlice";
import { useGetResultsGroupsQuery } from "../../app/scenarioApi";
import { getNameWithoutDuplicates } from "../../utils/targetUtils";

am4core.useTheme(am4themes_animated);

const CHART_ID = "results-waterfall-chart";

export const ChartSeriesEnum = {
  Spend: "spend",
  Contribution: "contribution",
};

function addSeriesPrefix(prefix, field) {
  return `${prefix}-${field}`;
}

function shouldIgnoreStep(stepsRangeSize) {
  return stepsRangeSize < 1;
}

function ResultsWaterfallChart({
  metadata,
  setMetadata,
  isInPresentationMode,
  enterPresentationMode,
  exitPresentationMode,
  presentationModePopOverContainer,
}) {
  const [chartSeries, setChartSeries] = useState(null);
  const [currentAxisStep, setCurrentAxisStep] = useState(null);
  const chart = useRef(null);
  // Used to apply the stored step before listening to the axis for feedback
  const isFirstAxisBoundsChange = useRef(true);

  const {
    isScenarioReady,
    id: scenarioId,
    userId,
    resultsHierarchy,
    mediaHierarchy,
  } = useSelector(selectScenario);

  const targets = useSelector(selectExpandedAndSelectedShortMediaTargets);

  const {
    data: groups,
    isLoading: groupsIsLoading,
    isSuccess: groupsIsSuccess,
  } = useGetResultsGroupsQuery(
    {
      scenarioId,
      userId,
      targets,
      hierarchy: resultsHierarchy,
    },
    {
      skip: !isScenarioReady || targets === null,
    }
  );

  // TODO add axis step process?
  const isLoading = useMemo(
    () => groupsIsLoading || !groupsIsSuccess,
    [groupsIsLoading, groupsIsSuccess]
  );

  // Window of recently used step values.
  // Window gets reset whenever the chart data is changed.
  const axisRecentStepsWindowSize = 10;
  const axisRecentStepsWindow = useRef(Array(axisRecentStepsWindowSize));
  useEffect(() => {
    axisRecentStepsWindow.current.pop();
    axisRecentStepsWindow.current.unshift(currentAxisStep);
    // console.log("currentAxisStep", currentAxisStep, axisRecentStepsWindow.current);
  }, [currentAxisStep]);

  function setCurrentSeriesKey(currentSeriesKey) {
    setMetadata((prevState) => ({
      ...prevState,
      currentSeriesKey,
    }));
  }

  function setChartAxisBounds(chartAxisBounds) {
    setMetadata((prevState) => ({
      ...prevState,
      chartAxisBounds:
        typeof chartAxisBounds === "function"
          ? chartAxisBounds(prevState.chartAxisBounds)
          : chartAxisBounds,
    }));
  }

  useLayoutEffect(() => {
    if (!metadata?.currentSeriesKey) setCurrentSeriesKey(ChartSeriesEnum.Spend);
    if (!metadata?.chartAxisBounds) setChartAxisBounds({});
  }, [metadata]);

  useLayoutEffect(() => {
    // Ignore the first axis step change if we already have a value. If this is not done, an infinite cycle is likely to form
    isFirstAxisBoundsChange.current =
      metadata?.chartAxisBounds[metadata?.currentSeriesKey]?.axisStep !==
      undefined;
    // console.log("SET isFirstAxisBoundsChange", isFirstAxisBoundsChange.current, metadata?.chartAxisBounds[metadata?.currentSeriesKey]?.axisStep, metadata?.chartAxisBounds[metadata?.currentSeriesKey], metadata?.currentSeriesKey)
  }, [metadata?.currentSeriesKey]);

  /**
   * Instantiate the chart
   */
  useLayoutEffect(() => {
    // Create chart instance
    chart.current = am4core.create(CHART_ID, am4charts.XYChart);

    chart.current.zoomOutButton.disabled = true;

    createChartTitle(chart.current, "", 45);

    chart.current.maskBullets = false;

    // Create Axes

    // Category Axis
    const categoryAxisWidth = 0.7;
    const categoryAxis = chart.current.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.startLocation = 0;
    categoryAxis.endLocation = 1;
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.truncate = true;
    categoryAxis.renderer.labels.template.maxWidth = 200;
    categoryAxis.renderer.labels.template.fill = textColor;
    categoryAxis.renderer.line.strokeOpacity = 1;
    categoryAxis.renderer.line.strokeWidth = 2;
    categoryAxis.renderer.line.stroke = am4core.color("#cdcdcd");
    categoryAxis.renderer.labels.template.rotation = -90;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";

    // Create Value Axis
    const valueAxis = chart.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.adjustLabelPrecision = false;
    valueAxis.strictMinMax = true;

    // value format
    valueAxis.numberFormatter = getNumberFormatter();
    valueAxis.numberFormatter._format = valueAxis.numberFormatter.format;
    valueAxis.numberFormatter.format = (value, format, precision) => {
      if (value < 1e6) {
        const roundedValue = Math.round(value / 1e3) * 1e3;
        if (roundedValue === 0) return "$0K";
        return valueAxis.numberFormatter._format(
          roundedValue,
          "$#a",
          precision
        );
      }
      return valueAxis.numberFormatter._format(value, "$#.0##a", precision);
    };

    // Both extremeschanged and selectionextremeschanged events lie so this is those events but it actually works
    valueAxis.adapter.add("min", (v, target) => {
      if (
        isNaN(target.step) ||
        target.step == null ||
        axisRecentStepsWindow.current.includes(target.step) ||
        // if window is full and values keep growing
        (axisRecentStepsWindow.current.at(-1) != null &&
          axisRecentStepsWindow.current.every(
            (v, i, arr) => (arr[i - 1] ?? target.step) > v
          ))
      )
        return v;
      setCurrentAxisStep(target.step);
      return v;
    });

    zeroAnimations(valueAxis);

    function addSeries(name, fieldPrefix) {
      // Create Series
      const series = chart.current.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = addSeriesPrefix(fieldPrefix, "close");
      series.dataFields.categoryX = "category";
      series.dataFields.openValueY = addSeriesPrefix(fieldPrefix, "open");

      series.name = name;
      // series.fill = am4core.color(getBarColor("final " + fieldPrefix));
      series.clustered = false;
      series.hidden = true;

      // remove outline
      series.columns.template.strokeWidth = 0;
      series.columns.template.height = am4core.percent(100);
      series.columns.template.width = am4core.percent(100 * categoryAxisWidth);
      series.stroke = am4core.color("#fff");

      // Set bar colors
      function getOutsideBarColor(dataItem) {
        if (
          dataItem.dataContext[addSeriesPrefix(fieldPrefix, "isFinal")] ||
          dataItem.dataContext[addSeriesPrefix(fieldPrefix, "isStart")]
        ) {
          return am4core.color(getColorBySeriesKey("final_" + fieldPrefix));
        }
        if (dataItem.dataContext.value < 0) {
          return am4core.color(getColorBySeriesKey("down"));
        }
        return am4core.color(getColorBySeriesKey("up"));
      }

      function getColor(dataItem) {
        if (dataItem.dataContext[addSeriesPrefix(fieldPrefix, "isStart")]) {
          return am4core.color(getColorBySeriesKey("starting_" + fieldPrefix));
        }
        return getOutsideBarColor(dataItem);
      }

      series.columns.template.adapter.add("fill", function (fill, target) {
        if (!target.dataItem) return fill;
        return getColor(target.dataItem);
      });

      addBarLabels({
        series: series,
        numberFormatter: bigMoneyFormatter,
        getTextValue: (dataItem) =>
          Math.abs(dataItem ? dataItem.dataContext.value : ""),
        isPositiveBar: () => true,
        labelSize: { mainSize: 30, orthoSize: 65 },
        verticalLabelSwapWidth: 65,
        defaultLocation: 0.5,
        getOutsideBarColor: getOutsideBarColor,
        forceOutside: (dataItem) =>
          dataItem.dataContext[addSeriesPrefix(fieldPrefix, "isEnd")] != null,
      });

      // Create Step Lines
      const stepSeries = chart.current.series.push(
        new am4charts.StepLineSeries()
      );
      stepSeries.dataFields.categoryX = "category";
      stepSeries.dataFields.valueY = addSeriesPrefix(fieldPrefix, "stepValue");
      stepSeries.hidden = true;
      stepSeries.noRisers = true;
      stepSeries.stroke = textColor;
      stepSeries.strokeDasharray = "2,2";
      // stepSeries.startLocation = (1 - categoryAxisWidth) / 2;
      stepSeries.startLocation = 1 - (1 - categoryAxisWidth) / 2;
      stepSeries.endLocation = 1 + (1 - categoryAxisWidth) / 2;
      stepSeries.hiddenInLegend = true;
      zeroAnimations(stepSeries);

      const seriesList = [series, stepSeries];
      return {
        visibility: {
          show: () => seriesList.forEach((s) => s.show()),
          hide: () => seriesList.forEach((s) => s.hide()),
        },
        series: series,
      };
    }

    // Create Series
    setChartSeries({
      [ChartSeriesEnum.Spend]: {
        name: "Spend",
        exportPrefix: "ResultsSpendStoryChart",
        ...addSeries("Spend", "spend"),
      },
      [ChartSeriesEnum.Contribution]: {
        name: resultName,
        exportPrefix: `Results${resultName}StoryChart`,
        ...addSeries(resultName, "contribution"),
      },
    });

    chart.current.exporting.adapter.add("data", function (data) {
      data.data = data.data.map((row) => {
        let name = "";
        if (row["spend-open"] !== undefined) {
          name = "Spend";
        } else if (row["contribution-open"] !== undefined) {
          name = "Return";
        }
        return {
          ...row,
          Name: name,
        };
      });
      return data;
    });
    chart.current.exporting.dataFields = {
      category: "Category",
      Name: "Name",
      value: "Value",
    };
  }, []);

  useLayoutEffect(() => {
    function resize() {
      chart.current?.svgContainer.measure();
    }
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  // Store the current step value for the series
  useLayoutEffect(() => {
    if (currentAxisStep == null || metadata?.currentSeriesKey == null) return;
    // console.log(currentAxisStep / 1e6, "mil", metadata.currentSeriesKey);
    if (isFirstAxisBoundsChange.current) {
      // console.log("ignored", currentAxisStep / 1e6, "mil")
      isFirstAxisBoundsChange.current = false;
      return;
    }
    setChartAxisBounds((prevState) => {
      if (
        shouldIgnoreStep(prevState[metadata?.currentSeriesKey]?.stepsRangeSize)
      )
        return prevState;
      return {
        ...prevState,
        [metadata.currentSeriesKey]: {
          ...prevState[metadata.currentSeriesKey],
          axisStep: currentAxisStep,
        },
      };
    });
  }, [currentAxisStep, metadata?.currentSeriesKey]);

  // Change the series
  useLayoutEffect(() => {
    if (chartSeries == null || metadata == null) return;
    handleChangeSeries(
      metadata.currentSeriesKey,
      chart.current,
      chartSeries,
      (n) => n + " Story"
    );
  }, [chartSeries, metadata?.currentSeriesKey]);

  // Apply chart axis bounds
  useLayoutEffect(() => {
    if (
      metadata == null ||
      metadata?.chartAxisBounds?.[metadata.currentSeriesKey] == null
    )
      return;

    const axis = chart.current.yAxes.values[0];
    const currentBounds = metadata?.chartAxisBounds[metadata?.currentSeriesKey];
    const currentStep = currentBounds.axisStep || 0;

    const getPositiveStepDelta = (v) => v % currentStep || 0;
    const getNegativeStepDelta = (v) => currentStep - getPositiveStepDelta(v);
    const _addStepPadding = (v, actual, sign, comparator) => {
      if (shouldIgnoreStep(currentBounds.stepsRangeSize) || currentStep === 0) {
        return actual * 1.2;
      }
      return (
        v +
        sign *
          (comparator(v, actual - sign * 0.25 * currentStep) ? 0 : currentStep)
      );
    };
    const addStepBottomPadding = (v, actual) =>
      _addStepPadding(v, actual, -1, (a, b) => a < b);
    const addStepTopPadding = (v, actual) =>
      _addStepPadding(v, actual, 1, (a, b) => a < b); // v + (v < actual + 0.25 * currentStep ? 0 : currentStep);

    axis.min = !shouldIgnoreStep(currentBounds.stepsRangeSize)
      ? addStepBottomPadding(
          currentBounds.min - getPositiveStepDelta(currentBounds.min),
          currentBounds.min
        )
      : 0;
    axis.max = addStepTopPadding(
      currentBounds.max + getNegativeStepDelta(currentBounds.max),
      currentBounds.max
    );

    // console.log("At Apply", {
    //   ...currentBounds,
    //   adjustedMin: axis.min,
    //   adjustedMax: axis.max,
    //   stepsMin: currentBounds.max - currentBounds.stepsRangeSize,
    //   currentStep,
    // });

    // force axis to update
    axis.hide();
    axis.show();
  }, [metadata?.chartAxisBounds, metadata?.currentSeriesKey]);

  const data = useMemo(() => {
    if (groups == null) return null;

    // TODO this can have collisions.
    // The hierarchy would need to be purposely made to cause an issue though

    function getSeriesData(fieldSuffix) {
      function getField(o, prefix) {
        return o[prefix + "_" + fieldSuffix];
      }
      const result = {
        steps: groups.reduce(
          (acc, g) => ({
            ...acc,
            [getNameWithoutDuplicates(g, mediaHierarchy)]:
              getField(g, "final") - getField(g, "starting"),
          }),
          {}
        ),
        start: groups.reduce((acc, g) => acc + getField(g, "starting"), 0),
        final: groups.reduce((acc, g) => acc + getField(g, "final"), 0),
      };

      return result;
    }

    return Object.fromEntries(
      ["spend", "contribution"].map((seriesKey) => [
        seriesKey,
        getSeriesData(seriesKey),
      ])
    );
  }, [groups]);

  useEffect(() => {
    axisRecentStepsWindow.current = Array(axisRecentStepsWindowSize);
    // console.log("currentAxisStep", axisRecentStepsWindow.current);
  }, [metadata?.currentSeriesKey, data]);

  useLayoutEffect(() => {
    if (!data) return;

    const newChartAxisBounds = {};

    chart.current.data = Object.entries(data).reduce((acc, [type, d]) => {
      const chartData = d || {
        start: 0,
        steps: {},
        final: 0,
      };

      const chartDataList = [
        {
          category: startingName,
          value: chartData.start,
        },
        ...Object.entries(chartData.steps).map(([category, value]) => ({
          category,
          value,
        })),
        {
          category: finalName,
          value: chartData.final,
        },
      ];

      const stepAxisValues = Object.values(chartData.steps).reduce(
        (acc, v) => acc.concat((acc.at(-1) || chartData.start) + v),
        []
      );
      const endsAxisValues = [chartData.start, chartData.final];
      const allAxisValues = [
        chartData.start,
        ...stepAxisValues,
        chartData.final,
      ];

      const endsMinSize = Math.min(...endsAxisValues);
      const endsMaxSize = Math.max(...endsAxisValues);
      const stepsRangeSize =
        Math.max(...stepAxisValues, endsMaxSize) -
        Math.min(...stepAxisValues, endsMinSize);
      const allMax = Math.max(...allAxisValues);
      const min =
        endsMinSize > stepsRangeSize * 2 ? endsMinSize - stepsRangeSize : 0;
      const max = allMax;
      newChartAxisBounds[type] = {
        min,
        max,
        stepsRangeSize,
      };

      // Make data into a waterfall
      // by setting the middle steps open value to the previous steps close value
      // and the close value the the previous close + current value.
      return acc.concat(
        chartDataList.reduce(
          (acc, cur, idx, arr) => {
            if (idx === 0 || idx === arr.length - 1) {
              return {
                data: acc.data.concat({
                  ...cur,
                  [addSeriesPrefix(type, "open")]: 0,
                  [addSeriesPrefix(type, "close")]: cur.value,
                  [addSeriesPrefix(type, "isEnd")]: true,
                  [addSeriesPrefix(type, "isStart")]:
                    idx === 0 ? true : undefined,
                  [addSeriesPrefix(type, "isFinal")]:
                    idx === arr.length - 1 ? true : undefined,
                  [addSeriesPrefix(type, "stepValue")]:
                    idx === 0 ? cur.value : undefined,
                }),
                prevClose: cur.value,
              };
            }
            const [min, max] = [acc.prevClose, acc.prevClose + cur.value].sort(
              (a, b) => a - b
            );
            return {
              data: acc.data.concat({
                ...cur,
                [addSeriesPrefix(type, "open")]: min,
                [addSeriesPrefix(type, "close")]: max,
                [addSeriesPrefix(type, "stepValue")]: acc.prevClose + cur.value,
              }),
              prevClose: acc.prevClose + cur.value,
            };
          },
          { data: [], prevClose: NaN }
        ).data
      );
    }, []);

    setChartAxisBounds((prevState) =>
      Object.fromEntries(
        Object.entries(newChartAxisBounds).map(([key, value]) => [
          key,
          {
            ...prevState[key],
            ...value,
          },
        ])
      )
    );

    refreshAxis(chart.current.xAxes.values[0]);
  }, [data]);

  return (
    <LoadingSection isLoading={isLoading} type={LoadingType.Cover}>
      <ChartControls
        amChart={chart.current}
        // chartExportFormats={["svg", "png", "csv"]}
        chartExportFormats={oldDefaultExportFormats}
        presentationModePopOverContainer={presentationModePopOverContainer}
        isInPresentationMode={isInPresentationMode}
        enterPresentationMode={enterPresentationMode}
        exitPresentationMode={exitPresentationMode}
      >
        <ToggleButtonGroup
          color="primary"
          value={metadata?.currentSeriesKey}
          exclusive
          onChange={(_, v) => {
            if (v !== null) setCurrentSeriesKey(v);
          }}
        >
          <ToggleButton value={ChartSeriesEnum.Spend}>Spend</ToggleButton>
          <ToggleButton value={ChartSeriesEnum.Contribution}>
            {resultName}
          </ToggleButton>
        </ToggleButtonGroup>
      </ChartControls>
      <Box id={CHART_ID} style={{ width: "100%", height: "100%" }}></Box>
    </LoadingSection>
  );
}

ResultsWaterfallChart.propTypes = {
  data: PropTypes.object,
};

export default ResultsWaterfallChart;
