import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import "ag-grid-enterprise";

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetSalesGroupChildrenQuery,
  useLazyGetSalesGroupQuery,
  useLazyGetSalesItemsQuery,
} from "../../app/scenarioApi";
import {
  selectGetNestedSalesChildrenTargets,
  selectGetSalesTreeNodeData,
  selectScenario,
  setShouldSetSalesGridOptions,
  updateSalesTreeNodeData,
} from "../../app/scenarioSlice";
import TargetCellRenderer from "../../components/TargetCellRenderer/TargetCellRenderer.jsx";
import { AspirationType, getChannelDisplayName } from "../../constants";
import { scenarioDataHelpers } from "../../scenarioDataUtils/scenarioDataUtils";
import { getFormatters, makeColStates } from "../GridUtils";
import DataGrid, {
  getChildEffectedCVsWithBlanks,
  getParentEffectedCVs,
  GridParsingEnum,
} from "./DataGrid";
import { 
  useVariableValue 
} from '@devcycle/react-client-sdk'


const SalesGrid = ({ gridKey }) => {
  const maximizeSalesFlag = useVariableValue('maximize-sales', false);
  const [gridApi, setGridApi] = useState(null);
  useEffect(() => {
    if (maximizeSalesFlag === false) {
      gridApi?.sizeColumnsToFit();
    }
  }, [maximizeSalesFlag, gridApi])

  const {
    isScenarioReady,
    aspiration,
    shouldSetSalesGridOptions,
    salesHierarchy,
  } = useSelector(selectScenario);

  const intl = useIntl();
  const formatters = useMemo(
    () => getFormatters(intl, { hideOnValue: true }),
    [intl]
  );

  const cellDoubleClicked = useCallback((event) => {
    event.node.setDataValue(event.column.colId, !event.value);
  }, []);

  const agGridColumnItems = useMemo(
    () =>
      [
        ...(salesHierarchy ?? []).map((field) => ({
          headerName: getChannelDisplayName(field),
          field,
          cellStyle: { textAlign: "left" },
          rowGroup: true,
          aggFunc: "channelAgg",
        })),
        {
          headerName: "Period",
          field: "period",
          cellStyle: { textAlign: "left" },
          valueFormatter: formatters.date,
          aggFunc: "periodAgg",
          minWidth: 220,
        },
        {
          field: "starting_sales",
          type: "numericColumn",
          editable: true,
          valueFormatter: formatters.currencyWithoutCents,
          aggFunc: "sum",
          gridParsingType: GridParsingEnum.Number,
          defaultValue: 0,
        },
        {
          field: "maximize",
          cellRenderer: "targetCellRenderer",
          onCellDoubleClicked: cellDoubleClicked,
          headerClass: "center-header",
          aggFunc: (params) => {
            const hasTrue = params.values.some((v) => v === true);
            const hasFalse = params.values.some((v) => v === false);
            const hasNull = params.values.some((v) => v === null);
            if (hasTrue === hasFalse || hasNull) {
              return null;
            }
            return hasTrue;
          },
          gridParsingType: GridParsingEnum.Boolean,
          show: aspiration === AspirationType.Optimize && maximizeSalesFlag,
        },
      ]
        .filter(({ show }) => [undefined, true].includes(show))
        .map(({ show, ...item }) => {
          const headerName =
            item.headerName ||
            scenarioDataHelpers[gridKey].getFieldDisplayName(
              item.field,
              aspiration
            );
          return {
            ...item,
            ...(item.type === "numericColumn" && {
              cellEditor: "constrainedNumberEditor",
            }),
            headerName,
          };
        }),
    [
      aspiration,
      formatters,
      cellDoubleClicked,
      gridKey,
      salesHierarchy,
      maximizeSalesFlag,
    ]
  );

  const defaultColState = useMemo(
    () => [
      ...makeColStates(
        "left",
        { colId: "ag-Grid-AutoColumn", hide: false },
        { colId: "period", hide: false }
      ),
      ...makeColStates(
        "",
        ...(salesHierarchy ?? []).map((key) => ({
          colId: key,
          hide: true,
        })),
        { colId: "starting_sales", hide: false },
        { colId: "maximize", hide: false }
      ),
    ],
    [salesHierarchy]
  );

  const [getSalesGroupChildren, ,] = useLazyGetSalesGroupChildrenQuery();
  const [getSalesGroup, ,] = useLazyGetSalesGroupQuery();
  const [getSalesItems, ,] = useLazyGetSalesItemsQuery();

  const getSalesTreeNodeData = useSelector(selectGetSalesTreeNodeData);

  const dispatch = useDispatch();

  const getIsNodeExpanded = useCallback(
    (shortTarget) => {
      const { expanded } = getSalesTreeNodeData(shortTarget);
      return expanded;
    },
    [getSalesTreeNodeData]
  );

  const updateIsNodeExpanded = useCallback(
    (shortTarget, expanded) => {
      dispatch(
        updateSalesTreeNodeData({
          shortTarget,
          data: {
            expanded,
          },
        })
      );
    },
    [dispatch]
  );

  const getSalesNestedChildrenTargets = useSelector(
    selectGetNestedSalesChildrenTargets
  );

  useEffect(() => {
    if (
      gridApi == null ||
      shouldSetSalesGridOptions == null ||
      !isScenarioReady
    )
      return;

    const { shortTarget } = shouldSetSalesGridOptions;
    dispatch(setShouldSetSalesGridOptions(null));

    const parentCVs = getParentEffectedCVs(shortTarget, salesHierarchy);

    const childCVs = getChildEffectedCVsWithBlanks(
      shortTarget,
      salesHierarchy,
      getSalesNestedChildrenTargets
    );

    // console.log(
    //   "RESET SALES potentialBlanksEffectedCVs",
    //   parentCVs,
    //   childCVs,
    // );

    [...parentCVs, ...childCVs].forEach((route) => {
      gridApi.refreshServerSide({ route });
    });
  }, [
    gridApi,
    shouldSetSalesGridOptions,
    dispatch,
    getSalesNestedChildrenTargets,
    salesHierarchy,
    isScenarioReady,
  ]);

  return (
    <DataGrid
      id={"sales-grid"}
      gridKey={gridKey}
      hierarchy={salesHierarchy ?? []}
      colStateKey={"sales_col_state"}
      rowStateKey={"sales_row_state"}
      agGridColumnItems={agGridColumnItems}
      defaultColState={defaultColState}
      getIsNodeExpanded={getIsNodeExpanded}
      updateIsNodeExpanded={updateIsNodeExpanded}
      gridOptions={{
        components: {
          targetCellRenderer: TargetCellRenderer,
        },
      }}
      getGroupChildren={getSalesGroupChildren}
      getGroup={getSalesGroup}
      getItems={getSalesItems}
      gridApi={gridApi}
      setGridApi={setGridApi}
    />
  );
};

export default SalesGrid;
