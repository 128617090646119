import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import companyApi from "./companyApi";
import { BASE_API_URL, endpointsUrl } from "./endpoints";
import { enqueueSnackbar } from "notistack";
import LogRocket from "logrocket";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: ({ userId }) => ({
        url: "users/get",
        method: "POST",
        body: {
          user_id: userId,
        },
      }),
      transformResponse: (responseData) => {
        // convert naming scheme of some returned fields
        const { drawer_open, company_id, scenario_id, ...other } =
          responseData.data;
        return {
          ...other,
          drawerOpen: drawer_open ?? true,
          companyId: company_id,
          scenarioId: scenario_id,
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { userId } = queryArgs;
        return userId;
      },
      providesTags: (_result, _error, { userId }) => {
        return [{ type: "User", id: userId }, "User"];
      },
    }),
    setCurrentScenario: builder.mutation({
      query: ({ userId, scenarioId }) => ({
        url: "users/update",
        method: "POST",
        body: {
          user_id: userId,
          updateMap: {
            scenario_id: scenarioId,
          },
        },
      }),
      invalidatesTags: (_result, error, { userId }) => {
        if (error?.status === 304) return;
        return [{ type: "User", id: userId }];
      },
      // Leave this as undo on failure.
      // Bc: It will undo itself on the next successful mutation anyway
      async onQueryStarted(
        { userId, scenarioId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          userApi.util.updateQueryData("getUser", { userId }, (draft) => {
            Object.assign(draft, { scenarioId });
          })
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
          console.error("Failed to set current scenario", e);
        }
      },
    }),
    setDrawerOpen: builder.mutation({
      query: ({ userId, drawerOpen }) => ({
        url: "users/update",
        method: "POST",
        body: {
          user_id: userId,
          updateMap: {
            drawer_open: drawerOpen,
          },
        },
      }),
      invalidatesTags: (_result, error, { userId }) => {
        if (error?.status === 304) return;
        return [{ type: "User", id: userId }];
      },
      // Leave this as undo on failure. Bc: not a common use feature, and
      // The best we could do is prevent refetch on error but refetches will
      // still occur from other mutations which would seem more broken to the user
      async onQueryStarted(
        { userId, drawerOpen },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          userApi.util.updateQueryData("getUser", { userId }, (draft) => {
            Object.assign(draft, { drawerOpen });
          })
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
          console.error("Failed to set drawer open", e);
        }
      },
    }),
    setCurrentLoad: builder.mutation({
      query: ({ userId, companyId, companyName }) => ({
        url: "users/update",
        method: "POST",
        body: {
          user_id: userId,
          updateMap: {
            company_id: companyId,
            company: companyName,
          },
        },
      }),
      invalidatesTags: (_result, error, { userId }) => {
        if (error?.status === 304) return;
        return [{ type: "User", id: userId }];
      },
      async onQueryStarted(
        { userId, companyId, companyName },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          userApi.util.updateQueryData("getUser", { userId }, (draft) => {
            Object.assign(draft, { companyId });
          })
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
          const errorMessage = "Set model failed";
          console.error(errorMessage, e);
          enqueueSnackbar(errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
          });
          LogRocket.captureException(new Error(errorMessage), {
            extra: {
              userId,
              companyId,
              companyName,
            },
          });
        }
      },
    }),
    importLoad: builder.mutation({
      query: ({ userId, file }) => {
        const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("file", file);
        return {
          url: BASE_API_URL.concat("import-model/"),
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (_result, _error, { userId }) => {
        return [{ type: "User", id: userId }];
      },
    }),
    deleteLoad: builder.mutation({
      query: ({ userId, companyId }) => ({
        url: "companies/delete",
        method: "POST",
        body: {
          user_id: userId,
          company_id: companyId,
        },
      }),
      invalidatesTags: (_result, _error, { userId }) => {
        return [{ type: "User", id: userId }];
      },
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          companyApi.util.invalidateTags([{ type: "Company", id: companyId }])
        );
      },
    }),
  }),
});

export default userApi;
export const {
  useGetUserQuery,
  useSetCurrentScenarioMutation,
  useSetDrawerOpenMutation,
  useSetCurrentLoadMutation,
  useImportLoadMutation,
  useDeleteLoadMutation,
} = userApi;
