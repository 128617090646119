import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import "ag-grid-enterprise";

import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetMediaGroupChildrenQuery,
  useLazyGetMediaGroupQuery,
  useLazyGetMediaItemsQuery,
  usePropagateConstraintMutation,
} from "../../app/scenarioApi";
import {
  selectGetMediaTreeNodeData,
  selectGetNestedMediaChildrenTargets,
  selectScenario,
  setShouldSetMediaGridOptions,
  updateMediaTreeNodeData,
} from "../../app/scenarioSlice";
import {
  getChannelDisplayName,
  isOptimizationAspiration,
} from "../../constants";
import {
  optimizationInfinity,
  scenarioDataHelpers,
} from "../../scenarioDataUtils/scenarioDataUtils";
import { getFormatters, makeColStates } from "../GridUtils";
import DataGrid, {
  getChildEffectedCVsWithBlanks,
  getParentEffectedCVs,
  GridParsingEnum,
  isGridOptimizationInfinity,
  makeFullBlankTarget,
} from "./DataGrid";

export function getNodeData(node) {
  return node.group ? node.data : node.data;
}

function isFooterRow(node) {
  return node.data.isTotalRow;
}

function constraintsIsEditable({ node }) {
  return !isFooterRow(node);
}

export function getImpressionsFormatter(formatter) {
  return (params) => {
    const nodeData = params.node.data;
    if (nodeData.period == null) {
      return null;
    }
    const prefix = params.column.colId.substring(
      0,
      params.column.colId.indexOf("_")
    );
    let value = params.value;
    const spend = nodeData[prefix + "_spend"];
    if (-0.5 <= spend && spend < 0.5) {
      value = 0 * spend;
    }
    return formatter({ value });
  };
}

function formatCpm(formatter) {
  return (params) => {
    let value = params.value;
    if (isGridOptimizationInfinity(value)) {
      return "";
    }
    return formatter({ value });
  };
}

const MediaGrid = ({ gridKey }) => {
  const [gridApi, setGridApi] = useState(null);

  const {
    userId,
    id: scenarioId,
    isScenarioReady,
    aspiration,
    shouldSetMediaGridOptions,
    mediaHierarchy,
  } = useSelector(selectScenario);
  const dispatch = useDispatch();

  const [propagateConstraint] = usePropagateConstraintMutation();

  const intl = useIntl();
  const formatters = useMemo(
    () => getFormatters(intl, { hideOnValue: true }),
    [intl]
  );

  // TODO convert into a aggrid formatter in GridUtils.
  const percentOnePossibleSignedFormatter = useCallback(
    (value, signOverride) => {
      if (value == null || isNaN(value)) return null;
      const sign = signOverride != null ? signOverride : value >= 0 ? "+" : "-";
      return (
        sign +
        intl.formatNumber(Math.abs(value), {
          format: "percentOnePossible",
        })
      );
    },
    [intl]
  );

  const getSpendBoundSettings = useCallback(
    (constraintField) => {
      const defaultValue =
        constraintField === "lower_spend" ? 0 : optimizationInfinity;
      return {
        valueGetter: (params) => {
          if (isFooterRow(params.node)) {
            return null;
          }
          const spendBase = params.node.data?.starting_spend;
          if (!spendBase || spendBase < 0.5) return null;
          if (params.node.data[constraintField] === defaultValue) return null;
          const value =
            ((params.node.data[constraintField] - spendBase) / spendBase) * 100;
          const roundedValue = Math.round(value * 10) / 10;
          return roundedValue;
        },
        valueFormatter: (params) => {
          return params.node.data[constraintField] === defaultValue
            ? null
            : percentOnePossibleSignedFormatter(
                params.value != null ? params.value / 100 : null,
                constraintField === "lower_spend" && params.value === 0
                  ? "-"
                  : undefined
              );
        },
        gridParsingType: GridParsingEnum.Number,
        show: isOptimizationAspiration(aspiration),
      };
    },
    [aspiration, percentOnePossibleSignedFormatter]
  );

  const getSpendValueGetter = useCallback((prefix) => {
    const field = prefix + "_spend";
    const defaultValue = field === "lower_spend" ? 0 : optimizationInfinity;
    return (params) => {
      if (
        isFooterRow(params.node) ||
        params.node.data[field] === defaultValue
      ) {
        return null;
      }
      return params.node.data[field];
    };
  }, []);

  const agGridColumnItems = useMemo(
    () =>
      [
        ...(mediaHierarchy ?? []).map((field) => ({
          headerName: getChannelDisplayName(field),
          field,
          cellStyle: { textAlign: "left" },
          rowGroup: true,
          aggFunc: "channelAgg",
        })),
        {
          headerName: "Period",
          field: "period",
          cellStyle: { textAlign: "left" },
          valueFormatter: formatters.date,
          minWidth: 220,
        },
        {
          field: "lower_spend",
          type: "numericColumn",
          editable: constraintsIsEditable,
          valueGetter: getSpendValueGetter("lower"),
          gridParsingType: GridParsingEnum.Number,
          defaultValue: 0,
          valueFormatter: formatters.currencyWithoutCents.hideOnValue(0, ""),
          show: isOptimizationAspiration(aspiration),
        },
        {
          field: "lower_spend_pct",
          type: "numericColumn",
          editable: constraintsIsEditable,
          ...getSpendBoundSettings("lower_spend"),
        },
        {
          field: "lower_impressions",
          type: "numericColumn",
          editable: constraintsIsEditable,
          valueGetter: (params) => {
            const { lower_impressions } = params.node.data;
            if (!params.node.group || lower_impressions == null) {
              return null;
            }
            return Math.round(lower_impressions);
          },
          gridParsingType: GridParsingEnum.Number,
          defaultValue: 0,
          valueFormatter: (params) => {
            if (params.data.lower_spend === 0) return "";
            return formatters.decimalZero(params);
          },
          show: isOptimizationAspiration(aspiration),
        },
        {
          field: "starting_spend",
          type: "numericColumn",
          editable: true,
          valueFormatter: formatters.currencyWithoutCents,
          aggFunc: "sum",
          gridParsingType: GridParsingEnum.Number,
          defaultValue: 0,
        },
        {
          field: "starting_impressions",
          type: "numericColumn",
          editable: true,
          valueFormatter: getImpressionsFormatter(formatters.decimalZero),
          valueGetter: ({ data }) => Math.round(data.starting_impressions),
          aggFunc: "sum",
          gridParsingType: GridParsingEnum.Number,
          defaultValue: 0,
        },
        {
          field: "final_spend",
          type: "numericColumn",
          editable: true,
          valueFormatter: formatters.currencyWithoutCents,
          aggFunc: "sum",
          gridParsingType: GridParsingEnum.Number,
          defaultValue: 0,
          show: !isOptimizationAspiration(aspiration),
        },
        {
          field: "final_impressions",
          type: "numericColumn",
          editable: true,
          valueFormatter: getImpressionsFormatter(formatters.decimalZero),
          valueGetter: ({ data }) => Math.round(data.final_impressions),
          aggFunc: "sum",
          gridParsingType: GridParsingEnum.Number,
          defaultValue: 0,
          show: !isOptimizationAspiration(aspiration),
        },
        {
          field: "upper_spend",
          type: "numericColumn",
          editable: constraintsIsEditable,
          valueGetter: getSpendValueGetter("upper"),
          gridParsingType: GridParsingEnum.Number,
          defaultValue: optimizationInfinity,
          valueFormatter: formatters.currencyWithoutCents.hideOnValue(
            optimizationInfinity,
            ""
          ),
          show: isOptimizationAspiration(aspiration),
        },
        {
          field: "upper_spend_pct",
          type: "numericColumn",
          editable: constraintsIsEditable,
          ...getSpendBoundSettings("upper_spend"),
        },
        {
          field: "upper_impressions",
          type: "numericColumn",
          editable: constraintsIsEditable,
          valueGetter: (params) => {
            // if (isFooterRow(params.node)) {
            //   return null;
            // }

            const { upper_impressions } = params.node.data;
            if (
              !params.node.group ||
              upper_impressions === optimizationInfinity
            ) {
              return null;
            }
            return Math.round(upper_impressions);
          },
          gridParsingType: GridParsingEnum.Number,
          defaultValue: optimizationInfinity,
          valueFormatter: (params) => {
            if (params.data.upper_spend === optimizationInfinity) return "";
            return formatters.decimalZero(params);
          },
          show: isOptimizationAspiration(aspiration),
        },
        {
          field: "cpm",
          type: "numericColumn",
          editable: true,
          valueFormatter: formatCpm(formatters.currency),
          gridParsingType: GridParsingEnum.Number,
        },
        {
          field: "half_life",
          type: "numericColumn",
          editable: true,
          valueFormatter: formatters.decimalTwoPossible,
          aggFunc: "avg",
          gridParsingType: GridParsingEnum.Number,
        },
        {
          field: "half_saturation_impressions",
          type: "numericColumn",
          editable: false,
          valueFormatter: formatters.decimalZero,
          aggFunc: "avg",
          gridParsingType: GridParsingEnum.Number,
        },
        {
          field: "saturation_shape",
          type: "numericColumn",
          editable: false,
          valueFormatter: formatters.decimalOnePossible,
          aggFunc: "avg",
          gridParsingType: GridParsingEnum.Number,
        },
      ]
        .filter(({ show }) => [undefined, true].includes(show))
        .map(({ show, ...item }) => {
          const headerName =
            item.headerName ||
            scenarioDataHelpers[gridKey].getFieldDisplayName(
              item.field,
              aspiration
            );
          return {
            ...item,
            ...(item.type === "numericColumn" && {
              cellEditor: "constrainedNumberEditor",
            }),
            headerName,
          };
        }),
    [
      aspiration,
      formatters,
      getSpendValueGetter,
      getSpendBoundSettings,
      gridKey,
      mediaHierarchy,
    ]
  );

  const defaultColState = useMemo(
    () => [
      ...makeColStates(
        "left",
        { colId: "ag-Grid-AutoColumn", hide: false },
        { colId: "period", hide: false }
      ),
      ...makeColStates(
        "",
        ...(mediaHierarchy ?? []).map((key) => ({
          colId: key,
          hide: true,
        })),
        { colId: "lower_spend", hide: true },
        { colId: "lower_spend_pct", hide: false },
        { colId: "lower_impressions", hide: true },
        { colId: "starting_spend", hide: false },
        { colId: "starting_impressions", hide: true },
        { colId: "final_spend", hide: false },
        { colId: "final_impressions", hide: true },
        { colId: "upper_spend", hide: true },
        { colId: "upper_spend_pct", hide: false },
        { colId: "upper_impressions", hide: true },
        { colId: "cpm", hide: true },
        { colId: "half_life", hide: true },
        { colId: "half_saturation_impressions", hide: true },
        { colId: "saturation_shape", hide: true }
      ),
    ],
    [mediaHierarchy]
  );

  const [getMediaGroupChildren, ,] = useLazyGetMediaGroupChildrenQuery();
  const [getMediaGroup, ,] = useLazyGetMediaGroupQuery();
  const [getMediaItems, ,] = useLazyGetMediaItemsQuery();

  const getMediaTreeNodeData = useSelector(selectGetMediaTreeNodeData);

  const getIsNodeExpanded = useCallback(
    (shortTarget) => {
      const { expanded } = getMediaTreeNodeData(shortTarget);
      return expanded;
    },
    [getMediaTreeNodeData]
  );

  const updateIsNodeExpanded = useCallback(
    (shortTarget, expanded) => {
      dispatch(
        updateMediaTreeNodeData({
          shortTarget,
          data: {
            expanded,
          },
        })
      );
    },
    [dispatch]
  );

  const getMediaNestedChildrenTargets = useSelector(
    selectGetNestedMediaChildrenTargets
  );

  useEffect(() => {
    if (
      gridApi == null ||
      shouldSetMediaGridOptions == null ||
      !isScenarioReady
    )
      return;

    const { shortTarget, changedFields } = shouldSetMediaGridOptions;
    dispatch(setShouldSetMediaGridOptions(null));

    if (
      changedFields.every((k) => ["lower_spend", "upper_spend"].includes(k))
    ) {
      const route = [...mediaHierarchy, "period"].reduce(
        (acc, k, i) =>
          shortTarget[k] != null && i !== 0
            ? acc.concat(shortTarget[mediaHierarchy[i - 1]])
            : acc,
        []
      );
      // console.log("SMALL MEDIA RESET", changedFields, shortTarget, route);
      gridApi.refreshServerSide({ route });

      if (
        shortTarget.period == null &&
        Object.keys(shortTarget).length !== mediaHierarchy.length &&
        getMediaNestedChildrenTargets(shortTarget).length === 0
      ) {
        // has a blank children to update
        const blankRoutes = Array(
          mediaHierarchy.length - Object.keys(shortTarget).length
        )
          .fill(undefined)
          .map((_, i) =>
            makeFullBlankTarget(
              shortTarget,
              mediaHierarchy.slice(0, Object.keys(shortTarget).length + i)
            )
          );
        // console.log(
        //   "BLANK CONSTRAINT",
        //   shortTarget,
        //   blankRoutes,
        // );
        blankRoutes.forEach((route) => {
          gridApi.refreshServerSide({ route });
        });
      }

      return;
    }

    const parentCVs = getParentEffectedCVs(shortTarget, mediaHierarchy);

    const childCVs = getChildEffectedCVsWithBlanks(
      shortTarget,
      mediaHierarchy,
      getMediaNestedChildrenTargets
    );

    // console.log(
    //   "RESET MEDIA potentialBlanksEffectedCVs",
    //   parentCVs,
    //   childCVs,
    // );

    [...parentCVs, ...childCVs].forEach((route) => {
      gridApi.refreshServerSide({ route });
    });
  }, [
    gridApi,
    shouldSetMediaGridOptions,
    dispatch,
    getMediaNestedChildrenTargets,
    mediaHierarchy,
    isScenarioReady,
  ]);

  return (
    <DataGrid
      id={"media-grid"}
      gridKey={gridKey}
      hierarchy={mediaHierarchy ?? []}
      colStateKey={"media_col_state"}
      rowStateKey={"media_row_state"}
      agGridColumnItems={agGridColumnItems}
      defaultColState={defaultColState}
      getIsNodeExpanded={getIsNodeExpanded}
      updateIsNodeExpanded={updateIsNodeExpanded}
      gridOptions={
        {
          // groupIncludeTotalFooter: true,
        }
      }
      cellEditorProps={{
        getNumDecimals: (field) => {
          if (field.endsWith("_spend_pct")) {
            return 1;
          }
        },
      }}
      getGroupChildren={getMediaGroupChildren}
      getGroup={getMediaGroup}
      getItems={getMediaItems}
      gridApi={gridApi}
      setGridApi={setGridApi}
      getContextMenuItems={(params) => {
        console.log(params);
        const data = params.node.data;
        const isGroup = params.node.group;
        const field = params.column.colId;
        const mediaKey = params.node.field;
        return [
          ...[
            {
              name: "Copy Down",
              action: () => {
                // console.log(data);
                const value = Math.round(data[field] * 1000) / 1000; // Input has one decimal precision

                const options = {
                  userId,
                  scenarioId,
                  target: data.shortTarget,
                  mediaHierarchy,
                  field,
                  bottomLevelName:
                    mediaKey !== mediaHierarchy.at(-1)
                      ? mediaHierarchy.at(-1)
                      : "period",
                };
                if (
                  (field === "lower_spend_pct" && data.lower_spend === 0) ||
                  (field === "upper_spend_pct" &&
                    data.upper_spend === optimizationInfinity)
                ) {
                  propagateConstraint({
                    ...options,
                    isDefaultSet: true,
                  });
                  return;
                }
                propagateConstraint({
                  ...options,
                  value,
                });
              },
            },
          ].filter(
            () =>
              isOptimizationAspiration(aspiration) &&
              (field === "lower_spend_pct" || field === "upper_spend_pct") &&
              constraintsIsEditable(params) &&
              isGroup
          ),
        ];
      }}
    />
  );
};

export default MediaGrid;
