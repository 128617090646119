import "./App.css";
import React, { useEffect, useReducer, useRef, useState } from "react";
// import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { IntlProvider } from "react-intl";
// import Container from "@mui/material/Container";
// import SalesForecastGrid from "./components/SalesForecastGrid/SalesForecastGrid";
// import SalesForecastChart from "./components/ReachCurveChart/ReachCurveChart";
// import MediaPlanGrid from "./components/MediaPlanGrid/MediaPlanGrid"
import ScenarioContext from "./context/scenarioContext";
import Home from "./containers/Home/Home";
import Login from "./containers/Login/Login";
// import UserContext from "./context/userContext";
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from "./context/userContext";
import AppContext from "./context/appContext";
import { LicenseManager } from "ag-grid-enterprise";
import { sortBy } from "lodash";
import { AspirationType, colors } from "./constants";
import { scenarioDataReducer } from "./scenarioDataUtils/scenarioDataUtils";
import LogRocket from "logrocket";

import { SnackbarProvider } from "notistack";
import { setupStore } from "./app/store";
import { Provider } from "react-redux";
import { useMemo } from "react";
import { callMongoFunction } from "./Api";
import { selectScenario } from "./app/scenarioSlice";
import { Box, CircularProgress } from "@mui/material";
import {
  useIsDevCycleInitialized,
  withDevCycleProvider,
  useDevCycleClient,
} from "@devcycle/react-client-sdk";
import Snack from "./containers/Home/Snack";

LicenseManager.setLicenseKey(
  "CompanyName=in4mation insights,LicensedApplication=Optimetry,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-033966,SupportServicesEnd=1_December_2023_[v2]_MTcwMTM4ODgwMDAwMA==b4a2dbc2c0749f80fe83ec2144913cc6"
);

const theme = createTheme({
  palette: {
    mode: "light",
    type: "light",
    text: {
      hint: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      default: "#fafafa",
    },
    primary: {
      main: "#29abe2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ff1b33",
      contrastText: "#fff",
    },
    success: {
      main: colors.green,
      contrastText: "#fff",
    },
    warning: {
      main: colors.yellow,
      contrastText: "#fff",
    },
    error: {
      main: colors.red,
      contrastText: "#fff",
    },
    warning: {
      main: colors.yellow,
      contrastText: "#303030",
    },
    [AspirationType.Simulate]: {
      main: "#55c5de",
      hover: "#66cbe1",
      contrastText: "#fff",
    },
    [AspirationType.Optimize]: {
      main: "#fe9f47",
      hover: "#fea959",
      contrastText: "#fff",
    },
    [AspirationType.Grow]: {
      main: "#b2c843",
      hover: "#bace56",
      contrastText: "#fff",
    },
  },
});

export const intlConfig = {
  locale: "en",
  formats: {
    number: {
      decimalZero: {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      decimalOnePossible: {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      },
      decimalTwo: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      decimalTwoPossible: {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
      decimalThreePossible: {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      },
      currency: {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      currencyWithoutCents: {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      percentZero: {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      percentOnePossible: {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      },
      percentTwoPossible: {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
  },
};

// export function switchWeekDataPrefixes(week, { reverse, keepOld } = {}) {
//   const prefixes = [
//     ["planned", "starting"],
//     ["recommended", "final"],
//   ];
//   if (reverse) {
//     prefixes.forEach((prefixSet) => prefixSet.reverse());
//   }
//   Object.keys(week).forEach((key) => {
//     prefixes.forEach(([target, replacement]) => {
//       if (key.includes(target)) {
//         week[key.replace(target, replacement)] = week[key];
//         if (!keepOld) delete week[key];
//       }
//     });
//   });
// }

// export function convertAllOldChannels(e) {
//   if (e[MediaChannel.One] === undefined) e[MediaChannel.One] = e.media;
//   if (e[MediaChannel.Two] === undefined) e[MediaChannel.Two] = e.channel;
//   if (e[MediaChannel.Three] === undefined) e[MediaChannel.Three] = e.platform || e.outlet;
//   if (e[SalesChannel.One] === undefined) e[SalesChannel.One] = e.market || e.geography;
// }

function App() {
  const store = useMemo(() => setupStore(), []);

  // Authentication helpers
  const { isAuthenticated, isLoading, error, loginWithRedirect, user } =
    useAuth0();

  const devcycleClient = useDevCycleClient();
  const devCycleIdentified = useRef(false);
  const dvcReady = useIsDevCycleInitialized();

  // If not authenticated, not loading, and not error, then login.
  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, error, loginWithRedirect]);

  const [userID, setUserID] = useState(null);

  const userContext = {
    userID: userID,
    setUserID: setUserID,
  };

  // If not null, set User ID.
  useEffect(() => {
    if (isAuthenticated) {
      userContext.setUserID(user.sub);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // If User ID not null
    if (userContext.userID !== null) {
      // If staging or production
      if (window.location.href.includes("optimetry.app")) {
        // Initialize LogRocket analytics
        LogRocket.init("hj779b/optimetry");
        LogRocket.identify(userContext.userID);
      }
    }
  }, [userContext.userID]);

  const [isWizard, setIsWizard] = useState(false);
  const [shouldOpenNewScenario, setShouldOpenNewScenario] = useState(false);
  const [scenariosToRun, setScenariosToRun] = useState([]);
  const [scenariosRan, setScenariosRan] = useState([]);
  const [isRunningCurrentScenario, setIsRunningCurrentScenario] =
    useState(false);

  const appContext = {
    isWizard,
    setIsWizard,
    shouldOpenNewScenario,
    setShouldOpenNewScenario,
    scenariosToRun,
    setScenariosToRun,
    scenariosRan,
    setScenariosRan,
    isRunningCurrentScenario,
    setIsRunningCurrentScenario,
  };

  // const [scenarioName, setScenarioName] = useState("");
  // const [mediaBudget, setMediaBudget] = useState(0);
  const [scenario, setScenario] = useState({});
  const [scenarioData, dispatchScenarioData] = useReducer(
    scenarioDataReducer,
    null
  );

  const scenarioContext = {
    // scenarioName,
    // setScenarioName,
    // mediaBudget,
    // setMediaBudget,
    scenario,
    setScenario,
    scenarioData,
    dispatchScenarioData,
  };

  // if (isAuthenticated) {

  // }

  // if (isLoading) {
  //   return <div>Loading ...</div>;
  // }

  // userContext.setUserID(user.sub);

  if (!isAuthenticated) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Login error={error} loginWithRedirect={loginWithRedirect} />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  if (user && !devCycleIdentified.current) {
    const devCycleUser = {
      user_id: user.sub,
      email: user.email,
    };
    devcycleClient.identifyUser(devCycleUser);
    devCycleIdentified.current = true;
  }

  if (!dvcReady) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              height: "100vh",
            }}
          >
            <CircularProgress
              color="primary"
              sx={{ margin: "auto", textAlign: "center" }}
            />
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return (
    <>
      <Provider store={store}>
        <AppContext.Provider value={appContext}>
          <UserContext.Provider value={userContext}>
            <ScenarioContext.Provider value={scenarioContext}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <IntlProvider {...intlConfig}>
                    <SnackbarProvider
                      maxSnack={4}
                      Components={{
                        success: Snack,
                        error: Snack,
                        warning: Snack,
                        info: Snack,
                      }}
                    >
                      <Home />
                    </SnackbarProvider>
                  </IntlProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </ScenarioContext.Provider>
          </UserContext.Provider>
        </AppContext.Provider>
      </Provider>
    </>
  );

  // return (
  // <>
  //   {/* <CssBaseline /> */}
  //   <ScenarioContext.Provider value={currentScenario}>
  //     <ThemeProvider theme={theme}>
  //       <IntlProvider {...intlConfig}>
  //         <Container className="App">
  //           {/* <SalesForecastChart></SalesForecastChart> */}
  //           <Box mt={3}><SalesForecastGrid/></Box>
  //           <Box mt={3} mb={3}><MediaPlanGrid/></Box>
  //         </Container>
  //       </IntlProvider>
  //     </ThemeProvider>
  //   </ScenarioContext.Provider>
  // </>

  // );
}

export default withDevCycleProvider({
  sdkKey: import.meta.env.VITE_DEV_CYCLE_KEY,
  options: {
    deferInitialization: true,
  },
})(App);
