import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PeriodSelect, { convertDate } from "../../components/PeriodSelect/PeriodSelect";
import { getISODate } from "../../constants";
import {
  selectNewScenarioWizard,
  setEndDate,
  setStartDate,
} from "./newScenarioWizardSlice";

const NewScenarioPeriod = memo(function NewScenarioPeriod({
  company,
  aspiration,
}) {
  const { startDate, endDate } = useSelector(selectNewScenarioWizard);
  const dispatch = useDispatch();

  const dateBoundaries = useMemo(() => {
    if (company == null) return null;
    const earliestStart = new Date(company.defaultStartDate);
    const earliestEnd = new Date(company.defaultStartDate);
    earliestEnd.setDate(earliestEnd.getDate() + 6);
    const latestStart = new Date(company.defaultEndDate);
    const latestEnd = new Date(company.defaultEndDate);
    latestStart.setDate(latestStart.getDate() - 6);

    return {
      earliestStart,
      earliestEnd,
      latestStart,
      latestEnd,
    };
  }, [company?.defaultStartDate, company?.defaultEndDate]);

  return (
    <Box mt={3} ml={3}>
      <Typography variant={"body1"}>
        {`Pick Period to ${
          aspiration.charAt(0).toUpperCase() + aspiration.slice(1)
        }`}
      </Typography>
      <Stack direction="row" spacing={3} mt={3} mb={3} mr={3}>
        <PeriodSelect
          label="Start date"
          value={new Date(startDate)}
          onChange={(date) => dispatch(setStartDate(convertDate(date)))}
          defaultDate={
            company?.defaultStartDate
              ? new Date(company.defaultStartDate)
              : null
          }
          minDate={dateBoundaries?.earliestStart}
          maxDate={dateBoundaries?.latestStart}
        />
        <PeriodSelect
          label="End date"
          value={new Date(endDate)}
          onChange={(date) => dispatch(setEndDate(convertDate(date)))}
          defaultDate={
            company?.defaultEndDate ? new Date(company.defaultEndDate) : null
          }
          minDate={dateBoundaries?.earliestEnd}
          maxDate={dateBoundaries?.latestEnd}
        />
      </Stack>
    </Box>
  );
});

export default NewScenarioPeriod;
