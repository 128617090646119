import { Box, Button, Container, Typography } from "@mui/material";

function TopLevelError() {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography component="h1" variant="h5">
          Something went wrong
        </Typography>
        <Button href="/" fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
          Home
        </Button>
      </Box>
    </Container>
  );
}

export default TopLevelError;
