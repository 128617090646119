import { Box, IconButton, Paper, Typography } from "@mui/material";
import { closeSnackbar, SnackbarContent } from "notistack";
import React from "react";
import { colors } from "../../constants";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
// import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function getVariantSettings(variant) {
  let borderColor = "white";
  let VariantIcon = null;
  switch (variant) {
    case "success":
      borderColor = colors.green;
      VariantIcon = CheckCircleOutlinedIcon;
      break;
    case "error":
      borderColor = colors.red;
      VariantIcon = CancelOutlinedIcon;
      break;
    case "warning":
      borderColor = colors.yellow;
      VariantIcon = ReportProblemOutlinedIcon;
      break;
    case "info":
      borderColor = colors.simulationBlue;
      VariantIcon = InfoOutlinedIcon;
      break;
  }
  return { borderColor, VariantIcon };
}

const Snack = React.forwardRef((props, ref) => {
  const { id, message, noCloseButton, variant } = props;

  const { borderColor, VariantIcon } = getVariantSettings(variant);

  return (
    <SnackbarContent ref={ref} role="alert">
      <Paper
        elevation={6}
        sx={{
          px: 2,
          py: 1.5,
          border: "solid 2px",
          borderColor,
          display: "flex",
          gap: 1.5,
        }}
      >
        <VariantIcon sx={{ color: borderColor }}></VariantIcon>
        <Box>
          <Typography>{message}</Typography>
        </Box>
        {noCloseButton !== true ? (
          <IconButton
            aria-label="close"
            title="Close"
            size="small"
            onClick={() => {
              closeSnackbar(id);
            }}
            sx={{
              color: "inherit",
              padding: 0,
            }}
          >
            <CloseRoundedIcon
              sx={{
                color: "rgba(0, 0, 0, 0.25)",
              }}
            />
          </IconButton>
        ) : null}
      </Paper>
    </SnackbarContent>
  );
});

export default Snack;
