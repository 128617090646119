import { useLayoutEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { selectChangePersisted } from "../containers/MyResults/scenarioDataSlice";

/**
 * False when bar is a negative value (going down or left), else true.
 *
 * @callback actionCallback
 * @param {function} shouldAbort Returns true when another, more recent change has been detected
 * @param {Array} changes List of objects with the key and target
 */

/**
 *
 * @param {Array} watchedKeys The ScenarioDataKeys to watch for persistance.
 * @param {actionCallback} action Function to call when change detected. Takes in
 */
export function useOnScenarioDataPersist(watchedKeys, action) {
  const changePersisted = useSelector(selectChangePersisted);
  const seen = useRef({});

  const watchItems = useMemo(
    () =>
      watchedKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: changePersisted[key],
        }),
        {}
      ),
    [watchedKeys, changePersisted]
  );

  useLayoutEffect(() => {
    const changes = [];
    let abort = false;

    Object.entries(watchItems).forEach(([key, changedItem]) => {
      if (changedItem != null && changedItem.id > (seen.current[key] || 0)) {
        seen.current[key] = changedItem.id;
        changes.push({
          key,
          target: changedItem.target,
          changedFields: changedItem.changedFields,
        });
      }
    });

    if (changes.length === 0) return;

    action(() => abort, changes);

    return () => {
      abort = true;
    };
  }, [changePersisted, watchItems, action]);
}
