import { useState, useEffect } from "react";
import ActionModal from "../../components/ActionModal/ActionModal";
import { Box, TextField } from "@mui/material";

function CopyModal({ isOpen, actionProps, close, handle }) {
  const [name, setName] = useState("");

  useEffect(() => {
    setName(actionProps?.node.data.name + " (Copy)");
  }, [actionProps]);

  function submit() {
    handle(actionProps, name);
    close();
  }

  return (
    <ActionModal
      name="copy"
      open={isOpen}
      handleClose={close}
      title="Duplicate Scenario"
      primaryButton={{
        text: "Create",
        onClick: submit,
      }}
      secondaryButton={{ text: "Cancel", onClick: close }}
    >
      <Box sx={{ display: "flex", width: "100%" }}>
        <TextField
          size="small"
          label="Name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          sx={{ mt: 2, mb: 1, width: "100%" }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              submit();
              e.preventDefault();
            }
          }}
        />
      </Box>
    </ActionModal>
  );
}

export default CopyModal;
