import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Box, Button, ToggleButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectScenario } from "../../app/scenarioSlice";
import { AspirationType } from "../../constants";

function getBorderRadiusSx(borderStyle) {
  switch (borderStyle) {
    case "top":
      return {
        borderRadius: 0,
        borderTopRightRadius: "4px",
        borderTopLeftRadius: "4px",
      };
    case "bottom":
      return {
        borderRadius: 0,
        borderBottomRightRadius: "4px",
        borderBottomLeftRadius: "4px",
      };
    case "middle":
    default:
      return { borderRadius: 0 };
  }
}

const OptimizationLevelButton = ({
  id,
  name,
  selected,
  disabled,
  onChange,
  draggable,
  borderStyle,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const dragTransformStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { aspiration } = useSelector(selectScenario);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        position: "relative",
        ...(isDragging && { zIndex: 1 }),
        ...getBorderRadiusSx(borderStyle),
        ...(isDragging && { borderRadius: "4px" }),
      }}
      ref={setNodeRef}
      style={dragTransformStyle}
      {...attributes}
    >
      <ToggleButton
        value={id}
        color="primary"
        sx={{
          width: "100%",
          padding: 0,
          justifyContent: "flex-start",
          mb: "-1px",
          borderRadius: "inherit",
        }}
        disabled={disabled || aspiration === AspirationType.Simulate}
        selected={selected || aspiration === AspirationType.Simulate}
        onClick={(_, value) => onChange(value)}
      >
        <Box
          sx={{
            mx: "1em",
            my: "0.25em",
            mr: "2.5em", // give space to drag handel
            // single line buttons
            textWrap: "nowrap",
            overflow: "hidden",
          }}
        >
          <Typography sx={{ textTransform: "none" }}>{name}</Typography>
        </Box>
      </ToggleButton>
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: "-1px",
        }}
      >
        <Button
          variant="text"
          size="small"
          disabled={!draggable}
          {...listeners}
          sx={{
            minWidth: 0,
            height: "100%",
            pointerEvents: "auto",
            cursor: "grab",
            ...getBorderRadiusSx(borderStyle),
            ...(isDragging && { borderRadius: "4px" }),
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            boxShadow: "inset 1px 0px 0px 0px rgba(0, 0, 0, 0.12)",
            color: "rgba(0, 0, 0, 0.54)",
          }}
        >
          <DragHandleIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default OptimizationLevelButton;
