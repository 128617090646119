import { AspirationType } from "../../constants";
import { optimizationInfinity } from "../../scenarioDataUtils/scenarioDataUtils";
import {
  createScenarioName,
  defaultChangeInSpend,
  defaultGrowthTarget,
} from "../NewScenarioWizard/NewScenarioWizard";

import { v4 as uuidv4 } from "uuid";
import scenariosApi from "../../app/scenariosApi";
import { addIsSwitchingScenario } from "../../app/scenarioSlice";

const AiChatActionType = {
  CreateScenario: "create-scenario",
};

export default async function (aiBody, options) {
  const { actionType, ...body } = aiBody;
  switch (actionType) {
    case AiChatActionType.CreateScenario:
      return handleCreateScenario(body, options);
    default:
      console.error("Unknown actionType", actionType)
  }
}

async function fillCreateScenarioDefaults(body, options) {
  const {
    aspiration,
    mediaChannels: media_channels,
    start_date,
    end_date,
  } = body;
  const { companyId, formatters, getCompanyMediaBudget } = options;

  let { scenario_name, change_in_spend, media_budget, growth_target } = body;

  // Business question defaults
  if (aspiration === AspirationType.Simulate && change_in_spend == null) {
    change_in_spend = defaultChangeInSpend;
  }
  if (aspiration === AspirationType.Grow) {
    if (growth_target == null) {
      growth_target = defaultGrowthTarget;
    }
    if (media_budget == null) {
      media_budget = optimizationInfinity;
    }
  }

  // optimize media budget
  if (aspiration === AspirationType.Optimize && media_budget == null) {
    const { data: calculatedMediaBudget, isError } =
      await getCompanyMediaBudget({
        randomId: uuidv4(), // ensure there are no cache issues
        companyId,
        mediaChannels: media_channels,
        startDate: start_date,
        endDate: end_date,
      });
    if (isError) {
      console.error("Issue calculating media budget. Continuing");
    }
    media_budget =
      calculatedMediaBudget != null
        ? Math.ceil(calculatedMediaBudget * 100) / 100
        : optimizationInfinity;
  }

  // name
  if (!scenario_name) {
    scenario_name = createScenarioName({
      aspiration,
      changeInSpend: change_in_spend,
      mediaBudget: media_budget,
      growthTarget: growth_target,
      formatters,
    });
  }

  return {
    start_date,
    end_date,
    media_channels,
    aspiration,
    scenario_name,
    change_in_spend,
    media_budget,
    growth_target,
  };
}

async function handleCreateScenario(body, options) {
  const { companyId, userId, appContext, dispatch, createScenario } = options;
  const bodyWithDefaults = await fillCreateScenarioDefaults(body, options);

  const id = new Date().getTime();

  dispatch(addIsSwitchingScenario({ id, isCreatingScenario: true }));
  appContext.setShouldOpenNewScenario(true);

  console.log(bodyWithDefaults);

  await createScenario({
    ...bodyWithDefaults,
    company_id: companyId,
    user_id: userId,
    scenario_id: id,
  });

  dispatch(scenariosApi.util.invalidateTags(["Scenarios"]));

  appContext.setScenariosToRun((prevState) => prevState.concat(id));
}
