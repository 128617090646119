import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeInfo, selectIsInfoOpen } from "../../app/myAiSlice";

function InfoDialog() {
  const dispatch = useDispatch();
  const isInfoOpen = useSelector(selectIsInfoOpen);
  return (
    <Dialog
      open={isInfoOpen}
      onClose={() => dispatch(closeInfo())}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <DialogTitle>How to use My AI</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <ListItemText primary="Create a scenario to optimize my media budget." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Create a simulation with a 7% increase for Feb 2022." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Create an optimization for 2022 with a $180M media budget." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Create an growth scenario for Q3 2022 with a 1% sales increase." />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch(closeInfo())} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InfoDialog;
