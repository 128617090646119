import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";


const Header = ({ title, subtitle, children }) => {
  const [rightTextNode, setRightTextNode] = useState(null);
  const rightTextRef = useCallback((node) => setRightTextNode(node));
  const [isRightTextOverflowing, setIsRightTextOverflowing] = useState(null);

  useEffect(() => {
    if (!rightTextNode || typeof children !== "string") return;
    setIsRightTextOverflowing(
      rightTextNode.offsetHeight > rightTextNode.parentElement.offsetHeight
    );
    const update = () => {
      setIsRightTextOverflowing(
        rightTextNode.offsetHeight > rightTextNode.parentElement.offsetHeight
      );
    };
    window.addEventListener("resize", update);
    return () => window.removeEventListener("resize", update);
  }, [children, rightTextNode]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid
          container
          gap={3}
          flexWrap={"nowrap"}
          sx={{ justifyContent: "space-between", alignItems: "baseline" }}
        >
          <Grid item flexShrink={typeof children === "string" ? 0 : 1}>
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 0.87)",
              }}
              display="inline"
              variant="h4"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                marginLeft: 1,
                color: "rgba(0, 0, 0, 0.54)",
              }}
              display="inline"
              variant="subtitle1"
            >
              {subtitle}
            </Typography>
          </Grid>
          <Grid item flexGrow={1} sx={{ minWidth: 0, overflow: "hidden" }}>
            {typeof children === "string" ? (
              <Box
                sx={{
                  overflow: "hidden",
                  height: "1.75em",
                  textAlign: "right",
                  ...(isRightTextOverflowing && {
                    position: "relative",
                    "& > *": {
                      marginRight: "2ch !important",
                    },
                    "&::after": {
                      content: `"…"`,
                      position: "absolute",
                      top: 0,
                      right: 0,
                    },
                  }),
                }}
              >
                <Typography
                  sx={{
                    margin: "0 !important",
                    wordWrap: "break-word",
                    wordBreak: "break-word",
                  }}
                  variant="subtitle1"
                  ref={rightTextRef}
                  title={children}
                >
                  {children}
                </Typography>
              </Box>
            ) : (
              children
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Header;
