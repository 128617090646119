import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  Box,
  Checkbox,
  IconButton,
  TextField,
  checkboxClasses,
  createFilterOptions,
} from "@mui/material";
import {
  createRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  areIdListsEqual,
  getHeaderOptionParts,
  getOptionIdWithoutBlanks,
  getOptionParts,
  getOptionsWithDeselect,
  getOptionsWithSelect,
  isGroupOptionSelected,
  isGroupOptionSelectedWithIndeterminate,
  isSelectAllSelectedWithIndeterminate,
  toOptions,
} from "./options";

export const errorDelay = 750;

const indeterminateCheckboxStyles = {
  [`&, &.${checkboxClasses.checked}`]: {
    color: "grey",
  },
};

function getParsedCheckboxState(
  option,
  selectedIds,
  filteredIds,
  allIdsCount,
  maxDepth
) {
  let indeterminate = false;
  let checked = false;
  if (option.isGroup) {
    const selectState = isGroupOptionSelectedWithIndeterminate(
      option,
      selectedIds,
      filteredIds,
      allIdsCount,
      maxDepth
    );
    indeterminate = selectState === "indeterminate";
    checked = selectState !== "unchecked";
  } else {
    checked = selectedIds.includes(option.id);
  }
  return {
    checked,
    indeterminate,
  };
}

const selectAllId = "ChannelSelect:select-all,";
function getSelectAllLabel(isSelect, filterCount, allCount) {
  const selectWord = isSelect ? "Deselect" : "Select";
  const suffix = "All" + (filterCount !== allCount ? " " + filterCount : "");
  return `(${selectWord} ${suffix})`;
}
const selectAllOptionStub = {
  name: "",
  id: selectAllId,
  channel: {},
  depth: 0,
  isGroup: false,
  matchTerms: [],
  matchName: "",
  ancestorsIds: [],
  descendantsIds: [],
};
const headerId = "ChannelSelect:header,";
const headerOptionStub = {
  id: headerId,
  name: "",
  channel: {},
  depth: 0,
  isGroup: false,
  matchTerms: [],
  matchName: "",
  ancestorsIds: [],
  descendantsIds: [],
};

function ChannelItem({
  option,
  liProps,
  checked,
  indeterminate,
  onClick,
  expansionLevel,
  setExpansionLevel,
  sx,
  children,
}) {
  return (
    <Box
      component="li"
      key={option.id}
      data-id={option.id}
      {...liProps}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onClick();
      }}
      sx={sx}
      aria-selected={checked || indeterminate}
    >
      <Box sx={{ ml: 2 * option.depth }} />
      {option.isGroup ? (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setExpansionLevel(
              expansionLevel >= option.depth + 1
                ? option.depth
                : option.depth + 1
            );
          }}
        >
          <ExpandMoreIcon
            sx={{
              transform:
                expansionLevel === option.depth
                  ? "rotate(-90deg)"
                  : "rotate(0deg)",
            }}
          />
        </IconButton>
      ) : (
        // option.id === selectAllId ? <></> : <Box sx={{ width: "40px" }} />
        <Box sx={{ width: "40px" }} />
      )}
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        sx={{ ...(indeterminate && indeterminateCheckboxStyles) }}
        // onChange={(e) => handleCheckboxChange(e, option)}
      />
      {children}
    </Box>
  );
}

function handleScroll(scrollContainer, setCurrentHeaderId) {
  // console.log("ListboxComponent handleScroll", ref.current);
  if (scrollContainer == null) {
    // console.log("handleScroll scrollContainer is NULL");
    return;
  }
  // console.log(scrollContainer, scrollContainer?.querySelectorAll("li"))
  const containerTop = scrollContainer.getBoundingClientRect().top;
  const scrollItems = scrollContainer.querySelectorAll("li");
  // const rect = scrollItems[1].getBoundingClientRect();

  let isFirstChannelItem = true;
  for (const scrollItem of Array.from(scrollItems)) {
    if (
      scrollItem.dataset.id === selectAllId ||
      scrollItem.dataset.id === headerId
    ) {
      continue;
    }
    const scrollItemRect = scrollItem.getBoundingClientRect();
    const scrollItemTop = scrollItemRect.top;
    const scrollItemHeight = scrollItemRect.height;
    // const scrollItemBottom = scrollItem.getBoundingClientRect().bottom;
    if (scrollItemTop + scrollItemHeight - 32 > containerTop) {
      if (isFirstChannelItem) {
        setCurrentHeaderId(null);
      } else if (scrollItem.dataset.id) {
        setCurrentHeaderId(scrollItem.dataset.id);
      }
      break;
    }
    isFirstChannelItem = false;
  }

  // console.log("SCROLLHERE", containerTop, rect.top);
}

const ListboxComponent = ({
  children,
  getHeaderOption,
  filteredIds, // for UE trigger only
  ...other
}) => {
  // console.log("ListboxComponent rerender");
  const [currentHeaderId, setCurrentHeaderId] = useState(null);

  // useEffect(() => {
  //   console.log("currentHeaderId", currentHeaderId);
  // }, [currentHeaderId]);

  const ref = createRef();

  useLayoutEffect(() => {
    const scrollContainer = ref.current;

    // console.log("ListboxComponent UE", ref.current);
    function handleScrollWrapper() {
      handleScroll(scrollContainer, setCurrentHeaderId);
    }

    // console.log("ref", ref.current);
    // console.log("scrollContainer", scrollContainer);
    if (scrollContainer) {
      // console.log("ATTACH");
      scrollContainer.addEventListener("scroll", handleScrollWrapper);
    } else {
      // console.log("ATTACH FAIL");
    }

    return () => {
      // console.log("DETACH");
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScrollWrapper);
      } else {
        // console.log("DETACH FAIL");
      }
    };
  }, []);

  useEffect(() => {
    // console.log("filteredIds", ref.current)
    handleScroll(ref.current, setCurrentHeaderId);
  }, [filteredIds]);

  const [selectAll, ...optionChildren] = children;

  const headerNode =
    currentHeaderId != null ? getHeaderOption(currentHeaderId) : null;

  return (
    <ul {...other} ref={ref}>
      {headerNode ?? selectAll}
      {optionChildren}
    </ul>
  );
};

function ChannelSelect({
  channelsKey,
  label,
  initialValue,
  channels: _channels,
  setChannels,
  allChannels: _allChannels,
  hierarchy: _hierarchy,
  errors,
  setErrors,
  isLoading,
}) {
  const [open, setOpen] = useState(false);
  const filter = useMemo(
    () =>
      createFilterOptions({
        stringify: (o) => o.matchName,
      }),
    []
  );
  const [inputValue, setInputValue] = useState("");
  const channels = useMemo(
    () => (!isLoading ? _channels : []) ?? [],
    [_channels, isLoading]
  );
  const allChannels = useMemo(
    () => (!isLoading ? _allChannels : []) ?? [],
    [_allChannels, isLoading]
  );
  const hierarchy = useMemo(() => _hierarchy ?? [], [_hierarchy]);
  const allOptions = useMemo(
    () => (!isLoading ? toOptions(allChannels, hierarchy) : []) ?? [],
    [allChannels, isLoading, hierarchy]
  );
  const selectedIds = useMemo(
    () =>
      (!isLoading
        ? channels.map((c) =>
            getOptionIdWithoutBlanks(c, hierarchy.length - 1, hierarchy)
          )
        : []) ?? [],
    [channels, isLoading]
  );
  function setSelectedIds(newSelectedIds) {
    setChannels(
      allChannels.filter((c) =>
        newSelectedIds.includes(
          getOptionIdWithoutBlanks(c, hierarchy.length - 1, hierarchy)
        )
      )
    );
  }

  const [filteredIds, setFilteredIds] = useState(
    allOptions.filter(({ isGroup }) => !isGroup).map(({ id }) => id)
  );

  const [expansionLevel, setExpansionLevel] = useState(0);
  // useEffect(() => {
  //   if (!isLoading) {
  //     setExpansionLevel(hierarchy.length - 1);
  //   }
  // }, [isLoading, hierarchy]);

  useEffect(() => {
    // null -> empty; undefined -> initial value not used
    if (initialValue === undefined) return;
    setChannels(initialValue);
  }, [initialValue]);

  // useEffect(() => {
  //   console.log("selectedIds", selectedIds);
  // }, [selectedIds]);
  // useEffect(() => {
  //   console.log("filteredIds", filteredIds);
  // }, [filteredIds]);
  // useEffect(() => {
  //   console.log("allOptions", allOptions);
  // }, [allOptions]);

  const [recentlyEditedChannels, setRecentlyEditedChannels] = useState(false);
  const debouncedClearRecentlyEditedChannels = useDebouncedCallback(
    () => setRecentlyEditedChannels(false),
    errorDelay
  );

  const handleSelectOption = useCallback(
    (option) => {
      console.log("handleSelectOption", option.id);
      debouncedClearRecentlyEditedChannels();
      const newSelected = getOptionsWithSelect(
        option,
        selectedIds,
        filteredIds,
        allOptions
      );
      setSelectedIds(newSelected);
      setErrors((prevState) => ({
        ...prevState,
        [channelsKey]:
          newSelected.length === 0 ? "Select at least one" : undefined,
      }));
    },
    [selectedIds, filteredIds, allOptions]
  );

  const handleDeselectOption = useCallback(
    (option) => {
      console.log("handleDeselectOption", option.id);
      debouncedClearRecentlyEditedChannels();
      const newSelected = getOptionsWithDeselect(
        option,
        selectedIds,
        filteredIds,
        allOptions
      );
      setSelectedIds(newSelected);
      setErrors((prevState) => ({
        ...prevState,
        [channelsKey]:
          newSelected.length === 0 ? "Select at least one" : undefined,
      }));
    },
    [selectedIds, filteredIds]
  );

  function handleToggleSelection(option, checked, indeterminate) {
    const shouldSelect = !checked || indeterminate;
    if (option.id === selectAllId) {
      handleToggleSelectAll(shouldSelect);
      handleToggleSelectAll(shouldSelect);
      return;
    }
    if (shouldSelect) {
      handleSelectOption(option);
    } else {
      handleDeselectOption(option);
    }
  }

  const handleToggleSelectAll = useCallback(
    (select) => {
      debouncedClearRecentlyEditedChannels();
      if (!select) {
        const newSelected = selectedIds.filter(
          (id) => !filteredIds.includes(id)
        );
        setSelectedIds(newSelected);
        setErrors((prevState) => ({
          ...prevState,
          [channelsKey]:
            newSelected.length === 0 ? "Select at least one" : undefined,
        }));
      } else {
        const newSelected = selectedIds.concat(
          filteredIds.filter((id) => !selectedIds.includes(id))
        );
        setSelectedIds(newSelected);
        setErrors((prevState) => ({
          ...prevState,
          [channelsKey]:
            newSelected.length === 0 ? "Select at least one" : undefined,
        }));
      }
    },
    [selectedIds, filteredIds]
  );

  function handleClearOptions() {
    setSelectedIds([]);
  }

  const updateFilteredOptions = useDebouncedCallback((filtered) => {
    // console.log("debounce");
    setFilteredIds((prev) => {
      if (areIdListsEqual(prev, filtered)) {
        return prev;
      }
      return filtered;
    });
  }, 250);

  const handleChange = (_event, selectedOptions, reason, details) => {
    // console.log("handleChange", reason, details?.option);
    if (reason === "clear") {
      // console.log("CLEAR");
      handleClearOptions();
    } else if (reason === "selectOption" || reason === "removeOption") {
      const { option } = details;
      // console.log("handleChange", reason, option.id);
      if (option.id === selectAllId) {
        // console.log("handleToggleSelectAll reason", reason);
        handleToggleSelectAll(reason === "selectOption");
      } else {
        if (reason === "selectOption") {
          handleSelectOption(option);
        } else if (reason === "removeOption") {
          handleDeselectOption(option);
        }
      }
    }
  };

  // TODO fix filtering

  function filterOptions(options, state) {
    // console.log("filterOptions");
    const defaultFiltered = filter(options, state);
    const filteredIds = defaultFiltered.map(({ id }) => id);
    const filtered = options.filter(
      (o) =>
        filteredIds.findIndex(
          (id) =>
            o.id === id ||
            o.ancestorsIds.includes(id) ||
            o.descendantsIds.includes(id)
        ) !== -1
    );

    const newFilteredIds = filtered
      .filter(({ isGroup }) => !isGroup)
      .map(({ id }) => id);
    updateFilteredOptions(newFilteredIds);

    if (filtered.length === 0) {
      return [];
    }

    return [selectAllOptionStub, ...filtered];
  }

  const getHeaderOption = useCallback(
    (id) => {
      const headerMatchChildOption = id
        ? allOptions.find((o) => o.id === id) ?? null
        : null;
      const headerMatchOptionId =
        headerMatchChildOption?.matchTerms
          .slice(0, -1)
          .filter((v) => v != null)
          .join(",") ?? null;
      const headerMatchOption = headerMatchOptionId
        ? allOptions.find((o) => o.id === headerMatchOptionId) ?? null
        : null;
      if (headerMatchOption == null) {
      }
      const { checked, indeterminate } = headerMatchOption
        ? getParsedCheckboxState(
            headerMatchOption,
            selectedIds,
            filteredIds,
            allChannels.length,
            hierarchy.length - 1
          )
        : { checked: false, indeterminate: false };

      const partsList = getHeaderOptionParts(headerMatchOption, inputValue);

      // console.log(
      //   "rerender header",
      //   id,
      //   selectedIds.filter((s) => s === id),
      //   checked,
      //   indeterminate
      // );

      return (
        <ChannelItem
          key={headerId}
          option={{
            ...headerMatchOption,
            id: headerId,
            isGroup: false,
            depth: 0,
          }}
          // option={option}
          sx={{
            zIndex: 2, // above other option checkboxes
            position: "sticky",
            top: "-8px",
            padding: "0 4px 0 16px",
            boxShadow: "0 1px 0 rgba(0, 0, 0, 0.12)",
            backgroundColor:
              checked || indeterminate ? "rgb(238 248 253)!important" : "white",
          }}
          checked={checked}
          indeterminate={indeterminate}
          liProps={{
            tabIndex: -1,
            role: "option",
            className: "MuiAutocomplete-option",
          }}
          onClick={() => {
            handleToggleSelection(headerMatchOption, checked, indeterminate);
          }}
          expansionLevel={0} // doesn't matter since not group
          setExpansionLevel={setExpansionLevel}
        >
          <Box
            sx={{
              display: "flex",
              whiteSpace: "pre-wrap",
            }}
          >
            {partsList.flatMap((parts, index) => {
              const s = parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ));

              return index === partsList.length - 1
                ? s
                : [s, <ChevronRightIcon key={index + "chevron"} />];
            })}
          </Box>
        </ChannelItem>
      );
    },
    [allOptions, selectedIds, filteredIds, inputValue]
  );

  // console.log("rerender");

  return (
    <Autocomplete
      id={`${channelsKey}-channels-autocomplete`}
      disableListWrap
      clearOnEscape
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={allOptions}
      value={useMemo(
        () => [
          ...allOptions.filter((option) =>
            option.isGroup
              ? isGroupOptionSelected(option, selectedIds, filteredIds)
              : selectedIds.includes(option.id)
          ),
        ],
        [allOptions, selectedIds, filteredIds]
      )}
      inputValue={inputValue}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      renderOption={(props, option, { selected, inputValue }) => {
        if (expansionLevel < option.depth) {
          return null;
        }

        // @ts-ignore key does not exist
        const { key: _, ...safeProps } = props;

        // select all is converted to the header when needed
        // this solves the issue off scroll length changing with a conditional sticky header
        // a fixed header has sizing issues
        if (option.id === selectAllId) {
          const selectState = isSelectAllSelectedWithIndeterminate(
            selectedIds,
            filteredIds
          );
          const checked = selectState !== "unchecked";
          const indeterminate = selectState === "indeterminate";
          const text = getSelectAllLabel(
            checked && !indeterminate,
            filteredIds.length,
            allChannels.length
          );
          return (
            <ChannelItem
              key={headerId}
              // option={headerMatchOption}
              option={option}
              // selected={selected}
              checked={checked}
              indeterminate={indeterminate}
              liProps={safeProps}
              onClick={() => {
                handleToggleSelection(option, checked, indeterminate);
              }}
              expansionLevel={expansionLevel}
              setExpansionLevel={setExpansionLevel}
            >
              <div>{text}</div>
            </ChannelItem>
          );
        }

        const { checked, indeterminate } = getParsedCheckboxState(
          option,
          selectedIds,
          filteredIds,
          allChannels.length,
          hierarchy.length - 1
        );

        const parts = getOptionParts(option, inputValue);

        return (
          <ChannelItem
            option={option}
            liProps={safeProps}
            checked={checked}
            indeterminate={indeterminate}
            onClick={() => {
              handleToggleSelection(option, checked, indeterminate);
            }}
            expansionLevel={expansionLevel}
            setExpansionLevel={setExpansionLevel}
          >
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </ChannelItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={
            label + ` (${selectedIds.length} of ${allChannels.length} selected)`
          }
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(!recentlyEditedChannels && errors[channelsKey])}
          helperText={
            (!recentlyEditedChannels ? errors[channelsKey] : undefined) || " "
          }
          placeholder="Search"
          value={inputValue}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              // stop deletion of tags
              event.stopPropagation();
            }
          }}
        />
      )}
      ListboxComponent={ListboxComponent}
      // @ts-ignore
      ListboxProps={{ getHeaderOption, filteredIds }}
      renderTags={() => null}
      filterOptions={filterOptions}
      disableCloseOnSelect
      multiple
      onInputChange={(_, value, reason) => {
        if (reason === "input") {
          // ignore other reasons that clear the input
          setInputValue(value);
        }
      }}
      onChange={handleChange}
      clearIcon={null}
    />
  );
}

export default ChannelSelect;
