import axios from "axios";
import {
  scenarioDataHelpers,
  ScenarioDataKeys,
} from "./scenarioDataUtils/scenarioDataUtils";
import { getNameFromTarget } from "./containers/CategoryDataUtils";
import { app } from "./app/mongo";

export async function updateGridRows(collection, rows) {
  const result = await axios.post(
    getBaseUrl().concat("updateGridRows/"),
    JSON.stringify({
      collection,
      rows,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // TODO handel errors

  return result;
}

// export async function getCurvesChartData(user_id, scenario_id, mediaTarget) {
//   const result = await axios.post(
//     // getBaseUrl().concat("response-curve/"),
//     "http://localhost:5000/response-curve/",
//     JSON.stringify({
//       user_id,
//       scenario_id,
//       ...mediaTarget,
//     }),
//     {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     }
//   );
//   // TODO handel errors
//   return {
//     name: getNameFromTarget(
//       mediaTarget,
//       scenarioDataHelpers[ScenarioDataKeys.Media].channelKeys
//     ).trim(),
//     target: mediaTarget,
//     ...result.data,
//   };
// }

export async function createScenario(
  company_id,
  user_id,
  scenario_id,
  scenario_name,
  start_date,
  end_date,
  sales_channels,
  media_channels,
  aspiration,
  change_in_spend,
  media_budget,
  growth_target
) {
  const result = await axios.post(
    getBaseUrl().concat("create-scenario/"),
    // "http://localhost:5000/create-scenario/",
    JSON.stringify({
      company_id,
      user_id,
      scenario_id,
      scenario_name,
      start_date,
      end_date,
      sales_channels,
      media_channels,
      aspiration,
      change_in_spend,
      media_budget,
      growth_target,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // TODO: handle errors
  return result;
}

export async function callMongoFunction(name, ...parameters) {
  return await app.currentUser.callFunction(name, ...parameters);
}

export async function getScenarioChannels(userId, scenarioId) {
  let channelsResult;
  try {
    channelsResult = await callMongoFunction("getChannels2", {
      userId,
      scenarioId,
    });
  } catch (e) {
    if (channelsResult === undefined) {
      channelsResult = {
        body: {
          message: e.message,
        },
      };
    }
  }

  // All errors in data handled by mongo function. If we get the data, it is good
  if (
    channelsResult?.body?.data == null ||
    channelsResult?.body?.message != null
  ) {
    console.error(
      "Invalid targets when switching to scenario",
      scenarioId,
      "Message:",
      channelsResult?.body?.message
    );
    return null;
  }

  return channelsResult.body.data;
}
