import { updateGridRows } from "../Api";
import { ALL_CHANNEL_KEYS } from "../containers/CategoryDataUtils";
import {
  GroupAggregationType,
  scenarioDataHelpers,
  ScenarioDataKeys,
} from "./scenarioDataUtils";

const contributionRecalculationKeys = ["starting_spend", "final_spend"];

const channelKeys = ALL_CHANNEL_KEYS;

function getFieldAggregationType(field) {
  switch (field) {
    case "starting_spend":
    case "final_spend":
    case "incremental_spend":
    case "starting_impressions":
    case "final_impressions":
    case "incremental_impressions":
      return GroupAggregationType.Sum;
    // TODO add the rest of the fields
    default:
      return GroupAggregationType.Nothing;
  }
}

function getFieldConstraints(field) {
  switch (field) {
    case "starting_spend":
    case "final_spend":
      return scenarioDataHelpers[ScenarioDataKeys.Media].getFieldConstraints(
        field
      );
    default:
      return {
        isValueValid: () => true,
        valueConstraintsText: "",
      };
  }
}

/**
 * Prepare the item to be persisted.
 *
 * @param {Object} item The item to prepare.
 * @param {Object} user_id The current user ID.
 * @param {Object} scenario_id The current scenario ID.
 * @returns a new object which is safe for persisting in the backend.
 */
function prepareItemForBackend(item, user_id, scenario_id) {
  return {
    ...item,
    user_id,
    scenario_id,
  };
}

// /**
//  * Save the item in the database.
//  *
//  * @param {Object} item An item which was prepared by `prepareItemForBackend`.
//  * @see {@link prepareItemForBackend}
//  */
// async function persistItemHandler(item) {
//   throw new Error(
//     "Results persistItemHandler should never be called. The contribution calculation will always be run which makes this redundant."
//   );
// }

/**
 * Save the items in the database.
 *
 * @param {Object} item List of items which were prepared by `prepareItemForBackend`.
 * @see {@link prepareItemForBackend}
 */
async function persistManyHandler(items) {
  const response = await updateGridRows("runs", items);
  // console.log("persistManyHandler RESULTS", items, response);
  // TODO handel errors
}

/**
 * Modify the given item with side effects of the edit.
 *
 * @param {Object} item The item to modify
 * @param {Object[]} modifiedFields The fields that were modified
 * @returns the field that were modified (including those in the input)
 */
function runSideEffectsOnItem(item, modifiedFields, scenarioAspiration, cpm) {
  if (cpm == null) return [...modifiedFields];
  // TODO refactor

  const getFieldWithPrefix = (prefix, field) => [prefix, field].join("_");
  const shouldRunFieldSideEffects = (field) =>
    modifiedFields.includes(field) && item[field] != null;

  const changes = {};
  const getValueWithChange = (field) => changes[field] || item[field];

  const spendToImpressions = (v) => (v / cpm) * 1000;
  const impressionsToSpend = (v) => (v * cpm) / 1000;

  // spend / impressions with priority
  ["starting", "final"].forEach((prefix) => {
    if (cpm != null) {
      const [spend, impressions] = ["spend", "impressions"].map((f) =>
        getFieldWithPrefix(prefix, f)
      );
      if (shouldRunFieldSideEffects(spend)) {
        changes[impressions] = spendToImpressions(getValueWithChange(spend));
      } else if (shouldRunFieldSideEffects(impressions)) {
        changes[spend] = impressionsToSpend(getValueWithChange(impressions));
      }
    }
  });

  Object.entries(changes).forEach(([field, value]) => {
    item[field] = value;
  });

  return [...modifiedFields, ...Object.keys(changes)];
}

export default {
  contributionRecalculationKeys,
  channelKeys,
  getFieldAggregationType,
  prepareItemForBackend,
  // persistItemHandler,
  persistManyHandler,
  runSideEffectsOnItem,

  getFieldConstraints,
};
