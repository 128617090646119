import { useVariableValue } from "@devcycle/react-client-sdk";
import { Box, FormHelperText, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetCompanyQuery } from "../../app/companyApi";
import { useGetScenarioChannelsQuery } from "../../app/scenarioChannelsApi";
import { useGetScenarioPeriodQuery } from "../../app/scenarioPeriodApi";
import {
  useEditScenarioMutation,
  useRenameScenarioMutation,
} from "../../app/scenariosApi";
import ActionModal from "../../components/ActionModal/ActionModal";
import ChannelSelect from "../../components/ChannelSelect/ChannelSelect";
import LoadingSection, {
  LoadingType,
} from "../../components/LoadingSection/LoadingSection";
import PeriodSelect, {
  convertDate,
} from "../../components/PeriodSelect/PeriodSelect";
import ScenarioChannelsInput from "../../components/ScenarioChannelsInput/ScenarioChannelsInput";
import {
  AspirationType,
  getISODate,
  getMaybeNullDate,
  getStartOfWeekDate,
  zeroTimeString,
} from "../../constants";
import { areTargetListsEqual } from "../../utils/targetUtils";

function EditModal({ isOpen, actionProps, close, handle }) {
  const hierarchicalChannelSelectFlag = useVariableValue(
    "hierarchical-channel-select",
    false
  );

  const {
    scenarioId,
    userId,
    aspiration,
    companyId,
    name: initialName,
  } = actionProps?.data ?? {};
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [mediaChannels, setMediaChannels] = useState([]);

  const [initialStartDate, setInitialStartDate] = useState(null);
  const [initialEndDate, setInitialEndDate] = useState(null);
  const [initialMediaChannels, setInitialMediaChannels] = useState(null);

  const [errors, setErrors] = useState({});

  const {
    data: company,
    isLoading: companyIsLoading,
    isFetching: companyIsFetching,
    isSuccess: companyIsSuccess,
  } = useGetCompanyQuery(
    {
      companyId,
    },
    {
      skip: companyId == null,
    }
  );

  const {
    data: scenarioChannels,
    isLoading: scenarioChannelsAreLoading,
    isFetching: scenarioChannelsAreFetching,
    isSuccess: scenarioChannelsAreSuccess,
  } = useGetScenarioChannelsQuery(
    {
      scenarioId,
      userId,
    },
    {
      skip: scenarioId == null || userId == null,
    }
  );

  const {
    data: scenarioPeriod,
    isLoading: scenarioPeriodIsLoading,
    isFetching: scenarioPeriodIsFetching,
    isSuccess: scenarioPeriodIsSuccess,
  } = useGetScenarioPeriodQuery(
    {
      scenarioId,
      userId,
    },
    {
      skip: scenarioId == null || userId == null,
    }
  );

  const isLoadingCompany = useMemo(
    () => companyIsLoading || !companyIsSuccess || companyIsFetching,
    [companyIsLoading, companyIsSuccess, companyIsFetching]
  );

  const isLoadingChannels = useMemo(
    () =>
      scenarioChannelsAreLoading ||
      !scenarioChannelsAreSuccess ||
      scenarioChannelsAreFetching,
    [
      scenarioChannelsAreLoading,
      scenarioChannelsAreSuccess,
      scenarioChannelsAreFetching,
    ]
  );

  const isLoadingPeriod = useMemo(
    () =>
      scenarioPeriodIsLoading ||
      !scenarioPeriodIsSuccess ||
      scenarioPeriodIsFetching,
    [scenarioPeriodIsLoading, scenarioPeriodIsSuccess, scenarioPeriodIsFetching]
  );

  const isLoading = useMemo(
    () => isLoadingCompany || isLoadingChannels || isLoadingPeriod,
    [isLoadingCompany, isLoadingChannels, isLoadingPeriod]
  );

  useEffect(() => {
    if (initialName == null) return;
    setName(initialName);
  }, [initialName]);

  useEffect(() => {
    if (scenarioPeriod == null || isLoadingPeriod || scenarioPeriodIsFetching) {
      return;
    }
    const startOfWeekStart = getISODate(
      getStartOfWeekDate(scenarioPeriod.start + zeroTimeString)
    );
    setInitialStartDate(startOfWeekStart);
    setInitialEndDate(scenarioPeriod.end + zeroTimeString);
    setStartDate(startOfWeekStart);
    setEndDate(scenarioPeriod.end + zeroTimeString);
  }, [scenarioPeriod, isLoadingPeriod, scenarioPeriodIsFetching]);

  useEffect(() => {
    if (
      scenarioChannels == null ||
      isLoadingChannels ||
      scenarioChannelsAreFetching
    ) {
      setInitialMediaChannels(null);
      return;
    }
    setInitialMediaChannels(scenarioChannels.media);
  }, [scenarioChannels, isLoadingChannels, scenarioChannelsAreFetching]);

  const [renameScenario] = useRenameScenarioMutation();
  const [editScenario] = useEditScenarioMutation();

  const handleNameEdit = useCallback(() => {
    if (initialName == null || name === initialName) return;
    renameScenario({
      userId,
      scenarioId,
      name,
    });
  }, [renameScenario, userId, scenarioId, name, initialName]);

  const handleNameSubmit = useCallback(() => {
    handle(actionProps, handleNameEdit);
    close();
  }, [handleNameEdit, actionProps]);

  const periodChanged = useMemo(() => {
    if (
      startDate == null ||
      endDate == null ||
      initialStartDate == null ||
      initialEndDate == null
    ) {
      return false;
    }
    return startDate !== initialStartDate || endDate !== initialEndDate;
  }, [startDate, endDate, initialStartDate, initialEndDate]);
  const channelsChanged = useMemo(() => {
    if (initialMediaChannels == null || mediaChannels == null) {
      return false;
    }
    return !areTargetListsEqual(initialMediaChannels, mediaChannels);
  }, [mediaChannels, initialMediaChannels]);

  const handlePeriodAndChannelsEdit = useCallback(() => {
    if (company == null) {
      console.error(
        "Could not submit period and channels edit. Company was null"
      );
      return;
    }
    if (startDate == null || endDate == null || mediaChannels == null) {
      console.error(
        "Could not submit period and channels edit. Input was null",
        {
          startDate,
          endDate,
          mediaChannels,
        }
      );
      return;
    }

    if (!periodChanged && !channelsChanged) return;

    editScenario({
      userId,
      scenarioId,
      aspiration,
      companyId,
      companyMediaHierarchy: company.mediaHierarchy,
      companySalesHierarchy: company.salesHierarchy,
      startPeriod: startDate,
      endPeriod: endDate,
      mediaChannels,
      periodChanged,
      channelsChanged,
    });
  }, [
    editScenario,
    userId,
    scenarioId,
    aspiration,
    companyId,
    company,
    startDate,
    endDate,
    mediaChannels,
    periodChanged,
    channelsChanged,
  ]);

  const handleSubmit = useCallback(() => {
    handle(actionProps, handleNameEdit);
    handle(actionProps, handlePeriodAndChannelsEdit);
    close();
  }, [handleNameEdit, handlePeriodAndChannelsEdit]);

  const getWaringText = useCallback(() => {
    if (
      aspiration === AspirationType.Simulate &&
      (periodChanged || channelsChanged)
    ) {
      return "Warning: Projected spend will be reset to initial spend.";
    }
    if (
      aspiration !== AspirationType.Simulate &&
      (periodChanged || channelsChanged)
    ) {
      return "Warning: Constraints will be reset to default values.";
    }
  }, [aspiration, periodChanged, channelsChanged]);

  return (
    <ActionModal
      name="edit"
      open={isOpen}
      handleClose={close}
      title="Edit Scenario"
      primaryButton={{
        text: "Save",
        onClick: handleSubmit,
      }}
      secondaryButton={{ text: "Cancel", onClick: close }}
    >
      <LoadingSection
        isLoading={isLoading}
        type={LoadingType.Cover}
        sx={{
          width: "calc(100% + 1em)", // Fix period input calendar button overflow
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            pt: 1,
            width: "calc(100% - 1em)", // Fix period input calendar button overflow
          }}
        >
          <TextField
            variant="standard"
            size="small"
            label="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            onFocus={(event) => {
              event.target.select();
            }}
            inputProps={{ min: 0 }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleNameSubmit();
                e.preventDefault();
              }
            }}
            helperText=" "
          />
          <PeriodSelect
            label="Start date"
            value={new Date(startDate)}
            onChange={(date) => setStartDate(convertDate(date))}
            defaultDate={getMaybeNullDate(company?.defaultStartDate)}
            minDate={getMaybeNullDate(company?.defaultStartDate)}
            maxDate={getMaybeNullDate(company?.defaultEndDate)}
          />
          <PeriodSelect
            label="End date"
            value={new Date(endDate)}
            onChange={(date) => setEndDate(convertDate(date))}
            defaultDate={getMaybeNullDate(company?.defaultEndDate)}
            minDate={getMaybeNullDate(company?.defaultStartDate)}
            maxDate={getMaybeNullDate(company?.defaultEndDate)}
          />
          {hierarchicalChannelSelectFlag ? (
            <ChannelSelect
              channelsKey="mediaChannels"
              label="Media Channels"
              initialValue={initialMediaChannels}
              channels={mediaChannels}
              setChannels={setMediaChannels}
              allChannels={company?.mediaChannels}
              hierarchy={company?.mediaHierarchy}
              errors={errors}
              setErrors={setErrors}
              isLoading={isLoadingChannels}
            />
          ) : (
            <ScenarioChannelsInput
              channelsKey="mediaChannels"
              label="Media Channels"
              initialValue={initialMediaChannels}
              channels={mediaChannels}
              setChannels={setMediaChannels}
              allChannels={company?.mediaChannels}
              hierarchy={company?.mediaHierarchy}
              errors={errors}
              setErrors={setErrors}
              isLoading={isLoadingChannels}
            />
          )}
        </Box>
        <Box>
          <FormHelperText error={true}>{getWaringText() ?? " "}</FormHelperText>
        </Box>
      </LoadingSection>
    </ActionModal>
  );
}

export default EditModal;
