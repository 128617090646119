import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpointsUrl } from "./endpoints";

export const scenarioChannelsApi = createApi({
  reducerPath: "scenarioChannelsApi",
  baseQuery: fetchBaseQuery({ baseUrl: endpointsUrl }),
  tagTypes: ["ScenarioChannel"],
  endpoints: (builder) => ({
    getScenarioChannels: builder.query({
      query: ({ userId, scenarioId }) => ({
        url: "scenarios/getChannels",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
        },
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (_result, _error, { userId, scenarioId }) => {
        return [
          {
            type: "ScenarioChannel",
            id: JSON.stringify({ userId, scenarioId }),
          },
        ];
      },
    }),
  }),
});

export default scenarioChannelsApi;
export const { useGetScenarioChannelsQuery } = scenarioChannelsApi;
