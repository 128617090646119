import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Box, Typography, useTheme } from "@mui/material";
import {
  faChartBar,
  faChartLine,
  faRoute,
} from "@fortawesome/free-solid-svg-icons";
import CreateCard from "./CreateCard";
import NewScenarioWizard from "../NewScenarioWizard/NewScenarioWizard";
import { AspirationType } from "../../constants";

const insetStyles = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 2,
};

const containedBaseStyles = {
  position: "relative",
  width: "100%",
  paddingTop: "100%",
  color: "#FFF",
  borderRadius: "10px",
};

const subtitleStyles = {
  marginTop: 2,
  color: "rgba(0, 0, 0, 0.54)",
};

const CreateNewScenario = () => {
  const [aspiration, setAspiration] = useState("");
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  return (
    <>
      <Box mt={3}>
        <Paper variant="outlined" style={{ padding: 24, borderRadius: "10px" }}>
          <Typography
            variant="h5"
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              marginBottom: 3,
            }}
          >
            {"Create New Scenario"}
          </Typography>
          <Grid container spacing={3} justifyContent="space-evenly">
            <Grid item xs={12} sm={4} md={3} lg={2.5}>
              <CreateCard
                aspiration={AspirationType.Simulate}
                setAspiration={setAspiration}
                handleOpen={() => setOpen(true)}
                icon={faRoute}
                inset={insetStyles}
                contained={{
                  ...containedBaseStyles,
                  backgroundColor: theme.palette[AspirationType.Simulate].main,
                  "&:hover": {
                    backgroundColor:
                      theme.palette[AspirationType.Simulate].hover,
                  },
                }}
                disabled={false}
              />
              <Typography
                variant="subtitle1"
                align={"center"}
                sx={{
                  marginTop: 2,
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              >
                {"Simulate business results a change delivers"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2.5}>
              <CreateCard
                aspiration={AspirationType.Optimize}
                setAspiration={setAspiration}
                handleOpen={() => setOpen(true)}
                icon={faChartBar}
                inset={insetStyles}
                contained={{
                  ...containedBaseStyles,
                  backgroundColor: theme.palette[AspirationType.Optimize].main,
                  "&:hover": {
                    backgroundColor:
                      theme.palette[AspirationType.Optimize].hover,
                  },
                }}
                disabled={false}
              />
              <Typography
                variant="subtitle1"
                align={"center"}
                sx={subtitleStyles}
              >
                {"Optimize business results on a budget"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2.5}>
              <CreateCard
                aspiration={AspirationType.Grow}
                setAspiration={setAspiration}
                handleOpen={() => setOpen(true)}
                icon={faChartLine}
                inset={insetStyles}
                contained={{
                  ...containedBaseStyles,
                  backgroundColor: theme.palette[AspirationType.Grow].main,
                  "&:hover": {
                    backgroundColor: theme.palette[AspirationType.Grow].hover,
                  },
                }}
                disabled={false}
              />
              <Typography
                variant="subtitle1"
                align={"center"}
                sx={subtitleStyles}
              >
                {"Grow business results most efficiently"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <NewScenarioWizard
        aspiration={aspiration}
        open={open}
        setOpen={setOpen}
      ></NewScenarioWizard>
    </>
  );
};

export default CreateNewScenario;
