import { color as am4Color } from "@amcharts/amcharts4/.internal/core/utils/Color";
import { getColorBySeriesKey } from "../../constants";

function cellStylePercent(params, columns) {
  const values = [];
  params.api.forEachNode((node) => {
    if (node.stub === true || node.data.isTotalRow) return; // loading node is not ready yet. Don't include total row
    // If group and visible
    if (node.group && node.rowIndex !== null) {
      columns.forEach((column) => values.push(node.data[column]));
    }
  });
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  return (params.value - minValue) / (maxValue - minValue);
}

export function cellStyle(params, columns) {
  if (!params.node.group || params.data.isTotalRow) return null;
  // If group and not total row
  const color = getColorBySeriesKey(columns.at(-1));
  const cellPercentage = cellStylePercent(params, columns);
  return {
    backgroundColor: am4Color(color).lighten(1 - cellPercentage).hex,
  };
}
