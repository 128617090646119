import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "./endpoints";

export const myAiApi = createApi({
  reducerPath: "myAiApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL }),
  // baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:5000/" }),
  endpoints: (builder) => ({
    getPromptAction: builder.query({
      query: (body) => ({
        url: "/my-ai/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export default myAiApi;
export const { useLazyGetPromptActionQuery } = myAiApi;
