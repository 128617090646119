import { createSlice } from "@reduxjs/toolkit";
import { setSeriesProperties } from "../../../utils/am5ChartUtils";

export const sliceName = "planChart";

export const ChartToggleSeriesEnum = {
  Contribution: "contribution",
  Sales: "sales",
};

export const ChartSeriesEnum = {
  StartingSpend: "starting_spend",
  FinalSpend: "final_spend",
  StartingContribution: "starting_contribution",
  FinalContribution: "final_contribution",
  StartingSales: "starting_sales",
  FinalSales: "final_sales",
};

const initialSeriesProperties = {
  [ChartSeriesEnum.StartingSpend]: {
    isVisible: true,
  },
  [ChartSeriesEnum.FinalSpend]: {
    isVisible: true,
  },
};

export const slice = createSlice({
  name: sliceName,
  initialState: {
    seriesProperties: initialSeriesProperties,
    toggleSeries: ChartToggleSeriesEnum.Contribution,
  },
  reducers: {
    setSeriesProperty: (state, { payload }) =>
      setSeriesProperties(state, payload),
    batchSetSeriesProperty: (state, { payload }) => {
      payload.forEach((propertyChange) =>
        setSeriesProperties(state, propertyChange)
      );
    },
    setToggleSeries: (state, { payload }) => {
      state.toggleSeries = payload;
    },
    setSeriesData: (state, { payload: { seriesData, isAllMediaFiltered } }) => {
      state.isAllMediaFiltered = isAllMediaFiltered;
      state.seriesData = seriesData;
    },
  },
});

export const {
  setSeriesProperty,
  batchSetSeriesProperty,
  setToggleSeries,
  setSeriesData,
} = slice.actions;

export const selectPlanChartSeriesProperties = (state) =>
  state[sliceName].seriesProperties;

export const selectPlanChartData = (state) => state[sliceName].seriesData;

export const selectToggleSeries = (state) => state[sliceName].toggleSeries;

export const selectIsAllMediaFiltered = (state) =>
  state[sliceName].isAllMediaFiltered;

export default slice.reducer;
