import { isOptimizationAspiration } from "../constants";
import {
  ALL_CHANNEL_KEYS,
  ALL_MEDIA_CHANNEL_KEYS,
  categoryVectorToCategoryName,
  getNameFromTarget,
  getTargetWeekMap,
} from "../containers/CategoryDataUtils";
import {
  getCVFromTarget,
  getItemGroupMatcher,
  getTargetFromCV,
  getTargetFromItem,
  GridKeyEnum,
  scenarioDataHelpers,
  ScenarioDataKeys,
} from "../scenarioDataUtils/scenarioDataUtils";
import { targetToMediaTarget } from "./channelUtils";

// Extract the results targets from something that already has the levels combined like results from the backend or the coefficients
function getResultsTargetsFromCombinedSource(allLevelSource) {
  return Object.values(
    allLevelSource.reduce((acc, d) => {
      const target = getTargetFromItem(d, ALL_CHANNEL_KEYS);
      delete target.period;
      const name = categoryVectorToCategoryName(
        getCVFromTarget(target, ALL_CHANNEL_KEYS)
      );
      return {
        ...acc,
        [name]: target,
      };
    }, {})
  );
}

// break up targets between media channels and sales channels
function getLayeredTargets(resultsTargets) {
  // media category name to set of period already in the result
  const mediaTargets = resultsTargets.reduce((acc, item) => {
    const cv = ALL_MEDIA_CHANNEL_KEYS.map((k) => item[k]).filter(
      (v) => v != null
    );
    const name = categoryVectorToCategoryName(cv);
    acc[name] = {
      mediaTarget: getTargetFromCV(cv, ALL_MEDIA_CHANNEL_KEYS),
      subTargets: [...(acc[name]?.subTargets || []), item],
    };
    return acc;
  }, {});
  return Object.values(mediaTargets);
}

function getWeekWithSpendChangeCopy({
  week,
  mediaMatch,
  changedFields,
  scenarioAspiration,
}) {
  let newWeek = week;

  if (changedFields.includes("starting_spend")) {
    newWeek = {
      ...newWeek,
      starting_spend: mediaMatch.starting_spend,
      starting_impression: mediaMatch.starting_impression,
    };
  }
  if (
    changedFields.includes("final_spend") &&
    !isOptimizationAspiration(scenarioAspiration)
  ) {
    newWeek = {
      ...newWeek,
      final_spend: mediaMatch.final_spend,
      final_impression: mediaMatch.final_impression,
    };
  }
  return newWeek;
}

export function copySpendChange({
  target,
  media,
  results,
  changedFields,
  scenarioAspiration,
}) {
  const mediaTarget = targetToMediaTarget(target);
  const targetedMedia = media.filter(
    getItemGroupMatcher(mediaTarget, GridKeyEnum.Media)
  );
  const targetedResults = results.filter(
    getItemGroupMatcher(mediaTarget, GridKeyEnum.Results)
  );

  const resultsTargets = getResultsTargetsFromCombinedSource(targetedResults);

  const newResults = [];

  const mediaTargetWeekMap = getTargetWeekMap(
    targetedMedia,
    resultsTargets,
    scenarioDataHelpers[ScenarioDataKeys.Media].channelKeys
  );

  const layeredResultsTargets = getLayeredTargets(resultsTargets);

  // For each group of results at the media level
  layeredResultsTargets.forEach((mediaResultsGroupTarget) => {
    const { subTargets } = mediaResultsGroupTarget;

    subTargets.forEach((resultsTarget) => {
      // Filter results to group
      const currentTargetedResults = targetedResults.filter(
        getItemGroupMatcher(resultsTarget, GridKeyEnum.Results)
      );

      // For each week in group
      currentTargetedResults.forEach((currentWeek) => {
        const mediaMatch =
          mediaTargetWeekMap[
            getNameFromTarget(
              currentWeek,
              scenarioDataHelpers[ScenarioDataKeys.Media].channelKeys
            )
          ][currentWeek.period];

        if (mediaMatch == null) return;

        newResults.push(
          getWeekWithSpendChangeCopy({
            week: currentWeek,
            mediaMatch,
            changedFields,
            scenarioAspiration,
          })
        );
      });
    });
  });
  return newResults;
}
