import ActionModal from "../../components/ActionModal/ActionModal";

function DeleteConfirmModal({isOpen, actionProps, close, handle}) {
  return (
    <ActionModal
      name="delete"
      open={isOpen}
      handleClose={close}
      title="Delete Scenario"
      description={`Are you sure you want to delete "${actionProps?.node.data.name}"?`}
      primaryButton={{
        text: "Delete",
        onClick: () => {
          handle(actionProps);
          close();
        },
        color: "error",
      }}
      secondaryButton={{ text: "Cancel", onClick: close }}
    />
  );
}

export default DeleteConfirmModal;