import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ScenarioDataKeys } from "../../../scenarioDataUtils/scenarioDataUtils";
import { setSeriesProperties } from "../../../utils/am5ChartUtils";
import { targetContains } from "../../../utils/channelUtils";
import { selectMediaHierarchy } from "../../../app/scenarioSlice";
import { getNameWithoutDuplicates } from "../../../utils/targetUtils";

export const ChartSeriesEnum = {
  Profit: "profit",
  MarginalRoi: "marginalRoi",
  Contribution: "contribution",
  Roi: "roi",
};

export const ChartRangesEnum = {
  StartingSpend: "starting_spend",
  FinalSpend: "final_spend",
  ProfitMax: "max_profit_spend",
  UnderInvestment: "underInvestment",
  OverInvestment: "overInvestment",
};

export const UnderOverKey = "underOver";

export const sliceName = "curvesChart";

function getTargetName(target, mediaHierarchy) {
  return getNameWithoutDuplicates(target || {}, mediaHierarchy).trim();
}

const initialSeriesProperties = {
  [ChartSeriesEnum.Profit]: {
    isVisible: true,
  },
  [ChartSeriesEnum.MarginalRoi]: {
    isVisible: true,
  },
  [ChartSeriesEnum.Contribution]: {
    isVisible: false,
  },
  [ChartSeriesEnum.Roi]: {
    isVisible: false,
  },
  [ChartRangesEnum.StartingSpend]: {
    isVisible: true,
  },
  [ChartRangesEnum.FinalSpend]: {
    isVisible: true,
  },
  [ChartRangesEnum.ProfitMax]: {
    isVisible: true,
  },
  [UnderOverKey]: {
    isVisible: true,
  },
};

const initialSeriesData = {};

export const slice = createSlice({
  name: sliceName,
  initialState: {
    seriesProperties: initialSeriesProperties,
    seriesData: initialSeriesData,
  },
  reducers: {
    setSeriesProperty: (state, { payload }) =>
      setSeriesProperties(state, payload),
    batchSetSeriesProperty: (state, { payload }) => {
      payload.forEach((propertyChange) =>
        setSeriesProperties(state, propertyChange)
      );
    },
    addSeriesData: (state, { payload: data }) => {
      state.seriesData[data.name] = { ...data };
      state.seriesData[data.name].isValid = true;
    },
    removeSeriesData: (state, { payload: key }) => {
      state.seriesData[key].isValid = false;
    },
    updateSeriesDataValidation: (
      state,
      { payload: { key, target, changedFields } }
    ) => {
      Object.values(state.seriesData).forEach((o) => {
        if (
          key === ScenarioDataKeys.Sales &&
          changedFields.includes("starting_sales")
        ) {
          o.isValid = false;
          return;
        }
        if (
          targetContains(target, o.target) ||
          targetContains(o.target, target)
        ) {
          o.isValid = false;
        }
      });
    },
    setTargetInfo: (state, { payload }) => {
      state.targets = {
        targetMap: payload.targetMap,
        currentTarget: payload.currentTarget,
      };
    },
    setCurrentTarget: (state, { payload }) => {
      state.targets.currentTarget = payload;
    },
    reinitialize: (state, { payload: currentScenarioId }) => {
      if (state.scenarioId == null) {
        state.scenarioId = currentScenarioId;
        return;
      }
      if (state.scenarioId !== currentScenarioId) {
        return {
          scenarioId: currentScenarioId,
          seriesProperties: state.seriesProperties,
          seriesData: initialSeriesData,
        };
      }
    },
  },
});

export const {
  setSeriesProperty,
  batchSetSeriesProperty,
  addSeriesData,
  removeSeriesData,
  updateSeriesDataValidation,
  setTargetInfo,
  setCurrentTarget,
  reinitialize,
} = slice.actions;

export const selectCurvesChartSeriesProperties = (state) =>
  state[sliceName].seriesProperties;

const selectSeriesData = (state) => state[sliceName].seriesData;

const selectTargets = (state) => state[sliceName].targets;

export const selectCurvesChartData = createSelector(
  [selectSeriesData, selectTargets, selectMediaHierarchy],
  (seriesData, targets, mediaHierarchy) =>
    mediaHierarchy != null
      ? seriesData[getTargetName(targets?.currentTarget, mediaHierarchy)]
      : null
);
export const selectTargetInfo = createSelector(
  [selectTargets, selectMediaHierarchy],
  (targets, mediaHierarchy) => ({
    targetMap: targets?.targetMap || {},
    currentTarget: targets?.currentTarget || {},
    currentTargetName:
      mediaHierarchy != null
        ? getTargetName(targets?.currentTarget, mediaHierarchy)
        : null,
  })
);
export const selectValidSeriesDataKeys = createSelector(
  [selectSeriesData],
  (seriesData) =>
    Object.values(seriesData).reduce(
      (acc, v) => acc.concat(v.isValid ? v.name : []),
      []
    )
);

export default slice.reducer;
