import { Box, InputLabel, Slider } from "@mui/material";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getShortTarget,
  optimizationInfinity,
} from "../../../scenarioDataUtils/scenarioDataUtils";
import { SliderModeEnum, SliderSourceTypeEnum } from "./Sliders";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfinity } from "@fortawesome/free-solid-svg-icons";
import { bigMoneyFormatter } from "../../ChartUtils";

import classes from "./Sliders.module.css";
import { useDebouncedCallback } from "use-debounce";
import { objectMap } from "../../../utils/objectUtil";
import { selectScenario } from "../../../app/scenarioSlice";
import { useSelector } from "react-redux";
import { getNameWithoutDuplicates } from "../../../utils/targetUtils";

const optimizationThumbSize = 18; // px. Matches value in Sliders.module.css

const optimizationLabelExtraVerticalPadding = 4;

const sliderScaleMin = 0;
// too large (10000) and there are rounding errors on optimization infinity
// too small and there is not enough granularity to select to the tenths place
// without the snapping around zero, every value can have a point with 401 (for constraints sliders -20% to +20%)
// (20 + 20) * 10 + 1. +1 for zero
const sliderScaleMax = 400;
const sliderScaleStep = 1;
const clampToSliderRange = (v) =>
  Math.min(Math.max(v, sliderScaleMin), sliderScaleMax);

const labelSuffix = "Spend";

function handelEqualBounds(sliderBounds, field, v) {
  if (sliderBounds[0] !== sliderBounds[1]) return v;
  return field === "lower_spend" ? -Infinity : Infinity;
}

function SpendSlider({
  sliderMode,
  sliderSourceType,
  spendGroup,
  spendGroupIsFetching,
  update,
  onSpendChange,
  getSliderBounds,
}) {
  const { isScenarioReady, mediaHierarchy } = useSelector(selectScenario);

  const [sliderBounds, setSliderBounds] = useState([0, 0]);
  useEffect(() => {
    if (spendGroupIsFetching) return;
    setSliderBounds(getSliderBounds(spendGroup));
  }, [spendGroupIsFetching, getSliderBounds, spendGroup]);

  const sliderKeys = useMemo(() => {
    if (sliderSourceType === SliderSourceTypeEnum.Spend) return ["final_spend"];
    if (sliderSourceType === SliderSourceTypeEnum.Constraint)
      return ["lower_spend", "upper_spend"];
    return [];
  }, [sliderSourceType]);

  const scaledSliderValuesBase = useMemo(
    () =>
      sliderKeys.reduce(
        (acc, k) => ({
          ...acc,
          [k]: handelEqualBounds(
            sliderBounds,
            k,
            scaleToSliderRange(spendGroup[k], ...sliderBounds)
          ),
        }),
        {}
      ),
    [sliderKeys, sliderBounds, spendGroup]
  );

  const isSliding = useRef(false);
  const [scaledSliderValues, setScaledSliderValues] = useState(null);

  useEffect(() => {
    if (isSliding.current || spendGroupIsFetching) return;
    setScaledSliderValues(scaledSliderValuesBase);
  }, [spendGroupIsFetching, scaledSliderValuesBase]);

  const name = useMemo(() => {
    if (spendGroup == null || !isScenarioReady) return null;
    return getNameWithoutDuplicates(spendGroup, mediaHierarchy);
  }, [isScenarioReady, spendGroup, mediaHierarchy]);

  const label = useMemo(
    () => (name != null ? name + " " + labelSuffix : ""),
    [name]
  );

  const marks = useMemo(() => {
    return [
      ...[0, 1].map((i) => ({
        value: i * (sliderScaleMax - sliderScaleMin),
        label: formatSlidersLabel(
          handelEqualBounds(
            sliderBounds,
            i === 0 ? "lower_spend" : "upper_spend",
            sliderBounds[i]
          ),
          true
        ),
      })),
      ...(sliderMode === SliderModeEnum.Percent
        ? [
            {
              value: 0.5 * (sliderScaleMax - sliderScaleMin),
              label: "0%",
              // scaledSnapRange: 0.25 * (sliderScaleMax - sliderScaleMin),
              snapRange: 0.5, // 0.5%
            },
          ]
        : []),
    ];
  }, [sliderMode, sliderBounds]);

  const [sliderElement, setSliderElement] = useState(null);

  const [
    optimizationSliderContainerWidth,
    setOptimizationSliderContainerWidth,
  ] = useState(0);
  const recordSliderContainerWidthChange = useDebouncedCallback(
    (value) => setOptimizationSliderContainerWidth(value),
    250
  );

  useEffect(() => {
    if (!sliderElement) return;
    const updateWidth = () => {
      recordSliderContainerWidthChange(sliderElement.offsetWidth);
    };
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [scaledSliderValues, sliderElement]);

  const optimizationSliderLabelPositioning = useMemo(() => {
    if (
      sliderSourceType !== SliderSourceTypeEnum.Constraint ||
      scaledSliderValues == null
    )
      return null;

    const slidersHorizontalPadding = optimizationThumbSize;
    // const extraShift = 8;
    const extraShift = 0;

    const sliderWidth = sliderElement?.offsetWidth;
    const sliderScaledRange = sliderScaleMax - sliderScaleMin;
    const spendPctFromEdge = {
      lower:
        clampToSliderRange(scaledSliderValues.lower_spend) / sliderScaledRange,
      upper:
        (sliderScaledRange -
          clampToSliderRange(scaledSliderValues.upper_spend)) /
        sliderScaledRange,
    };

    function getLabelSelector(thumb) {
      return `.MuiSlider-thumb[data-index="${thumb}"] .MuiSlider-valueLabel`;
    }

    const labelMeasurements = ["lower", "upper"].reduce((acc, k, i) => {
      if (!sliderElement)
        return {
          ...acc,
          [k]: null,
        };
      const labelElement = sliderElement.querySelector(getLabelSelector(i));
      const labelWidth = labelElement.offsetWidth;

      const pxFromSliderEdge = spendPctFromEdge[k] * sliderWidth;
      const pxFromSliderContainerEdge =
        pxFromSliderEdge + slidersHorizontalPadding;

      const shouldUseEdgePositioning = pxFromSliderContainerEdge <= labelWidth;

      return {
        ...acc,
        [k]: {
          labelElement,
          labelWidth,
          pxFromSliderEdge,
          pxFromSliderContainerEdge,
          shouldUseEdgePositioning,
        },
      };
    }, {});

    const result = objectMap(labelMeasurements, ([k, currentLabel], i, arr) => {
      let xTranslate = 0;
      const yTranslate = -(
        2 * optimizationThumbSize +
        optimizationLabelExtraVerticalPadding
      );
      if (currentLabel != null) {
        const [, otherLabel] = arr.at(i - 1);
        const pxFromOther =
          sliderWidth -
          currentLabel.pxFromSliderEdge -
          otherLabel.pxFromSliderEdge;

        const edgeAlignShiftPx =
          currentLabel.pxFromSliderContainerEdge + extraShift;

        const pxFromOtherLabel =
          -otherLabel.labelWidth +
          otherLabel.pxFromSliderContainerEdge +
          pxFromOther;

        // other label is edge and the labels are overlapping
        const shouldUseOtherEdgePositioning =
          otherLabel.shouldUseEdgePositioning && pxFromOtherLabel < 0;

        const defaultPosition = k === "upper" ? "50%" : "-50%";
        const edgePosition = `calc(${k === "upper" ? "-50%" : "50%"} ${
          k === "upper" ? "+" : "-"
        } ${edgeAlignShiftPx}px)`;
        const otherEdgePosition = `calc(${defaultPosition} ${
          k === "upper" ? "-" : "+"
        } ${pxFromOtherLabel + (k === "upper" ? 0 : 1)}px)`;

        // console.log(
        //   k,
        //   currentLabel.shouldUseEdgePositioning,
        //   edgePosition,
        //   shouldUseOtherEdgePositioning,
        //   otherEdgePosition,
        //   defaultPosition
        // );

        xTranslate = currentLabel.shouldUseEdgePositioning
          ? edgePosition
          : shouldUseOtherEdgePositioning
          ? otherEdgePosition
          : defaultPosition;
      }

      const style = {
        [getLabelSelector(i)]: {
          transform: `translate(${xTranslate}, ${yTranslate}px)`,
        },
      };

      return [k, style];
    });

    // console.log(result);
    return result;
  }, [scaledSliderValues, sliderElement, optimizationSliderContainerWidth]);

  function percentToSpend(value) {
    const baseValue = spendGroup.starting_spend;
    return value * baseValue + baseValue;
  }

  function spendToPercent(value) {
    const baseValue = spendGroup.starting_spend;
    // const percentNumber = Math.round((newValue / baseValue) * 100);
    // return percentNumber + "%";
    return (value - baseValue) / baseValue;
  }

  function formatSlidersLabel(value, ignoreSubstitutions = false) {
    if (
      !ignoreSubstitutions &&
      sliderSourceType === SliderSourceTypeEnum.Constraint &&
      // should be checking for value equal to zero but sometimes it's a bit off
      // so include less than 1 cent as zero
      // TODO investigate why
      ((value >= 0 && value < 0.01) || value === optimizationInfinity)
    ) {
      return "Free";
    }
    // console.log(value);
    if (sliderMode === SliderModeEnum.Percent) {
      const baseValue = spendGroup.starting_spend;

      if (
        (!ignoreSubstitutions && baseValue < 1) ||
        Math.abs(value) === Infinity
      ) {
        return (
          <span>
            {Math.sign(value) === 1 ? "+" : "-"}
            <FontAwesomeIcon icon={faInfinity} />%
          </span>
        );
      }

      const percentNumber =
        Math.round(spendToPercent(value, spendGroup) * 1000) / 10;
      // console.log(spendToPercent(value, spendGroup), percentNumber);
      if (percentNumber > 100) {
        // return ((Math.round((percentNumber) / 10) / 10) + 1) + "X";
        return <span>{Math.round(percentNumber / 10) / 10 + 1} x</span>;
      }
      return (percentNumber >= 0 ? "+" : "-") + Math.abs(percentNumber) + "%";
    }
    return bigMoneyFormatter.format(value);
  }

  function adjustScaledValue(scaledValue) {
    const markSnapped = snapValueToMarks(scaledValue);
    const stepSnapped = snapValueToStep(markSnapped);
    return clampToSliderRange(stepSnapped);
  }

  function snapValueToMarks(scaledValue) {
    let snappedScaledValue = scaledValue;
    marks.forEach(({ value: scaledMarkValue, scaledSnapRange }) => {
      if (!scaledSnapRange) return;
      // console.log(scaledMarkValue, scaledValue, scaledSnapRange);
      if (Math.abs(scaledValue - scaledMarkValue) < scaledSnapRange) {
        snappedScaledValue = scaledMarkValue;
      }
    });
    marks.forEach(({ value: scaledMarkValue, snapRange }) => {
      if (!snapRange) return;
      const categoryValue = scaleToCategoryRange(scaledValue, ...sliderBounds);
      const markValue = scaleToCategoryRange(scaledMarkValue, ...sliderBounds);
      const markCompareValue =
        sliderMode === SliderModeEnum.Percent
          ? spendToPercent(markValue, spendGroup) * 100
          : markValue;
      const currentCompareValue =
        sliderMode === SliderModeEnum.Percent
          ? spendToPercent(categoryValue, spendGroup) * 100
          : categoryValue;
      // console.log(scaledMarkValue, categoryValue, markValue, markCompareValue, snapRange, currentCompareValue, Math.abs(currentCompareValue - markCompareValue) < snapRange);
      if (Math.abs(currentCompareValue - markCompareValue) < snapRange) {
        snappedScaledValue = scaledMarkValue;
      }
    });
    return snappedScaledValue;
  }

  function snapValueToStep(scaledValue) {
    const percentStep = 0.001;
    const dollarStep = 1000;
    let adjustedCategoryValue = scaleToCategoryRange(
      scaledValue,
      ...sliderBounds
    );
    const snapValue = (v, s) => Math.round(v / s) * s;

    if (sliderMode === SliderModeEnum.Percent) {
      const percentValue = spendToPercent(adjustedCategoryValue);
      const snappedPercent = snapValue(percentValue, percentStep);
      adjustedCategoryValue = percentToSpend(snappedPercent);
    } else {
      adjustedCategoryValue = snapValue(adjustedCategoryValue, dollarStep);
    }

    const snappedScaledValue = scaleToSliderRange(
      adjustedCategoryValue,
      ...sliderBounds
    );
    // console.log(
    //   scaledValue,
    //   scaleToCategoryRange(
    //     scaledValue,
    //     ...spendGroup.sliderBounds
    //   ),
    //   adjustedCategoryValue,
    //   snappedScaledValue
    // );
    return snappedScaledValue;
  }

  const [isSpendGroupSynced, setIsSpendGroupSynced] = useState(false);
  if (isSliding.current && isSpendGroupSynced) {
    setIsSpendGroupSynced(false);
  }
  useEffect(() => {
    if (!isSliding.current) {
      setIsSpendGroupSynced(true);
    }
  }, [spendGroup]);

  const sliderValue = useMemo(() => {
    if (!sliderKeys || !scaledSliderValues) return null;
    return sliderKeys.reduce((acc, k) => {
      const v = scaledSliderValues[k];
      if (sliderKeys.length === 1) return v;
      return acc.concat(v);
    }, []);
  }, [sliderKeys, scaledSliderValues]);

  if (sliderValue == null) return <></>;

  return (
    <Box sx={{ py: 1 }}>
      <InputLabel
        shrink
        sx={{
          pb:
            4 +
            (sliderSourceType === SliderSourceTypeEnum.Constraint
              ? optimizationLabelExtraVerticalPadding / 8
              : 0),
          width: "133%",
          overflow: "visible",
          whiteSpace: "normal",
        }}
      >
        {label}
      </InputLabel>
      <Slider
        ref={(element) => setSliderElement(element)}
        value={sliderValue}
        min={sliderScaleMin}
        // step={Math.min(1000, constraintsData.max / 10)}
        step={sliderScaleStep}
        max={sliderScaleMax}
        scale={(v) => scaleToCategoryRange(v, ...sliderBounds)}
        disabled={
          Array.isArray(sliderBounds) && sliderBounds[0] === sliderBounds[1]
        }
        valueLabelDisplay={"on"}
        valueLabelFormat={(_, thumb) => {
          const key = sliderKeys[thumb];
          let value;
          if (!isSliding.current && isSpendGroupSynced) {
            // Use the real value
            // TODO fix flash of percent
            value = spendGroup[key];
          } else {
            // Calculate the value from the slider value.
            // Can have floating point issues.
            // But floating point issues only matter when it's a substitution value
            // which only matters when not sliding.
            value =
              Math.abs(scaledSliderValues[key]) === Infinity
                ? scaledSliderValues[key]
                : scaleToCategoryRange(
                    scaledSliderValues[key],
                    ...sliderBounds
                  );
          }
          const ignoreSubstitutions =
            sliderSourceType === SliderSourceTypeEnum.Constraint &&
            key === "upper_spend" &&
            value === 0;
          return formatSlidersLabel(value, ignoreSubstitutions);
        }}
        marks={marks}
        onChange={(_, newValue, thumb) => {
          isSliding.current = true;
          // convert the value to a flat array so can always be treated the same as range sliders
          const newAdjustedScaledValues = []
            .concat(newValue)
            .map((v) => adjustScaledValue(v));
          // console.log(shortName, newAdjustedScaledValues, sliderKeys, thumb);
          setScaledSliderValues((prevState) => ({
            ...prevState,
            [sliderKeys[thumb]]: newAdjustedScaledValues[thumb],
          }));
          onSpendChange({
            [sliderKeys[thumb]]: scaleToCategoryRange(
              newAdjustedScaledValues[thumb],
              ...sliderBounds
            ),
          });
        }}
        onChangeCommitted={() => {
          isSliding.current = false;
          update({
            target: getShortTarget(spendGroup, mediaHierarchy),
            updateMap: objectMap(scaledSliderValues, ([k, v]) => [
              k,
              scaleToCategoryRange(v, ...sliderBounds),
            ]),
          });
        }}
        {...(sliderSourceType === SliderSourceTypeEnum.Constraint && {
          className: classes.optimizationSlider,
          sx: Object.assign(
            {},
            ...["lower", "upper"].map(
              (field) => optimizationSliderLabelPositioning?.[field]
            )
          ),
          disableSwap: true,
          getAriaLabel: (thumb) =>
            name + " " + ["Lower", "Upper"][thumb] + " " + labelSuffix,
        })}
        {...(sliderSourceType === SliderSourceTypeEnum.Spend && {
          className: classes.slider,
          "aria-label": label,
          ...(sliderMode === SliderModeEnum.Percent && { track: false }),
        })}
      />
    </Box>
  );
}

const linearCurve = {
  toCategoryRange: (normalizedInput) => normalizedInput,
  toSliderRange: (normalizedInput) => normalizedInput,
};

const linearPiecewiseCurve = {
  toCategoryRange: (normalizedInput) => {
    if (normalizedInput < 0.4) return 0.5 * normalizedInput;
    else if (normalizedInput < 0.8) return normalizedInput - 0.5 * 0.4;
    return 2 * normalizedInput - 1.5 * 0.4 - 0.4;
  },
  toSliderRange: (normalizedInput) => {
    if (normalizedInput < 0.2) return 2 * normalizedInput;
    else if (normalizedInput < 0.6) return normalizedInput + 0.5 * 0.4;
    return 0.5 * normalizedInput + 0.5 * 0.2 + 0.4;
  },
};

// toCategoryRange is the inverse of toSliderRange.
const scaleCurve = linearCurve;
// const scaleCurve = linearPiecewiseCurve;

/**
 * Scale a slider value to a category value.
 * Uses `scaleCurve` to curve the scale.
 *
 * @param {Number} v The value to scale.
 * @param {Number} start The bottom of the range of the sliders data.
 * @param {Number} stop The top of the range of the sliders data.
 * @returns The value after being curved and scaled back to a dollar amount.
 */
const scaleToCategoryRange = (v, start, stop) => {
  const normalizedInput =
    (v - sliderScaleMin) / (sliderScaleMax - sliderScaleMin);
  const curvedResult = scaleCurve.toCategoryRange(normalizedInput);
  const scaledResult = curvedResult * (stop - start) + start;
  // console.log(
  //   "scaleToCategoryRange",
  //   v,
  //   start,
  //   stop,
  //   normalizedInput,
  //   curvedResult,
  //   scaledResult
  // );
  return scaledResult;
};

/**
 * Scale a category value to a category value.
 * Uses `scaleCurve` to curve the scale.
 * Constraint: `stop` > `start`
 *
 * @param {Number} v The value to scale.
 * @param {Number} start The bottom of the range of the sliders data.
 * @param {Number} stop The top of the range of the sliders data.
 * @returns The value after being curved and scaled to a slider position.
 */
const scaleToSliderRange = (v, start, stop) => {
  const normalizedInput = (v - start) / (stop - start);
  const curvedResult = scaleCurve.toSliderRange(normalizedInput);
  const scaledResult =
    curvedResult * (sliderScaleMax - sliderScaleMin) + sliderScaleMin;
  // console.log("scaleToSliderRange", v, start, stop, normalizedInput, curvedResult, scaledResult);
  return scaledResult;
};

export default SpendSlider;
