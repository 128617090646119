import { combineReducers, configureStore } from "@reduxjs/toolkit";
import undoable, { excludeAction } from "redux-undo";
import curvesChartApi from "../containers/MyResults/CurvesChart/curvesChartApi";
import curvesChartReducer, {
  sliceName as curvesChartSliceName,
} from "../containers/MyResults/CurvesChart/curvesChartSlice";
import planChartReducer, {
  sliceName as planChartSliceName,
} from "../containers/MyResults/PlanChart/planChartSlice";
import scenarioDataReducer, {
  sliceName as scenarioDataSliceName,
} from "../containers/MyResults/scenarioDataSlice";
import createScenarioApi from "../containers/NewScenarioWizard/createScenarioApi";
import newScenarioWizardReducer, {
  setChangeInSpendNoUndo,
  setGrowthTargetNoUndo,
  setMediaBudgetNoUndo,
} from "../containers/NewScenarioWizard/newScenarioWizardSlice";
import optimizationLevelSliderApi from "../features/OptimizationLevel/optimizationLevelApi";
import companyApi from "./companyApi";
import companyMediaBudgetApi from "./companyMediaBudgetApi";
import insightsGridStateApi from "./insightsGridStateApi";
import myAiApi from "./myAiApi";
import myAiSlice from "./myAiSlice";
import planChartApi from "./planChartApi";
import scenarioApi from "./scenarioApi";
import scenarioChannelsApi from "./scenarioChannelsApi";
import scenarioMetadataApi from "./scenarioMetadataApi";
import scenarioPeriodApi from "./scenarioPeriodApi";
import scenariosApi from "./scenariosApi";
import scenarioSlice, { changeScenarioListener } from "./scenarioSlice";
import userApi from "./userApi";
import curveCountSliderApi from "../features/CurveCount/curveCountApi";

const rootReducer = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [scenarioApi.reducerPath]: scenarioApi.reducer,
  [scenariosApi.reducerPath]: scenariosApi.reducer,
  [insightsGridStateApi.reducerPath]: insightsGridStateApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [companyMediaBudgetApi.reducerPath]: companyMediaBudgetApi.reducer,
  [scenarioChannelsApi.reducerPath]: scenarioChannelsApi.reducer,
  [scenarioMetadataApi.reducerPath]: scenarioMetadataApi.reducer,
  [scenarioPeriodApi.reducerPath]: scenarioPeriodApi.reducer,
  [optimizationLevelSliderApi.reducerPath]: optimizationLevelSliderApi.reducer,
  [createScenarioApi.reducerPath]: createScenarioApi.reducer,
  [curvesChartApi.reducerPath]: curvesChartApi.reducer,
  [planChartApi.reducerPath]: planChartApi.reducer,
  [myAiApi.reducerPath]: myAiApi.reducer,
  [curveCountSliderApi.reducerPath]: curveCountSliderApi.reducer,
  newScenarioWizard: undoable(newScenarioWizardReducer, {
    filter: excludeAction([
      setChangeInSpendNoUndo.type,
      setMediaBudgetNoUndo.type,
      setGrowthTargetNoUndo.type,
    ]),
  }),
  [planChartSliceName]: planChartReducer,
  [curvesChartSliceName]: curvesChartReducer,
  [scenarioDataSliceName]: scenarioDataReducer,
  [scenarioSlice.name]: scenarioSlice.reducer,
  [myAiSlice.name]: myAiSlice.reducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        userApi.middleware,
        scenarioApi.middleware,
        scenariosApi.middleware,
        insightsGridStateApi.middleware,
        companyApi.middleware,
        companyMediaBudgetApi.middleware,
        scenarioChannelsApi.middleware,
        scenarioMetadataApi.middleware,
        scenarioPeriodApi.middleware,
        optimizationLevelSliderApi.middleware,
        createScenarioApi.middleware,
        curvesChartApi.middleware,
        planChartApi.middleware,
        myAiApi.middleware,
        curveCountSliderApi.middleware,
      ]).prepend(changeScenarioListener.middleware),
    preloadedState,
  });
};

export function getUndoablePreloadedState(present, past = [], future = []) {
  return {
    past,
    present,
    future,
  };
}
