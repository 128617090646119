function objectListHelper(object, f) {
  return Object.fromEntries(f(Object.entries(object != null ? object : {})));
}

export function objectMap(object, f) {
  return objectListHelper(object, (entries) => entries.map(f));
}

export function objectFilter(object, f) {
  return objectListHelper(object, (entries) => entries.filter(f));
}
