import { isMediaKey, isSalesKey } from "../utils/targetUtils";
import {
  ALL_MEDIA_CHANNEL_KEYS,
  ALL_SALES_CHANNEL_KEYS,
} from "./CategoryDataUtils";

export const dateFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  timeZone: "UTC",
};

function compareNumbers(a, b, comparator) {
  const aMissing = typeof a !== "string";
  const bMissing = typeof b !== "string";
  if (aMissing && bMissing) {
    return null;
  } else if (aMissing) {
    return b;
  } else if (bMissing) {
    return a;
  }
  return comparator(a, b) ? b : a;
}

function minISODateString(a, b) {
  return compareNumbers(a, b, (a, b) => a > b);
}

function maxISODateString(a, b) {
  return compareNumbers(a, b, (a, b) => a < b);
}

export function periodAggFunction(intl, params) {
  const result = {
    min: null,
    max: null,
    toString: function () {
      const startData = new Date(this.min);
      if (this.min === this.max) {
        return dateFormatter(intl, { value: startData });
      }
      startData.setDate(startData.getDate() - 6); // set to the beginning of the week for ranges
      return (
        dateFormatter(intl, { value: startData }) +
        " - " +
        dateFormatter(intl, { value: this.max })
      );
    },
  };
  params.values.forEach(function (value) {
    const groupNode = value != null && typeof value === "object";
    const minValue = groupNode ? value.min : value;
    const maxValue = groupNode ? value.max : value;
    result.min = minISODateString(minValue, result.min);
    result.max = maxISODateString(maxValue, result.max);
  });
  return result;
}

export function combinedResultsAgg(params) {
  function buildResult(value) {
    return {
      field: params.rowNode.field,
      value,
      toString: () => value.toString(),
    };
  }
  function aggMedia() {
    return params.values.reduce((acc, value) => {
      const groupNode = value != null && typeof value === "object";
      return acc + ((groupNode ? value.value : value) || 0);
    }, 0);
  }
  function aggSales() {
    return Math.max(...params.values);
  }
  const valuesField = params.values.at(0)?.field;
  if (valuesField == null || isMediaKey(valuesField)) {
    return buildResult(aggMedia());
  } else if (isSalesKey(valuesField)) {
    return buildResult(aggSales());
  }
  return buildResult(0);
}

export function channelAggFunc(params) {
  const [firstUnique, ...otherUnique] = [...new Set(params.values)];
  if (otherUnique.length === 0 && firstUnique) return firstUnique;
  return null;
}

export function makeColStates(pinned, ...colStates) {
  return colStates.map((item) => ({
    hide: false,
    ...item,
    pinned,
  }));
}

export const dateFormatter = (intl, params) => {
  if (params.value != null && !params.node?.group) {
    return intl.formatDate(new Date(params.value), dateFormatOptions);
  }
  return params.value;
};

const simpleFormattersData = [
  {
    name: "currencyWithoutCents",
    options: {
      format: "currencyWithoutCents",
    },
  },
  {
    name: "currency",
    options: {
      format: "currency",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  {
    name: "decimalZero",
    options: {
      format: "decimalZero",
    },
  },
  {
    name: "decimalOnePossible",
    options: {
      format: "decimalOnePossible",
    },
  },
  {
    name: "decimalTwoPossible",
    options: {
      format: "decimalTwoPossible",
    },
  },
  {
    name: "decimalThreePossible",
    options: {
      format: "decimalThreePossible",
    },
  },
  {
    name: "percentZero",
    options: {
      format: "percentZero",
    },
  },
  {
    name: "percentOnePossible",
    options: {
      format: "percentOnePossible",
    },
  },
];

function addFormatterOptions(f) {
  // Makes the formatter return null if the given primative matches the value to format. Does not allow object matching
  f.hideOnValue = (hideValue, valueOnHide = null) => {
    return (params) => {
      if (params.value === hideValue) return valueOnHide;
      return f(params);
    };
  };
}

/**
 * Get an object with all aggrid formatters available.
 * @param {*} intl From the grids call to useIntl.
 * @returns Formatter function with options added on
 */
export function getFormatters(intl) {
  const result = {
    date: (params) => dateFormatter(intl, params),
    ...simpleFormattersData.reduce(
      (acc, { name, options }) => ({
        ...acc,
        [name]: (params) =>
          params.value != null
            ? intl.formatNumber(params.value, options)
            : null,
      }),
      {}
    ),
  };
  Object.values(result).forEach(addFormatterOptions);
  return result;
}

export function getMinWidth(text) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = "600 12px Roboto";
  return context.measureText(text).width + 80;
}
