const timeBetweenKeys = 2000;

const events = [];
const sequence = [
  "ArrowUp",
  "ArrowUp",
  "ArrowDown",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowLeft",
  "ArrowRight",
  "KeyB",
  "KeyA",
];
let sequenceProgress = 0;
let timeout;

export function addKonamiEvent(f) {
  events.push(f);
}

window.addEventListener("keydown", (e) => {
  if (sequence[sequenceProgress] === e.code) {
    sequenceProgress++;
    clearTimeout(timeout);
    if (sequenceProgress === sequence.length) {
      events.forEach((f) => f());
    }
  } else {
    sequenceProgress = 0;
  }
  timeout = setTimeout(() => {
    sequenceProgress = 0;
  }, timeBetweenKeys);
});
