import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import "../grids.css";

import classes from "./ScenarioGrid.module.css";

import {
  faClone,
  faFileExcel,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faChartBar,
  faChartLine,
  faEdit,
  // faPlayCircle,
  faRoute,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScenarioContext from "../../context/scenarioContext";
import UserContext from "../../context/userContext";
import {
  applyColState,
  applyRowState,
  setColState,
} from "../GridState";

import { useVariableValue } from "@devcycle/react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useCopyScenarioMutation,
  useDeleteScenarioMutation,
  useGetScenariosQuery,
  useImportScenarioMutation,
} from "../../app/scenariosApi";
import {
  addIsSwitchingScenario,
  selectScenario,
} from "../../app/scenarioSlice";
import { AspirationType } from "../../constants";
import exportScenario from "../../utils/exportScenario";
import CopyModal from "./CopyModal";
import DeleteConfirmModal from "./DeleteConfirmModal";
import EditModal from "./EditModal";
import RenameModal from "./RenameModal";
import { useSnackbar } from "notistack";
import { CloseRounded as CloseRoundedIcon } from "@mui/icons-material";

const headerHeight = 64;

function ScenarioGrid() {
  const editScenarioFlag = useVariableValue("edit-scenario", false);
  const importScenarioFlag = useVariableValue("import-scenario", false);

  const dispatch = useDispatch();

  const userContext = useContext(UserContext);
  const { id: scenarioId, userId, switchingToId } = useSelector(selectScenario);

  const {
    data: scenarios,
    isLoading: scenariosIsLoading,
    isSuccess: scenariosIsSuccess,
  } = useGetScenariosQuery(
    {
      userId: userContext.userID,
    },
    {
      skip: userContext.userID == null,
    }
  );

  const [copyScenario] = useCopyScenarioMutation();
  const [deleteScenario] = useDeleteScenarioMutation();
  const [importScenario] = useImportScenarioMutation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const importScenarioHandler = useCallback(
    async (file) => {
      const response = await importScenario({
        userId,
        file,
      });

      let { message, status: variant } =
        (response.error ?? response).data ?? {};

      if (message == null && response.error) {
        message = "Import failed: unexpected error";
        variant = "error";
        console.error(response);
      }

      enqueueSnackbar(message, {
        variant,
        autoHideDuration: variant === "success" ? 3000 : null,
        noCloseButton: variant === "success",
      });
    },
    [userId, enqueueSnackbar, closeSnackbar]
  );

  const navigate = useNavigate();
  const theme = useTheme();
  const scenarioContext = useContext(ScenarioContext);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  // stores the action as a key and a value of the set of all loading scenario ids
  const gridActionIsLoading = useRef({});

  const [filterData, setFilterData] = useState(
    Object.fromEntries(Object.values(AspirationType).map((v) => [v, true]))
  );

  const filterDataRef = useRef(filterData);
  useEffect(() => {
    filterDataRef.current = filterData;
    if (gridApi) gridApi.onFilterChanged();
  }, [gridApi, filterData]);

  const [deleteConfirmModalData, setDeleteConfirmModalData] = useState({
    isOpen: false,
    actionProps: null,
  });
  const closeDeleteConfirmModal = useCallback(
    () => setDeleteConfirmModalData({ isOpen: false, actionProps: null }),
    []
  );
  const openDeleteConfirmModal = useCallback(
    (props) => setDeleteConfirmModalData({ isOpen: true, actionProps: props }),
    []
  );

  const [copyModalData, setCopyModalData] = useState({
    isOpen: false,
    actionProps: null,
  });
  const closeCopyModal = useCallback(() => {
    setCopyModalData({ isOpen: false, actionProps: null });
  }, []);
  const openCopyModal = useCallback((props) => {
    setCopyModalData({ isOpen: true, actionProps: props });
  }, []);

  const [editModalData, setEditModalData] = useState({
    isOpen: false,
    actionProps: null,
  });
  const closeEditModal = useCallback(() => {
    setEditModalData({ isOpen: false, actionProps: null });
  }, []);
  const openEditModal = useCallback((props) => {
    setEditModalData({ isOpen: true, actionProps: props });
  }, []);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }, []);

  const colState = useMemo(
    () =>
      scenarioContext.scenario?.scenarios_col_state ?? [
        {
          colId: "ag-Grid-AutoColumn",
          hide: false,
          pinned: "left",
        },
        {
          colId: "aspiration",
          hide: false,
          pinned: "",
        },
        {
          colId: "name",
          hide: false,
          pinned: "",
        },
        {
          colId: "createdDate",
          hide: false,
          pinned: "",
        },
        {
          colId: "lastRunDate",
          hide: false,
          pinned: "",
        },
        {
          colId: "actions",
          hide: false,
          pinned: "",
        },
      ],
    [scenarioContext.scenario?.scenarios_col_state]
  );

  const intl = useIntl();

  const dateFormatter = useCallback(
    (params) => {
      if (!params.value) return null;
      return intl.formatDate(new Date(params.value), {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    [intl]
  );

  const detailedDateFormatter = useCallback(
    (params) => {
      if (!params.value) return null;
      return intl.formatDate(new Date(params.value), {
        dateStyle: "full",
        timeStyle: "long",
      });
    },
    [intl]
  );

  const agGridColumnItems = useMemo(
    () => [
      {
        headerName: "Type",
        field: "aspiration",
        cellRenderer: "typeCellRenderer",
        cellStyle: { textAlign: "left" },
        editable: false,
        width: 100,
      },
      {
        headerName: "Name",
        field: "name",
        cellStyle: { textAlign: "left" },
        editable: false,
        minWidth: 150,
        flex: 3,
      },
      {
        headerName: "Date Created",
        field: "createdDate",
        valueFormatter: dateFormatter,
        cellStyle: { textAlign: "left" },
        editable: false,
        minWidth: 150,
        flex: 1,
        cellClass: "dateUS",
      },
      {
        headerName: "Last Run",
        field: "lastRunDate",
        valueFormatter: dateFormatter,
        cellStyle: { textAlign: "left" },
        editable: false,
        minWidth: 120,
        flex: 1,
        cellClass: "dateUS",
      },
      {
        headerName: "Action",
        field: "actions",
        cellRenderer: "actionsCellRenderer",
        cellStyle: { textAlign: "left" },
        editable: false,
        minWidth: 150,
        flex: 2,
      },
    ],
    [dateFormatter]
  );

  const onFirstDataRendered = useCallback(
    (params) => {
      applyColState(params.columnApi, colState);
      applyRowState(
        params.api,
        scenarios.map((s) => ({
          selected: s.scenarioId === (switchingToId ?? scenarioId),
        }))
      );
    },
    [colState, scenarios, switchingToId, scenarioId]
  );

  useEffect(() => {
    if (gridApi == null || scenarios == null || scenariosIsLoading) return;
    let idsMatch = gridApi.getDisplayedRowCount() === scenarios.length;
    if (idsMatch) {
      gridApi.forEachNode((node, index) => {
        if (scenarios[index] == null) {
          // fix for when gird has rows that are not displayed which made the length pass when it shouldn't
          idsMatch = false;
          return;
        }
        if (node.data._id !== scenarios[index]._id) {
          idsMatch = false;
        }
      });
    }
    if (idsMatch) {
      gridApi.applyTransaction({ update: scenarios });
    } else {
      gridApi.setRowData(scenarios);
    }
    applyRowState(
      gridApi,
      scenarios.map((s) => ({
        selected: s.scenarioId === (switchingToId ?? scenarioId),
      }))
    );
  }, [gridApi, scenarios, scenariosIsLoading, switchingToId, scenarioId]);

  const onColumnMoved = useCallback(
    (params) => {
      setColState(params.columnApi, scenarioContext, "scenarios_col_state");
    },
    [scenarioContext]
  );

  const onColumnPinned = useCallback(
    (params) => {
      setColState(params.columnApi, scenarioContext, "scenarios_col_state");
    },
    [scenarioContext]
  );

  const onColumnVisible = useCallback(
    async (params) => {
      setColState(params.columnApi, scenarioContext, "scenarios_col_state");
    },
    [scenarioContext]
  );

  // useEffect(() => {
  //   updateColState(
  //     userId,
  //     scenarioId,
  //     "scenarios_col_state",
  //     scenarioContext.scenario?.scenarios_col_state
  //   );
  // }, [userId, scenarioId, scenarioContext.scenario?.scenarios_col_state]);

  const exportToExcel = useCallback(() => {
    gridApi.exportDataAsExcel({
      author: "Optimetry",
      fileName: "Scenarios",
      sheetName: "Scenarios",
      columnKeys: agGridColumnItems
        .map((o) => o.field)
        .filter((f) => f !== "actions"),
    });
  }, [gridApi, agGridColumnItems]);

  const getContextMenuItems = useCallback(() => {
    return [
      {
        name: "Export Excel",
        action: exportToExcel,
      },
      ...(importScenarioFlag
        ? [
            {
              name: "Import Scenario",
              action: () => {
                const input = document.createElement("input");
                input.accept =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                input.type = "file";
                input.onchange = () => importScenarioHandler(input.files[0]);
                input.click();
              },
            },
          ]
        : []),
    ];
  }, [exportToExcel, userId, importScenarioFlag]);

  const setLoading = useCallback((key, value, props) => {
    if (!gridActionIsLoading.current[key]) {
      gridActionIsLoading.current[key] = new Set();
    }
    if (value) {
      gridActionIsLoading.current[key].add(props.node.data._id);
    } else {
      gridActionIsLoading.current[key].delete(props.node.data._id);
    }
    props.api.refreshCells({ force: true, rowNodes: [props.node] });
  }, []);

  // add the first unused suffix: "", "(1)", "(2)", ...
  const handleDuplicateNameSuffix = useCallback((scenarios, name) => {
    let suffix = "";
    const usedSuffixes = scenarios.reduce((acc, s) => {
      if (s.name.startsWith(name)) {
        const end = s.name.replace(name, "").trim();
        if (end === "" || /\((\d+)\)/.exec(end) != null) {
          return acc.concat(end.replaceAll(/(\(|\))/g, ""));
        }
      }
      return acc;
    }, []);
    if (!usedSuffixes.includes("")) return name;
    const usedSuffixNumbers = new Set(
      usedSuffixes.map((s) => parseInt(s)).filter((n) => !isNaN(n) && n > 0)
    );
    for (let index = 0; index <= usedSuffixNumbers.size; index++) {
      if (!usedSuffixNumbers.has(index + 1)) {
        suffix = ` (${index + 1})`;
        break;
      }
    }
    return name + suffix;
  }, []);

  const handleEdit = useCallback(
    async (props, f) => {
      setLoading("edit", true, props);
      await f();
      setLoading("edit", false, props);
    },
    [setLoading]
  );

  const handleCopy = useCallback(
    async (props, copyName) => {
      setLoading("copy", true, props);

      const newName = handleDuplicateNameSuffix(scenarios, copyName);
      const newScenarioId = new Date().getTime();

      await copyScenario({
        userId: props.node.data.userId,
        scenarioId: props.node.data.scenarioId,
        newScenarioId,
        newName,
      });

      setLoading("copy", false, props);
    },
    [handleDuplicateNameSuffix, scenarios, setLoading, copyScenario]
  );

  const handleDelete = useCallback(
    async (props) => {
      setLoading("delete", true, props);
      await deleteScenario({
        userId: props.node.data.userId,
        scenarioId: props.node.data.scenarioId,
      });
      setLoading("delete", false, props);
    },
    [setLoading, deleteScenario]
  );

  const handleExport = useCallback(
    async (props) => {
      setLoading("export", true, props);

      await exportScenario({
        userId: props.node.data.userId,
        scenarioId: props.node.data.scenarioId,
      });

      setLoading("export", false, props);
    },
    [setLoading]
  );

  const TypeCellRenderer = React.memo(({ value }) => {
    const iconMap = {
      [AspirationType.Simulate]: faRoute,
      [AspirationType.Optimize]: faChartBar,
      [AspirationType.Grow]: faChartLine,
    };
    return (
      <FontAwesomeIcon
        icon={iconMap[value]}
        color={theme.palette[value].main}
        size="lg"
      />
    );
  });

  const ActionButton = React.memo(
    ({ label, icon, color = "#6C6C6C", ...buttonOptions }) => {
      return (
        <LoadingButton
          ref={(ref) => {
            if (!ref) return;
            ref.onclick = (e) => {
              e.stopPropagation();
              buttonOptions.onClick?.();
            };
            ref.ondblclick = (e) => e.stopPropagation();
          }}
          title={label}
          aria-label={label}
          color="primary"
          size="small"
          {...buttonOptions}
          sx={{ minWidth: "32px", aspectRatio: "1", borderRadius: "50%" }}
        >
          {!buttonOptions.loading ? (
            <FontAwesomeIcon icon={icon} color={color} size="lg" />
          ) : (
            <></>
          )}
        </LoadingButton>
      );
    }
  );

  const ActionsCellRenderer = React.memo((props) => {
    const fullExportFlag = useVariableValue("full-export", false);

    return (
      <>
        {/* <ActionButton
              label="Run"
              icon={faPlayCircle}
              onClick={(e) => {
                handleRun(props);
              }}
              loading={gridActionIsLoading.current.run?.has(props.node.data.id)}
            /> */}
        <ActionButton
          label="Edit"
          icon={faEdit}
          onClick={(e) => {
            openEditModal(props);
          }}
          loading={gridActionIsLoading.current.edit?.has(props.node.data._id)}
        />
        <ActionButton
          label="Duplicate"
          icon={faClone}
          onClick={(e) => {
            openCopyModal(props);
          }}
          loading={gridActionIsLoading.current.copy?.has(props.node.data._id)}
        />
        {fullExportFlag ? (
          <ActionButton
            label="Export"
            icon={faFileExcel}
            onClick={() => {
              handleExport(props);
            }}
            loading={gridActionIsLoading.current.export?.has(
              props.node.data._id
            )}
          />
        ) : (
          <></>
        )}
        <ActionButton
          label="Delete"
          icon={faTrashAlt}
          onClick={(e) => {
            openDeleteConfirmModal(props);
          }}
          loading={gridActionIsLoading.current.delete?.has(props.node.data._id)}
        />
      </>
    );
  });

  const FilterButton = React.memo(({ aspiration, text, width }) => {
    return (
      <Button
        className={classes.filterButton}
        sx={{
          width,
          m: 1,
          ...(filterData[aspiration]
            ? {
                "&:hover": {
                  backgroundColor: theme.palette[aspiration].hover,
                },
              }
            : {}),
        }}
        variant={filterData[aspiration] ? "contained" : "outlined"}
        color={aspiration}
        disableElevation
        onClick={() =>
          setFilterData((prevState) => ({
            ...prevState,
            [aspiration]: !prevState[aspiration],
          }))
        }
      >
        <Typography>{text}</Typography>
      </Button>
    );
  });

  return (
    <Grid
      container
      direction={"column"}
      sx={{ height: `calc(100vh - ${headerHeight}px - 24px)` }}
    >
      <Grid item>
        {/* <Typography sx={{ display: "inline-block", verticalAlign: "text-top" }}>Manage Scenarios</Typography> */}
        <Typography
          variant="h5"
          sx={{
            color: "rgba(0, 0, 0, 0.87)",
            marginBottom: 3,
            display: "inline-block",
          }}
        >
          {"Manage Scenarios"}
        </Typography>
        <Box sx={{ display: "inline-block", marginLeft: 3 }}>
          <FilterButton aspiration={AspirationType.Simulate} text="Simulate" />
          <FilterButton aspiration={AspirationType.Optimize} text="Optimize" />
          <FilterButton aspiration={AspirationType.Grow} text="Grow" />
        </Box>
      </Grid>
      <Grid item flexGrow="1">
        <Box
          id="scenarios-grid"
          className="ag-theme-material"
          sx={{ width: "calc(100% + 3rem)", height: "100%", mx: -3 }}
          {...(importScenarioFlag && {
            onDrop: (e) => {
              e.preventDefault();
              importScenarioHandler(e.dataTransfer.files[0]);
            },
            onDragOver: (e) => {
              // prevent download of file and fix drop not firing
              e.preventDefault();
            },
          })}
        >
          <AgGridReact
            defaultColDef={{
              resizable: true,
            }}
            columnDefs={agGridColumnItems}
            components={{
              actionsCellRenderer: ActionsCellRenderer,
              typeCellRenderer: TypeCellRenderer,
            }}
            enableGroupEdit={false}
            enableRangeSelection={false}
            fillHandleDirection={"y"}
            onColumnMoved={onColumnMoved}
            onColumnPinned={onColumnPinned}
            onColumnVisible={onColumnVisible}
            onFirstDataRendered={onFirstDataRendered}
            onGridReady={onGridReady}
            // isExternalFilterPresent={() => Object.values(filterData).some(v => v === false)}
            isExternalFilterPresent={() => true}
            doesExternalFilterPass={(node) => {
              return filterDataRef.current[node.data.aspiration];
            }}
            getRowId={function (params) {
              return params.data._id;
            }}
            rowSelection="single"
            onRowClicked={(params) => {
              dispatch(addIsSwitchingScenario({ id: params.data.scenarioId }));
            }}
            onRowDoubleClicked={(params) => {
              dispatch(addIsSwitchingScenario({ id: params.data.scenarioId }));
              navigate("/my-insights");
            }}
            suppressScrollOnNewData={true}
            suppressAggFuncInHeader={true}
            suppressCopyRowsToClipboard={true}
            suppressDragLeaveHidesColumns={true}
            suppressCellFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={20}
            getContextMenuItems={getContextMenuItems}
            excelStyles={[
              {
                id: "dateUS",
                dataType: "DateTime",
                numberFormat: { format: "m/d/yyyy" },
              },
            ]}
          />
        </Box>
      </Grid>

      <DeleteConfirmModal
        isOpen={deleteConfirmModalData.isOpen}
        actionProps={deleteConfirmModalData.actionProps}
        close={closeDeleteConfirmModal}
        handle={handleDelete}
      />
      <CopyModal
        isOpen={copyModalData.isOpen}
        actionProps={copyModalData.actionProps}
        close={closeCopyModal}
        handle={handleCopy}
      />
      {editScenarioFlag ? (
        <EditModal
          isOpen={editModalData.isOpen}
          actionProps={editModalData.actionProps}
          close={closeEditModal}
          handle={handleEdit}
        />
      ) : (
        <RenameModal
          isOpen={editModalData.isOpen}
          actionProps={editModalData.actionProps}
          close={closeEditModal}
          handle={handleEdit}
        />
      )}
    </Grid>
  );
}

export default ScenarioGrid;
