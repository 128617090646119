import { createSlice } from "@reduxjs/toolkit";
import { ScenarioDataKeys } from "../../scenarioDataUtils/scenarioDataUtils";

export const sliceName = "scenarioData";

export const slice = createSlice({
  name: sliceName,
  initialState: {
    persistance: {},
  },
  reducers: {
    addPersistanceEvent: (state, { payload }) => {
      state.persistance[payload.key] = {
        id: (state.persistance[payload.key]?.id || 0) + 1,
        target: payload.target,
        changedFields: payload.changedFields,
      };
    },
  },
});

export const { addPersistanceEvent } = slice.actions;

export const selectChangePersisted = (state) => state[sliceName].persistance;

export default slice.reducer;
