import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpointsUrl } from "../../app/endpoints";

const optimizationLevelSliderApi = createApi({
  reducerPath: "optimizationLevelSliderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: endpointsUrl,
  }),
  endpoints: (builder) => ({
    getOptimizationLevel: builder.query({
      query: ({ userId, scenarioId }) => ({
        url: "scenarios/getOptimizationLevel",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
        },
      }),
      transformResponse: (responseData) => responseData.data.optimizationLevel,
    }),
    setOptimizationLevel: builder.mutation({
      query: ({ userId, scenarioId, optimizationLevel }) => ({
        url: "scenarios/setOptimizationLevel",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          optimizationLevel,
        },
      }),
      async onQueryStarted(
        { userId, scenarioId, optimizationLevel },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          optimizationLevelSliderApi.util.updateQueryData(
            "getOptimizationLevel",
            { userId, scenarioId },
            (_draft) => optimizationLevel
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export default optimizationLevelSliderApi;

export const { useGetOptimizationLevelQuery, useSetOptimizationLevelMutation } =
  optimizationLevelSliderApi;
