import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { endpointsUrl } from "../../app/endpoints";

const curveCountApi = createApi({
  reducerPath: "curveCountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: endpointsUrl,
  }),
  endpoints: (builder) => ({
    getCurveCount: builder.query({
      query: ({ userId, scenarioId, aspiration, hierarchy, optimizationLevel }) => ({
        url: "scenarios/getCurveCount",
        method: "POST",
        body: {
          user_id: userId,
          scenario_id: scenarioId,
          aspiration,
          hierarchy,
          optimization_level: optimizationLevel,
        },
      }),
      transformResponse: (responseData) => responseData.data,
    }),
  }),
});

export default curveCountApi;

export const { useGetCurveCountQuery } =
  curveCountApi;
