import React, { useMemo } from "react";
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";

const TargetCellRenderer = ({ value }) => {
  const icon = useMemo(() => {
    if (value === true) {
      return <CheckIcon />;
    }
    if (value === false) {
      return <CloseIcon />;
    }
    return <RemoveIcon />;
  }, [value]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {icon}
    </Box>
  );
};

export default TargetCellRenderer;
