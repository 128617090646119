import { Box, styled, Zoom } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { parseDecimalInput } from "../../constants";
import { scenarioDataHelpers } from "../../scenarioDataUtils/scenarioDataUtils";
import { objectMap } from "../../utils/objectUtil";
import { getNodeData } from "./MediaGrid";

const ErrorTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    // boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

// https://www.ag-grid.com/archive/25.2.0/react-grid/component-cell-editor/#simple-cell-editor-example
// https://www.ag-grid.com/archive/29.3.5/react-data-grid/component-cell-editor/#simple-cell-editor-example
// https://www.ag-grid.com/archive/30.1.0/react-data-grid/component-cell-editor/#cell-editor-example

const KEY_BACKSPACE = "Backspace";
const KEY_DELETE = "Backspace";
const KEY_F2 = "F2";
const KEY_ENTER = "Enter";
const KEY_TAB = "Tab";
const KEY_LEFT = "ArrowLeft";
const KEY_RIGHT = "ArrowRight";

const ConstrainedNumberCellEditor = forwardRef((props, ref) => {
  // console.log(Fprops);
  const createInitialState = () => {
    let startValue;
    let highlightAllOnFocus = true;
    const { eventKey, charPress } = props;

    if (eventKey === KEY_BACKSPACE) {
      // if backspace pressed, we clear the cell
      startValue = "";
    } else if (charPress) {
      // if a letter was pressed, we start with the letter
      startValue = charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      let value = props.value;
      if (props.colDef.cellRenderer) {
        startValue =
          props.colDef.cellRenderer({ node: props.node, value }) || "";
      } else {
        startValue = value?.toString() || "";
      }
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: {
        string: startValue,
        float: parseDecimalInput(startValue).float,
      },
      highlightAllOnFocus,
    };
  };

  const initialState = useMemo(createInitialState, []);

  const [value, setValue] = useState(initialState.value);
  const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
    initialState.highlightAllOnFocus
  );
  const inputRef = useRef(null);
  const tooltipRef = useRef(null);

  const columnValidation = useMemo(() => {
    return scenarioDataHelpers[props.gridKey].getFieldConstraints(
      props.colDef.field
    );
  }, [props.gridKey, props.colDef.field]);

  const isValueAcceptable = useMemo(() => {
    // console.log("isValueAcceptable", props.node.group, props.colDef.field);
    return columnValidation.isValueValid(value.float, {
      data: objectMap(getNodeData(props.node), ([k, v]) => {
        return [k, v?.value != null ? v.value : v];
      }),
      stringValue: value.string,
    });
  }, [columnValidation, value, props.colDef.field, props.node]);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  useEffect(() => {
    if (!isValueAcceptable) {
      setTimeout(() => {
        setIsTooltipOpen(true);
      }, 0);
    }
    setIsTooltipOpen(false);
  }, [isValueAcceptable]);

  useLayoutEffect(
    () => () => {
      if (!tooltipRef.current) return;
      tooltipRef.current.style.display = "none";
    },
    []
  );

  const isCharNumeric = (char) => {
    return "1234567890-.".indexOf(char) !== -1;
  };

  const isDuplicateDecimal = (char) => {
    console.log(
      char,
      value.string,
      char === ".",
      value.string.indexOf(char) !== -1
    );
    return char === "." && value.string.indexOf(char) !== -1;
  };

  const cancelBeforeStart =
    props.charPress &&
    !isCharNumeric(props.charPress);

  const isLeftOrRight = (event) => {
    return [KEY_LEFT, KEY_RIGHT].indexOf(event.key) > -1;
  };

  const deleteOrBackspace = (event) => {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
  };

  const finishedEditingPressed = (event) => {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  };

  const onKeyDown = (event) => {
    if (isLeftOrRight(event) || deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (
      !finishedEditingPressed(event) &&
      (isDuplicateDecimal(event.key) || !isCharNumeric(event.key))
    ) {
      if (event.preventDefault) event.preventDefault();
    }

    if (finishedEditingPressed(event)) {
      props.stopEditing();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  // focus on the input
  useEffect(() => {
    // get ref from React component
    const eInput = inputRef.current;
    eInput.focus();
    if (highlightAllOnFocus) {
      eInput.select();

      setHighlightAllOnFocus(false);
    } else {
      // when we started editing, we want the caret at the end, not the start.
      // this comes into play in two scenarios:
      //   a) when user hits F2
      //   b) when user hits a printable character
      const length = eInput.value ? eInput.value.length : 0;
      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value.string;
      },

      isCancelBeforeStart() {
        return cancelBeforeStart;
      },

      isCancelAfterEnd() {
        return !isValueAcceptable;
      },
    };
  });

  // recreate the important parts of the default editor
  return (
    <Box className="ag-cell-edit-wrapper">
      <Box
        role="presentation"
        className="ag-cell-editor ag-text-field ag-input-field"
      >
        <Box
          role="presentation"
          className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
        >
          <ErrorTooltip
            open={isTooltipOpen}
            PopperProps={{ ref: tooltipRef }}
            title={columnValidation.valueConstraintsText}
            placement="top-start"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: { enter: 250, exit: 0 } }}
          >
            <input
              ref={inputRef}
              className="ag-input-field-input ag-text-field-input"
              type="text"
              aria-label="Input Editor"
              value={value.string}
              onChange={(event) => {
                setValue(
                  parseDecimalInput(
                    event.target.value,
                    props?.getNumDecimals?.(props.colDef.field)
                  )
                );
              }}
              style={{ width: "100%" }}
            />
          </ErrorTooltip>
        </Box>
      </Box>
    </Box>
  );
});

export default ConstrainedNumberCellEditor;
